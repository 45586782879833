import AboutUs from '@/components/additional/about-us.component';
import AntiScamPolicy from '@/components/additional/anti-scam-policy';
import ContactUs from '@/components/additional/contact-us';
import CookiePolicy from '@/components/additional/cookie-policy';
import FAQ from '@/components/additional/faq';
import PrivacyPolicy from '@/components/additional/privacy-policy';
import RefundPolicy from '@/components/additional/refund-policy';
import TermsOfUse from '@/components/additional/terms-of-use.component';
import ChatContainer from '@/components/chat-container';
import Chat from '@/components/chat-container/components/chat.component';
import EmptyChat from '@/components/chat-container/components/empty-chat.component';
import DrawerContainer from '@/components/drawer';
import ChatLayout from '@/components/layouts/chat.layout';
import MainLayout from '@/components/layouts/main.layout';
import SecureRoute from '@/hocs/secure-route.hoc';
import UnsecureRoute from '@/hocs/unsecured-route.hoc';
import UserPhotoAlbums from '@/pages/photo-albums';
import AlbumPhotoDirectory from '@/pages/photo-albums/components/photo-album-directory';
import UserVideoAlbums from '@/pages/video-albums';
import AlbumVideoDirectory from '@/pages/video-albums/components/video-album-directory';
import EditProfile from '@/pages/edit-profile';
import AboutProfile from '@/pages/edit-profile/components/about-profile.component';
import AppearanceProfile from '@/pages/edit-profile/components/appearance-profile.component';
import EditMainInfo from '@/pages/edit-profile/components/edit-main-info.component';
import PerfectWoman from '@/pages/edit-profile/components/perfect-woman.component';
import Home from '@/pages/home';
import Messages from '@/pages/messages';
import Notifications from '@/pages/notifications';
import News from '@/pages/news';
import MessagesHeader from '@/pages/messages/components/messages-header';
import SearchUsers from '@/pages/search-users';
import UserSettings from '@/pages/settings';
import AccountInfo from '@/pages/settings/components/general-info.component';
import UserProfile from '@/pages/user-profle';
import Guests from '@/pages/guests';
import Contacts from '@/pages/contacts';
import { BrowserRouter as Router, Outlet, Route, Routes } from 'react-router-dom';
import OrderHistory from '@/pages/order-history';
import Credits from '@/pages/credits';
import PaymentHistory from '@/pages/payment-history';
import UserNotifications from '@/pages/settings/components/user-notifications';
import Gifts from '@/pages/gifts';
import GiftsHeader from '@/pages/gifts/components/gifts-header';
import DialogLayout from '@/components/layouts/dialog.layout';
import DialogContainer from '@/components/dialog-container';
import EmptyDialog from '@/components/chat-container/components/empty-dialog.component';
import Dialog from '@/components/dialog-container/components/dialog.component';
import ComplaintPolicy from '@/components/additional/complaint-policy';
import CancellationPolicy from '@/components/additional/cancellation-policy';
import RedirectSignIn from '@/pages/auth-redirects-sign-in';
import Blacklist from '@/pages/blacklist';
import PriceList from '@/components/additional/price-list';
import EmailChangeInfo from '@/pages/settings/components/email-change-info.component';
import NotFoundPage from "@/pages/404";

import {
    ABOUT,
    ANTI_SCAM_POLICY,
    CANCELLATION_POLICY,
    CHAT,
    CHAT_ACTIVE,
    CHAT_ACTIVE_MESSAGE,
    CHAT_SINGLE,
    COMPLAINT_POLICY,
    CONTACTS,
    COOKIE_POLICY,
    DIALOG,
    DIALOG_ACTIVE,
    DIALOG_ACTIVE_MESSAGE,
    DIALOG_SINGLE,
    FAQ_PAGE,
    HOME,
    NOT_FOUND_PAGE,
    PRICE_LIST,
    PRIVACY_POLICY,
    REFUND_POLICY,
    TERMS_OF_USE,
    USER,
    USER_BLACKLIST,
    USER_CONTACTS,
    USER_CREDITS, USER_EDIT, USER_EDIT_ABOUT, USER_EDIT_APPEARANCE, USER_EDIT_PERFECT,
    USER_GIFTS, USER_GIFTS_KIND, USER_GIFTS_KIND_DETAIL,
    USER_GUESTS,
    USER_MAIL, USER_MESSAGES, USER_MESSAGES_DETAIL,
    USER_NEWS,
    USER_NOTIFICATIONS, USER_ORDER_HISTORY, USER_PAYMENTS,
    USER_PHOTO_ALBUMS,
    USER_PHOTOS,
    USER_PROFILE,
    USER_SEARCH, USER_SETTINGS, USER_SETTINGS_EMAIL, USER_SETTINGS_NOTIFICATIONS,
    USER_SIGN_IN_REDIRECT,
    USER_VIDEO_ALBUMS,
    USER_VIDEOS,
    USER_WALLET
} from "@/routes/routes";

export const MainRouter = () => {
  // const hasChildren = (route: any) => route.children && route.children.length;

  return (
    <Routes>
      <Route
        path={HOME}
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <Home />
              </MainLayout>
            )}
          />
        }
      ></Route>
      <Route
        path={TERMS_OF_USE}
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <TermsOfUse />
              </MainLayout>
            )}
          />
        }
      ></Route>
      <Route
        path={PRIVACY_POLICY}
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <PrivacyPolicy />
              </MainLayout>
            )}
          />
        }
      ></Route>
      <Route
        path={COMPLAINT_POLICY}
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <ComplaintPolicy />
              </MainLayout>
            )}
          />
        }
      ></Route>
      <Route
        path={ANTI_SCAM_POLICY}
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <AntiScamPolicy />
              </MainLayout>
            )}
          />
        }
      />
      <Route
        path={COOKIE_POLICY}
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <CookiePolicy />
              </MainLayout>
            )}
          />
        }
      />
      <Route
        path={FAQ_PAGE}
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <FAQ />
              </MainLayout>
            )}
          />
        }
      />
      <Route
        path={REFUND_POLICY}
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <RefundPolicy />
              </MainLayout>
            )}
          />
        }
      ></Route>
      <Route
        path={PRICE_LIST}
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <PriceList />
              </MainLayout>
            )}
          />
        }
      />
      <Route
        path={CANCELLATION_POLICY}
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <CancellationPolicy />
              </MainLayout>
            )}
          />
        }
      ></Route>

      <Route
        path={ABOUT}
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <AboutUs />
              </MainLayout>
            )}
          />
        }
      ></Route>
      <Route
        path={CONTACTS}
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <ContactUs />
              </MainLayout>
            )}
          />
        }
      />
      <Route
        path={CHAT}
        element={
          <SecureRoute
            component={() => (
              <ChatLayout>
                <ChatContainer>
                  <Outlet />
                </ChatContainer>
              </ChatLayout>
            )}
          />
        }
      >
        <Route path={CHAT} element={<EmptyChat />} />
        <Route path={CHAT_SINGLE} element={<EmptyChat />} />
        <Route path={CHAT_ACTIVE} element={<Chat />} />
        <Route path={CHAT_ACTIVE_MESSAGE} element={<Chat />} />
      </Route>
      <Route
        path={DIALOG}
        element={
          <SecureRoute
            component={() => (
              <DialogLayout>
                <DialogContainer>
                  <Outlet />
                </DialogContainer>
              </DialogLayout>
            )}
          />
        }
      >
        <Route path={DIALOG} element={<EmptyDialog />} />
        <Route path={DIALOG_SINGLE} element={<EmptyDialog />} />
        <Route path={DIALOG_ACTIVE} element={<Dialog />} />
        <Route path={DIALOG_ACTIVE_MESSAGE} element={<Dialog />} />
      </Route>
      <Route
        path={USER}
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <DrawerContainer>
                  <Outlet />
                </DrawerContainer>
              </MainLayout>
            )}
          />
        }
      >
        <Route path={USER_SEARCH} element={<SearchUsers />} />
        <Route path={USER_WALLET} element={<SearchUsers />} />
        <Route path={USER_PROFILE} element={<UserProfile />} />
        <Route path={USER_PHOTO_ALBUMS} element={<UserPhotoAlbums />} />
        <Route path={USER_PHOTOS} element={<AlbumPhotoDirectory />} />
        <Route path={USER_VIDEO_ALBUMS} element={<UserVideoAlbums />} />
        <Route path={USER_VIDEOS} element={<AlbumVideoDirectory />} />
        <Route path={USER_CREDITS} element={<Credits />} />

        <Route path={USER_SIGN_IN_REDIRECT} element={<RedirectSignIn />} />
        <Route path={USER_MAIL} element={<div>mail</div>} />
        <Route path={USER_CONTACTS} element={<Contacts />} />
        <Route path={USER_GUESTS} element={<Guests />} />
        <Route path={USER_GIFTS} element={<div>gifts</div>} />
        <Route path={USER_BLACKLIST} element={<Blacklist />} />
        <Route path={USER_NEWS} element={<News />} />
        <Route path={USER_NOTIFICATIONS} element={<Notifications />} />

        <Route path={USER_ORDER_HISTORY} element={<OrderHistory />} />
        <Route path={USER_PAYMENTS} element={<PaymentHistory />} />

        <Route path={USER_MESSAGES} element={<Messages />}>
          <Route index element={<MessagesHeader />} />
          <Route path={USER_MESSAGES_DETAIL} element={<MessagesHeader enableMessageLayout={true} />} />
        </Route>

        <Route path={USER_GIFTS_KIND} element={<Gifts />}>
          <Route index element={<GiftsHeader />} />
          <Route path={USER_GIFTS_KIND_DETAIL} element={<GiftsHeader enableGiftLayout={true} />} />
        </Route>

        <Route path={USER_EDIT} element={<EditProfile />}>
          <Route index element={<EditMainInfo />} />
          <Route path={USER_EDIT_ABOUT} element={<AboutProfile />} />
          <Route path={USER_EDIT_APPEARANCE} element={<AppearanceProfile />} />
          <Route path={USER_EDIT_PERFECT} element={<PerfectWoman />} />
        </Route>

        <Route path={USER_SETTINGS} element={<UserSettings />}>
          <Route index element={<AccountInfo />} />
          <Route path={USER_SETTINGS_EMAIL} element={<EmailChangeInfo />} />
          <Route path={USER_SETTINGS_NOTIFICATIONS} element={<UserNotifications />} />
        </Route>
      </Route>
      <Route path={NOT_FOUND_PAGE} element={<NotFoundPage />} />
    </Routes>
  );
};

export default MainRouter;
