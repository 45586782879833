import clsx from "clsx";
import profilePicture from "@/assets/images/demo-avatar.jpeg";
import React, { memo } from "react";
import { useStyles } from "@/components/dialog-container/components/dialog-messages.component";
import { DialogAvatarProps } from "@/components/dialog-container/components/DialogAvatar/DialogAvatar.props";
import AvatarWithFallback from "@/components/AvatarWithFallback/AvatarWithFallback";

export const DialogAvatar = memo<DialogAvatarProps>(({
                                                       avatar,
                                                       isClient,
                                                     }) => {
  const classes = useStyles();

  return (
    <div className={classes.message}>
      <div className={clsx(
        classes.avatar,
        isClient ? classes.avatarLeft : classes.avatarRight,
        classes[isClient ? 'LeftBox' : 'RightBox'])}
      >
        <AvatarWithFallback src={avatar} className={classes.avatarImage} alt='' isUserPhoto={isClient} />
      </div>
    </div>
  )
})