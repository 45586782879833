import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { updateCurrentUserRequest } from "@/app/users/users.actions";
import { UserProfileDTO } from "@/app/users/users.reducer";
import BaseButton from "@/components/base/button.component";
import SecondaryTextField from "@/components/base/secondary-text-field.component";
import SelectTextField from "@/components/base/select-text-field.component";
import useStaffCheck from "@/hooks/useStaffCheck";
import { UserProfile } from "@/types/user-profile.types";
import {
  appearanceUserSchema,
  HEIGHT_MAX,
  HEIGHT_MIN,
  WEIGHT_MAX,
  WEIGHT_MIN,
} from "@/utils/validationSchemas";
import { FormControl, Slider, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Formik } from "formik";
import React, { Fragment, useEffect } from "react";
import { bodyList, eyeColorOptions, hairList } from "../edit.consts";

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    width: "100%",
  },
  formControl: {
    "& > $inputField:not(:first-child)": {
      marginTop: 20,
    },
  },
  saveButton: {
    width: "100%",
    maxWidth: 144,
    marginTop: 32,
  },
  inputAppendElement: {
    flexShrink: 0,
    padding: "0 6px",
    minWidth: 118,

    [theme.breakpoints.down("md")]: {
      minWidth: 80,
    },
  },

  inputField: {},
}));

const overrideStyles = {
  pageTitle: {
    marginBottom: 24,
  },
};

const AppearanceProfile = () => {
  useStaffCheck();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const userIsLoading = useAppSelector((state) => state.users.userIsLoading);

  const { profile: currentUser }: UserProfileDTO =
  useAppSelector((state) => state.users.currentUser) || {};

  const initialData = {
    hairColor: currentUser.hairColor,
    eyeColor: currentUser.eyeColor,
    body: currentUser.body,
    height: currentUser.height || HEIGHT_MIN,
    weight: currentUser.weight || WEIGHT_MIN,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Typography variant="h3" style={overrideStyles.pageTitle}>
        Appearance
      </Typography>
      <Formik
        initialValues={initialData}
        validateOnChange={false}
        validationSchema={appearanceUserSchema}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(updateCurrentUserRequest(values as UserProfile));
        }}
      >
        {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
          <form onSubmit={handleSubmit} className={classes.formContainer}>
            <FormControl fullWidth className={classes.formControl}>
              <div className={classes.inputField}>
                <SelectTextField
                  label="hairColor"
                  placeholder="Select hair color"
                  list={hairList}
                  setFieldValue={setFieldValue}
                  prepend={
                    <Typography
                      variant="body1"
                      fontWeight="400"
                      className={classes.inputAppendElement}
                    >
                      Hair color:
                    </Typography>
                  }
                  inputAppendElement={classes.inputAppendElement}
                />
              </div>
              <div className={classes.inputField}>
                <SelectTextField
                  label="eyeColor"
                  placeholder="Select eyes color"
                  list={eyeColorOptions}
                  setFieldValue={setFieldValue}
                  prepend={
                    <Typography
                      variant="body1"
                      fontWeight="400"
                      className={classes.inputAppendElement}
                    >
                      Eyes color:
                    </Typography>
                  }
                  inputAppendElement={classes.inputAppendElement}
                />
              </div>
              <div className={classes.inputField}>
                <SelectTextField
                  label="body"
                  placeholder="Select body type"
                  list={bodyList}
                  setFieldValue={setFieldValue}
                  prepend={
                    <Typography
                      variant="body1"
                      fontWeight="400"
                      className={classes.inputAppendElement}
                    >
                      Body type:
                    </Typography>
                  }
                  inputAppendElement={classes.inputAppendElement}
                />
              </div>
              <div
                className={classes.inputField}
                style={{ maxWidth: "100%", padding: "0 11px" }}
              >
                <Typography variant="body1">
                  Height:{" "}
                  <span
                    style={{ color: "#808080" }}
                  >{values.height || HEIGHT_MIN}</span>
                </Typography>

                <Slider
                  name="height"
                  min={HEIGHT_MIN}
                  max={HEIGHT_MAX}
                  step={0.1}
                  getAriaLabel={() => "height range"}
                  value={values.height || HEIGHT_MIN}
                  onChange={(event, value) => setFieldValue("height", value)}
                  valueLabelDisplay="auto"
                  disableSwap
                />
              </div>
              <div className={classes.inputField}>
                <SecondaryTextField
                  label="weight"
                  placeholder="lbs"
                  prepend={
                    <Typography
                      variant="body1"
                      fontWeight="400"
                      className={classes.inputAppendElement}
                    >
                      Weight:
                    </Typography>
                  }
                  onChange={handleChange}
                  type="number"
                  min={WEIGHT_MIN}
                  max={WEIGHT_MAX}
                />
              </div>
              <div style={{ marginTop: 32 }}>
                <BaseButton
                  loading={userIsLoading}
                  color="primary"
                  text="Save"
                  className={classes.saveButton}
                  // textStyle={classes.headerTextStyle}
                  onClick={() => handleSubmit()}
                />
              </div>
            </FormControl>
          </form>
        )}
      </Formik>
    </Fragment>
  );
};

export default AppearanceProfile;
