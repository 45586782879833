import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import StatusOnline from '@/components/base/statuses/status-online.component';
import { CircularProgress, Divider, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { formatCreatedAtDate, isToday } from '@/utils/formatDate';
import { differenceInMinutes, format } from 'date-fns';
import BaseTextField from '@/components/base/text-field.component';
import BaseTextarea from '@/components/base/base-textarea.component';
import { Formik } from 'formik';
import { writeMessageSchema } from '@/utils/validationSchemas';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import BaseButton from '@/components/base/button.component';
import { ReactComponent as AttachIcon } from '@/assets/icons/attach.svg';
import clsx from 'clsx';
import {
  addToBookmarksAction,
  getClosedAttachRequest,
  getUserMessageRequest,
  sendMailRequest,
} from '@/app/mails/mails.actions';
import { calculateAge } from '@/helpers/helpers';
import { ReactComponent as AddContactIcon } from '@/assets/icons/profile-actions/add-contact.svg';
import { ReactComponent as PresentIcon } from '@/assets/icons/profile-actions/present.svg';

import DialogPhotoFileUpload from '@/components/dialogs/upload-photo-file.dialog.component';
import { postPhotosRequest, postVideosRequest } from '@/app/users/users.actions';
import { UserPhotoType, UserVideoType } from '@/app/users/users.types';
import { Gallery } from 'react-photoswipe-gallery';
import UploadPhotoItem from '@/components/base/upload-photo-item';
import { MailKind } from '@/app/mails/mails.types';
import useBlankProfileAvatart from '@/hooks/useBlankProfileAvatart';
import { useBinarySwitcher } from '@/hooks/useBinarySwitcher';
import DialogGift from '@/components/dialogs/send-gift.dialog.component';
import useBalanceCheckHook from '@/hooks/useBalanceCheckHook';
import { ISettings } from '@/types/user-profile.types';
import DialogVideoFileUpload from '@/components/dialogs/upload-video-file.dialog.component';
import UploadVideoItem from '@/components/base/upload-video-item';
import AvatarWithFallback from "@/components/AvatarWithFallback/AvatarWithFallback";

type UserMessageOpennedProps = {
  messageId: string;
};

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 18,
    width: '100%',
  },
  cardContainer: {
    flexDirection: 'column',
    width: '100%',
    backgroundColor: '#F6F6F6',
  },
  headerTopSide: {
    display: 'flex',
    flexDirection: 'row',
    padding: 14,
    borderRadius: 10,
  },
  headerBottomSide: {
    padding: 14,
  },
  photoWrapper: {
    position: 'relative',
    marginRight: 20,
    maxWidth: 124,
    maxHeight: 130,
    aspectRatio: '116 / 130',
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      maxWidth: 72,
      maxHeight: 76,
    },
  },
  photoInner: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  profilePhoto: {
    width: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: 10,
    height: '100%',
  },
  profilePhotoStatus: {
    position: 'absolute',
    bottom: 8,
    left: 8,
  },
  formActions: {
    gap: 10,
    display: 'flex',
    width: '100%',
  },
  messageInfo: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: '10px 0',
  },
  messageHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  profileInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  profileMiddle: {
    display: 'flex',
    flexDirection: 'column',
    padding: 15,
  },
  profileText: {
    wordBreak: 'break-word',
  },
  profileMeta: {
    display: 'flex',
    alignItems: 'center',
    gap: 9,
  },
  profileName: {
    overflowWrap: 'anywhere',
  },
  profileActions: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 10,
    color: '#808080',
  },
  divider: {
    paddingTop: 15,
  },
  messageFooter: {
    color: '#808080',

    marginTop: 12,
    display: 'flex',
    justifyContent: 'space-between',
  },
  messageInputContainer: {
    display: 'flex',
    width: '100%',
    gap: 22,
    [theme.breakpoints.down('sm')]: {
      gap: 5,
    },
  },
  senderAvatar: {
    maxWidth: 56,
    minWidth: 56,
    maxHeight: 56,
    aspectRatio: '56 / 56',
  },
  wrapper: {
    display: 'flex',
    gap: 24,
    flexFlow: 'row wrap',
  },
  avatarInner: {
    borderRadius: '50%',
    width: '100%',
    height: '100%',
  },
  formBtn: {
    minWidth: '120px !important',
  },
  uploaderWrapper: {
    overflow: 'scroll',
  },
  attachedImagesContainer: {
    display: 'flex',
    gap: 10,
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: 20,
  },
  attachedImagesTopContainer: {
    display: 'flex',
    gap: 10,
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: 20,
    marginTop: 15,
  },
  attachedIcon: {
    maxWidth: 42,
    maxHeight: 42,
    '& > .MuiButton-startIcon': {
      margin: 0,
    },
    minWidth: '0 !important',
  },
  attachedImagesInner: {
    border: `2px dashed ${theme.palette.radioSelected.main}`,
    borderRadius: 5,
    padding: 4,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    flex: '1 1 100%',
  },
  sendedImagesInner: {
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    flex: '1 1 100%',
  },
  profileCta: {
    display: 'flex',
    gap: 24,
    height: '42px',
  },
  metaCtaIcon: {
    maxWidth: 42,
    maxHeight: 42,

    '& > .MuiButton-startIcon': {
      margin: 0,
    },
    minWidth: '0 !important',
  },

  profileSlideWrapper: {
    display: 'flex',
    flexWrap: 'wrap',

    '& .pswp__zoom-wrap': {
      display: 'flex',
      justifyContent: 'center',
    },

    '& .pswp__img': {
      width: 'auto !important',
      position: 'relative !important',
    },
    '& .pswp__img--placeholder': {
      display: 'none',
    },
  },
}));

const initValues = {
  receiverId: null,
  subject: '',
  text: '',
  photoIds: [],
};

const UserMessageOpenned = ({ messageId }: UserMessageOpennedProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { kind } = useParams<{
    kind: MailKind;
  }>();
  const { currentUserPicture, oppositeUserPicture } = useBlankProfileAvatart();
  const userMessage = useAppSelector((state) => state.mails.userMessage);
  const currentUserProfileId = useAppSelector((state) => state.users.currentUser?.profile?.id);
  const isLoading = useAppSelector((state) => state.mails.isUserMessageLoading);
  const isSending = useAppSelector((state) => state.mails.isSending);
  const [isGiftDialogOpen, openGiftDialog, hideGiftDialog] = useBinarySwitcher();

  const windowWidth = useMemo(() => window.innerWidth, []);
  const windowHeight = useMemo(() => window.innerHeight - 90, []);

  const { currentUser, differentUser } = useMemo(() => {
    if (!userMessage)
      return {
        currentUser: null,
        differentUser: null,
      };

    return userMessage?.profileReceiver.id === currentUserProfileId
      ? {
        currentUser: userMessage.profileReceiver,
        differentUser: userMessage.profileSender,
      }
      : {
        currentUser: userMessage.profileSender,
        differentUser: userMessage.profileReceiver,
      };
  }, [currentUserProfileId, userMessage]);

  useEffect(() => {
    dispatch(getUserMessageRequest(messageId, () => navigate(-1)));
  }, [dispatch, messageId]);

  const classes = useStyles();

  const formatedCreatedAtDate = useCallback((createdAt: Date) => {
    const timestamp = new Date();

    const month = format(timestamp, 'MMM do');
    const localeTime = formatCreatedAtDate(createdAt);

    return isToday(timestamp) ? `${localeTime}` : `${month}`;
  }, []);

  const isUserActiveNow = useMemo(
    () => differentUser && differenceInMinutes(new Date(), new Date(differentUser.activeAt)) < 15,
    [differentUser]
  );

  //************ */
  const [isPhotoModalVisible, setIsPhotoModalVisible] = useState(false);
  const [isVideoModalVisible, setIsVideoModalVisible] = useState(false);
  const privateImages = useAppSelector((state) => state.users['privatePhotos']);
  const privateVideos = useAppSelector((state) => state.users['privateVideos']);

  const { wallet } = useAppSelector((state) => state.users);
  const { creditsMessagePhotoOpen, creditsMessageVideoOpen, creditsMessageSend, creditsMessageVideoSend } =
    useAppSelector<ISettings>((state) => state.users.settings);
  const { checkBalance } = useBalanceCheckHook(wallet?.balance || 0);

  const handlePhotoFileUploading = useCallback(
    (image: FormData) => {
      dispatch(postPhotosRequest(UserPhotoType.PRIVATE_VIEW, image));
    },
    [dispatch]
  );

  const handleVideoFileUploading = useCallback(
    (video: File) => {
      dispatch(postVideosRequest(UserVideoType.PRIVATE_VIEW, video));
    },
    [dispatch]
  );

  const [attachedPhotoIds, setAttachedPhotoIds] = useState<Set<string>>(new Set());
  const [attachedVideoIds, setAttachedVideoIds] = useState<Set<string>>(new Set());

  const handleClickPhoto = useCallback(
    (id: string, small?: string, origin?: string, openHandler?: () => void, onClose?: () => void) => {
      const tempAttachedPhotoIds = new Set(attachedPhotoIds);

      if (tempAttachedPhotoIds.has(id)) {
        tempAttachedPhotoIds.delete(id);
      } else {
        tempAttachedPhotoIds.add(id);
      }

      setAttachedPhotoIds(tempAttachedPhotoIds);

      if (onClose) {
        onClose();
      }
    },
    [attachedPhotoIds]
  );

  const handleClickVideo = useCallback(
    (id: string, thumbnail?: string, origin?: string, openHandler?: () => void, onClose?: () => void) => {
      const tempAttachedVideoIds = new Set(attachedVideoIds);

      if (tempAttachedVideoIds.has(id)) {
        tempAttachedVideoIds.delete(id);
      } else {
        tempAttachedVideoIds.add(id);
      }

      checkBalance(creditsMessageSend + (Array.from(tempAttachedVideoIds).length) * creditsMessageVideoSend, () => {
        setAttachedVideoIds(tempAttachedVideoIds);
      });

      if (onClose) {
        onClose();
      }
    },
    [attachedVideoIds, checkBalance, creditsMessageSend, creditsMessageVideoSend]
  );

  const attachedImages = useMemo(
    () => privateImages.data.filter((image) => attachedPhotoIds.has(image.id)),
    [attachedPhotoIds, privateImages]
  );

  const attachedVideos = useMemo(
    () => privateVideos.data.filter((video) => attachedVideoIds.has(video.id)),
    [attachedVideoIds, privateVideos]
  );

  const handleClosePhoto = () => setIsPhotoModalVisible(false);
  const handleOpenPhoto = () => setIsPhotoModalVisible(true);

  const handleCloseVideo = () => setIsVideoModalVisible(false);
  const handleOpenVideo = () => setIsVideoModalVisible(true);

  const [isBookmark, setIsbookmark] = useState(false);

  useEffect(() => {
    if (userMessage?.isBookmarks) {
      setIsbookmark(userMessage.isBookmarks);
    }
  }, [userMessage?.isBookmarks]);

  const metaActions = useMemo(
    () =>
      isBookmark
        ? [
          {
            icon: <PresentIcon />,
            onClick: openGiftDialog,
          },
        ]
        : [
          {
            icon: <PresentIcon />,
            onClick: openGiftDialog,
          },
          {
            icon: <AddContactIcon />,
            onClick: () => {
              setIsbookmark(true);
              dispatch(addToBookmarksAction(differentUser.id, kind));
            },
          },
        ],
    [differentUser?.id, dispatch, isBookmark, kind]
  );

  const metaButtons = useMemo(
    () => (
      <div className={classes.profileCta}>
        {metaActions.map((action, idx) => (
          <BaseButton
            key={idx}
            inverted
            text=''
            className={classes.metaCtaIcon}
            textStyle=''
            onClick={action.onClick}
            Icon={action.icon}
          />
        ))}
      </div>
    ),
    [classes.metaCtaIcon, classes.profileCta, metaActions]
  );


  const openAttachImage = useCallback(
    ({
       id,
       small,
       origin,
       paidAt,
       profileId,
       open,
     }: {
      id: string;
      small: string;
      origin: string;
      paidAt: string | null;
      profileId: string | null;
      open: () => void;
    }) => {
      if (paidAt || profileId === currentUserProfileId) {
        open();
      } else {
        checkBalance(creditsMessagePhotoOpen, () => {
          dispatch(getClosedAttachRequest(id, messageId, false));
        });
      }
    },
    [currentUserProfileId, dispatch, messageId]
  );

  const openAttachVideo = useCallback(
    ({
       id,
       thumbnail,
       origin,
       paidAt,
       profileId,
       open,
     }: {
      id: string;
      thumbnail: string;
      origin: string;
      paidAt: string | null;
      profileId: string | null;
      open: () => void;
    }) => {
      if (paidAt || profileId === currentUserProfileId) {
        open();
      } else {
        checkBalance(creditsMessageVideoOpen, () => {
          dispatch(getClosedAttachRequest(id, messageId, true));
        });
      }
    },
    [currentUserProfileId, dispatch, messageId]
  );

  const [tabValue, setTabValue] = useState(0); // 0 for Image, 1 for Video

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  if (isLoading || !userMessage) return <CircularProgress color='secondary' style={{ margin: '0 auto' }} />;

  return (
    <>
      <DialogGift
        receiver={{
          id: differentUser.id,
          name: differentUser.name,
          avatarSmall: differentUser.profileAvatarSmall || oppositeUserPicture,
        }}
        open={isGiftDialogOpen}
        closeHandler={hideGiftDialog}
      />

      <DialogPhotoFileUpload
        className={classes.uploaderWrapper}
        handleFileUploading={handlePhotoFileUploading}
        defaultValue={privateImages}
        isOpen={isPhotoModalVisible}
        onClose={handleClosePhoto}
        handleClickPhoto={handleClickPhoto}
        attachedPhotoIds={attachedPhotoIds as Set<string>}
      />
      <DialogVideoFileUpload
        className={classes.uploaderWrapper}
        handleFileUploading={handleVideoFileUploading}
        defaultValue={privateVideos}
        isOpen={isVideoModalVisible}
        onClose={handleCloseVideo}
        handleClickVideo={handleClickVideo}
        attachedVideoIds={attachedVideoIds as Set<string>}
      />
      <div className={classes.wrapper}>
        <div className={classes.cardContainer}>
          <div className={classes.headerTopSide}>
            <div className={classes.photoWrapper}>
              <div className={classes.photoInner}>
                <Link to={`/user/${differentUser.id}`}>
                  <AvatarWithFallback src={differentUser?.avatarLarge}
                                      className={classes.profilePhoto} />
                </Link>
              </div>
              {isUserActiveNow ? (
                <div className={classes.profilePhotoStatus}>
                  <StatusOnline small />
                </div>
              ) : null}
            </div>

            <div className={classes.messageInfo}>
              <div className={classes.messageHeader}>
                <div className={classes.profileInfo}>
                  <Typography variant='body1'>{`ID: ${differentUser.pageId}`}</Typography>
                  <div className={classes.profileMeta}>
                    <Typography variant='body2' color='currentcolor' className={classes.profileName}>
                      {differentUser.name}
                      {differentUser.birthday ? `, ${calculateAge(differentUser.birthday)}` : null}
                    </Typography>
                  </div>
                </div>
                <div className={classes.profileActions}>
                  <Typography color='currentColor' fontWeight={400}>
                    {formatedCreatedAtDate(userMessage.createdAt)}
                  </Typography>
                </div>
              </div>
              <Divider className={classes.divider} />
              <div className={classes.messageFooter}>{metaButtons}</div>
            </div>
          </div>

          <div className={classes.profileMiddle}>
            <Typography variant='body3'>{userMessage.subject}</Typography>
            <Typography fontWeight={400} color='currentcolor' className={classes.profileText}>
              {userMessage.text}
            </Typography>
          </div>

          {userMessage.messageAttachments && userMessage.messageAttachments.length > 0 && (
            <div className={classes.headerBottomSide}>
              <Divider />
              <div className={classes.attachedImagesTopContainer}>
                <BaseButton
                  inverted
                  text=''
                  className={classes.attachedIcon}
                  textStyle=''
                  onClick={() => {
                  }}
                  Icon={<AttachIcon />}
                  sx={{
                    ':hover': {
                      backgroundColor: '#EEEEEE',
                    },
                  }}
                />
                <Typography variant='body2'>Attached images</Typography>

                <div className={classes.sendedImagesInner}>
                  <div className={classes.profileSlideWrapper}>
                    <Gallery>
                      {userMessage.messageAttachments.map((image) => (
                        <UploadPhotoItem
                          key={image.id}
                          {...image}
                          isPrivate={false}
                          isEditable={false}
                          handleClick={openAttachImage}
                          isExpended={false}
                          withoutIcon
                          windowWidth={windowWidth}
                          windowHeight={windowHeight}
                        />
                      ))}
                    </Gallery>
                  </div>
                </div>
              </div>
            </div>
          )}
          {userMessage.messageAttachmentsVideos && userMessage.messageAttachmentsVideos.length > 0 && (
            <div className={classes.headerBottomSide}>
              <Divider />
              <div className={classes.attachedImagesTopContainer}>
                <BaseButton
                  inverted
                  text=''
                  className={classes.attachedIcon}
                  textStyle=''
                  onClick={() => {
                  }}
                  Icon={<AttachIcon />}
                  sx={{
                    ':hover': {
                      backgroundColor: '#EEEEEE',
                    },
                  }}
                />
                <Typography variant='body2'>Attached videos</Typography>

                <div className={classes.sendedImagesInner}>
                  <div className={classes.profileSlideWrapper}>
                    {userMessage.messageAttachmentsVideos.map((video) => (
                      <UploadVideoItem
                        key={video.id}
                        {...video}
                        isPrivate={false}
                        isEditable={false}
                        handleClick={openAttachVideo}
                        isExpended={false}
                        withoutIcon
                        windowWidth={windowWidth}
                        windowHeight={windowHeight}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className={classes.messageInputContainer}>
          <div className={classes.senderAvatar}>
            <AvatarWithFallback src={currentUser?.avatarLarge} isUserPhoto className={classes.avatarInner} />
          </div>

          <Formik
            initialValues={initValues}
            validationSchema={writeMessageSchema}
            onSubmit={({ receiverId: { id }, ...rest }) => {
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
              <form onSubmit={handleSubmit} className={classes.formContainer}>
                <BaseTextField
                  label='subject'
                  placeholder='Subject:'
                  onChange={handleChange}
                  type='text'
                  baseClass={classes.formTextField}
                />
                <BaseTextarea
                  label='text'
                  placeholder='Message:'
                  onChange={handleChange}
                  className={classes.formTextField}
                />

                {attachedImages && attachedImages.length > 0 && (
                  <div className={classes.attachedImagesContainer}>
                    <BaseButton
                      inverted
                      text=''
                      className={classes.attachedIcon}
                      textStyle=''
                      onClick={() => {
                      }}
                      Icon={<AttachIcon />}
                      sx={{
                        ':hover': {
                          backgroundColor: '#EEEEEE',
                        },
                      }}
                    />
                    <Typography variant='body2'>Attached images</Typography>

                    <div className={classes.attachedImagesInner}>
                      <div className={classes.profileSlideWrapper}>
                        <Gallery>
                          {attachedImages.map((image) => (
                            <UploadPhotoItem
                              key={image.id}
                              {...image}
                              isPrivate={image?.private}
                              isEditable={true}
                              handleRemoveFile={(id) => handleClickPhoto(id)}
                              isExpended={false}
                              windowWidth={windowWidth}
                              windowHeight={windowHeight}
                            />
                          ))}
                        </Gallery>
                      </div>
                    </div>
                  </div>
                )}
                {attachedVideos && attachedVideos.length > 0 && (
                  <div className={classes.attachedImagesContainer}>
                    <BaseButton
                      inverted
                      text=''
                      className={classes.attachedIcon}
                      textStyle=''
                      onClick={() => {
                      }}
                      Icon={<AttachIcon />}
                      sx={{
                        ':hover': {
                          backgroundColor: '#EEEEEE',
                        },
                      }}
                    />
                    <Typography variant='body2'>Attached videos</Typography>

                    <div className={classes.attachedImagesInner}>
                      <div className={classes.profileSlideWrapper}>
                        {attachedVideos.map((video) => (
                          <UploadVideoItem
                            key={video.id}
                            {...video}
                            isPrivate={video?.private}
                            isEditable={true}
                            handleRemoveFile={(id) => handleClickVideo(id)}
                            isExpended={false}
                            windowWidth={windowWidth}
                            windowHeight={windowHeight}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                )}

                <div className={classes.formActions}>
                  <BaseButton
                    loading={isSending}
                    disabled={isSending}
                    type='submit'
                    color='primary'
                    text='Send'
                    className={clsx(classes.formBtn, classes.sendBtn)}
                    onClick={() => {
                      checkBalance(
                        creditsMessageSend + creditsMessageVideoSend * Array.from(attachedVideoIds).length,
                        () => {
                          dispatch(
                            sendMailRequest({
                              subject: values.subject,
                              text: values.text,
                              receiverId: differentUser.id,
                              photoIds: Array.from(attachedPhotoIds),
                              videoIds: Array.from(attachedVideoIds),
                              closeHandler: () => navigate(-1),
                            })
                          );
                        }
                      );
                    }}
                  />
                  <BaseButton
                    inverted
                    text='Attach Photo'
                    className={clsx(classes.formBtn, classes.attachBtn)}
                    textStyle={classes.attachTextStyle}
                    Icon={<AttachIcon />}
                    onClick={handleOpenPhoto}
                  />
                  <BaseButton
                    inverted
                    text='Attach Video'
                    className={clsx(classes.formBtn, classes.attachBtn)}
                    textStyle={classes.attachTextStyle}
                    Icon={<AttachIcon />}
                    onClick={handleOpenVideo}
                  />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default UserMessageOpenned;
