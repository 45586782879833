import { useAppBreakpoints, useContainerWidth } from '@/hooks';
import { Badge, CircularProgress, Container, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import BaseCard from '@/components/base/base-card.component';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import BlacklistCard from '@/pages/blacklist/components/guest-card.component';
import MobileBlackListCard from '@/pages/blacklist/components/mobile-guest-card.component';
import { useBinarySwitcher } from '@/hooks/useBinarySwitcher';
import DialogWriteMail from '@/components/dialogs/write-mail.dialog.component';
import useBlankProfileAvatart from '@/hooks/useBlankProfileAvatart';
import DialogGift from '@/components/dialogs/send-gift.dialog.component';
import { IBlackList } from '@/types/user-profile.types';

const useStyles = makeStyles((theme: Theme) => ({
  cardOverrides: {
    paddingBottom: 0,
  },
  contentWrapper: {
    zIndex: 1000,
    marginTop: 40,
    [theme.breakpoints.down('xl')]: {
      marginTop: 24,
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 14,
    },
  },
  cardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    marginBottom: 27,
    [theme.breakpoints.down('md')]: {
      marginBottom: 32,
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20,
    },
  },
  noNotifications: {
    margin: '0 auto',
    border: '2px dashed #503EB6',
    padding: '10px',
    width: '200px',
    display: 'flex',
    justifyContent: 'center',
  },
  loader: {
    margin: '0 auto',
    padding: '10px',
    width: '200px',
    display: 'flex',
    justifyContent: 'center',
  },
  guestCardContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, 170px)',
    gridGap: '20px',
  },
  label: {
    display: 'inline-block',
  },
  badge: {
    '& > .MuiBadge-standard': {
      top: 'calc(100% - 13px)',
      right: '-22px',

      [theme.breakpoints.down('md')]: {
        top: 'calc(100% - 11px)',
      },
    },
  },
  metaCtaIcon: {
    maxWidth: 32,
    maxHeight: 32,
    minHeight: '32px !important',
    minWidth: '0 !important',

    '& > .MuiButton-startIcon': {
      margin: 0,
    },
  },
}));

const Blacklist = () => {
  const classes = useStyles();
  const width = useContainerWidth();
  const { xs, mdAndDown } = useAppBreakpoints();
  const dispatch = useAppDispatch();
  const { oppositeUserPicture } = useBlankProfileAvatart();

  const blockedUsers: IBlackList[] = useAppSelector((state) => state?.users?.blockedUsers);

  const stylesOverrides = useMemo(
    () => ({
      contentWrapper: {
        padding: 0,
        maxWidth: 968,
      },
      subHeaderButton: {
        width: '100%',
        height: xs ? 24 : 36,
        minHeight: xs ? 24 : 36,
      },
    }),
    [xs]
  );

  const [isLoading, setIsLoading] = useState(false);

  const windowWidth = useMemo(() => window.innerWidth, []);
  const windowHeight = useMemo(() => window.innerHeight, []);
  const [isWriteDialogOpen, openDialog, hideDialog] = useBinarySwitcher();
  const [isGiftDialogOpen, openGiftDialog, hideGiftDialog] = useBinarySwitcher();
  const [currentDialogUser, setCurrentDialogUser] = useState(null);

  const openGiftDialoghandler = useCallback(
    ({ id, name, avatar }: { id: string; name: string; avatar: string }) => {
      setCurrentDialogUser({
        id,
        name,
        avatar,
      });

      openGiftDialog();
    },
    [openDialog]
  );

  const openDialoghandler = useCallback(
    ({ id, name, avatar }: { id: string; name: string; avatar: string }) => {
      setCurrentDialogUser({
        id,
        name,
        avatar,
      });

      openDialog();
    },
    [openDialog]
  );

  const closeDialogHandler = useCallback(() => {
    setCurrentDialogUser(null);
    hideDialog();
    hideGiftDialog();
  }, [hideDialog]);

  const contentBody = useMemo(() => {
    if (isLoading)
      return (
        <div className={classes.loader}>
          <CircularProgress color='secondary' style={{ margin: '0 auto' }} />
        </div>
      );
    if (!blockedUsers?.length) return <div className={classes.noNotifications}>No users in blacklist</div>;

    if (mdAndDown) {
      return (
        <BaseCard wrapperOverrideStyle={{ margin: 0, marginBottom: 10 }} cardOverrideStyle={classes.cardOverrides}>
          <div className={classes.cardWrapper}>
            {blockedUsers.map((item) => (
              <MobileBlackListCard key={item.id} user={item} windowWidth={windowWidth} windowHeight={windowHeight} />
            ))}
          </div>
        </BaseCard>
      );
    }

    return (
      <div className={classes.guestCardContainer}>
        {blockedUsers.map((item) => (
          <BlacklistCard user={item} key={item.id} windowWidth={windowWidth} windowHeight={windowHeight} />
        ))}
      </div>
    );
  }, [
    classes.guestCardContainer,
    classes.cardOverrides,
    classes.cardWrapper,
    classes.loader,
    classes.noNotifications,
    blockedUsers,
    isLoading,
    mdAndDown,
    openDialoghandler,
    windowHeight,
    windowWidth,
  ]);

  return (
    <>
      <Container maxWidth={width} className={classes.contentWrapper} style={stylesOverrides.contentWrapper}>
        <BaseCard wrapperOverrideStyle={{ margin: 0, marginBottom: 10 }} cardOverrideStyle={classes.cardOverrides}>
          <div className={classes.cardWrapper}>
            <header className={classes.header}>
              <Badge badgeContent={blockedUsers?.length} color='primary' className={classes.badge}>
                {/* @ts-ignore */}
                <Typography variant='h2'>BlackList</Typography>
              </Badge>
            </header>
          </div>
        </BaseCard>

        <div>{contentBody}</div>
      </Container>

      <DialogWriteMail
        open={isWriteDialogOpen && currentDialogUser}
        closeHandler={closeDialogHandler}
        receiver={{
          id: currentDialogUser?.id,
          name: currentDialogUser?.name,
          avatarSmall: currentDialogUser?.avatarSmall || oppositeUserPicture,
        }}
      />
      <DialogGift
        open={isGiftDialogOpen && currentDialogUser}
        closeHandler={closeDialogHandler}
        receiver={{
          id: currentDialogUser?.id,
          name: currentDialogUser?.name,
          avatarSmall: currentDialogUser?.avatarSmall || oppositeUserPicture,
        }}
      />
    </>
  );
};

export default Blacklist;
