import { ReactComponent as ArrowLeftIcon } from "@/assets/icons/left-arrow.svg";
import { ReactComponent as ArrowRightIcon } from "@/assets/icons/right-arrow.svg";
import { Container, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React, { FC, useRef } from "react";
import { Grid, Navigation, SwiperOptions } from "swiper";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import BaseButton from "../../../base/button.component";
import { useTheme } from "@mui/material/styles";
import BaseBadge from "@/components/base/base-badge.component";
import ProfileCardSmall from "@/components/base/profille-card-small.component";

interface SliderSlidesPerView {
  sm?: number | "auto" | undefined;
  md?: number | "auto" | undefined;
  lg?: number | "auto" | undefined;
  xl?: number | "auto" | undefined;
}

type UsersSliderMobileProps = {
  title: string;
  data: any;
  slidesPerView?: SliderSlidesPerView;
  disablePadding?: boolean;
  isCheckOnlineIsState?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  ml: {
    "& > *:not(:first-child)": {
      marginLeft: 28,
    },
  },
  disabledNavigationBtn: {
    backgroundColor: "rgb(229, 229, 229) !important",
    cursor: "not-allowed !important",
    boxShadow: "none !important",
  },
  sliderNavigation: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  sliderNavigationControl: {
    display: "flex",
  },
  sliderContent: {
    padding: "28px 0",
  },
  container: {
    marginBottom: 40,
  },
}));

const UsersSliderMobile: FC<UsersSliderMobileProps> = ({
                                                         slidesPerView,
                                                         disablePadding = false,
                                                         data,
                                                         title,
                                                         isCheckOnlineIsState,
                                                       }) => {
  const { palette } = useTheme();
  const classes = useStyles();
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const defaultSlidesPerView: SliderSlidesPerView = {
    sm: 4,
    md: 6,
    lg: 6,
    xl: 8,
  };
  const modules = [Grid, Navigation];

  const slidesPreView: SliderSlidesPerView = Object.assign(
    defaultSlidesPerView,
    slidesPerView
  );
  const swiperOption: SwiperOptions = {
    slidesPerView: "auto",
    spaceBetween: 8,
    grid: {
      rows: 2,
      fill: "row",
    },
    breakpoints: {
      0: {
        slidesPerView: 3,
        spaceBetween: 8,
        grid: {
          rows: 1,
          fill: "row",
        },
      },
      640: {
        slidesPerView: slidesPreView.sm,
        spaceBetween: 8,
        grid: {
          rows: 2,
          fill: "row",
        },
      },
      768: {
        slidesPerView: slidesPreView.md,
        spaceBetween: 8,
        grid: {
          rows: 2,
          fill: "row",
        },
      },
      875: {
        slidesPerView: 7,
        spaceBetween: 8,
        grid: {
          rows: 2,
          fill: "row",
        },
      },
      1024: {
        slidesPerView: slidesPreView.lg,
        spaceBetween: 8,
        grid: {
          rows: 2,
          fill: "row",
        },
      },
      1280: {
        slidesPerView: slidesPreView.xl,
        spaceBetween: 8,
        grid: {
          rows: 2,
          fill: "row",
        },
      },
    },
  };

  if (!data.length) return null;

  return (
    <Container
      disableGutters={disablePadding}
      className={classes.container}
      maxWidth={"xl"}
      style={{ padding: disablePadding ? "0 !important" : "0 24px" }}
    >
      <div>
        <div className={classes.sliderNavigation}>
          <BaseBadge value={data.length} color={palette.success.main}>
            <Typography variant="h2">{title}</Typography>
          </BaseBadge>{" "}
          <div className={clsx(classes.sliderNavigationControl, classes.ml)}>
            <BaseButton
              text=""
              variant="contained"
              size="small"
              ref={navigationPrevRef}
              Icon={<ArrowLeftIcon />}
              color="primary"
            />
            <BaseButton
              text=""
              variant="contained"
              size="small"
              ref={navigationNextRef}
              Icon={<ArrowRightIcon />}
              color="primary"
            />
          </div>
        </div>
        <div className={classes.sliderContent}>
          <Swiper
            slidesPerView={swiperOption.slidesPerView}
            breakpoints={swiperOption.breakpoints}
            spaceBetween={swiperOption.spaceBetween}
            grid={{
              rows: 2,
              fill: "row",
            }}
            modules={modules}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
              disabledClass: classes.disabledNavigationBtn,
            }}
            onBeforeInit={(swiper: any) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current;
              swiper.params.navigation.nextEl = navigationNextRef.current;
            }}
          >
            {data.map((user, idx) => (
              <SwiperSlide key={idx}>
                <ProfileCardSmall to={`/user/${user.id}`} user={user} isCheckOnlineIsState={isCheckOnlineIsState} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </Container>
  );
};

export default UsersSliderMobile;
