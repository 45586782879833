import { useAppSelector } from "@/app/hooks";
import {
  Box, List, Popover,
  PopoverProps,
  Theme
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useNavigate } from "react-router-dom";

type DialogProfileOptionsProps = {
  anchorEl: PopoverProps["anchorEl"];
  setAnchorEl: React.Dispatch<React.SetStateAction<null>>;
  list: Array<React.ReactNode>;
};

const useStyles = makeStyles((theme: Theme) => ({
  dialogInner: {
    "& > .MuiTypography-root": {
      color: "inherit",
    },

    display: "flex",
    flexDirection: "column",
    width: 180,
    padding: 0,
    paddingTop: 0,
    justifyContent: "center",

    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  listItem: {},
  listItemButton: {
    "&:hover > .MuiListItemText-root	": {
      color: "#503EB6",
    },
  },
}));

const stylesOverrides = {
  dialogInner: {
    paddingTop: 0,
  },
  listItemButton: {
    padding: "6px 16px",
  },
};

const DialogEditProfile = ({
  anchorEl,
  setAnchorEl,
  list,
}: DialogProfileOptionsProps) => {
  const classes = useStyles();

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      transformOrigin={{
        vertical: -15,
        horizontal: 0,
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      PaperProps={{
        style: {
          borderRadius: 10,
          overflow: "initial",
          boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          mt: "10px",
          "&::before": {
            backgroundColor: "white",
            content: '""',
            display: "block",
            position: "absolute",
            width: 12,
            height: 12,
            top: -16,
            transform: "rotate(45deg)",
            left: "calc(50% - 6px)",
          },
        }}
      />
      <List className={classes.dialogInner} style={stylesOverrides.dialogInner}>
        {list}
      </List>
    </Popover>
  );
};

export default DialogEditProfile;
