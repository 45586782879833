import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/');
  };

  return (
      <Container
          maxWidth="md"
          sx={{
              textAlign: 'center',
              mt: 0,
              minHeight: '100vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
          }}
      >
        <Typography variant="h1" color="primary" gutterBottom sx={{ fontWeight: '600' }}>
          404
        </Typography>
        <Typography variant="h5" gutterBottom>
          Oops! The page you’re looking for doesn’t exist.
        </Typography>
        <Box mt={4}>
          <Button
              variant="contained"
              color="primary"
              onClick={handleGoHome}
              data-testid="go-home-button"
          >
            Go Home
          </Button>
        </Box>
      </Container>
  );
};

export default NotFoundPage;
