import { useEffect, useState } from "react";
import uuid from 'uuid'
import Hotjar from "@hotjar/browser";

import { useAppSelector } from "@/app/hooks";
import { generateKey } from "@/utils/generateKey";
import { getCookie, setCookie } from "@/utils/cookies";
import CpaService from "@/services/cpa.service";

const HOTJAR_ID = 5302627;
const HOTJAR_SNIPPET_VERSION = 6;
const HOTJAR_SESSION_KEY = "hotjar_session";

const HotjarIntegration = () => {
  const [cpaName, setCpaName] = useState<string | null>(null);
  const currentUser = useAppSelector((state) => state.users.currentUser);

  const getCpaName = async () => {
    const cpaData = await CpaService.getCpaMe();
    setCpaName(cpaData?.cpaName ? cpaData.cpaName : '');
  }

  const getSessionId = () => {
    const sessionKey = getCookie(HOTJAR_SESSION_KEY);

    if (!Boolean(sessionKey) || sessionKey === null) {
      const newSession = generateKey('')
      setCookie(HOTJAR_SESSION_KEY, newSession)

      return newSession;
    }

    return sessionKey;
  }

  const getUserInfo = () => {
    if (currentUser) {
      return {
        id: `${currentUser?.id}`,
        email: currentUser?.email,
        agencyStaff: currentUser?.agencyStaff,
        role: currentUser?.role,
        status: currentUser?.status,
        createdAt: currentUser?.profile?.createdAt,
        pageId: currentUser?.profile?.pageId,
        cpaName: cpaName
      };
    }

    return {
      id: `${generateKey('anonymous')}`,
      email: '',
      agencyStaff: null,
      role: null,
      status: null,
      createdAt: null,
      pageId: null,
      cpaName: ''
    };
  }

  useEffect(() => {
    if (Boolean(currentUser)) {
      getCpaName();
    }

    const userProps = getUserInfo();

    if (process.env.NODE_ENV === 'production' && userProps.cpaName !== null) {
      const sessionID = getSessionId()
      const currentUserProps = Boolean(currentUser) ? userProps : {};

      Hotjar.init(HOTJAR_ID, HOTJAR_SNIPPET_VERSION);

      Hotjar.identify(sessionID, currentUserProps);

      Hotjar.stateChange(window.location.pathname);
    }
  }, [currentUser, window.location.pathname, getCpaName]);

  return null
};

export default HotjarIntegration;
