import { useAppSelector } from '@/app/hooks';
import {
  deleteBookmarksRequest,
  DialogToUsersDialogsSuccess,
  readUsersDialogsSuccess,
  setBookmarksRequest,
  updatePinSuccess,
} from '@/app/users/users.actions';
import BaseTextarea from '@/components/base/base-textarea.component';
import BaseButton from '@/components/base/button.component';
import { calculateAge, getGoogleTranslationKey } from '@/helpers/helpers';
import { validationSchemaDialogMessage } from '@/utils/validationSchemas';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Field, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import EmptyChat from './empty-dialog.component';
import DialogMessages from './dialog-messages.component';
import UserInfo from './user-info.component';
import ActionsButtonsComponent from './actions-buttons.component';
import { useBinarySwitcher } from '@/hooks/useBinarySwitcher';
import { UserProfileDTO } from '@/app/users/users.reducer';
import DialogProfileInfo from './profile-info.dialog.component';
import { ReactComponent as SendButton } from '@/assets/icons/chats/send.svg';
import { ReactComponent as TranslateButton } from '@/assets/icons/chats/translate.svg';
import { IconButton } from '@mui/material';
import { ReactComponent as AttachFile } from '@/assets/icons/attach.svg';
import { ReactComponent as ArrowLeft } from '@/assets/icons/chats/arrow-left.svg';
import { ReactComponent as ArrowRight } from '@/assets/icons/chats/arrow-right.svg';
import { toggleAvailableUsersBar, toggleLastUsersBar, toggleModalOpen, toggleSnackbarOpen } from '@/app/ui/ui.actions';
import useSound from 'use-sound';
import { StorageService } from '@/services/storage.service';
import messageInviteSound from '@/assets/sounds/dialog-invite.mp3';
import messageSound from '@/assets/sounds/send-dialog-massage.mp3';

import Socket from '@/services/Socket';
import { BalanceDialog } from './balance-dialog.component';
import DialogSendPhotoOrVideo from './dialog-send-photo.component';
import DialogWriteMail from '@/components/dialogs/write-mail.dialog.component';
import DialogGift from '@/components/dialogs/send-gift.dialog.component';
import ProfilesService from '@/services/profiles.service';
import { UserRolesEnum } from '@/components/chat-container/enums/user-roles.enum';
import { DialogMessage, ISettings } from '@/types/user-profile.types';
import useBalanceCheckHook from '@/hooks/useBalanceCheckHook';
import { socketRoutes } from "@/components/socketRoutes";
import {
  readUserActiveDialogInvitesAction,
  updateDialogInviteSocketData
} from "@/app/dialogInvites/dialogInvites.actions";
import StickersUploadButton from "@/components/base/StickersUploadButton";
import StickersContainer from "@/components/base/StickersContainer/StickersContainer";
import PhotosService from "@/services/photos.service";
import { UserPhotoType } from "@/app/users/users.types";
import { defaultUserPhotosData, IUserPhotosData } from "@/app/users/users.dto";
import DialogUserPhotos from "@/components/dialog-container/components/DialogUserPhotos/DialogUserPhotos";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '28px 28px 0 28px',
    zIndex: '90',
    background: '#FFFFFF',
    borderRadius: '10px 10px 10px 10px',
    boxShadow: '0px 0px 20px rgb(0 0 0 / 5%)',
    textAlign: 'center',
    overflow: 'hidden',
    wordBreak: 'break-word',
    [theme.breakpoints.down('sm')]: {
      padding: '12px',
    },
    height: 'calc(100% - 28px)',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
  },
  image: {},
  name: {},
  chatContainer: {
    height: 'calc(100vh - 424px)',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 200px)',
      position: 'relative',
    },
  },
  chatContainerOpen: {
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 428px)',
      position: 'relative',
    },
  },
  chatDate: {},
  myMessage: {},
  referenceMessage: {},
  smallAvatar: {
    width: '56px',
    height: '56px',
    borderRadius: '60px',
  },
  formTextField: {
    width: 'auto',
    padding: '10px 30px',
  },
  senButton: {
    width: '100%',
    maxWidth: 144,
    marginTop: 32,
  },
  formBtn: {
    minWidth: '120px !important',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '0 auto',
    minHeight: '40px',
    position: 'relative',
    paddingLeft: '0',
  },
  formActions: {
    gap: 10,
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  translateMobile: {
    position: 'absolute',
    right: '7px',
    top: '-10px',
    [theme.breakpoints.down('lg')]: {
      right: '-6px',
    },
  },
  symbolCounter: {
    position: 'absolute',
    right: '44px',
    top: '-20px',
    color: '#503EB6',
    [theme.breakpoints.down('lg')]: {
      right: '46px',
    },
  },
  translateMobileButton: {
    background: 'none!important',
    paddingLeft: '0!important',
    paddingRight: '0!important',
    boxShadow: 'none!important',
    width: '20px',
    minWidth: '20px',
    height: '20px',
    cursor: 'pointer',
  },
  actionsFullScreen: {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  chats: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      marginTop: '0px',
    },
  },
  availableChatWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100px',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  lastChatsWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100px',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },

  availableChatBtn: {
    background: '#eee!important',
  },
  lastChatBtn: {
    background: '#eee!important',
  },

  genderChatBtn: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#000000',
    paddingLeft: '12px',
  },
  chatsBtn: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#000000',
    paddingLeft: '12px',
  },
  chatTypeWrapper: {
    '& button': {
      paddingLeft: '0!important',
      paddingRight: '0!important',
    },
  },
  chatsWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '74px',
    background: '#EEEEEE',
    borderRadius: '20px',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  textFieldButtons: {
    position: 'absolute',
    bottom: '0px',
    right: '0px',
  },
  formButton: {
    background: 'none!important',
    paddingLeft: '0!important',
    paddingRight: '0!important',
    boxShadow: 'none!important',
    minWidth: '34px !important',

    '& span': {
      marginRight: '0!important',
      marginLeft: '0!important',
    },
  },
  attachButton: {
    position: 'absolute',
    left: '0px',
    bottom: '0px'
  },
}));

const Dialog = () => {
  const classes = useStyles();
  let { id, otherId } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showStickers, setShowStickers] = useState(false);

  let initialMessage = searchParams.get('message');

  const dispatch = useDispatch();

  const { currentUser, availableUsers, bookmarks, wallet, usersWithActiveTextChat, userDialogs, searchUsers } =
    useAppSelector((state) => state.users);

  const { creditsDialogTextSend, creditsStickerSend } = useAppSelector((state) => state.users.settings);

  const [userInBookmark, setUserInBookmark] = useState(false);
  const [referenceUser, setReferenceUser] = useState(null);
  const [detailedUser, setDetailedUser] = useState(null);
  const [userDialog, setUserDialog] = useState(null);
  const [age, setAge] = useState('');
  const [isPhotosSliderOpen, setPhotosSliderOpen] = useState(false);
  const [publicPhotos, setPublicPhotos] = useState<IUserPhotosData>(defaultUserPhotosData);
  const [isWriteDialogOpen, openDialog, hideDialog] = useBinarySwitcher();
  const [isGiftDialogOpen, openGiftDialog, hideGiftDialog] = useBinarySwitcher();
  const [isSendPhotoDialogOpen, openSendPhotoDialog, hideSendPhotoDialog] = useBinarySwitcher();
  const [isProfileDialogOpen, openProfileDialog, closeProfileDialog] = useBinarySwitcher();

  const getReferenceUserInfo = useCallback(async () => {
    const data = await ProfilesService.getProfileById(referenceUser?.id);
    setDetailedUser(data);
    openProfileDialog();
  }, [openProfileDialog, referenceUser?.id]);

  const getReferenceUserDialog = useCallback(async (id: string, offset = 0) => {
    const data = await ProfilesService.getProfileDialogById(id, offset);
    dispatch(readUsersDialogsSuccess(id));
    setUserDialog(data);
  }, [dispatch]);

  const getMoreReferenceUserDialog = useCallback(
    async (id: string, offset = 0) => {
      const data = await ProfilesService.getProfileDialogById(id, offset);
      setUserDialog({ ...data, data: [...data.data, ...userDialog.data] });
    },
    [userDialog]
  );

  const gender = currentUser.profile.gender;

  const genderLabel = gender === 'male' ? ' Girl' : 'Man';


  const togglePhotoHandler = useCallback(() => {
    if (referenceUser && publicPhotos.data.length > 0) {
      setPhotosSliderOpen(!isPhotosSliderOpen);
    }
  }, [referenceUser, publicPhotos.data.length, isPhotosSliderOpen]);

  const toggleBookmarkHandler = useCallback(() => {
    if (userInBookmark) {
      dispatch(deleteBookmarksRequest(referenceUser));
      return;
    }
    dispatch(setBookmarksRequest(referenceUser));
  }, [dispatch, referenceUser, userInBookmark]);

  const pinnedProfileHandler = useCallback(async () => {
    const index = userDialogs.data.findIndex(
      (dialog) =>
        Number.isFinite(dialog.pinRanked) && (dialog.receiverProfile.id === referenceUser.id || dialog.senderProfile.id === referenceUser.id)
    );
    if (index === -1) {
      await ProfilesService.setPinned(referenceUser.id);
      dispatch(updatePinSuccess(referenceUser.id, true));
      return;
    }
    await ProfilesService.deletePinned(referenceUser.id);
    dispatch(updatePinSuccess(referenceUser.id, false));
  }, [referenceUser, userDialogs]);

  const toggleAvailableDrawer = useCallback(() => {
    dispatch(toggleAvailableUsersBar());
  }, [dispatch]);

  const toggleLastChatDrawer = useCallback(() => {
    dispatch(toggleLastUsersBar());
  }, [dispatch]);

  const getUserByProfileIdIfExists = useCallback(async () => {
    try {
      let user: any = {}
      const reference = (availableUsers || []).filter((user) => user.id === otherId);
      if (reference.length) {
        const referenceUser = reference[0];
        user = referenceUser
        setReferenceUser(referenceUser);
        if (referenceUser.birthday) {
          const calcAge = calculateAge(referenceUser.birthday);
          setAge(`, ${calcAge}`);
        } else {
          setAge('');
        }
      } else {
        const all = (searchUsers.data || []).filter((user) => user.id === otherId);
        if (all.length) {
          const userToShow = all[0];
          user = userToShow
          setReferenceUser(userToShow);
          if (userToShow.birthday) {
            const calcAge = calculateAge(userToShow.birthday);
            setAge(`, ${calcAge}`);
          } else {
            setAge('');
          }
        } else {
          const userProfile = await ProfilesService.getProfileById(otherId);
          user = referenceUser
          setReferenceUser(userProfile);
        }
      }
    } catch {
    }
  }, [availableUsers, otherId, searchUsers.data]);

  const loadDialogPhotos = useCallback(async (otherId: string) => {
    const photos = await PhotosService.getUserPhotos(otherId, UserPhotoType.PUBLIC_VIEW)
    setPublicPhotos(photos || defaultUserPhotosData)
  }, [])

  useEffect(() => {
    if (otherId) {
      getReferenceUserDialog(otherId);
      getUserByProfileIdIfExists();
      loadDialogPhotos(otherId)
    }
  }, [otherId, getReferenceUserDialog, loadDialogPhotos]);

  useEffect(() => {
    if (otherId) {
      dispatch(readUserActiveDialogInvitesAction({ id: otherId }));
    }
  }, [dispatch, otherId]);

  useEffect(() => {
    if (!referenceUser || !bookmarks) return;
    const prepareBookMark = bookmarks.map((bookmark: UserProfileDTO) => {
      return bookmark.id;
    });

    setUserInBookmark(prepareBookMark.includes(referenceUser?.id));
  }, [bookmarks, referenceUser]);

  const [playbackInviteRate] = React.useState(1);
  const [playInvite] = useSound(messageInviteSound, {
    playbackInviteRate,
    // `interrupt` ensures that if the sound starts again before it's
    // ended, it will truncate it. Otherwise, the sound can overlap.
    interrupt: true,
  });

  const inviteMessageSound = useCallback(() => {
    const isAudioTurnOn = StorageService.getAudio();
    if (isAudioTurnOn) {
      playInvite();
    }
  }, [playInvite]);

  const notificationNewDialogClient = useCallback(
    (data: any) => {
      let changeCounter = true;
      if (data.data.senderProfile.id === referenceUser?.id) {
        setUserDialog((prevDialog) => ({
          ...prevDialog,
          data: [...prevDialog.data, data.data]
        }));
        changeCounter = false;
      }

      inviteMessageSound();

      dispatch(DialogToUsersDialogsSuccess(data.data, changeCounter));
    },
    [dispatch, inviteMessageSound, referenceUser]
  );

  const notificationNewDialogInviteClient = useCallback(
    (data: any) => {
      notificationNewDialogClient(data)
      dispatch(updateDialogInviteSocketData({ message: data.data }));
    },
    [dispatch, notificationNewDialogClient]
  );

  const notificationNewDialogReceiveOwn = useCallback(
    (data: any) => {
      const updatedData = { ...data.data, seen: true }
      if (currentUser?.profile?.id === data.data.senderProfile.id) {
        setUserDialog((prevDialog) => ({
          ...prevDialog,
          data: [...prevDialog.data, updatedData]
        }));
      }

      dispatch(DialogToUsersDialogsSuccess(updatedData, false));
    },
    [currentUser, dispatch]
  );

  useEffect(() => {
    if (Socket && Socket.socket && userDialog) {
      Socket.off(socketRoutes.dialogNotificationClient);
      Socket.off(socketRoutes.dialogNotificationReceiveOwn);
      Socket.off(socketRoutes.dialogNotificationDistribution);
      Socket.off(socketRoutes.dialogNotificationAutoinvite);//

      Socket.on(socketRoutes.dialogNotificationClient, notificationNewDialogClient);
      Socket.on(socketRoutes.dialogNotificationReceiveOwn, notificationNewDialogReceiveOwn);
      Socket.on(socketRoutes.dialogNotificationDistribution, notificationNewDialogClient);
      Socket.on(socketRoutes.dialogNotificationAutoinvite, notificationNewDialogInviteClient);
    }
  }, [notificationNewDialogClient, notificationNewDialogInviteClient, notificationNewDialogReceiveOwn, userDialog]);

  useEffect(() => {
    if (!referenceUser) {
      return;
    }

    const prepareBookMark = bookmarks.map((bookmark: UserProfileDTO) => {
      return bookmark.id;
    });

    const isIsInBookMarks = prepareBookMark.includes(referenceUser?.id);

    if (!isIsInBookMarks && referenceUser.itHasMyMessage && referenceUser.itHasReferenceMessage) {
      dispatch(setBookmarksRequest(referenceUser));
    }
  }, [referenceUser?.itHasMyMessage, referenceUser?.itHasReferenceMessage, referenceUser?.id]);

  const initValues = {
    message: '',
  };

  /**
   * CHAT SECTION
   */

  const [playbackRate] = React.useState(1);
  const [play] = useSound(messageSound, {
    playbackRate,
    // `interrupt` ensures that if the sound starts again before it's
    // ended, it will truncate it. Otherwise, the sound can overlap.
    interrupt: true,
  });

  const sendMessageSound = () => {
    const isAudioTurnOn = StorageService.getAudio();
    if (isAudioTurnOn) {
      play();
    }
  };

  const isStaff = currentUser.role === UserRolesEnum.STAFF;

  const fetchGoogleTranslation = useCallback(async (value: string, from: string, to: string) => {
    const apiKey = getGoogleTranslationKey();

    const response = await fetch(
      `https://translation.googleapis.com/language/translate/v2?source=${from}&target=${to}&key=${apiKey}&q=${value}&format=text`
    );
    const { data } = await response.json();

    return data.translations[0].translatedText;
  }, []);

  const translateHisText = useCallback(
    async (message) => {
      if (message.translate || !isStaff) {
        return;
      }
      const translate = await fetchGoogleTranslation(message.text, 'en', 'ru');

      const results = userDialog.results.map((result) => {
        if (result.id === message.id) {
          return {
            ...result,
            translate,
          };
        }
        return result;
      });
      setUserDialog({ ...userDialog, results });
    },
    [isStaff, fetchGoogleTranslation, userDialog]
  );

  const { creditsDialogPhotoOpen } = useAppSelector<ISettings>((state) => state.users.settings);
  const { checkBalance } = useBalanceCheckHook(wallet?.balance || 0);

  const revealPhoto = useCallback(
    async (message: DialogMessage) => {
      checkBalance(creditsDialogPhotoOpen, async () => {
        const data = await ProfilesService.payForDialogPhoto(message.photo.id);
        setUserDialog({
          ...userDialog,
          data: [
            ...userDialog.data.map((result: DialogMessage) => {
              if (result && result.photo && result.photo.id === data.photoId) {
                return {
                  ...result,
                  photo: {
                    ...result.photo,
                    ...data,
                    paidAt: new Date(),
                  },
                };
              } else {
                return result;
              }
            }),
          ],
        });
      });
    },
    [userDialog, setUserDialog]
  );

  const revealVideo = useCallback(
    async (message: DialogMessage) => {
      checkBalance(creditsDialogPhotoOpen, async () => {
        const data = await ProfilesService.payForDialogVideo(message.video.id);
        setUserDialog({
          ...userDialog,
          data: [
            ...userDialog.data.map((result: DialogMessage) => {
              if (result && result.video && result.video.id === data.videoId) {
                return {
                  ...result,
                  video: {
                    ...result.video,
                    ...data,
                    paidAt: new Date(),
                  },
                };
              } else {
                return result;
              }
            }),
          ],
        });
      });
    },
    [userDialog, setUserDialog]
  );

  const translateMyText = useCallback(async (text, form: any) => {
    const translate = await fetchGoogleTranslation(text, 'ru', 'en');
    form.setFieldValue('message', translate);
  }, []);

  const sendDialogMessageCallback = useCallback(async ({ receiverId, text }) => {
    try {
      await ProfilesService.sendDialogMessage({ receiverId, text });
    } catch (e) {
      if (e.message === 'Profile blocked by yourself') {
        dispatch(toggleModalOpen('You have blocked that user, you can go to profile and unblock', 'error', receiverId));
      }
      dispatch(
        toggleSnackbarOpen(
          e?.message,
          'error'
        )
      );
    }
  }, []);

  const sendDialogStickerCallback = useCallback(async ({ receiverId, stickerId }) => {
    try {
      await ProfilesService.sendDialogStickerMessage({
        receiverId,
        stickerId
      });
    } catch (e) {
      if (e.message === 'Profile blocked by yourself') {
        dispatch(toggleModalOpen('You have blocked that user, you can go to profile and unblock', 'error', receiverId));
      }
      dispatch(
        toggleSnackbarOpen(
          e?.message,
          'error'
        )
      );
    }
  }, [dispatch]);

  /**
   * END CHAT SECTION
   */
  if (!referenceUser) return <EmptyChat />;

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <UserInfo
          openDialogHandler={getReferenceUserInfo}
          profilePicture={referenceUser.avatarSmall}
          name={referenceUser.name}
          pageId={referenceUser.pageId}
          profileId={referenceUser.id}
          age={age}
          mailClickHandler={openDialog}
          togglePhotoHandler={togglePhotoHandler}
          isPhotosSliderOpen={isPhotosSliderOpen}
          contactsClickHandler={toggleBookmarkHandler}
          pinnedProfileHandler={pinnedProfileHandler}
          giftClickHandler={openGiftDialog}
          isOnline={referenceUser.isOnline}
          isPhotosButtonVisible={publicPhotos.data.length > 0}
        />
        <div
          className={classes.chats}
          style={{
            justifyContent: 'space-between',
            alignItems: 'self-end',
          }}
        >
          <div style={{ display: 'block' }}>
            <div className={classes.availableChatWrapper} onClick={toggleAvailableDrawer}>
              <IconButton className={classes.availableChatBtn}>
                <ArrowRight />
              </IconButton>
              <div className={classes.genderChatBtn}>{genderLabel}</div>
            </div>
          </div>
          <div style={{ display: 'block' }}>
            <div className={classes.lastChatsWrapper} onClick={toggleLastChatDrawer}>
              <IconButton className={classes.lastChatBtn}>
                <ArrowLeft />
              </IconButton>
              <div className={classes.chatsBtn}>Dialogs</div>
            </div>
          </div>
        </div>
      </div>
      <DialogUserPhotos isPhotosSliderOpen={isPhotosSliderOpen} photos={publicPhotos}
                        togglePhotoHandler={togglePhotoHandler} />
      <DialogSendPhotoOrVideo
        referenceUser={referenceUser}
        open={isSendPhotoDialogOpen}
        closeHandler={hideSendPhotoDialog}
        isStaff={isStaff}
      />
      <DialogWriteMail receiver={referenceUser} open={isWriteDialogOpen} closeHandler={hideDialog} />
      <DialogGift isDialog={true} receiver={referenceUser} open={isGiftDialogOpen} closeHandler={hideGiftDialog} />
      <ActionsButtonsComponent
        mailClickHandler={openDialog}
        togglePhotoHandler={togglePhotoHandler}
        isPhotosSliderOpen={isPhotosSliderOpen}
        contactsClickHandler={toggleBookmarkHandler}
        pinnedProfileHandler={pinnedProfileHandler}
        giftClickHandler={openGiftDialog}
        isPhotosButtonVisible={publicPhotos.data.length > 0}
      />
      {userDialog && (
        <DialogMessages
          initialMessage={initialMessage}
          currentUser={currentUser.profile}
          referenceUser={referenceUser}
          userDialog={userDialog}
          getReferenceUserDialog={getMoreReferenceUserDialog}
          translateHisText={translateHisText}
          revealPhoto={revealPhoto}
          revealVideo={revealVideo}
        />
      )}
      <Formik
        initialValues={initValues}
        validationSchema={validationSchemaDialogMessage}
        onSubmit={(
          values: {
            message: string;
          },
          { setSubmitting, resetForm }
        ) => {
          if (!values.message) {
            return;
          }
          if (values.message.length > 250) {
            dispatch(toggleSnackbarOpen('Message should be less than 250 characters', 'error'));
            return;
          }
          if (isStaff) {
            setSubmitting(true);
            sendMessageSound();
            sendDialogMessageCallback({
              receiverId: referenceUser.id,
              text: values.message,
            });
            resetForm();
            return;
          }
          if (wallet.balance <= creditsDialogTextSend) {
            checkBalance(creditsDialogTextSend, () => {
              setDialogOpen(true);
            });
          } else {
            setSubmitting(true);
            sendMessageSound();
            sendDialogMessageCallback({
              receiverId: referenceUser.id,
              text: values.message,
            });
            resetForm();
          }
        }}
      >
        {({ values, setSubmitting, resetForm, handleChange, handleSubmit }) => {
          const onStickerClick = (id: string) => {
            const sendSticker = () => {
              setSubmitting(true);
              sendMessageSound();
              sendDialogStickerCallback({
                receiverId: referenceUser.id,
                stickerId: id,
              });
              resetForm();
            }
            if (isStaff) {
              sendSticker()
              return;
            }
            if (wallet.balance < creditsStickerSend) {
              checkBalance(creditsStickerSend, () => {
                setDialogOpen(true);
              });
            } else {
              sendSticker()
            }

            setShowStickers(false)
          }
          return (
            <>
              <form
                onSubmit={handleSubmit}
                className={classes.formContainer}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleSubmit();
                  }
                }}
              >
                <Field
                  name='message'
                  value={values.message}
                  render={({ form }) => (
                    <>
                      <BaseTextarea
                        label='message'
                        placeholder='Message:'
                        onChange={handleChange}
                        className={classes.formTextField}
                      />

                      {isStaff && (
                        <div className={classes.translateMobile}>
                          <TranslateButton
                            className={clsx(classes.translateMobileButton)}
                            onClick={() => translateMyText(values.message, form)}
                          />
                        </div>
                      )}
                      {<div className={classes.symbolCounter}>{250 - (values.message.length || 0)}</div>}
                    </>
                  )}
                />
                <div className={classes.attachButton}>
                  <BaseButton
                    text=''
                    inverted
                    className={classes.formButton}
                    onClick={openSendPhotoDialog}
                    Icon={<AttachFile />}
                  />
                </div>
                <div className={classes.textFieldButtons}>
                  <StickersUploadButton onClick={() => setShowStickers(!showStickers)} inputText={values.message} />
                  <BaseButton
                    type='submit'
                    text=''
                    className={classes.formButton}
                    onClick={() => handleSubmit}
                    Icon={<SendButton />}
                  />
                </div>
              </form>
              <StickersContainer onClick={onStickerClick} onClose={() => setShowStickers(false)}
                                 inputText={values.message} isOpen={showStickers} />
            </>
          )
        }}
      </Formik>
      {
        detailedUser && (
          <DialogProfileInfo open={isProfileDialogOpen} closeHandler={closeProfileDialog} referenceUser={detailedUser} />
        )
      }
      <BalanceDialog open={dialogOpen} onClose={() => setDialogOpen(false)} />
    </div>
  )
};

export default Dialog;
