import { AnyAction } from 'redux';
import {
  TOGGLE_AVAILABLE_USERS_BAR,
  TOGGLE_LAST_USERS_BAR,
  TOGGLE_SNACKBAR_CLOSE,
  TOGGLE_SNACKBAR_OPEN,
  TOGGLE_VIDEO_CHAT_OPEN,
  TOGGLE_MYSTREAM,
  CLOSE_NOT_ENOUGH_BALANCE,
  OPEN_NOT_ENOUGH_BALANCE,
  TOGGLE_MODAL_CLOSE,
  TOGGLE_MODAL_OPEN,
} from './ui.types';

const initialState = {
  toggleSnackbar: false,
  snackbarMessage: null,
  snackbarType: null,
  availableUsersOpen: false,
  lastChatOpen: false,
  isOpenVideoChat: false,
  stream: null,
  isNotEnoughBalance: false,
  notEnoughBalance: 0,
  modalMessage: null,
  modalType: null,
  userId: null,
};

export default function reducer(state = initialState, action: AnyAction) {
  switch (action.type) {
    case TOGGLE_SNACKBAR_OPEN: {
      return {
        ...state,
        toggleSnackbar: true,
        snackbarMessage: action.payload.message,
        snackbarType: action.payload.type,
      };
    }

    case TOGGLE_SNACKBAR_CLOSE: {
      return {
        ...state,
        toggleSnackbar: false,
        snackbarMessage: null,
        snackbarType: null,
      };
    }

    case TOGGLE_AVAILABLE_USERS_BAR: {
      return {
        ...state,
        availableUsersOpen: !state.availableUsersOpen,
      };
    }

    case TOGGLE_MODAL_OPEN: {
      return {
        ...state,
        toggleModal: true,
        modalMessage: action.payload.message,
        modalType: action.payload.type,
        userId: action.payload.userId,
      };
    }

    case TOGGLE_MODAL_CLOSE: {
      return {
        ...state,
        toggleModal: false,
        modalMessage: null,
        modalType: null,
      };
    }

    case TOGGLE_LAST_USERS_BAR: {
      return {
        ...state,
        lastChatOpen: !state.lastChatOpen,
      };
    }

    case TOGGLE_VIDEO_CHAT_OPEN: {
      return {
        ...state,
        isOpenVideoChat: !state.isOpenVideoChat,
      }
    }

    case TOGGLE_MYSTREAM: {
      return {
        ...state,
        stream: action.payload.stream,
      }
    }

    case CLOSE_NOT_ENOUGH_BALANCE: {
      return {
        ...state,
        isNotEnoughBalance: false,
        notEnoughBalance: 0,
      }
    }

    case OPEN_NOT_ENOUGH_BALANCE: {
      return {
        ...state,
        isNotEnoughBalance: true,
        notEnoughBalance: action.payload.notEnoughBalance,
      }
    }

    default: {
      return state;
    }
  }
}
