import React, { useEffect, useState, useRef } from 'react';

import Grid from "@mui/material/Grid";
import { useStyles } from "@/components/base/StickersContainer/StickerContainer.styles";
import { StickerPack } from "@/types/stickers.types";
import StickersService from "@/services/stickers.service";
import StickersItemButton, {StickersUploadButtonType} from "@/components/base/StickersItemButton";
import {
    StickersContainerPacksProps,
} from "@/components/base/StickersContainer/StickerContainer.props";

type LoadMoreProps = {
    prevPage: boolean,
    nextPage: boolean,
    currentPage: number
}

const PER_PAGE = 30;

const StickerContainerPacks = ({
   onClick,
}: StickersContainerPacksProps) => {
    const classes = useStyles();
    const [stickerPacks, setStickerPacks] = useState<StickerPack[]>([])
    const stickersCategoryRefs = useRef(new Map<string, HTMLDivElement>());
    const [loadMoreProps, setLoadMoreProps] = useState<LoadMoreProps>({
        nextPage: false,
        prevPage: false,
        currentPage: 0,
    });

    const getStickerPacks = async (offset: number, limit: number) => {
        const currentStickerPacks = await StickersService.getStickerPacks(offset, limit);
        setLoadMoreProps((prevProps) => ({
            ...prevProps,
            nextPage: currentStickerPacks.nextPage,
            prevPage: currentStickerPacks.prevPage,
            currentPage: prevProps.currentPage + 1
        }))

        setStickerPacks([...stickerPacks, ...currentStickerPacks?.data]);
    }

    useEffect(() => {
        getStickerPacks(0,  PER_PAGE);
    }, []);

    const handleScrollBottom = (e) => {
        const target = e.target;

        if (Math.ceil(target.scrollHeight - target.scrollTop) === target.clientHeight) {
            if (loadMoreProps.nextPage) {
                getStickerPacks(PER_PAGE * loadMoreProps.currentPage, PER_PAGE);
            }
        }
    }

    const handleScrollRight = (e) => {
        const target = e.target;

        if (Math.ceil(target.scrollWidth - target.scrollLeft) === target.clientWidth) {
            if (loadMoreProps.nextPage) {
                getStickerPacks(PER_PAGE * loadMoreProps.currentPage, PER_PAGE);
            }
        }
    }

    const scrollToCategory = (category: string) => {
        const categoryElement = stickersCategoryRefs.current.get(category);
        if (categoryElement) {
            categoryElement.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };

    if (!stickerPacks.length) {
        return null;
    }

    return (
        <>
            <div className={classes.stickersCategories} onScroll={handleScrollRight}>
                {stickerPacks.map((pack) => {
                    if (!pack?.stickers.length) {
                        return null;
                    }

                    return <div key={`pack-${pack.id}`}>
                        <StickersItemButton
                            key={pack.id}
                            item={pack.stickers[0]}
                            onClick={() => scrollToCategory(pack.id)}
                            type={StickersUploadButtonType.extraSmall}
                        />
                    </div>
                })}
            </div>
            <div className={classes.stickersPacks} onScroll={handleScrollBottom}>
                {stickerPacks.map((pack) => {
                    if (!pack?.stickers.length) {
                        return null;
                    }

                    return (<div key={pack.id} ref={(el) => el && stickersCategoryRefs.current.set(pack.id, el)}>
                        <h5 className={classes.stickerPacksTitle}>{pack.name}</h5>
                        <Grid
                            container
                            spacing={2}
                        >
                            {pack?.stickers?.length && pack?.stickers?.map((sticker) => (
                                <Grid
                                    key={sticker.id}
                                    item
                                    xs={3}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <StickersItemButton
                                        item={sticker}
                                        onClick={onClick}
                                        key={sticker.id}
                                        type={StickersUploadButtonType.base}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </div>)
                })}
            </div>
        </>
    );
};

export default StickerContainerPacks;
