import { HttpService } from "./http.service";
import { ErrorWrapper } from "./utils";

export interface NotificationShape {
  id: string;
  type: string;
  createdAt: Date;
  profileId: string;
  profileName: string;
  profileAvatarSmall: string;
  profilePageId: number
}

export default class NotificationsService {
  static get entity(): string {
    return "news/notifications";
  }

  static async getNotifications(
    offset: number,
    isBookmarks?: boolean
  ): Promise<Array<NotificationShape>> {
    const request = `/${this.entity}${isBookmarks ? '/bookmarks' : ''}?limit=20&offset=${offset}`

    try {
      const response = await HttpService.get(request);
      return response.data;
    } catch (error: any) {
      const message = error?.response?.data?.error || error?.response?.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
