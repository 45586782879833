import StatusOnline from '@/components/base/statuses/status-online.component';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import React from 'react';
import profilePicture from '@/assets/images/demo-avatar.jpeg';
import clsx from "clsx";
import AvatarWithFallback from "@/components/AvatarWithFallback/AvatarWithFallback";

export interface IProps {
  profilePicture: string;
  isOnline: boolean;
  onClick?: () => void;
}

const useStyles = makeStyles<Theme, IProps>((theme: Theme) => ({
  photoWrapper: {
    maxWidth: '100px',
    maxHeight: '100px',
    aspectRatio: '100 / 100',
    width: '100%',

    position: 'relative',
    [theme.breakpoints.down('md')]: {
      maxWidth: 72,
      maxHeight: 76,
    },
  },
  photoInner: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  profilePhoto: {
    width: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: 10,
    height: '100%',
  },
  profilePhotoStatus: {
    position: 'absolute',
    bottom: 8,
    left: 8,
  },
  pointer: {
    cursor: 'pointer',
  }
}));

const UserAvatar = ({ ...props }: IProps) => {
  const classes = useStyles(props);
  const { isOnline, onClick } = props

  return (
    <div className={clsx(
      classes.photoWrapper,
      onClick ? classes.pointer : ""
    )} onClick={onClick}>
      <div className={classes.photoInner}>
        <AvatarWithFallback src={props?.profilePicture} className={classes.profilePhoto} isUserPhoto />
      </div>
      <div className={classes.profilePhotoStatus}>{isOnline && <StatusOnline small />}</div>
    </div>
  );
};

export default UserAvatar;
