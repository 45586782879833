import React, { Fragment, useEffect, useMemo } from "react";

import clsx from "clsx";
import { Formik } from "formik";

import { FormControl, Slider, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { updateCurrentUserRequest } from "@/app/users/users.actions";
import { UserProfileDTO } from "@/app/users/users.reducer";

import BaseTextarea from "@/components/base/base-textarea.component";
import BaseButton from "@/components/base/button.component";
import SecondaryTextFieldComponent from "@/components/base/secondary-text-field.component";
import SelectTextField from "@/components/base/select-text-field.component";

import { LanguageOptions } from "@/types/edit-select.types";
import { UserProfile } from "@/types/user-profile.types";

import useStaffCheck from "@/hooks/useStaffCheck";
import {
  AGE_MAX,
  AGE_MIN,
  HEIGHT_MAX,
  HEIGHT_MIN,
  perfectWomanSchema,
  TEXT_AREA_EDIT_MAX,
  WEIGHT_MAX,
  WEIGHT_MIN,
} from "@/utils/validationSchemas";

import { bodyList, childrenList, eyeColorOptions, hairList } from "../edit.consts";

const overrideStyles = {
  pageTitle: {
    marginBottom: 24,
  },
};

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    width: "100%",
  },
  aboutMeContainer: {
    display: "flex",

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  optionsTitlte: {
    flexShrink: 0,
    fontWeight: "400 !important",
    paddingLeft: 20,
  },
  aboutMeWrapper: {
    width: "100%",
    marginLeft: 80,
    [theme.breakpoints.down("md")]: {
      margin: "10px 4px",
    },
  },
  formControl: {
    "& > $inputField:not(:first-child)": {
      marginTop: 20,
    },
  },
  saveButton: {
    width: "100%",
    maxWidth: 144,
    marginTop: 32,
  },

  inputAppendElement: {
    flexShrink: 0,
    padding: "0 6px",
    minWidth: 118,

    [theme.breakpoints.down("md")]: {
      minWidth: 80,
    },
  },
  inputField: {},
}));

const PerfectWoman = () => {
  useStaffCheck();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { profile: currentUser }: UserProfileDTO = useAppSelector((state) => state.users.currentUser) || {};
  const userIsLoading = useAppSelector((state) => state.users.userIsLoading);

  const languageList: LanguageOptions[] = useMemo(
    () => [
      { value: "", name: "Not chosen" },
      { value: "basic", name: "Basic" },
      { value: "upper intermediate", name: "Upper Intermediate" },
      { value: "fluent", name: "Fluent" },
    ],
    []
  );

  const initialData = {
    perfectAge: currentUser.perfectAgeFrom && currentUser.perfectAgeTo ? [currentUser.perfectAgeFrom, currentUser.perfectAgeTo] : [AGE_MIN, AGE_MAX],
    perfectHair: currentUser.perfectHair,
    perfectEyes: currentUser.perfectEyes,
    perfectBody: currentUser.perfectBody,
    perfectHeight: currentUser.perfectHeightFrom && currentUser.perfectHeightTo ? [currentUser.perfectHeightFrom, currentUser.perfectHeightTo] : [HEIGHT_MIN, HEIGHT_MAX],
    perfectWeight: currentUser.perfectWeight || WEIGHT_MIN,
    perfectLangEnglish: currentUser.perfectLangEnglish,
    perfectChildren: currentUser.perfectChildren || null,
    perfectType: currentUser.perfectType || '',
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Typography variant="h3" style={overrideStyles.pageTitle}>
        Perfect {currentUser.gender === 'male' ? 'Woman' : 'Man'}
      </Typography>
      <Formik
        initialValues={initialData}
        validateOnChange={false}
        validationSchema={perfectWomanSchema}
        onSubmit={(values, { setSubmitting }) => {

          if (values.perfectHair) {
            //@ts-ignore
            values.perfectHair = values.perfectHair.toLowerCase()
          }
          ;

          //@ts-ignore
          if (values.perfectChildren === "no children") values.perfectChildren = "no";
          //@ts-ignore
          if (values.perfectChildren === "no but i want to have children") {
            values.perfectChildren = "no but I want to have children";
          }

          dispatch(updateCurrentUserRequest(values as unknown as UserProfile));
        }}
      >
        {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
          <form onSubmit={handleSubmit} className={classes.formContainer}>
            <FormControl fullWidth className={classes.formControl}>
              <div
                className={classes.inputField}
                style={{ maxWidth: "100%", padding: "0 11px" }}
              >
                <Typography variant="body1">
                  Age:{" "}
                  <span
                    style={{ color: "#808080" }}
                  >{`${values.perfectAge[0]} to ${values.perfectAge[1]}`}</span>
                </Typography>

                <Slider
                  name="perfectAge"
                  min={AGE_MIN}
                  max={AGE_MAX}
                  step={1}
                  getAriaLabel={() => "age range"}
                  value={values.perfectAge}
                  onChange={(event, value) =>
                    setFieldValue("perfectAge", value)
                  }
                  valueLabelDisplay="auto"
                  disableSwap
                />
              </div>

              <div className={classes.inputField}>
                <SelectTextField
                  label="perfectHair"
                  caseSensetive={true}
                  placeholder="Select hair color"
                  list={hairList}
                  setFieldValue={setFieldValue}
                  prepend={
                    <Typography
                      variant="body1"
                      fontWeight="400"
                      className={classes.inputAppendElement}
                    >
                      Hair color:
                    </Typography>
                  }
                  inputAppendElement={classes.inputAppendElement}
                />
              </div>
              <div className={classes.inputField}>
                <SelectTextField
                  label="perfectEyes"
                  placeholder="Select eyes color"
                  list={eyeColorOptions}
                  setFieldValue={setFieldValue}
                  prepend={
                    <Typography
                      variant="body1"
                      fontWeight="400"
                      className={classes.inputAppendElement}
                    >
                      Eyes color:
                    </Typography>
                  }
                  inputAppendElement={classes.inputAppendElement}
                />
              </div>
              <div className={classes.inputField}>
                <SelectTextField
                  label="perfectBody"
                  placeholder="Select body type"
                  list={bodyList}
                  setFieldValue={setFieldValue}
                  prepend={
                    <Typography
                      variant="body1"
                      fontWeight="400"
                      className={classes.inputAppendElement}
                    >
                      Body type:
                    </Typography>
                  }
                  inputAppendElement={classes.inputAppendElement}
                />
              </div>

              <div
                className={classes.inputField}
                style={{ maxWidth: "100%", padding: "0 11px" }}
              >
                <Typography variant="body1">
                  Height:{" "}
                  <span
                    style={{ color: "#808080" }}
                  >{`${values.perfectHeight[0]} to ${values.perfectHeight[1]}`}</span>
                </Typography>

                <Slider
                  name="perfectHeight"
                  min={HEIGHT_MIN}
                  max={HEIGHT_MAX}
                  step={0.1}
                  getAriaLabel={() => "perfect height range"}
                  value={values.perfectHeight}
                  onChange={(event, value) =>
                    setFieldValue("perfectHeight", value)
                  }
                  valueLabelDisplay="auto"
                  disableSwap
                />
              </div>

              <div className={classes.inputField}>
                <SecondaryTextFieldComponent
                  label="perfectWeight"
                  placeholder="lbs"
                  prepend={
                    <Typography
                      variant="body1"
                      fontWeight="400"
                      className={classes.inputAppendElement}
                    >
                      Weight:
                    </Typography>
                  }
                  onChange={handleChange}
                  type="number"
                  min={WEIGHT_MIN}
                  max={WEIGHT_MAX}
                />
              </div>

              <div className={classes.inputField}>
                <SelectTextField
                  placeholder={`English level`}
                  label={'perfectLangEnglish'}
                  list={languageList}
                  setFieldValue={setFieldValue}
                  prepend={
                    <Typography
                      variant="body1"
                      fontWeight="400"
                      className={classes.inputAppendElement}
                    >
                      English:
                    </Typography>
                  }
                  inputAppendElement={classes.inputAppendElement}
                />
              </div>

              <div className={classes.inputField}>
                <SelectTextField
                  label="perfectChildren"
                  placeholder="Children"
                  list={childrenList}
                  setFieldValue={setFieldValue}
                  prepend={
                    <Typography
                      variant="body1"
                      fontWeight="400"
                      className={classes.inputAppendElement}
                    >
                      Children:
                    </Typography>
                  }
                  inputAppendElement={classes.inputAppendElement}
                />
              </div>

              <div
                className={clsx(classes.inputField, classes.aboutMeContainer)}
              >
                <Typography variant="body1" className={classes.optionsTitlte}>
                  Perfect type:
                </Typography>
                <div className={classes.aboutMeWrapper}>
                  <BaseTextarea
                    label="perfectType"
                    placeholder={`Perfect type (${TEXT_AREA_EDIT_MAX} symbols max)`}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div style={{ marginTop: 32 }}>
                <BaseButton
                  loading={userIsLoading}
                  color="primary"
                  text="Save"
                  className={classes.saveButton}
                  onClick={() => handleSubmit()}
                />
              </div>
            </FormControl>
          </form>
        )}
      </Formik>
    </Fragment>
  );
};

export default PerfectWoman;
