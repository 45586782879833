import { Fade, CircularProgress, Theme, Badge } from '@mui/material';
import clsx from 'clsx';
import React, { CSSProperties, useCallback, useEffect, useMemo } from 'react';
import Dropzone from 'react-dropzone';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { makeStyles } from '@mui/styles';
import UploadVideoItem from '../base/upload-video-item';
import InfiniteScroll from 'react-infinite-scroller';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { UserVideoType } from '@/app/users/users.types';
import { getVideosRequest } from '@/app/users/users.actions';
import { IUserVideosData } from '@/app/users/users.dto';

export interface StyleProps {
  isExpended: boolean;
  isEditable: boolean;
}

type UploadFileLayoutProps = {
  className: string;
  handleFileUploading: (image: FormData) => void;
  handleRemoveFile: (id: string) => void;
  isUploading: boolean;
  handleDrop: (files: any) => void;
  isExpended?: boolean;
  isEditable?: boolean;
  isDelitable?: boolean;
  handleSelect?: (file: unknown) => void;
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  root: ({ isExpended }) => ({
    maxWidth: isExpended ? '100%' : 376,
    border: isExpended ? `2px dashed ${theme.palette.radioSelected.main}` : '',
    padding: isExpended ? 8 : 12,
    borderRadius: 10,
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'space-between',
  }),
  dropzone: ({ isExpended }) => ({
    cursor: 'pointer',
    color: theme.palette.radioSelected.main,
    outline: 'none',
    margin: 12,
    borderRadius: 15,
    width: isExpended ? 180 : 96,
    height: isExpended ? 180 : 96,
    backgroundColor: theme.palette.info.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  innerZone: {
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    borderRadius: 'inherit',
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
  },
  dropIcon: {
    fontSize: '2.7rem',
  },
  imageType: {
    borderRadius: 10,
    color: '#fff',
    fontSize: 12,
    fontWeight: 600,
    padding: '2px 6px',
  },
  publicImage: {
    backgroundColor: theme.palette.success.main,
  },
  privateImage: {
    backgroundColor: theme.palette.primary.main,
  },
  scrollSection: {
    overflow: 'auto',
    overflowX: 'hidden',
    height: '600px',
  },
}));

const UploadVideoLayout = ({
                             handleDrop,
                             className,
                             handleRemoveFile,
                             handleSelect,
                             isUploading,
                             isExpended = true,
                             isEditable = true,
                             isDelitable = false,
                           }: UploadFileLayoutProps) => {
  const classes = useStyles({ isExpended, isEditable });

  const windowWidth = useMemo(() => window.innerWidth, []);
  const windowHeight = useMemo(() => window.innerHeight - 90, []);

  const userId = useAppSelector((state) => state.users.currentUser?.id);
  const privateVideos: IUserVideosData = useAppSelector((state) => state.users.privateVideos);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getVideosRequest(userId, UserVideoType.PRIVATE_VIEW, privateVideos.data.length));
  }, []);

  const loadMorePrivateVideos = useCallback(
    async (pageNumber: number) => {
      if (userId) {
        dispatch(getVideosRequest(userId, UserVideoType.PRIVATE_VIEW, privateVideos.data.length));
      }
    },
    [dispatch, privateVideos, privateVideos.data, userId]
  );

  return (
    <Dropzone
      onDrop={handleDrop}
      accept='video/mp4, video/webm, video/ogg, application/mp4, video/quicktime, application/mp4, video/x-msvideo, video/x-flv'
    >
      {({ getRootProps, getInputProps }) => (
        <section className={classes.scrollSection}>
          <InfiniteScroll
            className={clsx(classes.root, className)}
            pageStart={0}
            useWindow={false}
            initialLoad={false}
            loadMore={loadMorePrivateVideos}
            hasMore={privateVideos.nextPage}
            loader={
              <div className='loader' key={0}>
                Loading ...
              </div>
            }
          >
            {isEditable && (
              <div {...getRootProps()} className={classes.dropzone}>
                <input {...getInputProps()} />
                <AddCircleOutlineIcon fontSize='medium' color='inherit' className={classes.dropIcon} />
              </div>
            )}
            {privateVideos.data.map(({ private: isPrivate, ...rest }) => (
              <UploadVideoItem
                {...rest}
                isEditable={isDelitable}
                isPrivate={isPrivate}
                isExpended={isExpended}
                handleRemoveFile={handleRemoveFile}
                handleSelect={handleSelect}
              />
            ))}

            <Fade in={isUploading}>
              <div className={classes.dropzone}>
                <CircularProgress color='inherit' size={24} />
              </div>
            </Fade>
          </InfiniteScroll>
        </section>
      )}
    </Dropzone>
  );
};

export default UploadVideoLayout;
