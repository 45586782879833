import { UserProfile } from "@/types/user-profile.types";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { differenceInMinutes } from "date-fns";
import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "./avatar.component";
// import StatusCamera from "./statuses/status-camera.component";
import StatusOnline from "./statuses/status-online.component";
import { calculateAge } from "@/helpers/helpers";
import { useSelector } from "react-redux";
import { selectUserIsAvailable } from "@/app/users/users.selectors";


type ProfileCardSmallProps = {
  to: string;
  user: UserProfile;
  isCheckOnlineIsState?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  // group relative focus:outline-none transition-all flex flex-col
  cardWrapper: {
    display: "flex",
    flexDirection: "column",
    transition: "all",
    position: "relative",
    textDecoration: 'none',
    cursor: "pointer",

    "&:focus": {
      outline: "none",
    },

    "&:hover $hoverElement": {
      opacity: '1',
    },
  },
  cardInner: {
    position: "relative",
    zIndex: 1,
  },
  cardInfo: {
    position: "absolute",
    bottom: 6,
    left: 6,
    display: "flex",
    flexDirection: "column",
    zIndex: 1,
  },
  hoverElement: {
    transition: "all .3s",

    background: 'rgba(0,0,0,.3)',
    width: '100%',
    height: '100%',
    opacity: '0',
    position: 'relative',
    borderRadius: '10px',
    zIndex: 2,
  },
  avatarProfileInfo: {
    display: "flex",
    fontSize: 18,
    lineHeight: "28px",
    margin: 0,
    textDecoration: 'none',
    marginTop: 10,
    color: "black",
  },
  avatarName: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  avatarAge: {
    flexShrink: 0,
  },
}));

const ProfileCardSmall = ({ to, user, isCheckOnlineIsState }: ProfileCardSmallProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const isUserAvailable = useSelector(state => selectUserIsAvailable(state, user?.id));

  const isUserActiveNow = useMemo(
    () => {
      if (isCheckOnlineIsState) {
        return isUserAvailable
      }
      return user?.activeAt && differenceInMinutes(new Date(), new Date(user.activeAt)) < 15;
    },
    [isCheckOnlineIsState, user?.activeAt, isUserAvailable]
  );


  const clickHandler = useCallback(() => {
    window.scrollTo(0, 0);
    navigate(to)
  }, [navigate, to]);

  return (
    <div onClick={clickHandler} className={classes.cardWrapper}>
      <div className={classes.cardInner}>
        <Avatar size="medium" url={user.avatarLarge} gender={user.gender}>
          <div className={classes.hoverElement} />
          <div className={classes.cardInfo}>
            {/* <StatusCamera /> */}
            {isUserActiveNow && <StatusOnline />}
          </div>
        </Avatar>
      </div>
      <p className={classes.avatarProfileInfo}>
        <span className={classes.avatarName}>
          {user && user.name
            ? user.name
            : "Username"}
        </span>
        {user?.birthday ? <span className={classes.avatarAge}>{`, ${calculateAge(user.birthday)}`}</span> : null}
      </p>

    </div>
  );
};

export default ProfileCardSmall;
