import { ReactComponent as SearchIcon } from '@/assets/icons/search.svg';
import profilePicture from '@/assets/images/demo-avatar.jpeg';
import BaseButton from '@/components/base/button.component';
import PrimaryBaseTextField from '@/components/base/text-field-base.component';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import UserLink from './user-link';
import { getSearchUsersRequest } from '@/app/users/users.actions';
import { IconButton } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllAvailableUsers,
  selectCurrentUser,
  selectSearchUsersData,
  selectUserBookmarks
} from "@/app/users/users.selectors";
import { ReactComponent as ContactsIcon } from '@/assets/icons/chats/contacts-black.svg';
import { ReactComponent as ContactsWhiteIcon } from '@/assets/icons/chats/contacts-white.svg';

interface AvailableUsersProps {
  onClick?: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '272px',
    padding: '14px',
    marginTop: '-12px',
    zIndex: '90',
    background: '#FFFFFF',
    borderRadius: '10px 0 0 10px',
    boxShadow: '0px 0px 20px rgb(0 0 0 / 5%)',
    overflowY: 'scroll',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '56px',
    fontSize: '22px',
    fontWeight: '600',
    color: '#000000',
  },
  buttonsWrapper: {
    paddingTop: '8px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  mainButton: {
    width: '80px',
    height: '24px',
    minHeight: '24px!important',
    padding: '0',
    boxShadow: 'none!important',
  },
  searchUsers: {
    marginTop: '14px',
  },
  usersWrapper: {
    marginTop: '27px',
    overflowY: 'scroll',
    height: 'calc(100dvh - 332px)',
    [theme.breakpoints.down('lg')]: {
      height: 'calc(100dvh - 175px)',
    },
  },
  searchedWrapper: {
    display: 'flex',
    maxHeight: 30,
    width: '100%',
  },
  searchedInput: {
    width: '100%',
    paddingRight: 6,
    backgroundSize: 14,
    lineHeight: '30px',
    outline: ' 8px ridge rgba(170, 50, 220, .6)',
    backgroundPosition: '94% 50%',
  },
  shown: {
    '& span': {
      color: 'white!important',
    },
  },
  hide: {
    '& span': {
      color: 'black!important',
    },
  },
  searchButtonBottom: {
    width: 40,
    lineHeight: '30px',
    height: 30,
    marginLeft: '10px!important',
  },
}));

const AvailableUsers: FC<AvailableUsersProps> = ({ onClick }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const availableUsers = useSelector(selectAllAvailableUsers);
  const searchUsersData = useSelector(selectSearchUsersData);
  const currentUser = useSelector(selectCurrentUser);
  const bookmarks = useSelector(selectUserBookmarks);

  const [viewState, setViewState] = useState<'all' | 'online' | 'bookmarks'>('all');
  const [filterText, setFilterText] = useState('');

  const filteredUsers = useMemo(() => {
    switch (viewState) {
      case 'online':
        return availableUsers.filter(
          (user) =>
            (user.name.includes(filterText) || String(user.pageId).includes(filterText)) &&
            user.gender !== currentUser.profile.gender
        );
      case 'bookmarks':
        return bookmarks.filter(
          (user) => user.name.includes(filterText) || String(user.pageId).includes(filterText)
        );
      default:
        return searchUsersData;
    }
  }, [viewState, filterText, availableUsers, bookmarks, searchUsersData, currentUser.profile.gender]);

  const handleSearch = useCallback(() => {
    if (viewState === 'all') {
      const searchParams = isNaN(Number(filterText))
        ? { name: encodeURIComponent(filterText) }
        : { pageId: filterText };
      dispatch(getSearchUsersRequest(searchParams));
    }
  }, [dispatch, filterText, viewState]);

  const getIconComponent = useCallback(
    (tab: string) => {
      if (tab === 'bookmarks') {
        return viewState === 'bookmarks' ? <ContactsWhiteIcon /> : <ContactsIcon />;
      }
      return undefined;
    },
    [viewState]
  );

  const genderLabel = currentUser?.profile?.gender === 'male' ? 'Girls' : 'Mens';

  const tabs = ['all', 'online', 'bookmarks']

  return (
    <div className={classes.container}>
      <div className={classes.title}>{`${genderLabel} List`}</div>
      <div className={classes.buttonsWrapper}>
        {tabs.map((tab) => (
          <BaseButton
            key={tab}
            text={
              tab === 'bookmarks'
                ? String(bookmarks?.length || 0)
                : tab.charAt(0).toUpperCase() + tab.slice(1)
            }
            color={viewState === tab ? 'primary' : 'info'}
            variant="contained"
            onClick={() => setViewState(tab as typeof viewState)}
            wrapperClass={clsx(classes.mainButton, {
              [classes.shown]: viewState === tab,
              [classes.hide]: viewState !== tab,
            })}
            Icon={getIconComponent(tab)}
          />
        ))}
      </div>
      <div className={classes.searchUsers}>
        <div className={classes.searchedWrapper}>
          <PrimaryBaseTextField
            onChange={(e) => setFilterText(e.target.value)}
            label="Name or ID"
            inputClassName={classes.searchedInput}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                handleSearch();
              }
            }}
          />
          <IconButton onClick={handleSearch} className={classes.searchButtonBottom}>
            <SearchIcon />
          </IconButton>
        </div>
      </div>
      <div className={classes.usersWrapper}>
        {filteredUsers.map((user) => (
          <UserLink
            key={user.id}
            onClick={onClick}
            userName={user.name}
            user={currentUser}
            uid={user.id}
            avatar={user.avatarSmall || profilePicture}
            pageId={String(user.pageId)}
            isNewMessage={false}
          />
        ))}
      </div>
    </div>
  );
};

export default memo(AvailableUsers);