import { HttpService, WsService } from "./http.service";
import { ErrorWrapper } from "./utils";
import { StorageService } from './storage.service';

export default class AcquiringService {
  static get entity(): string {
    return "payments-acquiring";
  }

  static async getAcquiring(): Promise<any> {
    try {
      const token = StorageService.getToken();
      if (!token) {
        return [];
      }
      const response = await HttpService.get(`/${this.entity}`);
      return response.data;
    } catch (error: any) {
      const message = error?.response?.data?.error || error?.response?.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
