import { useAppSelector } from "@/app/hooks";
import AdvertisementBanner from "@/assets/images/advertisement.png";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import DrawerNav from "./drawer-nav.component";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflowY: "scroll",
    zIndex: 10,
    boxSizing: "border-box",

    [theme.breakpoints.down("lg")]: {
      overflowY: "scroll",
    },

    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  closeButtonWrapper: {
    display: "none",
  },
  closeButton: {
    padding: "10px 30px 0",
    cursor: "pointer",
    border: "none",
    outline: "none",
  },
  menuButton: {
    display: "none",
  },

  "@media(max-width: 650px)": {
    menuButton: {
      display: "block",
    },
    closeButtonWrapper: {
      display: "block",
    },
  },
}));

const Drawer = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.users.currentUser);

  return (
    <div className={classes.container}>
      <div>
        {/* <DrawerProfile user={user || null} userIsLoading={userIsLoading} /> */}
        <DrawerNav user={currentUser} />
        {currentUser && <img
          style={{
            backgroundSize: "cover",
            borderRadius: 10,
            width: 160,
            height: 340,
            marginLeft: 25,
            cursor: 'pointer'
          }}
          src={AdvertisementBanner}
          alt='Wedaf credits banner'
          onClick={() => navigate('/user/credits')}
        />}
      </div>
    </div>
  );
};

export default Drawer;
