import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

const ROUTES = {
    contacts: '/contacts',
    faq: '/faq',
    privacyPolicy: '/privacy-policy',
    antiScamPolicy: '/anti-scam-policy',
    refundPolicy: '/refund-policy',
    termsOfUse: '/terms-of-use',
    cookiePolicy: '/cookie-policy',
    complaintPolicy: '/complaint-policy',
    cancellationPolicy: '/cancellation-policy',
}

const MetaTags = () => {
    const location = useLocation();
    const canonicalUrl = `${window.location.origin}${location.pathname}`;
    const META = {
        title: 'Wedaf',
        description: 'Our platform, wedaf.com is an international social network designed for meeting new people and engaging in global communication.',
    }

    switch (location.pathname) {
        case ROUTES.contacts:
            META.title = 'Contact Information | Wedaf';
            META.description = 'Get in touch with the Wedaf team: address, phone, email, and a contact form for your inquiries and proposals.';
            break;
        case ROUTES.faq:
            META.title = 'Frequently Asked Questions | Wedaf';
            META.description = 'Find answers to common questions about Wedaf: account recovery, profile settings, and other useful tips.';
            break;
        case ROUTES.privacyPolicy:
            META.title = 'Privacy Policy | Wedaf';
            META.description = 'Learn how Wedaf collects, uses, and protects your personal data. Read our privacy policy to understand your rights and our data security practices.';
            break;
        case ROUTES.antiScamPolicy:
            META.title = 'Anti-Scam Policy | Wedaf';
            META.description = 'Discover how Wedaf protects users from scams and fraudulent activities. Learn about our security measures and how to report suspicious behavior.';
            break;
        case ROUTES.refundPolicy:
            META.title = 'Refund Policy | Wedaf';
            META.description = 'Learn about Wedaf’s refund policy, including eligibility, processing times, and conditions for requesting a refund.';
            break;
        case ROUTES.termsOfUse:
            META.title = 'Terms of Use | Wedaf';
            META.description = 'Review Wedaf’s Terms of Use to understand the rules and guidelines for using our platform, including user responsibilities and legal agreements.';
            break;
        case ROUTES.cookiePolicy:
            META.title = 'Cookie Policy | Wedaf';
            META.description = 'Learn how Wedaf uses cookies to enhance your browsing experience. Read our cookie policy to manage your preferences and understand data collection practices.';
            break;
        case ROUTES.complaintPolicy:
            META.title = 'Complaint Policy | Wedaf';
            META.description = 'Understand Wedaf’s complaint policy, including how to submit a complaint, response times, and resolution procedures to ensure fair treatment.';
            break;
        case ROUTES.cancellationPolicy:
            META.title = 'Cancellation Policy | Wedaf';
            META.description = 'Learn about Wedaf’s cancellation policy, including terms, conditions, and procedures for canceling services or subscriptions.';
            break;
    }

    return (
        <Helmet>
            <title>{META.title}</title>
            <meta name="description" content={META.description}/>

            <meta property="og:title" content={META.title}/>
            <meta property="og:description" content={META.description}/>

            <link rel="canonical" href={canonicalUrl}/>
        </Helmet>
    )
};

export default MetaTags;
