import { EVideoStatus, IUserVideosData, SignedVideoUrlResponse, UserVideosDTO } from '@/app/users/users.dto';
import { UserVideoType } from '@/app/users/users.types';
import { HttpService } from './http.service';
import { ErrorWrapper } from './utils';
import { StorageService } from './storage.service';
import axios from 'axios';

export default class VideosService {
  static get entity(): string {
    return 'videos';
  }

  static async getVideos(offset = 0, viewType: UserVideoType): Promise<IUserVideosData> {
    try {
      const response = await HttpService.get(
        `/${this.entity}/${viewType === UserVideoType.PRIVATE_VIEW ? 'private' : 'public'}?offset=${offset}&limit=999`
      );
      return response.data;
    } catch (error: any) {
      const message = error?.response?.data?.error || error?.response?.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getUserVideos(id: string, type: UserVideoType): Promise<IUserVideosData> {
    try {
      const token = StorageService.getToken();
      if (token) {
        const response = await HttpService.get(`/${this.entity}/${type}?limit=999`);
        return response.data;
      }
      {
        const response = await HttpService.get(`/public/profiles/${id}`);
        return {
          nextPage: false,
          prevPage: false,
          data: response.data.videos,
        };
      }
    } catch (error: any) {
      const message = error?.response?.data?.error || error?.response?.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getSignedUrl(type: UserVideoType): Promise<SignedVideoUrlResponse> {
    try {
      const response = await HttpService.post(`/${this.entity}/upload/signed-url/init`, {
        private: `${type === UserVideoType.PRIVATE_VIEW}`,
      });
      return response.data;
    } catch (error: any) {
      const message = error?.response?.data?.error || error?.response?.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async changeVideoStatus(id: string): Promise<UserVideosDTO> {
    try {
      const response = await HttpService.post(`/${this.entity}/upload/signed-url/status`, {
        id,
        status: EVideoStatus.SUCCESS,
      });
      return response.data;
    } catch (error: any) {
      const message = error?.response?.data?.error || error?.response?.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async postVideo(signedUrl: string, file: File): Promise<UserVideosDTO> {
    try {
      const response = await HttpService.putToCustomUrl(signedUrl, file, {
        headers: {
          "Content-Type": file.type,
        },
      });
      return response.data;
    } catch (error: any) {
      const message = error?.response?.data?.error || error?.response?.statusText;
      throw new ErrorWrapper(error, message);
    }
  }


  static async deleteVideo(id: string, type: UserVideoType): Promise<void> {
    try {
      const response = await HttpService.delete(`/${this.entity}/${id}`);
      return response.data;
    } catch (error: any) {
      const message = error?.response?.data?.error || error?.response?.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
