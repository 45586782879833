import { closeNotEnoughBalance, openNotEnoughBalance } from '@/app/ui/ui.actions';
import { useDispatch } from 'react-redux';
import useIsStaffUserRole from './useIsStaffUserRole';

const useBalanceCheckHook = (balance: number) => {
  const dispatch = useDispatch();
  const isStaff = useIsStaffUserRole();

  const checkBalance = (price: number, onEnoughBalanceAction: () => void, onNotEnoughBalanceAction?: () => void) => {
    if (balance >= price || isStaff) {
      onEnoughBalanceAction();
      closeNotEnoughBalance();
    } else {
      onNotEnoughBalanceAction?.()
      dispatch(openNotEnoughBalance(price));
    }
  };

  return { checkBalance };
};

export default useBalanceCheckHook;
