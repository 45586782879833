import { HttpService, WsService } from "./http.service";
import { ErrorWrapper } from "./utils";

export default class EmailEventsBlacklistService {
  static get entity(): string {
    return "email-events-blacklist";
  }

  static async getEmailEventsBlacklist(): Promise<any> {
    try {
      const response = await HttpService.get(`/${this.entity}`);
      return response.data;
    } catch (error: any) {
      const message = error?.response?.data?.error || error?.response?.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async addEmailEventBlackList(emailEvent: string): Promise<any> {
    try {
      const response = await HttpService.post(`/${this.entity}`, { emailEvent });

      return response.data;
    } catch (error: any) {
      const message = error?.response?.data?.error || error?.response?.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async deleteEmailEventBlackList(id: string): Promise<any> {
    try {
      const response = await HttpService.delete(`/${this.entity}/${id}`);

      return response.data;
    } catch (error: any) {
      const message = error?.response?.data?.error || error?.response?.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
