import { Divider, IconButton, InputBase, Paper, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as EditIcon } from '@/assets/icons/edit.svg';
import clsx from 'clsx';
import { ProfileListTypes } from '@/types/profile-list.types';
import { UserProfileDTO } from '@/app/users/users.reducer';
import ProfileListInfo from './profile-list-info.component';
import { ReactComponent as AddUserIcon } from '@/assets/icons/contacts-plus.svg';
import { ReactComponent as ContactsIcon } from '@/assets/icons/new-mail-contacts-purple.svg';
import { ReactComponent as PhotoIcon, ReactComponent as CameraIcon } from '@/assets/icons/camera.svg';
import { ReactComponent as VideoIcon } from '@/assets/icons/video.svg';
import { ReactComponent as MessageIcon } from '@/assets/icons/profile-actions/message.svg';
import { ReactComponent as ChatIcon } from "@/assets/icons/navigation/chat.svg";
import { ReactComponent as EmailMiniIcon } from '@/assets/icons/profile-actions/email-mini.svg';
import { ReactComponent as AddContactIcon } from '@/assets/icons/profile-actions/add-contact.svg';
import { ReactComponent as PresentIcon } from '@/assets/icons/profile-actions/present.svg';
import { ReactComponent as AboutMeIcon } from '@/assets/icons/about-me.svg';
import Upload from '@mui/icons-material/Upload';
import { ReactComponent as WhiteCameraIcon } from '@/assets/icons/camera-white.svg';
import { useAppBreakpoints } from '@/hooks';
import BaseCard from '@/components/base/base-card.component';
import BaseButton from '@/components/base/button.component';
import useProfileRelation from '@/hooks/useProfileRelation';
import { useBinarySwitcher } from '@/hooks/useBinarySwitcher';
import DialogWriteMail from '@/components/dialogs/write-mail.dialog.component';
import useBlankProfileAvatart from '@/hooks/useBlankProfileAvatart';
import StatusOnline from '@/components/base/statuses/status-online.component';
import { differenceInMinutes } from 'date-fns';
import Send from '@mui/icons-material/Send';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { updateAvatarDataRequest, updateAvatarRequest, updateCurrentUserRequest, } from '@/app/users/users.actions';
import { IBlackList, UserProfile } from '@/types/user-profile.types';
import { toggleSnackbarOpen } from '@/app/ui/ui.actions';
import DialogUploadFile from '@/components/dialogs/upload-photo-file.dialog.component';
import CloseIcon from '@mui/icons-material/Close';
import BookmarksService from '@/services/bookmarks.service';
import useIsStaffUserRole from '@/hooks/useIsStaffUserRole';
import usePersistentTab from '@/components/base/use-persistance-tab-hook';
import DialogGift from '@/components/dialogs/send-gift.dialog.component';
import useDialogPersistentTab from '@/components/base/use-dialog-persistance-tab-hook';
import { defaultUserPhotosData, defaultUserVideosData } from '@/app/users/users.dto';
import { BlockOutlined } from '@mui/icons-material';
import AddBlockedUserModal from './add-blocked-user.component';
import DeleteBlockedUserModal from './delete-blocked-user.component';
import { useSelector } from "react-redux";
import { selectBlockedUsers, selectCurrentUser, selectCurrentUserAvatarLarge } from "@/app/users/users.selectors";
import { Gallery, Item } from "react-photoswipe-gallery";
import { useImageSizesCalculator } from "@/hooks/useImageSizesCalculator";
import AvatarWithFallback from "@/components/AvatarWithFallback/AvatarWithFallback";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  marginBlockUtil: {
    marginBottom: 24,
    [theme.breakpoints.down('md')]: {
      marginBottom: 16,
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 8,
    },
  },

  generalInfoWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },

  mainWrapper: {
    display: 'flex',
    width: '100%',
  },

  photoWrapper: {
    maxWidth: 252,
    aspectRatio: '252 / 358',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',

    [theme.breakpoints.down('md')]: {
      maxWidth: 72,
      maxHeight: 76,
    },
  },
  photoWrapperHover: {
    '&:hover $uploadPhoto	': {
      bottom: 0,
    },
  },
  uploadPhoto: {
    fontWeight: 800,
    backgroundColor: 'rgba(0,0,0,.5)',
    width: '100%',
    height: 45,
    position: 'absolute',
    bottom: -45,
    transition: 'all .3s',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    color: 'white',

    '&:hover': {
      color: 'rgb(80, 62, 182)',
    },
  },
  photoInner: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  profilePhoto: {
    width: '100%',
    borderRadius: 10,
    height: '100%',
  },
  infoWrapper: {
    width: '100%',
    marginLeft: 28,
    [theme.breakpoints.down('md')]: {
      marginLeft: 6,
      marginBottom: 6,
    },
  },
  profileTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
    },
  },
  profileUsername: {
    fontWeight: 700,
    fontSize: 34,
    overflowWrap: 'anywhere',

    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },
  },
  profileId: {
    fontSize: 14,
    lineHeight: '20px',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      lineHeight: '16px',
    },
  },
  generaInfoCard: {
    width: '100%',
    display: 'flex',
    padding: '28px 28px 32px',

    [theme.breakpoints.down('md')]: {
      padding: 16,
      paddingBottom: 0,
    },
  },
  infoEditIcon: {
    minWidth: 28,
    maxWidth: 28,
    height: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.info.main,
    borderRadius: 9999,
    marginLeft: 8,
  },
  infoEditButton: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: '#000',
    marginRight: 15,
    '&:hover $infoEditIcon': {
      opacity: 0.8,
    },
  },
  profileStatus: {
    display: 'block',
  },
  profileMetaInfoDesktop: {
    display: 'flex',
    marginTop: 24,
    gap: 22,
    flexWrap: 'wrap',
  },
  myProfileMetaContainer: {
    display: 'flex',
    width: '100%',
  },
  myProfileMetaAction: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down(500)]: {
      flexDirection: 'column',
    },
  },
  myProfileMetaInfo: {
    display: 'flex',
    marginLeft: 9,
    flexDirection: 'column',

    [theme.breakpoints.down(500)]: {
      alignItems: 'center',
      marginLeft: 0,
    },
  },
  profileListInfo: {
    display: 'block',
    paddingTop: 16,
    paddingBottom: 24,

    [theme.breakpoints.down('xl')]: {
      paddingBottom: 0,
    },

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },

  // class="grid grid-cols-2 gap-x-1 my-auto"
  profileMetaInfo: {
    display: 'none',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    columnGap: 1,
    marginTop: 'auto',
    marginBottom: 'auto',

    [theme.breakpoints.up('md')]: {
      dispaly: 'grid',
    },
  },
  // class="flex items-center space-x-2"
  profileMetaInfoItem: {
    display: 'flex',
    alignItems: 'center',
  },

  profileMetaInfoItemIcon: {
    flexShrink: 0,
    width: 32,
    height: 32,

    [theme.breakpoints.down('md')]: {
      width: 18,
      height: 18,
    },
  },

  // class="text-xs md:text-base xl:text-sm leading-none"
  profileMetaText: {
    marginLeft: 8,

    fontSize: '0.875rem',
    lineHeight: '1.25rem',

    [theme.breakpoints.down('xl')]: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
    },
  },

  // class="xl:text-lg !leading-none"
  profileMetaCounter: {
    fontSize: 18,
    lineHeight: '28px',

    [theme.breakpoints.down('xl')]: {
      fontSize: 16,
      lineHeight: '18px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      lineHeight: '18px',
    },
  },
  profileMetaDivider: {
    display: 'inline',
    [theme.breakpoints.down('md')]: {
      visibility: 'hidden',
    },
  },
  profileStatusMobile: {
    display: 'none',
    margin: '16px 0',

    [theme.breakpoints.down('md')]: {
      display: 'flex',
    },
  },
  uploadWrapper: {
    marginBottom: 15,
    display: 'flex',
    justifyContent: 'center',
  },

  metaCtaIcon: {
    maxWidth: 42,
    maxHeight: 42,

    '& > .MuiButton-startIcon': {
      margin: 0,
    },
    minWidth: '0 !important',
  },

  whiteCameraIcon: {
    marginLeft: '20px !importnant',
  },

  photoButtonContainer: {
    position: 'absolute',
    bottom: '10px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },

  avatarContainer: {
    transform: 'translateX(10px)',
    marginBottom: 20,
    '&::after': {
      content: "''",
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: 10,
      opacity: 0,
      transition: 'opacity',
      backgroundColor: 'linear-gradient(to right bottom, rgb(80, 62, 182), rgb(234, 65, 96))',
      '&:focus, &:hover': {
        opacity: 0.7,
      },
    },
  },
  avatarStatuses: {
    display: 'flex',
    flexDirection: 'column',

    position: 'absolute',
    bottom: 6,
    left: 6,
    zIndex: 1,
  },
  cardOverrides: {
    flexDirection: 'column',
  },
  onlineStatus: {
    position: 'absolute',
    bottom: 12,
    left: 12,
    [theme.breakpoints.down('md')]: {
      bottom: 4,
      left: 4,
    },
  },
  status: {
    [theme.breakpoints.down('md')]: {
      display: 'none !important',
    },
  },
  editWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  aboutMeIcon: {
    cursor: 'pointer',
    height: 16,
  },
  takePhotoButton: {
    width: '250px !important',
    height: '42px !important',
    marginLeft: '10px !important',
  },
  cropperPaper: {
    maxWidth: '100% !important'
  },
  cropperDialog: {
    [theme.breakpoints.down('lg')]: {
      marginBottom: 40,
    },
  },
  cropperContainer: {
    position: 'relative',
    boxSizing: 'border-box'
  },
  cropperContainerFooter: {
    justifyContent: 'center',
    padding: '3px 16px',
    display: "flex",
    flexDirection: 'column'
  },
  galleryContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  }
}));

type UserGeneralInfoProps = {
  mainInfo: ProfileListTypes[];
  currentProfile: UserProfileDTO;
  scrollAboutMe: () => void;
  openRegistrationForm: (status: boolean) => void;
};

const UserGeneralInfo: FC<UserGeneralInfoProps> = ({
                                                     currentProfile,
                                                     mainInfo,
                                                     scrollAboutMe,
                                                     openRegistrationForm
                                                   }) => {
  const [isMyProfile] = useProfileRelation();
  const { oppositeUserPicture, currentUserPicture } = useBlankProfileAvatart();
  const dispatch = useAppDispatch();

  const isStaff = useIsStaffUserRole();

  const myUser = useSelector(selectCurrentUser);

  const myUserAvatar = useSelector(selectCurrentUserAvatarLarge);

  const blockedUsers: IBlackList[] = useSelector(selectBlockedUsers);

  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const currentAvatar = useMemo(() => {
    if (currentProfile?.profile?.avatarLarge) return currentProfile?.profile?.avatarLarge;

    if (myUser?.profile?.id === currentProfile?.id) return myUserAvatar || currentUserPicture;

    if (isMyProfile) return currentUserPicture;

    return oppositeUserPicture;
  }, [
    currentProfile?.id,
    currentProfile?.profile?.avatarLarge,
    currentUserPicture,
    isMyProfile,
    myUser?.profile?.id,
    myUserAvatar,
    oppositeUserPicture,
  ]);

  const classes = useStyles();
  const { mdAndUp, smAndDown } = useAppBreakpoints();
  const navigate = useNavigate();
  const [isWriteDialogOpen, openDialog, hideDialog] = useBinarySwitcher();
  const [isGiftDialogOpen, openGiftDialog, hideGiftDialog] = useBinarySwitcher();

  const publicPhotos = useAppSelector((state) => state.users['publicPhotos']) || defaultUserPhotosData;
  const privatePhotos = useAppSelector((state) => state.users['privatePhotos']) || defaultUserPhotosData;

  const publicVideos = useAppSelector((state) => state.users['publicVideos']) || defaultUserVideosData;
  const privateVideos = useAppSelector((state) => state.users['privateVideos']) || defaultUserVideosData;

  const [isLoading, setIsLoading] = useState(null);
  const [videoStream, setVideoStream] = useState(null);

  const photoCounter = useMemo(
    () => publicPhotos.data.length + privatePhotos.data.length,
    [privatePhotos?.data.length, publicPhotos?.data.length]
  );

  const videoCounter = useMemo(
    () => publicVideos.data.length + privateVideos.data.length,
    [privateVideos?.data.length, publicVideos?.data.length]
  );
  const bookMarksCount = useAppSelector((state) => state.users.bookmarks) || [];

  const [isBookmark, setIsBookmark] = useState(currentProfile?.profile?.isBookmarks || false);
  const [currentStatus, setCurrentStatus] = useState(currentProfile?.profile?.status || '');
  const [isModalVisible, setModalVisible] = useState(false);

  const windowWidth = useMemo(() => window.innerWidth, []);
  const windowHeight = useMemo(() => window.innerHeight, []);

  const canvasWidth = useMemo(() => {
    if (windowWidth < 433) return 232;
    if (windowWidth > 839) return 640;

    return windowWidth - 200;
  }, [windowWidth]);

  const canvasHeight = useMemo(() => {
    if (windowWidth < 433) return 174.5;
    if (windowWidth > 839) return 480.5;

    return canvasWidth * 0.75;
  }, [canvasWidth, windowWidth]);

  const [openTab] = usePersistentTab(`/chat/${myUser?.profile?.id}/${currentProfile?.id}`);
  const [openDialogTab] = useDialogPersistentTab(`/dialog/${myUser?.profile?.id}/${currentProfile?.id}`);

  const openTabCallBack = useCallback(() => {
    openTab(`/chat/${myUser?.profile?.id}/${currentProfile?.id}`);
  }, [openTab, myUser?.profile?.id, currentProfile?.id]);

  const openDialogTabCallBack = useCallback(() => {
    openDialogTab(`/dialog/${myUser?.profile?.id}/${currentProfile?.id}`);
  }, [openDialogTab, myUser?.profile?.id, currentProfile?.id]);

  const [openAddUserToBlockedUserModal, setOpenAddUserToBlockedUserModal, closeOpenAddUserToBlockedUserModal] =
    useBinarySwitcher(false);
  const [openDeleteBlockedUserModal, setDeleteBlockedUserModal, closeDeleteBlockedUserModal] = useBinarySwitcher(false);

  const blockedUsersList = useMemo(() => blockedUsers.map((user) => user.blockedProfileId), [blockedUsers]);

  const metaActions = useMemo(() => {
    if (isBookmark) {
      return [
        {
          icon: <ChatIcon />,
          onClick: () => {
            if (!myUser) {
              openRegistrationForm(true);
              return;
            }
            openTabCallBack();
          },
        },
        {
          icon: <MessageIcon />,
          onClick: () => {
            if (!myUser) {
              openRegistrationForm(true);
              return;
            }
            openDialogTabCallBack();
          },
        },
        {
          icon: <EmailMiniIcon />,
          onClick: () => {
            if (!myUser) {
              openRegistrationForm(true);
              return;
            }
            openDialog();
          },
        },
        {
          icon: <CameraIcon />,
          onClick: () => {
            if (!myUser) {
              openRegistrationForm(true);
              return;
            }
            navigate(`/user/photos/public/${currentProfile?.id}`);
          },
        },
        {
          icon: <PresentIcon />,
          onClick: () => {
            if (!myUser) {
              openRegistrationForm(true);
              return;
            }
            openGiftDialog();
          },
        },
        {
          icon: <BlockOutlined color={blockedUsersList.includes(currentProfile?.id) ? 'primary' : 'success'} />,
          onClick: () => {
            if (blockedUsersList.includes(currentProfile?.id)) {
              setDeleteBlockedUserModal();
            } else {
              setOpenAddUserToBlockedUserModal();
            }
          },
        },
      ];
    }

    return [
      {
        icon: <ChatIcon />,
        onClick: () => {
          if (!myUser) {
            openRegistrationForm(true);
            return;
          }
          openTabCallBack();
        },
      },
      {
        icon: <MessageIcon />,
        onClick: () => {
          if (!myUser) {
            openRegistrationForm(true);
            return;
          }
          openDialogTabCallBack();
        },
      },
      {
        icon: <EmailMiniIcon />,
        onClick: () => {
          if (!myUser) {
            openRegistrationForm(true);
            return;
          }
          openDialog();
        },
      },
      {
        icon: <AddContactIcon />,
        onClick: async () => {
          if (!myUser) {
            openRegistrationForm(true);
            return;
          }
          try {
            await BookmarksService.addToBookmark(currentProfile?.profile.id);

            setIsBookmark(true);

            dispatch(toggleSnackbarOpen('Success!', 'success'));
          } catch {
            dispatch(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
          }
        },
      },
      {
        icon: <PresentIcon />,
        onClick: () => {
          if (!myUser) {
            openRegistrationForm(true);
            return;
          }
          openGiftDialog();
        },
      },
      {
        icon: <CameraIcon />,
        onClick: () => {
          if (!myUser) {
            openRegistrationForm(true);
            return;
          }
          navigate(`/user/photos/public/${currentProfile?.id}`);
        },
      },
      {
        icon: <BlockOutlined color={blockedUsersList.includes(currentProfile?.id) ? 'primary' : 'success'} />,
        onClick: () => {
          if (blockedUsersList.includes(currentProfile?.id)) {
            setDeleteBlockedUserModal();
          } else {
            setOpenAddUserToBlockedUserModal();
          }
        },
      },
    ];
  }, [
    currentProfile?.id,
    currentProfile?.profile.id,
    blockedUsersList,
    dispatch,
    myUser?.profile?.id,
    navigate,
    openDialog,
    openGiftDialog,
    windowHeight,
    windowWidth,
    isBookmark,
    openTab,
  ]);

  const isUserActiveNow = useMemo(
    () =>
      currentProfile?.profile?.activeAt &&
      differenceInMinutes(new Date(), new Date(currentProfile?.profile.activeAt)) < 15,
    [currentProfile?.profile?.activeAt]
  );

  const sendStatus = useCallback(() => {
    const phonePattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    if (phonePattern.test(currentStatus)) {
      dispatch(toggleSnackbarOpen("You can't set your phone as status", 'error'));

      return;
    }

    const emailPattern = /^\S+@\S+\.\S+$/gim;
    if (emailPattern.test(currentStatus)) {
      dispatch(toggleSnackbarOpen("You can't set your email as status", 'error'));

      return;
    }

    dispatch(updateCurrentUserRequest({ status: currentStatus } as UserProfile));
  }, [currentStatus, dispatch]);

  const statusHandler = useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = e.target.value;

    if (value.length >= 30) return;

    setCurrentStatus(e.target.value);
  }, []);


  const handleOpen = useCallback(() => !isStaff && setModalVisible(true), [isStaff]);

  const clearImageData = useCallback(() => {
    setImageData(null);
  }, []);

  const closeCameraHandler = useCallback(async () => {
    if (videoStream) {
      videoStream.getTracks().forEach((track) => {
        track.stop();
      });
    }

    setPhotoMode(false);
    setVideoStream(null);
  }, [videoStream]);

  const handleClose = useCallback(() => {
    closeCameraHandler();
    setModalVisible(false);
    clearImageData();
    setIsLoading(false);
  }, [clearImageData, closeCameraHandler]);

  const handleFileUploading = useCallback(
    (image: FormData) => {
      setIsLoading(true);
      dispatch(updateAvatarDataRequest(image, handleClose, () => {
        closeCameraHandler();
        clearImageData();
      }));
    },
    [clearImageData, closeCameraHandler, dispatch, handleClose]
  );

  const handleClickPhoto = useCallback(
    (id: string, small: string, origin: string, openHandler: () => void) => {
      dispatch(updateAvatarRequest({ photoId: id }));
    },
    [dispatch]
  );


  const [photoMode, setPhotoMode] = useState(false);
  const [imageData, setImageData] = useState(null);

  const cameraHandler = useCallback(async () => {
    try {
      let stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });

      setVideoStream(stream);
      setPhotoMode(true);

      if (videoRef.current) videoRef.current.srcObject = stream;
    } catch (err) {
      if (err.name === 'PermissionDeniedError') {
        dispatch(toggleSnackbarOpen('Permissions have not been granted', 'error'));
      }
    }
  }, [dispatch]);

  const data = useMemo(() => ([{
    origin: currentProfile?.profile?.avatarLarge,
    large: currentProfile?.profile?.avatarLarge,
    small: currentProfile?.profile?.avatarSmall
  }]), [currentProfile?.profile?.avatarLarge, currentProfile?.profile?.avatarSmall])

  const { calculatedImages } = useImageSizesCalculator(data)

  const photoHandler = useCallback(async () => {
    if (canvasRef.current && videoRef.current) {
      canvasRef.current
        .getContext('2d')
        .drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);
      let imageDataUrl = canvasRef.current.toDataURL('image/jpeg');

      setImageData(imageDataUrl);
    }
  }, []);

  const showAnotherUserStatus = useMemo(() => {
    if (!isMyProfile && !currentStatus) return false;

    return true;
  }, [currentStatus, isMyProfile]);

  return (
    <>
      <BaseCard cardOverrideStyle={classes.cardOverrides}>
        <div className={classes.mainWrapper}>
          <div
            className={clsx(classes.photoWrapper, !isStaff && classes.photoWrapperHover)}
            onClick={() => isMyProfile && smAndDown && handleOpen()}
          >
            {
              isMyProfile && (
                <>
                  <div className={classes.photoInner}>
                    <AvatarWithFallback src={currentAvatar} alt="User avatar" className={classes.profilePhoto}
                                        gender={currentProfile?.profile?.gender} isUserPhoto />
                    {!isMyProfile && isUserActiveNow && (
                      <div className={classes.onlineStatus}>
                        <StatusOnline small={smAndDown} />
                      </div>
                    )}
                  </div>
                  <div className={classes.uploadPhoto} onClick={handleOpen}>
                    <Upload />
                    {mdAndUp && 'Upload avatar'}
                  </div>
                </>
              )
            }

            {
              !isMyProfile && (
                <>
                  <div className={classes.photoInner}>
                    <Gallery>
                      <Item original={calculatedImages[0].large} thumbnail={calculatedImages[0].large}
                            width={calculatedImages[0].width} height={calculatedImages[0].height}>
                        {({ ref, open }) => (
                          <div className={classes.galleryContainer} ref={ref as any} onClick={open}>
                            <AvatarWithFallback src={currentAvatar} alt="User avatar" className={classes.profilePhoto}
                                                gender={currentProfile?.profile?.gender} />
                            {
                              isUserActiveNow && <div className={classes.onlineStatus}>
                                    <StatusOnline small={smAndDown} />
                                </div>
                            }
                          </div>
                        )}
                      </Item>
                    </Gallery>
                  </div>
                </>
              )
            }
          </div>

          <div className={classes.infoWrapper}>
            <div className={clsx(classes.profileTitle, classes.marginBlockUtil)}>
              <div>
                <div className={classes.profileUsername}>{currentProfile?.profile.name}</div>
                <div className={classes.profileId}>ID: {currentProfile?.profile.pageId}</div>
              </div>

              <div className={classes.editWrapper}>
                {isMyProfile && !isStaff && (
                  <Link to='/user/edit' className={classes.infoEditButton}>
                    {mdAndUp && 'Edit Profile'}
                    <span className={classes.infoEditIcon}>
                      <EditIcon />
                    </span>
                  </Link>
                )}

                <span className={classes.aboutMeIcon} onClick={scrollAboutMe}>
                  <AboutMeIcon />
                </span>
              </div>
            </div>

            {showAnotherUserStatus && (
              <Paper
                component='form'
                sx={{
                  p: '2px 4px',
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  boxShadow: 'none',
                  border: '1px solid rgba(0, 0, 0, 0.1)',
                  borderRadius: 50,
                  height: 37,
                }}
                onSubmit={(e) => {
                  e.preventDefault();
                  sendStatus();
                }}
                className={classes.status}
              >
                <InputBase
                  value={currentStatus}
                  sx={{ ml: 1, flex: 1 }}
                  placeholder='What new?'
                  inputProps={{ 'aria-label': 'What new?' }}
                  onChange={statusHandler}
                  disabled={!isMyProfile}
                />
                {isMyProfile && (
                  <IconButton
                    type='button'
                    sx={{ p: '10px' }}
                    aria-label='search'
                    onClick={sendStatus}
                    disabled={!isMyProfile}
                  >
                    <Send />
                  </IconButton>
                )}
              </Paper>
            )}

            <div className={clsx(classes.profileListInfo)}>
              <ProfileListInfo list={mainInfo} col='2' />
            </div>

            {mdAndUp && <Divider sx={{ height: 24 }} orientation='horizontal' />}

            <div className={classes.profileMetaInfoDesktop}>
              {isMyProfile ? (
                <div className={classes.myProfileMetaContainer}>
                  <div className={classes.myProfileMetaAction}>
                    <BaseButton
                      inverted
                      text=''
                      className={classes.metaCtaIcon}
                      textStyle=''
                      onClick={() => {
                        navigate(`/user/contacts`);
                      }}
                      Icon={<ContactsIcon />}
                    />
                    <div className={classes.myProfileMetaInfo}>
                      {bookMarksCount.length}
                      <span>contact{bookMarksCount.length > 1 ? 's' : ''}</span>
                    </div>
                  </div>
                  <div className={classes.myProfileMetaAction}>
                    <BaseButton
                      inverted
                      text=''
                      className={classes.metaCtaIcon}
                      textStyle=''
                      onClick={() => {
                        navigate(`/user/photo-albums/${currentProfile?.id}`);
                      }}
                      Icon={<CameraIcon />}
                    />
                    <div className={classes.myProfileMetaInfo}>
                      {photoCounter}
                      <span>photo{photoCounter > 1 ? 's' : ''}</span>
                    </div>
                  </div>
                  <div className={classes.myProfileMetaAction}>
                    <BaseButton
                      inverted
                      text=''
                      className={classes.metaCtaIcon}
                      textStyle=''
                      onClick={() => {
                        navigate(`/user/video-albums/${currentProfile?.id}`);
                      }}
                      Icon={<VideoIcon />}
                    />
                    <div className={classes.myProfileMetaInfo}>
                      {videoCounter}
                      <span>video{videoCounter > 1 ? 's' : ''}</span>
                    </div>
                  </div>
                </div>
              ) : (
                metaActions.map((action, idx) => (
                  <BaseButton
                    key={idx}
                    inverted
                    text=''
                    className={classes.metaCtaIcon}
                    textStyle=''
                    onClick={action.onClick}
                    Icon={action.icon}
                  />
                ))
              )}
            </div>

            <div className={classes.profileMetaInfo}>
              <div className={classes.profileMetaInfoItem}>
                <AddUserIcon className={classes.profileMetaInfoItemIcon} />
                <p className={classes.profileMetaText}>
                  <span className={classes.profileMetaCounter}>22</span>
                  <br className={classes.profileMetaDivider} />
                  contacts
                </p>
              </div>
              <div className={classes.profileMetaInfoItem}>
                <PhotoIcon className={classes.profileMetaInfoItemIcon} />
                <p className={classes.profileMetaText}>
                  <span className={classes.profileMetaCounter}>22</span>
                  <br className={classes.profileMetaDivider} />
                  photos
                </p>
              </div>
            </div>
          </div>
        </div>

        {showAnotherUserStatus && (
          <Paper
            component='form'
            sx={{
              p: '2px 4px',
              alignItems: 'center',
              width: '100%',
              boxShadow: 'none',
              border: '1px solid rgba(0, 0, 0, 0.1)',
              borderRadius: 50,
              height: 37,
            }}
            onSubmit={(e) => {
              e.preventDefault();
              sendStatus();
            }}
            className={classes.profileStatusMobile}
          >
            <InputBase
              value={currentStatus}
              sx={{ ml: 1, flex: 1 }}
              placeholder='What new?'
              inputProps={{ 'aria-label': 'What new?' }}
              onChange={statusHandler}
              disabled={!isMyProfile}
            />
            {isMyProfile && (
              <IconButton type='button' sx={{ p: '10px' }} aria-label='search' onClick={sendStatus}>
                <Send />
              </IconButton>
            )}
          </Paper>
        )}
      </BaseCard>

      <DialogUploadFile
        isLoading={isLoading}
        title={
          <div className={classes.uploadWrapper}>
            {!photoMode && (
              <BaseButton
                variant='contained'
                type='submit'
                text='Upload from camera'
                className={classes.formSubmitBtn}
                onClick={cameraHandler}
              />
            )}

            <div style={{ position: 'relative', display: photoMode && !imageData ? 'flex' : 'none' }}>
              <video id='video' width='100%' autoPlay ref={videoRef}></video>
              <div className={classes.photoButtonContainer}>
                <BaseButton
                  inverted
                  text=''
                  className={classes.metaCtaIcon}
                  textStyle=''
                  onClick={closeCameraHandler}
                  Icon={<CloseIcon />}
                />
                {smAndDown ? (
                  <BaseButton
                    text=''
                    className={clsx(classes.metaCtaIcon)}
                    textStyle=''
                    onClick={photoHandler}
                    Icon={<WhiteCameraIcon />}
                    style={{ marginLeft: 20 }}
                  />
                ) : (
                  <BaseButton
                    variant='contained'
                    type='submit'
                    text='Take photo'
                    className={classes.takePhotoButton}
                    onClick={photoHandler}
                  />
                )}
              </div>
            </div>

            <div style={{ position: 'relative', display: imageData ? 'flex' : 'none' }}>
              <canvas id='canvas' width={canvasWidth} height={canvasHeight} ref={canvasRef} />
              <div
                style={{
                  position: 'absolute',
                  bottom: '10px',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <BaseButton
                  inverted
                  text=''
                  className={clsx(classes.metaCtaIcon)}
                  textStyle=''
                  onClick={clearImageData}
                  Icon={<CloseIcon />}
                />
              </div>
            </div>
          </div>
        }
        className={classes.uploaderWrapper}
        titleClassName={classes.uploaderWrapper}
        handleFileUploading={handleFileUploading}
        handleClickPhoto={handleClickPhoto}
        defaultValue={{ nextPage: false, prevPage: false, data: [] }}
        isOpen={isModalVisible}
        onClose={handleClose}
        withCrop
      />


      <DialogWriteMail
        open={isWriteDialogOpen}
        closeHandler={hideDialog}
        receiver={{
          id: currentProfile?.profile.id,
          name: currentProfile?.profile.name,
          avatarSmall: currentProfile?.profile?.avatarSmall || oppositeUserPicture,
        }}
      />
      <DialogGift
        open={isGiftDialogOpen}
        closeHandler={hideGiftDialog}
        receiver={{
          id: currentProfile?.profile?.id,
          name: currentProfile?.profile?.name,
          avatarSmall: currentProfile?.profile?.avatarSmall || oppositeUserPicture,
        }}
      />
      <AddBlockedUserModal
        open={openAddUserToBlockedUserModal}
        handleClose={closeOpenAddUserToBlockedUserModal}
        currentProfileId={currentProfile?.id}
      />
      <DeleteBlockedUserModal
        open={openDeleteBlockedUserModal}
        handleClose={closeDeleteBlockedUserModal}
        currentProfileId={currentProfile?.id}
      />
    </>
  );
};

export default UserGeneralInfo;
