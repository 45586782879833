import { ReactComponent as ArrowLeftIcon } from "@/assets/icons/left-arrow.svg";
import { ReactComponent as ArrowRightIcon } from "@/assets/icons/right-arrow.svg";
import { useAppBreakpoints } from "@/hooks";
import { useBinarySwitcher } from "@/hooks/useBinarySwitcher";
import useBlankProfileAvatart from "@/hooks/useBlankProfileAvatart";
import { Container, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Navigation, SwiperOptions } from "swiper";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import BaseButton from "../base/button.component";
import SliderCard from "../base/slider-card";
import DialogWriteMail from "../dialogs/write-mail.dialog.component";
import DialogGift from '../dialogs/send-gift.dialog.component';

export interface SliderSlidesPerView {
  sm?: number | "auto" | undefined;
  md?: number | "auto" | undefined;
  lg?: number | "auto" | undefined;
  xl?: number | "auto" | undefined;
}

type UsersSliderProps = {
  slidesPerView?: SliderSlidesPerView;
  disablePadding?: boolean;
  children: (data: number) => React.ReactNode;
  fetcher: () => Promise<any[]>;
  customSpacing?: number;
  isUnloginned?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  ml: {
    "& > *:not(:first-child)": {
      marginLeft: 28,
    },
  },
  container: {
    marginBottom: 40,
  },
  sliderNavigation: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  sliderContent: {
    padding: "28px 0",
    [theme.breakpoints.down(470)]: {
      padding: "28px 0%",
    },
    [theme.breakpoints.down(369)]: {
      padding: "28px 20%",
    },
  },
  sliderNavigationControl: {
    display: "flex",
  },

  disabledNavigationBtn: {
    backgroundColor: "rgb(229, 229, 229) !important",
    cursor: "not-allowed !important",
    boxShadow: "none !important",
  },

  navigationBtn: {
    minHeight: 28,
    height: 28,
    minWidth: 28,
  },
  containerDisablePadding: {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
  },
  containerInnerOverrides: {
    padding: "0 24px",
    [theme.breakpoints.down(410)]: {
      padding: "0 10px",
    },
  }
}));

const UsersSlider = ({
                       slidesPerView,
                       children,
                       disablePadding = false,
                       fetcher,
                       customSpacing,
                       isUnloginned
                     }: UsersSliderProps) => {
  const classes = useStyles();
  const { oppositeUserPicture } = useBlankProfileAvatart();
  const windowWidth = useMemo(() => window.innerWidth, []);
  const windowHeight = useMemo(() => window.innerHeight, []);

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetcher();
        setData(res);
      } catch (error) {
        console.log('error:', error)
      }
    }

    fetchData();
  }, [fetcher])

  const [isWriteDialogOpen, openDialog, hideDialog] = useBinarySwitcher();
  const [isGiftDialogOpen, openGiftDialog, hideGiftDialog] = useBinarySwitcher();

  const [currentDialogUser, setCurrentDialogUser] = useState(null);

  const openDialoghandler = useCallback(({ id, name, avatarSmall }: {
    id: string,
    name: string,
    avatarSmall: string
  }) => {
    setCurrentDialogUser({
      id, name, avatar: avatarSmall
    });

    openDialog();
    hideGiftDialog();
  }, [openDialog])


  const openGiftDialoghandler = useCallback(({ id, name, avatarSmall: avatarSmall }: {
    id: string,
    name: string,
    avatarSmall: string
  }) => {
    setCurrentDialogUser({
      id, name, avatar: avatarSmall
    });

    openGiftDialog();
  }, [openGiftDialog])


  const closeDialogHandler = useCallback(() => {
    setCurrentDialogUser(null);
    hideDialog()
  }, [hideDialog])

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const { lg, downSm } = useAppBreakpoints();
  const defaultSlidesPerView: SliderSlidesPerView = {
    sm: 3,
    md: 4,
    lg: 5,
    xl: 6,
  };

  const modules = [Navigation];

  const slidesPreView: SliderSlidesPerView = Object.assign(
    defaultSlidesPerView,
    slidesPerView
  );

  const swiperOption: SwiperOptions = {
    slidesPerView: "auto",
    spaceBetween: 16,
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      375: {
        slidesPerView: 2,
      },
      470: {
        slidesPerView: 2,
      },
      640: {
        slidesPerView: slidesPreView.sm,
      },
      768: {
        slidesPerView: slidesPreView.md,
      },
      1024: {
        slidesPerView: slidesPreView.lg,
      },
      1280: {
        slidesPerView: slidesPreView.xl,
      },
    },
  };


  if (!data.length) return null;

  return (
    <Container
      disableGutters={disablePadding}
      maxWidth={lg ? "lg" : "xl"}
      className={clsx(classes.container, (disablePadding || downSm) && classes.containerDisablePadding)}
    >
      <div className={clsx(!disablePadding && classes.containerInnerOverrides)}>
        <div className={classes.sliderNavigation}>
          {children(data.length)}
          <div className={clsx(classes.sliderNavigationControl, classes.ml)}>
            <BaseButton
              text=""
              variant="contained"
              size="small"
              ref={navigationPrevRef}
              Icon={<ArrowLeftIcon />}
              color="primary"
              // disableElevation
            />
            <BaseButton
              text=""
              variant="contained"
              size="small"
              ref={navigationNextRef}
              Icon={<ArrowRightIcon />}
              color="primary"
              // disableElevation
            />
          </div>
        </div>
        <div className={classes.sliderContent}>
          <Swiper
            breakpoints={swiperOption.breakpoints}
            slidesPerView={swiperOption.slidesPerView}
            modules={modules}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
              disabledClass: classes.disabledNavigationBtn,
            }}
            onBeforeInit={(swiper: any) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current;
              swiper.params.navigation.nextEl = navigationNextRef.current;
            }}
          >
            {data.map((item, key) =>
              <SwiperSlide key={key} style={{ width: 170 }}>
                <SliderCard user={item}
                            key={item.id}
                            windowWidth={windowWidth}
                            windowHeight={windowHeight}
                            openDialoghandler={openDialoghandler}
                            openGiftDialoghandler={openGiftDialoghandler}
                            isUnloginned={isUnloginned}
                />
              </SwiperSlide>)}
          </Swiper>
        </div>
      </div>
      {
        !isUnloginned && <DialogWriteMail
              open={isWriteDialogOpen && currentDialogUser}
              closeHandler={closeDialogHandler}
              receiver={{
                id: currentDialogUser?.id,
                name: currentDialogUser?.name,
                avatarSmall: currentDialogUser?.avatarSmall || oppositeUserPicture,
              }}
          />
      }
      {
        !isUnloginned && <DialogGift
              open={isGiftDialogOpen && currentDialogUser}
              closeHandler={closeDialogHandler}
              receiver={{
                id: currentDialogUser?.id,
                name: currentDialogUser?.name,
                avatarSmall: currentDialogUser?.avatarSmall || oppositeUserPicture,
              }}
          />
      }
    </Container>
  );
};

export default UsersSlider;
