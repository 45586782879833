import { RootState } from "@/app/rootReducer";
import { createSelector } from "@reduxjs/toolkit";

export const selectCurrentUser = createSelector(
  (state: RootState) => state.users,
  (users) => users.currentUser
);

export const selectUserBookmarks = createSelector(
  (state: RootState) => state.users,
  (users) => users.bookmarks
);

export const selectUserDialogs = createSelector(
  (state: RootState) => state.users,
  (users) => users.userDialogs
);

export const selectAvailableUsers = createSelector(
  (state: RootState) => state.users,
  (users) => users.availableUsers
);

export const selectAllAvailableUsers = createSelector(
  (state: RootState) => state.users,
  (users) => users.allAvailableUsers
);

export const selectUserIsAvailable = createSelector(
  [selectAllAvailableUsers, (_, id) => id],
  (allAvailableUsers, id) => {
    return allAvailableUsers.some(user => user.id === id);
  }
);


export const selectSearchUsersData = createSelector(
  (state: RootState) => state.users,
  (users) => users.searchUsers.data
);

export const selectCurrentUserAvatarLarge = createSelector(
  selectCurrentUser,
  (currentUser) => currentUser?.profile?.avatarLarge,
);

export const selectBlockedUsers = createSelector(
  (state: RootState) => state.users,
  (users) => users.blockedUsers
);