import { ReactComponent as InfoIcon } from '@/assets/icons/edit-icons/info.svg';
import { makeStyles } from '@mui/styles';
import { Modal, Theme } from '@mui/material';
import React, { useCallback, useRef, useState } from 'react';
import UserAvatar from './user-avatar.component';
import { ReactComponent as ChatDots } from '@/assets/icons/chats/dots.svg';
import ActionsButtonsMobile from './actions-buttons-mobile.component';
import GuestsService from "@/services/guests.service";
import { useNavigate } from "react-router-dom";

export interface IProps {
  profilePicture: string;
  name: string;
  age: string;
  openDialogHandler: () => void;
  contactsClickHandler?: () => void;
  giftClickHandler?: () => void;
  mailClickHandler?: () => void;
  togglePhotoHandler?: () => void;
  pinnedProfileHandler?: () => void;
  isPhotosSliderOpen?: boolean;
  isOnline: boolean;
  isPhotosButtonVisible?: boolean;
  pageId: number;
  profileId: string;
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start',
  },
  nameWrapper: {
    display: 'flex',
    padding: '0 8px 0 28px',
    flexDirection: 'column',
    [theme.breakpoints.down('lg')]: {
      padding: '0 8px 0 12px',
      display: 'none',
    },
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    marginTop: 6,
    [theme.breakpoints.down('lg')]: {
      marginLeft: 4,
    },
  },
  name: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '24px',
    lineHeight: '32px',
    color: '#000000',
    [theme.breakpoints.down('lg')]: {
      fontSize: '24px',
      lineHeight: '26px',
      textAlign: 'left',
    },
  },
  modalWrapper: {
    background: '#FFFFFF',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)',
    borderRadius: '10px',
    padding: '4px 18px 18px 18px',
  },
  mobileWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',

    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  userInfoWrapperVideo: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  pageID: {
    width: '100%',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '20px',
    textAlign: 'left',
    marginTop: 10,
    [theme.breakpoints.down('lg')]: {
      marginTop: 2,
      marginLeft: 30,
    },
  },
  chatDots: {
    cursor: 'pointer',
    marginTop: 6,
    marginLeft: 'auto'
  },
  mobileContainer: {
    width: '100%',
  }
}));

const UserInfo = ({ ...props }: IProps) => {
  const {
    openDialogHandler,
    profilePicture,
    name,
    age,
    contactsClickHandler,
    giftClickHandler,
    mailClickHandler,
    togglePhotoHandler,
    pinnedProfileHandler,
    isPhotosSliderOpen,
    isOnline = false,
    isPhotosButtonVisible,
    pageId,
    profileId,
  } = props;
  const modalRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleOpen = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const navigateToProfile = async () => {
    navigate(`/user/${profileId}`);
  };

  const classes = useStyles(props);
  return (
    <div className={classes.container}>
      <>
        <UserAvatar profilePicture={profilePicture} isOnline={isOnline} onClick={navigateToProfile} />
        <div className={classes.userInfoWrapperVideo}>
          <div className={classes.mobileWrapper} ref={modalRef} id='simple-modal-title'>
            <div className={classes.mobileContainer}>
              <div className={classes.nameContainer}>
                <div className={classes.icon} onClick={openDialogHandler}>
                  <InfoIcon />
                </div>
                <div className={classes.name}>
                  {name}
                  {age}
                </div>
                <div className={classes.chatDots}>
                  <ChatDots onClick={toggleOpen} />
                </div>
              </div>
              <div className={classes.pageID}>
                ID: {pageId}
              </div>
            </div>
            <Modal
              open={isOpen}
              onClose={toggleOpen}
              className={classes.modal}
              aria-labelledby='simple-modal-title'
              container={() => modalRef.current}
            >
              <div className={classes.modalWrapper}>
                <ActionsButtonsMobile
                  mailClickHandler={() => {
                    mailClickHandler();
                    toggleOpen();
                  }}
                  togglePhotoHandler={() => {
                    togglePhotoHandler();
                    toggleOpen();
                  }}
                  isPhotosSliderOpen={isPhotosSliderOpen}
                  contactsClickHandler={() => {
                    contactsClickHandler();
                    toggleOpen();
                  }}
                  pinnedProfileHandler={() => {
                    pinnedProfileHandler();
                    toggleOpen();
                  }}
                  giftClickHandler={giftClickHandler}
                  isPhotosButtonVisible={isPhotosButtonVisible}
                />
              </div>
            </Modal>
          </div>
          <div className={classes.nameWrapper}>
            <div className={classes.nameContainer}>
              <div className={classes.icon} onClick={openDialogHandler}>
                <InfoIcon />
              </div>
              <div className={classes.name}>
                {name}
                {age}
              </div>
            </div>
            <div className={classes.pageID}>
              ID: {pageId}
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default UserInfo;
