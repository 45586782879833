import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import React from 'react';
import { Gallery } from 'react-photoswipe-gallery';
import ChatProfileUserSlider from '@/components/sliders/chat-profile-user-slider.component';
import { IUserPhotosData } from '@/app/users/users.dto';

export interface IProps {
  photos: IUserPhotosData;
  isPhotosSliderOpen: boolean;
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    width: '100%',
    maxWidth: '768px',
  },
}));

const ChatUserPhotos = ({ photos, isPhotosSliderOpen }: IProps) => {
  const classes = useStyles();

  if (!isPhotosSliderOpen) return null;

  return (
    <div className={classes.container}>
      <Gallery>
        <ChatProfileUserSlider defaultValue={photos} />
      </Gallery>
    </div>
  );
};

export default ChatUserPhotos;
