import { Link } from "react-router-dom";
import { Gallery } from "react-photoswipe-gallery";
import { useAppSelector } from "@/app/hooks";
import { IUserPhotosData, UserPhotosDTO, defaultUserPhotosData } from "@/app/users/users.dto";
import { UserProfileDTO } from "@/app/users/users.reducer";
import BaseCard from "@/components/base/base-card.component";
import BaseButton from "@/components/base/button.component";
import ProfileUserSlider from "@/components/sliders/profile-user-slider.component";
import { useAppBreakpoints } from "@/hooks";
import { ProfileListTypes } from "@/types/profile-list.types";
import { Theme, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import React, { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BaseBadge from "../../../components/base/base-badge.component";
import ProfileListInfo from "./profile-list-info.component";
import { ReactComponent as EditIcon } from "@/assets/icons/edit.svg";
import { ReactComponent as EditPurpleIcon } from "@/assets/icons/edit-purple.svg";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
  cardOverrides: {
    padding: 28,
    flexDirection: "column",
    width: "100%",

    [theme.breakpoints.down("lg")]: {
      padding: 20,
    },
  },
  cardInner: {
    marginTop: 24,
  },
  badgeWrapper: {
    marginBottom: 24,
  },
  profileSlideWrapper: {
    marginBottom: 24,

    '& .pswp__zoom-wrap': {
      display: 'flex',
      justifyContent: 'center',
    },

    '& .pswp__img--placeholder': {
      display: 'none'
    }
  },
  mainInfoWrapper: {
    display: "none",
    position: 'relative',
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  aboutInfoWrapper: {
    display: "grid",
    gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
    gap: 12,
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
    },
  },
  metaInfoWrapper: {
    display: "grid",
    gap: 32,
    gridTemplateColumns: "repeat(1, minmax(0, 1fr))",

    [theme.breakpoints.between("md", "xl")]: {
      gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
      gap: 20,
    },

    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
      gap: 16,
    },
  },
  metaCard: {
    // minHeight: 200,
    width: "100%",
    flex: "1 1 auto",
    display: "flex",
    borderRadius: 10,
    overflow: "hidden",
    flexDirection: "column",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
  },
  metaCardTitle: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    display: "flex",
    justifyContent: 'space-between',
    alignItems: 'center',
    color: "#fff",
    padding: 20,
    fontWeight: 600,
    fontSize: 18,
    [theme.breakpoints.up("xs")]: {
      fontSize: 20,
    },
  },
  metaCardContent: {
    background: "#fff",
    padding: 20,
    flex: "1 1 auto",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  photosSliderStub: {
    display: "flex",
    alignItems: "center",
    padding: 20,
  },
  stubSubtitle: {
    display: "flex",
    alignItems: "center",
    gap: 16,
    padding: 20,
    backgroundColor: theme.palette.info.light,
    borderRadius: 10,
  },

  infoEditButton: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "#000",
    marginRight: 15,
    "&:hover $infoEditIcon": {
      opacity: 0.8,
    },
  },

  infoEditButtonWhite: {
    color: "#fff",
  },

  absoluteEditButton: {
    position: 'absolute',
    right: 0,
    top: '10px'
  },

  infoEditIcon: {
    width: 28,
    height: 28,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.info.main,
    borderRadius: 9999,
    marginLeft: 8,
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '10px 0 5px'
  },
}));

type UserBasicInfoProps = {
  mainInfo: ProfileListTypes[];
  aboutList: ProfileListTypes[];
  appearanceList: ProfileListTypes[];
  perfectList: ProfileListTypes[];
  photos: IUserPhotosData;
  currentProfile: UserProfileDTO;
  openRegistrationForm: (status: boolean) => void;
};

const UserBasicInfo = ({
                         mainInfo,
                         aboutList,
                         appearanceList,
                         perfectList,
                         photos = defaultUserPhotosData,
                         currentProfile,
                         openRegistrationForm,
                       }: UserBasicInfoProps) => {
  const classes = useStyles();
  const { palette } = useTheme();
  const { downSm } = useAppBreakpoints();
  const listColumns = downSm ? 1 : 2;
  const navigate = useNavigate();
  const { id } = useParams<{
    id: string;
  }>();
  const currentUser = useAppSelector((state) => state.users.currentUser);
  const isMyProfile = id === currentUser?.id;
  const gender = useAppSelector((state) => state?.users?.currentUser?.profile?.gender);

  const windowHeight = useMemo(() => window.innerHeight, []);
  const windowWidth = useMemo(() => window.innerWidth, []);

  const renderUserPhotos = useCallback(() => {
    const stubSubtitle = isMyProfile ? (
      <div className={classes.stubSubtitle}>
        <Typography variant="body1">You have no photos yet.</Typography>
        <BaseButton
          text=" Add Photos"
          textStyle=""
          onClick={() => {
            navigate(`/user/photo-albums/${currentProfile?.id}`);
          }}
        />
      </div>
    ) : (
      <div className={classes.stubSubtitle}>
        <Typography variant="body1">No photos yet</Typography>
      </div>
    );

    if (photos.data.length < 1) {
      return (
        <>
          <div className={classes.badgeWrapper}>
            <BaseBadge value="0" color={palette.radioSelected.main}>
              <Typography variant="h2">
                {isMyProfile ? "My Photos" : "Photos"}
              </Typography>
            </BaseBadge>
          </div>
          <div className={classes.profileSlideWrapper}>{stubSubtitle}</div>
        </>
      );
    } else {
      return (
        <>
          <div className={classes.badgeWrapper}>
            <BaseBadge value={photos.data.length} color={palette.radioSelected.main}>
              <Typography variant="h2">
                {isMyProfile ? "My Photos" : "Photos"}
              </Typography>
            </BaseBadge>
          </div>
          <div className={classes.profileSlideWrapper}>
            <Gallery>
              <ProfileUserSlider defaultValue={photos.data} openRegistrationForm={openRegistrationForm} />
            </Gallery>
          </div>
        </>
      );
    }
  }, [isMyProfile, classes.stubSubtitle, classes.badgeWrapper, classes.profileSlideWrapper, photos.data, navigate, currentProfile?.id, palette.radioSelected.main, openRegistrationForm]);

  return (
    <>
      <BaseCard disableMargin={true} cardOverrideStyle={classes.cardOverrides}>
        {renderUserPhotos()}

        <div className={classes.mainInfoWrapper}>
          <ProfileListInfo list={mainInfo} col={listColumns} />
          {isMyProfile && (
            <Link to="/user/edit" className={clsx(classes.infoEditButton, classes.absoluteEditButton)}>
              Edit
              <span className={classes.infoEditIcon}>
                <EditIcon />
              </span>
            </Link>
          )}
        </div>
        <div>
          <div className={classes.title}>
            <Typography variant="h2">About Me</Typography>
            {isMyProfile && (
              <Link to="/user/edit/about" className={classes.infoEditButton}>
                Edit
                <span className={classes.infoEditIcon}>
                  <EditIcon />
                </span>
              </Link>
            )}
          </div>
          <ProfileListInfo list={aboutList} col={listColumns} />
        </div>
      </BaseCard>

      <div className={classes.metaInfoWrapper}>
        <div
          className={classes.metaCard}
          style={{ background: palette.radioSelected.main }}
        >
          <div className={classes.metaCardTitle}>
            Appearance
            {isMyProfile && (
              <Link to="/user/edit/appearance" className={clsx(classes.infoEditButton, classes.infoEditButtonWhite)}>
                Edit
                <span className={classes.infoEditIcon}>
                  <EditPurpleIcon />
                </span>
              </Link>
            )}
          </div>
          <div className={classes.metaCardContent}>
            <ProfileListInfo list={appearanceList} />
          </div>
        </div>
        <div
          className={classes.metaCard}
          style={{ background: palette.primary.main }}
        >
          <div className={classes.metaCardTitle}>
            Perfect {gender === 'male' ? 'Women' : 'Men'}
            {isMyProfile && (
              <Link to="/user/edit/perfect" className={clsx(classes.infoEditButton, classes.infoEditButtonWhite)}>
                Edit
                <span className={classes.infoEditIcon}>
                  <EditPurpleIcon />
                </span>
              </Link>
            )}
          </div>
          <div className={classes.metaCardContent}>
            <ProfileListInfo list={perfectList} />
          </div>
        </div>
      </div>
    </>
  );
};

export default UserBasicInfo;
