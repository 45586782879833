import React, { FC } from 'react';
import { Gallery } from 'react-photoswipe-gallery';
import ChatProfileUserSlider from '@/components/sliders/chat-profile-user-slider.component';
import {
  DialogUserPhotosProps
} from "@/components/dialog-container/components/DialogUserPhotos/DialogUserPhotos.props";
import { useStyles } from "@/components/dialog-container/components/DialogUserPhotos/styles";
import { useSwipeable } from "react-swipeable";


const DialogUserPhotos: FC<DialogUserPhotosProps> = ({ isPhotosSliderOpen, photos, togglePhotoHandler }) => {
  const classes = useStyles();

  const handlers = useSwipeable({
    onSwipedUp: () => {
      togglePhotoHandler();
    },
    preventScrollOnSwipe: true,
    trackMouse: true,
  });
  
  return (
    <div
      {...handlers}
      className={classes.container}
    >
      {
        isPhotosSliderOpen && <Gallery>
              <ChatProfileUserSlider defaultValue={photos} />
          </Gallery>
      }
    </div>
  );
};

export default DialogUserPhotos;
