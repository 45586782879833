export type UserPhotosDTO = {
  id: string;
  profileId: string;
  origin: string;
  large: string;
  small: string;
  createdAt: Date;
  private: true;
  originBlur: string;
  largeBlur: string;
  smallBlur: string;
};

export interface IUserPhotosData {
  nextPage: boolean;
  prevPage: boolean;
  data: Array<UserPhotosDTO>;
}

export const defaultUserPhotosData: IUserPhotosData = {
  prevPage: false,
  nextPage: true,
  data: [],
};

export type UserVideosDTO = {
  id: string;
  profileId: string;
  origin: string;
  thumbnail: string;
  status: EVideoStatus;
  private: boolean;
  createdAt: Date | string;
};

export interface IUserVideosData {
  nextPage: boolean;
  prevPage: boolean;
  data: Array<UserVideosDTO>;
}

export const defaultUserVideosData: IUserVideosData = {
  prevPage: false,
  nextPage: true,
  data: [],
};

export enum EVideoStatus {
  INIT = 'init',
  SUCCESS = 'success',
  FAILED = 'failed',
  CONVERTED = 'converted',
}

export type SignedVideoUrlResponse = {
  uploadUrl: string;
  video: UserVideosDTO;
};

export interface IInappMessage {
  id: string;
  userId: string;
  inappDistributionId: string;
  createdAt: string;
  seen: string;
  subject: string;
  body: string;
}

export interface IInappMessagePayload {
  prevPage: boolean;
  nextPage: boolean;
  data: IInappMessage[];
  isLoading: boolean;
}

export interface IQuestions {
  id: string;
  isActive: boolean;
  reward: 0;
  surname: boolean;
  gender: boolean;
  nickname: boolean;
  birthday: boolean;
  body: boolean;
  height: boolean;
  hairColor: boolean;
  eyeColor: boolean;
  religion: boolean;
  family: boolean;
  children: boolean;
  smoke: boolean;
  drink: boolean;
  education: boolean;
  profession: boolean;
  aboutMe: boolean;
  celebrationBirthday: boolean;
  celebrationChristmasDay: boolean;
  celebrationNewYearsDay: boolean;
  celebrationEaster: boolean;
  celebrationValentinesDay: boolean;
  celebrationHalloween: boolean;
  celebrationWomensDay: boolean;
  country: boolean;
  city: boolean;
  langEnglish: boolean;
  langSpanish: boolean;
  langFrench: boolean;
  langPortuguese: boolean;
  langGerman: boolean;
  avatar: boolean; // need for fallback
  avatarSmall: boolean;
  avatarLarge: boolean;
  status: boolean;
  weight: boolean;
  perfectAgeFrom: boolean;
  perfectAgeTo: boolean;
  perfectHair: boolean;
  perfectEyes: boolean;
  perfectBody: boolean;
  perfectHeightFrom: boolean;
  perfectHeightTo: boolean;
  perfectWeight: boolean;
  perfectLangEnglish: boolean;
  perfectChildren: boolean;
  perfectType: boolean;
}
