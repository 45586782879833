import { HttpService, WsService } from './http.service';
import { StorageService } from './storage.service';
import { ErrorWrapper } from './utils';

function generateParams(data: any) {
  return Object.keys(data)
    .map((key) => (data[key] ? `${key}=${encodeURIComponent(data[key])}` : ''))
    .join('&');
}

export default class WalletsService {
  static get entity(): string {
    return 'wallets';
  }

  static async getBalance(): Promise<{ balance: number }> {
    try {
      const token = StorageService.getToken();
      if (!token) {
        return { balance: 0 };
      }
      const response = await HttpService.get(`/${this.entity}/balance`);
      return response.data;
    } catch (error: any) {
      const message = error?.response?.data?.error || error?.response?.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async chargeBalance(payload: { config: string; targetProfileId: string }): Promise<{ balance: number }> {
    try {
      const response = await HttpService.post(`/transactions/${payload.config}`, {
        targetProfileId: payload.targetProfileId,
      });
      return response.data;
    } catch (error: any) {
      const message = error?.response?.data?.error || error?.response?.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
