import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import StatusOnline from '@/components/base/statuses/status-online.component';
import { CircularProgress, Divider, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { formatCreatedAtDate, isToday } from '@/utils/formatDate';
import { differenceInMinutes, format } from 'date-fns';
import BaseTextarea from '@/components/base/base-textarea.component';
import { Formik } from 'formik';
import { writeGiftSchema } from '@/utils/validationSchemas';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import BaseButton from '@/components/base/button.component';
import { ReactComponent as AttachIcon } from '@/assets/icons/attach.svg';
import clsx from 'clsx';
import {
  getAvailableToSendGiftsRequest,
  getClosedAttachRequest,
  getUserGiftRequest,
  postGiftRequest,
} from '@/app/gifts/gifts.actions';
import { calculateAge } from '@/helpers/helpers';
import { postPhotosRequest } from '@/app/users/users.actions';
import { UserPhotoType } from '@/app/users/users.types';
import { Gallery } from 'react-photoswipe-gallery';
import UploadPhotoItem from '@/components/base/upload-photo-item';
import { GiftKind, GiftsDTO } from '@/app/gifts/gifts.types';
import useBlankProfileAvatart from '@/hooks/useBlankProfileAvatart';
import UploadGift from '@/components/base/upload-gift';
import useFileGiftUtils from '@/components/dialogs/hooks/useFileGiftUtils';
import UploadGiftLayout from '@/components/layouts/upload-gift.layout';
import { useBinarySwitcher } from '@/hooks/useBinarySwitcher';
import DialogWriteMail from '@/components/dialogs/write-mail.dialog.component';
import { ReactComponent as EmailMiniIcon } from "@/assets/icons/profile-actions/email-mini.svg";
import AvatarWithFallback from "@/components/AvatarWithFallback/AvatarWithFallback";

type UserGiftOpennedProps = {
  giftId: string;
};

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 18,
    width: '100%',
  },
  cardContainer: {
    flexDirection: 'column',
    width: '100%',
    backgroundColor: '#F6F6F6',
  },
  headerTopSide: {
    display: 'flex',
    flexDirection: 'row',
    padding: 14,
    borderRadius: 10,
  },
  headerBottomSide: {
    padding: 14,
  },
  photoWrapper: {
    position: 'relative',
    marginRight: 20,
    maxWidth: 124,
    maxHeight: 130,
    aspectRatio: '116 / 130',
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      maxWidth: 72,
      maxHeight: 76,
    },
  },
  photoInner: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  profilePhoto: {
    width: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: 10,
    height: '100%',
  },
  profilePhotoStatus: {
    position: 'absolute',
    bottom: 8,
    left: 8,
  },
  formActions: {
    gap: 10,
    display: 'flex',
    width: '100%',
  },
  giftInfo: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: '10px 0',
  },
  giftHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  profileInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  profileMiddle: {
    display: 'flex',
    flexDirection: 'column',
    padding: 15,
  },
  profileText: {
    wordBreak: 'break-word',
  },
  profileMeta: {
    display: 'flex',
    alignItems: 'center',
    gap: 9,
  },
  profileName: {
    overflowWrap: 'anywhere',
  },
  profileActions: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 10,
    color: '#808080',
  },
  divider: {
    paddingTop: 15,
  },
  giftFooter: {
    color: '#808080',

    marginTop: 12,
    display: 'flex',
    justifyContent: 'space-between',
  },
  giftInputContainer: {
    display: 'flex',
    width: '100%',
    gap: 22,
    [theme.breakpoints.down('sm')]: {
      gap: 5,
    },
  },
  senderAvatar: {
    maxWidth: 56,
    minWidth: 56,
    maxHeight: 56,
    aspectRatio: '56 / 56',
  },
  wrapper: {
    display: 'flex',
    gap: 24,
    flexFlow: 'row wrap',
  },
  avatarInner: {
    borderRadius: '50%',
    width: '100%',
    height: '100%',
  },
  formBtn: {
    minWidth: '120px !important',
  },
  uploaderWrapper: {
    overflow: 'scroll',
  },
  attachedImagesContainer: {
    display: 'flex',
    gap: 10,
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: 20,
  },
  attachedImagesTopContainer: {
    display: 'flex',
    gap: 10,
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: 20,
    marginTop: 15,
  },
  attachedIcon: {
    maxWidth: 42,
    maxHeight: 42,
    '& > .MuiButton-startIcon': {
      margin: 0,
    },
    minWidth: '0 !important',
  },
  attachedImagesInner: {
    border: `2px dashed ${theme.palette.radioSelected.main}`,
    borderRadius: 5,
    padding: 4,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    flex: '1 1 100%',
  },
  sendedImagesInner: {
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    flex: '1 1 100%',
  },
  profileCta: {
    display: 'flex',
    gap: 24,
    height: '42px',
  },
  metaCtaIcon: {
    maxWidth: 42,
    maxHeight: 42,

    '& > .MuiButton-startIcon': {
      margin: 0,
    },
    minWidth: '0 !important',
  },

  profileSlideWrapper: {
    display: 'flex',
    flexWrap: 'wrap',

    '& .pswp__zoom-wrap': {
      display: 'flex',
      justifyContent: 'center',
    },

    '& .pswp__img': {
      width: 'auto !important',
      position: 'relative !important',
    },
    '& .pswp__img--placeholder': {
      display: 'none',
    },
  },
}));

const initValues = {
  receiverId: null,
  subject: '',
  text: '',
  photoIds: [],
};

const UserGiftOpenned = ({ giftId }: UserGiftOpennedProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { kind } = useParams<{
    kind: GiftKind;
  }>();
  const { oppositeUserPicture } = useBlankProfileAvatart();
  const [isWriteDialogOpen, openDialog, hideDialog] = useBinarySwitcher();
  const userGift = useAppSelector((state) => state.gifts.userGift);
  const currentUserProfileId = useAppSelector((state) => state.users.currentUser?.profile?.id);
  const currentUserAvatar = useAppSelector((state) => state.users.currentUser?.profile?.avatarSmall);
  const isLoading = useAppSelector((state) => state.gifts.isUserGiftLoading);
  const isSending = useAppSelector((state) => state.gifts.isSending);

  const windowWidth = useMemo(() => window.innerWidth, []);
  const windowHeight = useMemo(() => window.innerHeight - 90, []);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleClose = () => setIsModalVisible(false);
  const handleOpen = () => setIsModalVisible(true);

  const { currentUser, differentUser } = useMemo(() => {
    if (!userGift)
      return {
        currentUser: null,
        differentUser: null,
      };
    return userGift?.profile_id === currentUserProfileId
      ? {
        currentUser: {
          avatar: currentUserAvatar,
        },
        differentUser: {
          id: userGift.profileId,
          avatar: userGift.profileAvatarLarge,
          pageId: userGift.profilePageId,
          birthday: userGift.profileBirthday,
          activeAt: userGift.activeAt,
          name: userGift.profileName,
        },
      }
      : {
        currentUser: {
          avatar: currentUserAvatar,
        },
        differentUser: {
          id: userGift.profileId,
          avatar: userGift.profileAvatarLarge,
          pageId: userGift.profilePageId,
          birthday: userGift.profileBirthday,
          activeAt: userGift.activeAt,
          name: userGift.profileName,
        },
      };
  }, [currentUserProfileId, userGift]);

  useEffect(() => {
    dispatch(getUserGiftRequest(giftId, kind));
  }, [dispatch, giftId, kind]);

  const classes = useStyles();

  const formatedCreatedAtDate = useCallback((createdAt: Date) => {
    const timestamp = new Date();

    const month = format(timestamp, 'MMM do');
    const localeTime = formatCreatedAtDate(createdAt);

    return isToday(timestamp) ? `${localeTime}` : `${month}`;
  }, []);

  const isUserActiveNow = useMemo(
    () => differentUser && differenceInMinutes(new Date(), new Date(differentUser.activeAt)) < 15,
    [differentUser]
  );

  //************ */
  const [attachedGift, handleRemove, handleAdd, resetAttachments] = useFileGiftUtils<GiftsDTO>();
  const gifts = useAppSelector((state) => state.gifts['gifts']);
  const images = useMemo(() => [...gifts], [gifts]);
  //**** */

  const handleSelect = (image) => {
    handleAdd(image);
    handleClose();
  };
  const isUploading = useAppSelector((state) => state.users.photosIsUploading);

  const handleFileUploading = useCallback(
    (image: FormData) => {
      dispatch(postPhotosRequest(UserPhotoType.PRIVATE_VIEW, image));
    },
    [dispatch]
  );

  const handleDrop = useCallback(
    (acceptedFiles: any) => {
      const formData = new FormData();
      formData.append('file', acceptedFiles[0]);
      handleFileUploading(formData);
    },
    [handleFileUploading]
  );

  useEffect(() => {
    dispatch(getAvailableToSendGiftsRequest());
  }, [dispatch]);

  const [isBookmark, setIsbookmark] = useState(false);

  useEffect(() => {
    if (userGift?.isBookmarks) {
      setIsbookmark(userGift.isBookmarks);
    }
  }, [userGift?.isBookmarks]);

  const metaActions = useMemo(
    () =>
      isBookmark
        ? [
          {
            icon: <EmailMiniIcon />,
            onClick: openDialog,
          },
        ]
        : [
          {
            icon: <EmailMiniIcon />,
            onClick: openDialog,
          },
        ],
    [differentUser?.id, dispatch, isBookmark, kind]
  );

  const metaButtons = useMemo(
    () => (
      <div className={classes.profileCta}>
        {metaActions.map((action, idx) => (
          <BaseButton
            key={idx}
            inverted
            text=''
            className={classes.metaCtaIcon}
            textStyle=''
            onClick={action.onClick}
            Icon={action.icon}
          />
        ))}
      </div>
    ),
    [classes.metaCtaIcon, classes.profileCta, metaActions]
  );

  const openAttachImage = useCallback(
    ({
       id,
       small,
       origin,
       paidAt,
       profileId,
       open,
     }: {
      id: string;
      small: string;
      origin: string;
      paidAt: string | null;
      profileId: string | null;
      open: () => void;
    }) => {
      if (paidAt || profileId === currentUserProfileId) {
        open();
      } else {
        dispatch(getClosedAttachRequest(id, giftId));
      }
    },
    [currentUserProfileId, dispatch, giftId]
  );

  if (isLoading || !userGift) return <CircularProgress color='secondary' style={{ margin: '0 auto' }} />;

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.cardContainer}>
          <div className={classes.headerTopSide}>
            <div className={classes.photoWrapper}>
              <div className={classes.photoInner}>
                <Link to={`/user/${differentUser.id}`}>
                  <AvatarWithFallback src={differentUser?.avatar} className={classes.profilePhoto} />
                </Link>
              </div>
              {isUserActiveNow ? (
                <div className={classes.profilePhotoStatus}>
                  <StatusOnline small />
                </div>
              ) : null}
            </div>

            <div className={classes.giftInfo}>
              <div className={classes.giftHeader}>
                <div className={classes.profileInfo}>
                  <Typography
                    variant='body1'>{`ID: ${differentUser.pageId}`}</Typography>
                  <div className={classes.profileMeta}>
                    <Typography variant='body2' color='currentcolor' className={classes.profileName}>
                      {differentUser.name}
                      {differentUser.birthday ? `, ${calculateAge(differentUser.birthday)}` : null}
                    </Typography>
                  </div>
                </div>
                <div className={classes.profileActions}>
                  <Typography color='currentColor' fontWeight={400}>
                    {formatedCreatedAtDate(userGift.createdAt)}
                  </Typography>
                </div>
              </div>
              <Divider className={classes.divider} />
              <div className={classes.giftFooter}>{metaButtons}</div>
            </div>
          </div>

          <div className={classes.profileMiddle}>
            <Typography variant='body3'>
              <img height={200} alt={userGift.virtualGiftImage} src={userGift.virtualGiftImage} />
            </Typography>
            <Typography fontWeight={400} color='currentcolor' className={classes.profileText}>
              {userGift.text}
            </Typography>
          </div>

          {userGift.giftAttachments && userGift.giftAttachments.length > 0 && (
            <div className={classes.headerBottomSide}>
              <Divider />
              <div className={classes.attachedImagesTopContainer}>
                <BaseButton
                  inverted
                  text=''
                  className={classes.attachedIcon}
                  textStyle=''
                  onClick={() => {
                  }}
                  Icon={<AttachIcon />}
                  sx={{
                    ':hover': {
                      backgroundColor: '#EEEEEE',
                    },
                  }}
                />
                <Typography variant='body2'>Attached images</Typography>

                <div className={classes.sendedImagesInner}>
                  <div className={classes.profileSlideWrapper}>
                    <Gallery>
                      {userGift.giftAttachments.map((image) => (
                        <UploadPhotoItem
                          key={image.id}
                          {...image}
                          isPrivate={false}
                          isEditable={false}
                          handleClick={openAttachImage}
                          isExpended={false}
                          withoutIcon
                          windowWidth={windowWidth}
                          windowHeight={windowHeight}
                        />
                      ))}
                    </Gallery>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className={classes.giftInputContainer}>
          <div className={classes.senderAvatar}>
            <AvatarWithFallback src={currentUser?.avatar} className={classes.avatarInner} isUserPhoto />
          </div>
          <Formik
            initialValues={initValues}
            validationSchema={writeGiftSchema}
            onSubmit={({ receiverId: { id }, ...rest }) => {
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
              <form onSubmit={handleSubmit} className={classes.formContainer}>
                <BaseTextarea
                  label='text'
                  placeholder='Text:'
                  onChange={handleChange}
                  className={classes.formTextField}
                />

                {attachedGift && (
                  <div className={classes.attachedImagesContainer}>
                    {attachedGift && (
                      <div className={classes.attachedImagesContainer}>
                        <BaseButton
                          inverted
                          text=''
                          className={classes.attachedIcon}
                          textStyle=''
                          onClick={() => {
                          }}
                          Icon={<AttachIcon />}
                        />
                        <Typography variant='body2'>Attached gift</Typography>

                        <div className={classes.attachedImagesInner}>
                          <div className={classes.profileSlideWrapper}>
                            <Gallery>
                              <UploadGift
                                key={attachedGift.id}
                                handleRemoveFile={(id) => handleRemove({ id })}
                                windowWidth={windowWidth}
                                windowHeight={windowHeight}
                                {...attachedGift}
                              />
                            </Gallery>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <div className={classes.formActions}>
                  <BaseButton
                    loading={isSending}
                    disabled={isSending}
                    type='submit'
                    color='primary'
                    text='Send'
                    className={clsx(classes.formBtn, classes.sendBtn)}
                    onClick={() =>
                      dispatch(
                        postGiftRequest({
                          text: values.text,
                          receiverId: differentUser.id,
                          virtualGiftId: attachedGift?.id,
                          closeHandler: () => navigate(-1),
                          isDialog: false,
                        })
                      )
                    }
                  />
                  <BaseButton
                    inverted
                    text='Attach'
                    className={clsx(classes.formBtn, classes.attachBtn)}
                    textStyle={classes.attachTextStyle}
                    Icon={<AttachIcon />}
                    onClick={handleOpen}
                  />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      {isModalVisible && (
        <UploadGiftLayout
          isEditable={true}
          isExpended={false}
          handleDrop={handleDrop}
          className={''}
          handleFileUploading={() => {
          }}
          handleRemoveFile={() => {
          }}
          handleSelect={handleSelect}
          defaultValue={images}
          isUploading={isUploading}
          isDelitable={true}
        />
      )}
      <DialogWriteMail
        open={isWriteDialogOpen}
        closeHandler={hideDialog}
        receiver={{
          id: differentUser.id,
          name: differentUser.name,
          avatarSmall: differentUser.avatar || oppositeUserPicture,
        }}
      />
    </>
  );
};

export default UserGiftOpenned;
