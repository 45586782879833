import { CircularProgress, Fab, Theme } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ReactComponent as PencilIcon } from "@/assets/icons/pencil.svg";
import UserGift from "../user-gifts";
import { makeStyles } from "@mui/styles";
import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { GiftKind } from "@/app/gifts/gifts.types";
import BaseButton from "@/components/base/button.component";
import { checkGift, getGiftsRequest } from "@/app/gifts/gifts.actions";

const useStyles = makeStyles((theme: Theme) => ({
  giftsContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    flexDirection: "column",
    "& > $userGift:not(:first-child)": {
      marginTop: 10,
    },
  },
  userGift: {},
  loadMoreBtn: {
    maxWidth: 200,
    minWidth: 200,
  },
  loadMoreWrapper: {
    alignSelf: "center",
    minWidth: "200px !important",
  },
  createGiftButton: {
    position: 'sticky',
    bottom: '25px',
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  },
  loadButtonSection: {
    display: 'flex',
    flex: '1',
    justifyContent: 'center',
    marginTop: '28px'
  },
  nogifts: {
    margin: '0 auto',
    border: '2px dashed #503EB6',
    padding: '10px',
  }
}));

const stylesOverrides = {
  createGiftButton: {
    boxShadow: '0px 10px 30px rgb(234 65 96 / 50%)',
  }
};

type GiftItem = {
  id: string;
  checked: boolean;
  isUnread: boolean;
};

type GiftsSectionProps = {
  giftsKind: GiftKind;
  writeGifthandler: () => void;
};

const GiftsSection = ({ giftsKind, writeGifthandler }: GiftsSectionProps) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const gifts = useAppSelector((state) => state.gifts[giftsKind + "Gifts"]);
  const isLoading = useAppSelector((state) => state.gifts.isLoading);
  const selectedgifts = useAppSelector((state) => state.gifts.chosenIds);
  const isPaginationOver = useAppSelector((state) => state.gifts.isPaginationOver);
  const [listItems, setListItems] = useState(gifts);

  useEffect(() => {
    setListItems(gifts);
  }, [gifts]);

  const loadMore = useCallback(
    () => {
      dispatch(getGiftsRequest(giftsKind));
    },
    [dispatch, giftsKind]
  );

  const toggleCheckboxValue = useCallback(
    (gift: GiftItem) => {
      dispatch(checkGift(gift.id));
    },
    [dispatch]
  );

  const getLoadButton = useMemo(() => {
    if (isPaginationOver) return null;
    if (isLoading) return <CircularProgress color="secondary" style={{ margin: "0 auto" }} />;


    return <BaseButton
      text="Load More (20)"
      color="primary"
      wrapperClass={classes.loadMoreWrapper}
      className={classes.loadMoreBtn}
      onClick={loadMore}
    />

  }, [classes.loadMoreBtn, classes.loadMoreWrapper, isLoading, isPaginationOver, loadMore])

  return (
    <section className={classes.giftsContainer}>
      {listItems.length > 0 ?
        listItems.map((gift) => (
          <UserGift
            key={gift.id}
            kind={giftsKind}
            isUnread={!!gift.seen}
            avatar={gift.profileAvatarLarge}
            gift={gift}
            subject={gift.subject}
            isChecked={selectedgifts.has(gift.id)}
            onChange={toggleCheckboxValue}
            wrapperClassName={classes.userGift}
            timestamp={new Date(gift.createdAt)}
            userId={gift.profileId}
            name={gift.profileName}
            age={gift.profileBirthday}
          />
        )) : <div className={classes.nogifts}>There are no gifts</div>}
      <div className={classes.loadButtonSection}>
        {getLoadButton}
      </div>
      <div className={classes.createGiftButton}>
        <Fab
          color="primary"
          aria-label="add"
          size="small"
          style={stylesOverrides.createGiftButton}
          onClick={writeGifthandler}
        >
          <PencilIcon />
        </Fab>
      </div>
    </section>
  );
};

export default GiftsSection;
