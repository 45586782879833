import {
  DialogMessage,
  IAcquiring,
  IBlackList,
  ISettings,
  IVideoInviteProfile,
  UserDialog,
  UserProfile,
  UserProfileState,
} from '@/types/user-profile.types';
import {
  UserPhotosDTO,
  UserVideosDTO,
  IUserPhotosData,
  IInappMessagePayload,
  IQuestions,
  IUserVideosData,
} from './users.dto';
import {
  CLEAR_USER_REQUEST,
  CLEAR_USER_SUCCESS,
  DELETE_USER_PHOTOS_FAILURE,
  DELETE_USER_PHOTOS_REQUEST,
  DELETE_USER_PHOTOS_SUCCESS,
  GET_OWNER_PROFILE_FAILURE,
  GET_OWNER_PROFILE_REQUEST,
  GET_OWNER_PROFILE_SUCCESS,
  GET_SEARCH_USERS_FAILURE,
  GET_SEARCH_USERS_REQUEST,
  GET_SEARCH_USERS_SUCCESS,
  GET_USER_PHOTOS_FAILURE,
  GET_USER_PHOTOS_REQUEST,
  GET_USER_PHOTOS_SUCCESS,
  GET_USER_PROFILE_FAILURE,
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  POST_USER_PHOTOS_FAILURE,
  POST_USER_PHOTOS_REQUEST,
  POST_USER_PHOTOS_SUCCESS,
  UPDATE_CURRENT_USER_PROFILE_FAILURE,
  UPDATE_CURRENT_USER_PROFILE_REQUEST,
  UPDATE_CURRENT_USER_PROFILE_SUCCESS,
  UserPhotoType,
  GET_AVAILABLE_USERS_REQUEST,
  GET_AVAILABLE_USERS_SUCCESS,
  GET_AVAILABLE_USERS_FAILURE,
  SEND_MESSAGE_TO_CHAT_SUCCESS,
  RECEIVE_MESSAGE_TO_CHAT_SUCCESS,
  SEND_MESSAGE_TO_CHAT_REQUEST,
  GET_PROFILE_PHOTOS_REQUEST,
  GET_PROFILE_PHOTOS_SUCCESS,
  GET_PROFILE_PHOTOS_FAILURE,
  SET_BOOKMARKS_REQUEST,
  SET_BOOKMARKS_SUCCESS,
  SET_BOOKMARKS_FAILURE,
  GET_BOOKMARKS_REQUEST,
  GET_BOOKMARKS_SUCCESS,
  GET_BOOKMARKS_FAILURE,
  SET_BLOCKED_BY_ME_REQUEST,
  SET_BLOCKED_BY_ME_SUCCESS,
  SET_BLOCKED_BY_ME_FAILURE,
  DELETE_BLOCKED_BY_ME_REQUEST,
  DELETE_BLOCKED_BY_ME_SUCCESS,
  DELETE_BLOCKED_BY_ME_FAILURE,
  GET_BLOCKED_USERS_REQUEST,
  GET_BLOCKED_USERS_SUCCESS,
  GET_BLOCKED_USERS_FAILURE,
  DELETE_BOOKMARKS_REQUEST,
  DELETE_BOOKMARKS_SUCCESS,
  DELETE_BOOKMARKS_FAILURE,
  SET_UPDATE_AVAILABLE_USERS,
  SET_DISCONNECTED_AVAILABLE_USERS,
  REMOVE_INVITE,
  DISCONNECT_CHAT,
  TRANSLATE_MESSAGE_TO_CHAT_SUCCESS,
  REVEAL_PHOTO_CHAT_SUCCESS,
  UPDATE_BALANCE_REQUEST,
  UPDATE_BALANCE_SUCCESS,
  UPDATE_BALANCE_FAILED,
  UPDATE_INAPP_REQUEST,
  UPDATE_INAPP_SUCCESS,
  UPDATE_INAPP_FAILED,
  FILTER_INAPP_REQUEST,
  FILTER_INAPP_SUCCESS,
  FILTER_INAPP_FAILED,
  CHARGE_BALANCE_REQUEST,
  CHARGE_BALANCE_SUCCESS,
  CHARGE_BALANCE_FAILED,
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAILED,
  GET_EMAIL_EVENTS_BLACKLIST_REQUEST,
  GET_EMAIL_EVENTS_BLACKLIST_SUCCESS,
  GET_EMAIL_EVENTS_BLACKLIST_FAILED,
  USER_BALANCE_CHARGED_SUCCESS,
  GET_CREDIT_PACKS_REQUEST,
  GET_CREDIT_PACKS_SUCCESS,
  GET_CREDIT_PACKS_FAILED,
  SET_VIDEO_CALL,
  SET_USER_HEADER_SEARCH,
  CHANGE_USER_PASSWORD,
  SEND_PHOTO_MESSAGE_TO_CHAT_REQUEST,
  SEND_PHOTO_MESSAGE_TO_CHAT_SUCCESS,
  RECEIVE_PHOTO_MESSAGE_TO_CHAT_SUCCESS,
  REVEAL_PHOTO_CHAT_REQUEST,
  UPDATE_CURRENT_USER_AVATAR_REQUEST,
  CLEAR_AVAILABLE_USERS_SUCCESS,
  READ_MESSAGE,
  ADD_VIDEO_INVITE,
  REMOVE_VIDEO_INVITE,
  GET_USERS_DIALOGS_REQUEST,
  GET_USERS_DIALOGS_SUCCESS,
  GET_USERS_DIALOGS_FAILURE,
  ADD_USERS_DIALOGS_SUCCESS,
  GET_DIALOG_COUNTERS,
  GET_DIALOG_COUNTERS_SUCCESS,
  SOCKET_UPDATE_DIALOG_COUNT,
  READ_USERS_DIALOGS_REQUEST,
  UPDATE_PIN_SUCCESS,
  GET_ACQUIRING_REQUEST,
  GET_ACQUIRING_SUCCESS,
  GET_ACQUIRING_FAILED,
  UPDATE_QUESTIONS_REQUEST,
  UPDATE_QUESTIONS_SUCCESS,
  UPDATE_QUESTIONS_FAILED,
  FILTER_QUESTIONS_REQUEST,
  FILTER_QUESTIONS_SUCCESS,
  FILTER_QUESTIONS_FAILED,
  CLOSE_QUESTIONS_REQUEST,
  CLOSE_QUESTIONS_SUCCESS,
  CLOSE_QUESTIONS_FAILED,
  CLOSE_QUESTIONS_FOR_DAYS_REQUEST,
  CLOSE_QUESTIONS_FOR_DAYS_SUCCESS,
  CLOSE_QUESTIONS_FOR_DAYS_FAILED,
  GET_USER_VIDEOS_FAILURE,
  GET_USER_VIDEOS_REQUEST,
  GET_USER_VIDEOS_SUCCESS,
  POST_USER_VIDEOS_FAILURE,
  POST_USER_VIDEOS_REQUEST,
  POST_USER_VIDEOS_SUCCESS,
  GET_PROFILE_VIDEOS_REQUEST,
  GET_PROFILE_VIDEOS_SUCCESS,
  GET_PROFILE_VIDEOS_FAILURE,
  DELETE_USER_VIDEOS_FAILURE,
  DELETE_USER_VIDEOS_REQUEST,
  DELETE_USER_VIDEOS_SUCCESS,
  UserVideoType,
  REVEAL_VIDEO_CHAT_REQUEST,
  REVEAL_VIDEO_CHAT_SUCCESS,
  SEND_VIDEO_MESSAGE_TO_CHAT_REQUEST,
  SEND_VIDEO_MESSAGE_TO_CHAT_SUCCESS,
  RECEIVE_VIDEO_MESSAGE_TO_CHAT_SUCCESS,
  SET_VIDEO_CONVERTED,
  CHANGE_USER_EMAIL,
  CHANGE_USER_EMAIL_SUCCESS,
  GET_CURRENT_USER_DATA_REQUEST,
  UPDATE_CURRENT_USER_AVATAR_DATA_REQUEST,
  SET_UPDATE_AVAILABLE_USERS_FROM_COMPONENT,
} from './users.types';
import { CountersShape } from '@/services/mail.service';
import { EmailEventsBlacklist } from './users.reducer';

//public photos
export const getPhotosRequest = (userId: string, viewType: UserPhotoType, offset = 0) => ({
  type: GET_USER_PHOTOS_REQUEST,
  userId,
  viewType,
  offset,
});

export const getPhotosSuccess = (viewType: UserPhotoType, photos: IUserPhotosData) => ({
  type: GET_USER_PHOTOS_SUCCESS,
  viewType,
  photos,
});

export const getPhotosFailure = (viewType: UserPhotoType, error: unknown) => ({
  type: GET_USER_PHOTOS_FAILURE,
  error,
  viewType,
});

export const getProfilePhotosRequest = (profileId: string, viewType: UserPhotoType) => ({
  type: GET_PROFILE_PHOTOS_REQUEST,
  profileId,
  viewType,
});

export const getProfilePhotosSuccess = (viewType: UserPhotoType, photos: IUserPhotosData, profileId: string) => ({
  type: GET_PROFILE_PHOTOS_SUCCESS,
  viewType,
  photos,
  profileId,
});

export const getProfilePhotosFailure = (viewType: UserPhotoType, error: unknown) => ({
  type: GET_PROFILE_PHOTOS_FAILURE,
  error,
  viewType,
});

export const postPhotosRequest = (viewType: UserPhotoType, file: FormData) => ({
  type: POST_USER_PHOTOS_REQUEST,
  file,
  viewType,
});

export const postPhotosSuccess = (viewType: UserPhotoType, photo: UserPhotosDTO) => ({
  type: POST_USER_PHOTOS_SUCCESS,
  viewType,
  photo,
});

export const postPhotosFailure = (viewType: UserPhotoType, error: unknown) => ({
  type: POST_USER_PHOTOS_FAILURE,
  error,
  viewType,
});

export const deletePhotosRequest = (viewType: UserPhotoType, id: string) => ({
  type: DELETE_USER_PHOTOS_REQUEST,
  id,
  viewType,
});
export const deletePhotosSuccess = (viewType: UserPhotoType, id: string) => ({
  type: DELETE_USER_PHOTOS_SUCCESS,
  viewType,
  id,
});

export const deletePublicPhotosFailure = (viewType: UserPhotoType, error: unknown) => ({
  type: DELETE_USER_PHOTOS_FAILURE,
  error,
  viewType,
});

//VIDEO

//public videos
export const getVideosRequest = (userId: string, viewType: UserVideoType, offset = 0) => ({
  type: GET_USER_VIDEOS_REQUEST,
  userId,
  viewType,
  offset,
});

export const getVideosSuccess = (viewType: UserVideoType, videos: IUserVideosData) => ({
  type: GET_USER_VIDEOS_SUCCESS,
  viewType,
  videos,
});

export const getVideosFailure = (viewType: UserVideoType, error: unknown) => ({
  type: GET_USER_VIDEOS_FAILURE,
  error,
  viewType,
});

export const getProfileVideosRequest = (profileId: string, viewType: UserVideoType) => ({
  type: GET_PROFILE_VIDEOS_REQUEST,
  profileId,
  viewType,
});

export const getProfileVideosSuccess = (viewType: UserVideoType, videos: IUserVideosData, profileId: string) => ({
  type: GET_PROFILE_VIDEOS_SUCCESS,
  viewType,
  videos,
  profileId,
});

export const getProfileVideosFailure = (viewType: UserVideoType, error: unknown) => ({
  type: GET_PROFILE_VIDEOS_FAILURE,
  error,
  viewType,
});

export const postVideosRequest = (viewType: UserVideoType, file: File) => ({
  type: POST_USER_VIDEOS_REQUEST,
  file,
  viewType,
});

export const postVideosSuccess = (viewType: UserVideoType, video: UserVideosDTO) => ({
  type: POST_USER_VIDEOS_SUCCESS,
  viewType,
  video,
});

export const postVideosFailure = (viewType: UserVideoType, error: unknown) => ({
  type: POST_USER_VIDEOS_FAILURE,
  error,
  viewType,
});

export const deleteVideosRequest = (viewType: UserVideoType, id: string) => ({
  type: DELETE_USER_VIDEOS_REQUEST,
  id,
  viewType,
});
export const deleteVideosSuccess = (viewType: UserVideoType, id: string) => ({
  type: DELETE_USER_VIDEOS_SUCCESS,
  viewType,
  id,
});

export const deletePublicVideosFailure = (viewType: UserVideoType, error: unknown) => ({
  type: DELETE_USER_VIDEOS_FAILURE,
  error,
  viewType,
});

//GET OWNER

export const getOwnerProfileRequest = (id: string) => ({
  type: GET_OWNER_PROFILE_REQUEST,
  id,
});

export const getOwnerProfileSuccess = (user: UserProfile) => ({
  type: GET_OWNER_PROFILE_SUCCESS,
  user,
});

export const getSearchUsersRequest = (config: any) => ({
  type: GET_SEARCH_USERS_REQUEST,
  config,
});

export const getSearchUsersSuccess = (users: Array<UserProfile>) => ({
  type: GET_SEARCH_USERS_SUCCESS,
  users,
});

export const getSearchUsersFailure = (error: unknown) => ({
  type: GET_SEARCH_USERS_FAILURE,
  error,
});

export const setBookmarksRequest = (config: UserProfile) => ({
  type: SET_BOOKMARKS_REQUEST,
  config,
});

export const setBookmarksSuccess = (user: UserProfile) => ({
  type: SET_BOOKMARKS_SUCCESS,
  user,
});

export const setBookmarksFailure = (error: unknown) => ({
  type: SET_BOOKMARKS_FAILURE,
  error,
});

export const getBookmarksRequest = (config: any) => ({
  type: GET_BOOKMARKS_REQUEST,
  config,
});

export const getBookmarksSuccess = (users: Array<UserProfile>) => ({
  type: GET_BOOKMARKS_SUCCESS,
  users,
});

export const getBookmarksFailure = (error: unknown) => ({
  type: GET_BOOKMARKS_FAILURE,
  error,
});

export const deleteBookmarksRequest = (config: UserProfile) => ({
  type: DELETE_BOOKMARKS_REQUEST,
  config,
});

export const deleteBookmarksSuccess = (profileId: string) => ({
  type: DELETE_BOOKMARKS_SUCCESS,
  profileId,
});

export const deleteBookmarksFailure = (error: unknown) => ({
  type: DELETE_BOOKMARKS_FAILURE,
  error,
});

export const setBlockedByMeRequest = (profileId: string) => ({
  type: SET_BLOCKED_BY_ME_REQUEST,
  profileId,
});

export const setBlockedByMeSuccess = (user: IBlackList) => ({
  type: SET_BLOCKED_BY_ME_SUCCESS,
  payload: {
    user,
  },
});

export const setBlockedByMeFailure = (error: unknown) => ({
  type: SET_BLOCKED_BY_ME_FAILURE,
  error,
});

export const deleteBlockedByMeRequest = (profileId: string) => ({
  type: DELETE_BLOCKED_BY_ME_REQUEST,
  profileId,
});

export const deleteBlockedByMeSuccess = (profileId: string) => ({
  type: DELETE_BLOCKED_BY_ME_SUCCESS,
  profileId,
});

export const deleteBlockedByMeFailure = (error: unknown) => ({
  type: DELETE_BLOCKED_BY_ME_FAILURE,
  error,
});

export const getBLockedUsersRequest = () => ({
  type: GET_BLOCKED_USERS_REQUEST,
});

export const getBLockedUsersSuccess = (users: Array<IBlackList>) => ({
  type: GET_BLOCKED_USERS_SUCCESS,
  users,
});

export const getBLockedUsersFailure = (error: unknown) => ({
  type: GET_BLOCKED_USERS_FAILURE,
  error,
});

export const updatePinSuccess = (profileId: string, isPinned: boolean) => ({
  type: UPDATE_PIN_SUCCESS,
  payload: { profileId, isPinned },
});

export const updateCurrentUserRequest = (user: UserProfile) => ({
  type: UPDATE_CURRENT_USER_PROFILE_REQUEST,
  user,
});

export const updateAvatarRequest = (body: { photoId: string }) => ({
  type: UPDATE_CURRENT_USER_AVATAR_REQUEST,
  body,
});

export const updateAvatarDataRequest = (file: FormData, onSuccess: () => void, onError: () => void) => ({
  type: UPDATE_CURRENT_USER_AVATAR_DATA_REQUEST,
  body: { file, onSuccess, onError },
});

export const getCurrentUserDataRequest = () => ({
  type: GET_CURRENT_USER_DATA_REQUEST,
});


export const updateCurrentUserSuccess = (user: UserProfile) => ({
  type: UPDATE_CURRENT_USER_PROFILE_SUCCESS,
  user,
});
export const updateCurrentUserFailure = (error: unknown) => ({
  type: UPDATE_CURRENT_USER_PROFILE_FAILURE,
  error,
});

export const getOwnerProfileFailure = (error: unknown) => ({
  type: GET_OWNER_PROFILE_FAILURE,
  error,
});

export const getUserProfileRequest = (userId: string) => ({
  type: GET_USER_PROFILE_REQUEST,
});

export const getUserProfileSuccess = (user: UserProfile) => ({
  type: GET_USER_PROFILE_SUCCESS,
  user,
});

export const getUserProfileFailure = (error: unknown) => ({
  type: GET_USER_PROFILE_FAILURE,
  error,
});

// CLEAR USER
export const clearUserRequest = () => ({
  type: CLEAR_USER_REQUEST,
});

export const clearUserSuccess = () => ({
  type: CLEAR_USER_SUCCESS,
});

//GET AVAILABLE USERS FOR CHAT PAGE
export const getAvailableUsersRequest = (config: { name?: string; id?: string }) => ({
  type: GET_AVAILABLE_USERS_REQUEST,
  config,
});

export const getAvailableUsersSuccess = (users: Array<UserProfile>) => ({
  type: GET_AVAILABLE_USERS_SUCCESS,
  users,
});

export const getAvailableUsersFailure = (error: unknown) => ({
  type: GET_AVAILABLE_USERS_FAILURE,
  error,
});

export const getUsersDialogsRequest = (config: { offset: number }) => ({
  type: GET_USERS_DIALOGS_REQUEST,
  config,
});

export const getUsersDialogsSuccess = (dialogs: UserDialog) => ({
  type: GET_USERS_DIALOGS_SUCCESS,
  dialogs,
});

export const getUsersDialogsFailure = (error: unknown) => ({
  type: GET_USERS_DIALOGS_FAILURE,
  error,
});

export const DialogToUsersDialogsSuccess = (dialogs: DialogMessage, changeCounter: boolean) => ({
  type: ADD_USERS_DIALOGS_SUCCESS,
  payload: { dialogs, changeCounter },
});

export const readUsersDialogsSuccess = (id: string) => ({
  type: READ_USERS_DIALOGS_REQUEST,
  payload: { id },
});

export const getDialogCountersAction = () => ({
  type: GET_DIALOG_COUNTERS,
});

export const getDialogCountersSuccessAction = (payload: CountersShape) => ({
  type: GET_DIALOG_COUNTERS_SUCCESS,
  payload,
});

export const socketUpdateDialogCount = (payload: CountersShape) => ({
  type: SOCKET_UPDATE_DIALOG_COUNT,
  payload,
});

export const readMessage = (uid: string) => ({
  type: READ_MESSAGE,
  payload: { uid },
});

export const clearAvailableUsersSuccess = () => ({
  type: CLEAR_AVAILABLE_USERS_SUCCESS,
});

export const translateMessageSuccess = (payload: { toProfileId: string; date: string; translate: string }) => ({
  type: TRANSLATE_MESSAGE_TO_CHAT_SUCCESS,
  payload,
});

export const revealPhotoMessageRequest = (payload: {
  toProfileId: string;
  photoId: string;
  date: string;
  closeHandler: Function;
}) => ({
  type: REVEAL_PHOTO_CHAT_REQUEST,
  payload,
});

export const revealPhotoMessageSuccess = (payload: {
  toProfileId: string;
  date: string;
  img?: {
    photoId: string;
    origin: string;
    large: string;
    small: string;
  };
  closeHandler: Function;
}) => ({
  type: REVEAL_PHOTO_CHAT_SUCCESS,
  payload,
});

export const revealVideoMessageRequest = (payload: {
  toProfileId: string;
  videoId: string;
  date: string;
  closeHandler: Function;
}) => ({
  type: REVEAL_VIDEO_CHAT_REQUEST,
  payload,
});

export const revealVideoMessageSuccess = (payload: {
  toProfileId: string;
  date: string;
  video?: {
    videoId: string;
    origin: string;
    thumbnail: string;
  };
  closeHandler: Function;
}) => ({
  type: REVEAL_VIDEO_CHAT_SUCCESS,
  payload,
});

export const setSendVideoMessageRequest = (
  payload: {
    toProfileId: string;
    videoId: string;
    origin: string;
    thumbnail: string;
  } & { closeHandler: Function; isStaff: boolean; isDialog?: boolean }
) => ({
  type: SEND_VIDEO_MESSAGE_TO_CHAT_REQUEST,
  payload,
});

export const setSendVideoMessageSuccess = (payload: {
  toProfileId: string;
  text: string;
  video?: {
    videoId: string;
    origin: string;
    thumbnail: string;
  };
}) => ({
  type: SEND_VIDEO_MESSAGE_TO_CHAT_SUCCESS,
  payload,
});

export const setSendMessageRequest = (payload: {
  toProfileId: string;
  text: string,
  isSticker?: boolean,
  sticker?: any,
  isStaff?: boolean
}) => ({
  type: SEND_MESSAGE_TO_CHAT_REQUEST,
  payload,
});

export const setSendMessageSuccess = (payload: {
  toProfileId: string;
  text: string;
  img?: {
    photoId: string;
    origin: string;
    large: string;
    small: string;
  };
  video?: {
    videoId: string;
    origin: string;
    large: string;
    small: string;
  };
}) => ({
  type: SEND_MESSAGE_TO_CHAT_SUCCESS,
  payload,
});

export const setSendPhotoMessageRequest = (
  payload: {
    toProfileId: string;
    photoId: string;
    origin: string;
    large: string;
    small: string;
    originBlur: string;
    largeBlur: string;
    smallBlur: string;
  } & { closeHandler: Function; isStaff: boolean; isDialog?: boolean }
) => ({
  type: SEND_PHOTO_MESSAGE_TO_CHAT_REQUEST,
  payload,
});

export const setSendPhotoMessageSuccess = (payload: {
  toProfileId: string;
  text: string;
  img?: {
    photoId: string;
    origin: string;
    large: string;
    small: string;
  };
}) => ({
  type: SEND_PHOTO_MESSAGE_TO_CHAT_SUCCESS,
  payload,
});

export const setVideoChatProfile = (payload: { videoProfileId: string | null }) => ({
  type: SET_VIDEO_CALL,
  payload,
});

export const setReceiveMessageSuccess = (payload: {
  profileId: string;
  profileName: string;
  text: string;
  img?: {
    photoId: string;
    origin: string;
    large: string;
    small: string;
  };
  video?: {
    videoId: string;
    origin: string;
    thumbnail: string;
  };
  currentUserId: string;
  isChatOpen: boolean;
}) => ({
  type: RECEIVE_MESSAGE_TO_CHAT_SUCCESS,
  payload,
});

export const setReceivePhotoMessageSuccess = (payload: {
  profileId: string;
  img?: {
    photoId: string;
    origin: string;
    large: string;
    small: string;
  };
  currentUserId: string;
  isChatOpen: boolean;
}) => ({
  type: RECEIVE_PHOTO_MESSAGE_TO_CHAT_SUCCESS,
  payload,
});

export const setReceiveVideoMessageSuccess = (payload: {
  profileId: string;
  video?: {
    videoId: string;
    origin?: string;
    thumbnail: string;
  };
  currentUserId: string;
  isChatOpen: boolean;
}) => ({
  type: RECEIVE_VIDEO_MESSAGE_TO_CHAT_SUCCESS,
  payload,
});

export const setConvertedVideoMessageSuccess = (payload: { video?: UserVideosDTO; viewType: UserVideoType }) => ({
  type: SET_VIDEO_CONVERTED,
  payload,
  video: payload.video,
  viewType: payload.viewType,
});

export const setUpdateAvailableUsersFromComponent = (payload: UserProfileState[]) => ({
  type: SET_UPDATE_AVAILABLE_USERS_FROM_COMPONENT,
  payload,
});

export const setUpdateAvailableUsers = (payload: UserProfileState) => ({
  type: SET_UPDATE_AVAILABLE_USERS,
  payload,
});

export const setDisconnectedAvailableUsers = (payload: UserProfileState) => ({
  type: SET_DISCONNECTED_AVAILABLE_USERS,
  payload,
});

export const setInviteRemove = (payload: string) => ({
  type: REMOVE_INVITE,
  payload,
});

export const setVideoInviteAdd = (payload: IVideoInviteProfile) => ({
  type: ADD_VIDEO_INVITE,
  payload,
});

export const setVideoInviteRemove = (payload: string) => ({
  type: REMOVE_VIDEO_INVITE,
  payload,
});

export const disconnectChat = (payload: string) => ({
  type: DISCONNECT_CHAT,
  payload,
});

export const updateBalanceRequest = () => ({
  type: UPDATE_BALANCE_REQUEST,
});

export const updateBalanceSuccess = (payload: { balance: number }) => ({
  type: UPDATE_BALANCE_SUCCESS,
  payload,
});

export const updateBalanceFailed = (error: unknown) => ({
  type: UPDATE_BALANCE_FAILED,
  error,
});

export const updateInappRequest = () => ({
  type: UPDATE_INAPP_REQUEST,
});

export const updateInappSuccess = (payload: { inAppMessages: IInappMessagePayload }) => ({
  type: UPDATE_INAPP_SUCCESS,
  payload,
});

export const updateInappFailed = (error: unknown) => ({
  type: UPDATE_INAPP_FAILED,
  error,
});

export const filterInappRequest = (inappDistributionId: string) => ({
  type: FILTER_INAPP_REQUEST,
  inappDistributionId,
});

export const filterInappSuccess = (payload: { inappDistributionId: string }) => ({
  type: FILTER_INAPP_SUCCESS,
  payload,
});

export const filterInappFailed = (error: unknown) => ({
  type: FILTER_INAPP_FAILED,
  error,
});

export const updateQuestionsRequest = () => ({
  type: UPDATE_QUESTIONS_REQUEST,
});

export const updateQuestionsSuccess = (payload: { questions: IQuestions[] }) => ({
  type: UPDATE_QUESTIONS_SUCCESS,
  payload,
});

export const updateQuestionsFailed = (error: unknown) => ({
  type: UPDATE_QUESTIONS_FAILED,
  error,
});

export const filterQuestionsRequest = (id: string) => ({
  type: FILTER_QUESTIONS_REQUEST,
  id,
});

export const filterQuestionsSuccess = (payload: { id: string }) => ({
  type: FILTER_QUESTIONS_SUCCESS,
  payload,
});

export const filterQuestionsFailed = (error: unknown) => ({
  type: FILTER_QUESTIONS_FAILED,
  error,
});

export const closeQuestionsRequest = (id: string) => ({
  type: CLOSE_QUESTIONS_REQUEST,
  id,
});

export const closeQuestionsSuccess = (payload: { id: string }) => ({
  type: CLOSE_QUESTIONS_SUCCESS,
  payload,
});

export const closeQuestionsFailed = (error: unknown) => ({
  type: CLOSE_QUESTIONS_FAILED,
  error,
});

export const closeQuestionsForDaysSuccess = () => ({
  type: CLOSE_QUESTIONS_FOR_DAYS_SUCCESS,
});

export const chargeBalanceRequest = (config: string, toProfileId: string) => ({
  type: CHARGE_BALANCE_REQUEST,
  payload: { config, toProfileId },
});

export const chargeBalanceSuccess = (payload: { balance: number }) => ({
  type: CHARGE_BALANCE_SUCCESS,
  payload,
});

export const chargeBalanceFailed = (error: unknown) => ({
  type: CHARGE_BALANCE_FAILED,
  error,
});

export const getSettingsRequest = () => ({
  type: GET_SETTINGS_REQUEST,
});

export const getSettingsSuccess = (settings: ISettings) => ({
  type: GET_SETTINGS_SUCCESS,
  payload: { settings },
});

export const getSettingsFailed = (error: unknown) => ({
  type: GET_SETTINGS_FAILED,
  error,
});

export const getEmailEventsBlacklistRequest = () => ({
  type: GET_EMAIL_EVENTS_BLACKLIST_REQUEST,
});

export const getEmailEventsBlacklistSuccess = (emailEventsBlacklist: EmailEventsBlacklist[]) => ({
  type: GET_EMAIL_EVENTS_BLACKLIST_SUCCESS,
  payload: { emailEventsBlacklist },
});

export const getEmailEventsBlacklistFailed = (error: unknown) => ({
  type: GET_EMAIL_EVENTS_BLACKLIST_FAILED,
  error,
});

export const getAcquiringRequest = () => ({
  type: GET_ACQUIRING_REQUEST,
});

export const getAcquiringSuccess = (acquiring: IAcquiring[]) => ({
  type: GET_ACQUIRING_SUCCESS,
  payload: { acquiring },
});

export const getAcquiringFailed = (error: unknown) => ({
  type: GET_ACQUIRING_FAILED,
  error,
});

export const userBalanceChargedSuccess = (payload: { profileId: string }) => ({
  type: USER_BALANCE_CHARGED_SUCCESS,
  payload,
});

export const setHeaderUserSearch = (id: string | null) => ({
  type: SET_USER_HEADER_SEARCH,
  payload: id,
});

export const changeUserPasswordRequest = (password: string) => ({
  type: CHANGE_USER_PASSWORD,
  password,
});

export const changeUserEmailRequest = (email: string) => ({
  type: CHANGE_USER_EMAIL,
  email,
});

export const changeUserEmailSuccess = (email: string) => ({
  type: CHANGE_USER_EMAIL_SUCCESS,
  email,
});
