import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';

import { useDispatch } from "react-redux";
import dayjs from 'dayjs';
import { startCase } from 'lodash';
import { Breakpoint, CircularProgress, Container, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getRandomElementFromArray } from 'utils/getRandomElementFromArray';

import { UserProfileDTO } from '@/app/users/users.reducer';
import { useAppSelector } from '@/app/hooks';
import { getCurrentUserDataRequest } from "@/app/users/users.actions";

import UsersSlider from '@/components/sliders/UsersSlider/UsersSlider';
import UserBasicInfo from './components/user-basic-info.component';
import UserGeneralInfo from './components/user-general-info.component';
import MuilirowSlider from '@/components/sliders/multirow.slider.component';
import DialogRegistration from '@/components/dialogs/registration-dialog.component';

import { ProfileListTypes } from '@/types/profile-list.types';
import { UserChildrenEnum } from '@/types/user-profile.types';

import { useAppBreakpoints } from '@/hooks';
import useUserProfile from '@/hooks/useUserProfile';
import useUserPhotos from '@/hooks/useUserPhotos';

import SlidersService from '@/services/sliders.service';

const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    zIndex: 1000,
    marginTop: 40,
    [theme.breakpoints.down('xl')]: {
      marginTop: 24,
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 14,
    },
  },
  aboutContainer: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0,1fr) 268px',
    gap: 32,
    margin: '20px 0',
    [theme.breakpoints.down('xl')]: {
      gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
      gap: 16,
    },
    [theme.breakpoints.down('lg')]: {
      gap: 20,
    },
  },
}));

const UserProfile = () => {
  const classes = useStyles();
  const { lg, sm, lgAndUp } = useAppBreakpoints();

  const [isLoading, currentProfile] = useUserProfile();
  const currentUser = useAppSelector((state) => state.users.currentUser);
  const [, photos] = useUserPhotos();

  const dispatch = useDispatch();

  const stylesOverrides = {
    contentWrapper: {
      padding: 0,
      maxWidth: lg ? 738 : 968,
    },
    contentLoader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };

  const aboutMeRef = useRef();

  let width = 'xl';
  if (!lg) width = 'md';
  if (sm) width = 'sm-';
  if (lgAndUp) width = 'xl';

  const defaultValue = 'Not Filled';

  const userHolidays =
    currentProfile &&
    Object.entries(currentProfile)
      .reduce((acc, [key, value]) => {
        if (key.includes('celebration') && value) {
          acc.push(startCase(key.replace('celebration', '')));
          return acc;
        } else {
          return acc;
        }
      }, [] as Array<string>)
      .join(', ');

  const userLangs =
    currentProfile &&
    Object.entries(currentProfile)
      .reduce((acc, [key, value]) => {
        if (key.includes('lang') && value && key !== 'langEnglish') {
          acc.push(`${startCase(key.replace('lang', ''))} (${startCase(value as unknown as string)})`);
          return acc;
        } else {
          return acc;
        }
      }, [] as Array<string>)
      .join(', ');

  function formatDate(date) {
    const formattedDate = dayjs(date).format('DD MMM');
    return formattedDate;
  }

  function getAge(date) {
    const birthDate = dayjs(date);
    const today = dayjs();
    const age = today.diff(birthDate, 'year');
    return age;
  }

  const mainInfo: ProfileListTypes[] = useMemo(
    () => [
      {
        label: 'Country',
        value: currentProfile?.profile?.country || defaultValue,
      },
      {
        label: 'City',
        value: startCase(currentProfile?.profile?.city) || defaultValue,
      },
      {
        label: 'Religion',
        value: startCase(currentProfile?.profile?.religion as string) || defaultValue,
      },
      {
        label: 'Date Birth',
        value: currentProfile?.profile?.birthday
          ? `${formatDate(currentProfile?.profile?.birthday)}, (${getAge(currentProfile?.profile?.birthday)})`
          : defaultValue,
      },
      {
        label: 'English',
        value: startCase(currentProfile?.profile?.langEnglish) || defaultValue,
      },
      {
        label: 'Marital',
        value: currentProfile?.profile?.family || defaultValue,
      },
    ],
    [
      currentProfile?.profile?.birthday,
      currentProfile?.profile?.city,
      currentProfile?.profile?.country,
      currentProfile?.profile?.langEnglish,
      currentProfile?.profile?.family,
      currentProfile?.profile?.religion,
    ]
  );

  const appearanceList: ProfileListTypes[] = useMemo(
    () => [
      {
        label: 'Hair color',
        value: currentProfile?.profile?.hairColor || defaultValue,
      },
      {
        label: 'Eyes color',
        value: currentProfile?.profile?.eyeColor || defaultValue,
      },
      {
        label: 'Body type',
        value: currentProfile?.profile?.body || defaultValue,
      },
      { label: 'Height', value: currentProfile?.profile?.height || defaultValue },
      { label: 'Weight', value: currentProfile?.profile?.weight || defaultValue },
    ],
    [
      currentProfile?.profile?.body,
      currentProfile?.profile?.eyeColor,
      currentProfile?.profile?.hairColor,
      currentProfile?.profile?.height,
      currentProfile?.profile?.weight,
    ]
  );

  const perfectList: ProfileListTypes[] = useMemo(
    () => [
      {
        label: 'Age',
        value:
          currentProfile?.profile?.perfectAgeFrom && currentProfile?.profile?.perfectAgeTo
            ? `From: ${currentProfile?.profile?.perfectAgeFrom} to: ${currentProfile?.profile?.perfectAgeTo}`
            : defaultValue,
      },
      { label: 'Hair Color', value: startCase(currentProfile?.profile?.perfectHair) || defaultValue },
      { label: 'Eyes Color', value: startCase(currentProfile?.profile?.perfectEyes) || defaultValue },
      { label: 'Body Type', value: startCase(currentProfile?.profile?.perfectBody) || defaultValue },
      {
        label: 'Height',
        value:
          currentProfile?.profile?.perfectHeightFrom && currentProfile?.profile?.perfectHeightTo
            ? `From: ${currentProfile?.profile?.perfectHeightFrom} to: ${currentProfile?.profile?.perfectHeightTo}`
            : defaultValue,
      },
      { label: 'Weight', value: startCase(currentProfile?.profile?.perfectWeight) || defaultValue },
      { label: 'English', value: startCase(currentProfile?.profile?.perfectLangEnglish) || defaultValue },
      { label: 'Children', value: startCase(currentProfile?.profile?.perfectChildren) || defaultValue },
      { label: 'Type of women', value: startCase(currentProfile?.profile?.perfectType) || defaultValue },
    ],
    [
      currentProfile?.profile?.perfectAgeFrom,
      currentProfile?.profile?.perfectAgeTo,
      currentProfile?.profile?.perfectBody,
      currentProfile?.profile?.perfectChildren,
      currentProfile?.profile?.perfectEyes,
      currentProfile?.profile?.perfectHair,
      currentProfile?.profile?.perfectHeightFrom,
      currentProfile?.profile?.perfectHeightTo,
      currentProfile?.profile?.perfectLangEnglish,
      currentProfile?.profile?.perfectType,
      currentProfile?.profile?.perfectWeight,
    ]
  );

  const aboutList: ProfileListTypes[] = useMemo(
    () => [
      {
        label: 'Education',
        value: startCase(currentProfile?.profile?.education) || defaultValue,
      },
      {
        label: 'Profession',
        value: startCase(currentProfile?.profile?.profession) || defaultValue,
      },
      {
        label: 'Alcohol',
        value: startCase(currentProfile?.profile?.drink) || defaultValue,
      },
      {
        label: 'Smoking',
        value: startCase(currentProfile?.profile?.smoke) || defaultValue,
      },
      { label: 'I live', value: '' || defaultValue },
      {
        label: 'Children',
        value: currentProfile?.profile?.children
          ? startCase(UserChildrenEnum[currentProfile?.profile?.children as keyof typeof UserChildrenEnum])
          : defaultValue,
      },
      { label: 'Flowers', value: '' || defaultValue },
      { label: 'Holidays', value: userHolidays || defaultValue },
      { label: 'Pets', value: '' || defaultValue },
      { label: 'Languages', value: userLangs || defaultValue },
      { label: 'Goals', value: '' || defaultValue, full: true },
      { label: 'Interests', value: '' || defaultValue, full: true },
      {
        label: 'About me',
        value: currentProfile?.profile?.aboutMe || defaultValue,
        full: true,
      },
    ],
    [
      currentProfile?.profile?.aboutMe,
      currentProfile?.profile?.children,
      currentProfile?.profile?.drink,
      currentProfile?.profile?.education,
      currentProfile?.profile?.profession,
      currentProfile?.profile?.smoke,
      userHolidays,
      userLangs,
    ]
  );

  const scrollAboutMe = useCallback(() => {
    if (aboutMeRef?.current) {
      (aboutMeRef.current as HTMLAnchorElement).scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const [isOpenRegistrationForm, openRegistrationForm] = useState(false);

  useEffect(() => {
    dispatch(getCurrentUserDataRequest())
  }, [dispatch]);

  const renderContent = useCallback(() => {
    if (isLoading) {
      return <CircularProgress color='primary' size={48} />;
    } else {
      return (
        <>
          <UserGeneralInfo
            currentProfile={currentProfile as UserProfileDTO}
            mainInfo={mainInfo}
            scrollAboutMe={scrollAboutMe}
            openRegistrationForm={openRegistrationForm}
          />
          <div className={classes.aboutContainer} ref={aboutMeRef}>
            <UserBasicInfo
              perfectList={perfectList}
              mainInfo={mainInfo}
              aboutList={aboutList}
              appearanceList={appearanceList}
              photos={photos}
              currentProfile={currentProfile as UserProfileDTO}
              openRegistrationForm={openRegistrationForm}
            />
          </div>

          {Boolean(currentUser) && (
            <>
              <UsersSlider
                disablePadding={true}
                slidesPerView={{ xl: 5, md: 4, lg: 4 }}
                fetcher={SlidersService.getLastJoined}
                customSpacing={20}
                isUnloginned={!currentUser}
                title="New Members"
                isCheckOnlineIsState
              />
              <MuilirowSlider disablePadding={true} />
            </>
          )}
        </>
      );
    }
  }, [
    isLoading,
    currentProfile,
    mainInfo,
    scrollAboutMe,
    classes.aboutContainer,
    perfectList,
    aboutList,
    appearanceList,
    photos,
  ]);

  const generateTitle = useMemo(() => {
    if (!Boolean(currentProfile)) return '';

    const titleVariants = ['profile for communication', 'profile for dating'];
    const someProfileType = getRandomElementFromArray(titleVariants);

    return `${currentProfile?.profile?.name} - ${someProfileType}`
  }, [currentProfile?.profile?.id]);

  const generateWomenDescription = useMemo(() => {
    if (!Boolean(currentProfile)) return '';

    const descriptionVariants = {
      type: getRandomElementFromArray(['Join', 'Add']),
      for: getRandomElementFromArray(['for girls', 'for women', 'for ladies']),
      decision: getRandomElementFromArray(['communication', 'sympathy']),
      result: getRandomElementFromArray(['interesting', 'positive']),
    };

    return `${descriptionVariants.type} in the social network of dating ${descriptionVariants.for}. Here you will find ${descriptionVariants.decision} and a lot of ${descriptionVariants.result}`;
  }, [currentProfile?.profile?.id]);

  const generateMenDescription = useMemo(() => {
    if (!Boolean(currentProfile)) return '';

    const descriptionVariants = {
      type: getRandomElementFromArray(['Join', 'Add']),
      for: getRandomElementFromArray(['for guys', 'for men']),
      offer: getRandomElementFromArray(['dating', 'communication', 'sympathy']),
      result: getRandomElementFromArray(['interesting', 'positive']),
      profileType: getRandomElementFromArray(['real', 'verified', 'real']),
    };

    return `${descriptionVariants.type} in the social network of dating ${descriptionVariants.for}. We offer ${descriptionVariants.offer} and a lot of ${descriptionVariants.result}. Only ${descriptionVariants.profileType} profiles.`;
  }, [currentProfile?.profile?.id]);

  const generateDescription = currentProfile?.profile?.gender === 'male' ? generateMenDescription : generateWomenDescription;

  return (
    <>
      <Helmet>
        <title>{generateTitle}</title>
        <meta name="description" content={generateDescription} />

        <meta property="og:title" content={generateTitle} />
        <meta property="og:description" content={generateDescription} />
      </Helmet>
      {}
      <Container
        maxWidth={width as Breakpoint}
        className={classes.contentWrapper}
        style={isLoading ? stylesOverrides.contentLoader : stylesOverrides.contentWrapper}
      >
        {currentProfile && isOpenRegistrationForm && (
          <DialogRegistration
            handleViewProfile={() => {
            }}
            open={isOpenRegistrationForm}
            closeHandler={() => openRegistrationForm(false)}
            referenceProfile={currentProfile?.profile}
            isRegistration={true}
            metaActions={[]}
          />
        )}
        {renderContent()}
      </Container>
    </>
  );
};

export default UserProfile;
