import { useCallback, useEffect, useMemo, useState } from "react";
import BaseCard from "@/components/base/base-card.component";
import { ReactComponent as MailIcon } from '@/assets/icons/white-mail.svg';
import { ReactComponent as GuestsIcon } from "@/assets/icons/drawer-icons/guests.svg";
import { useAppBreakpoints, useContainerWidth } from "@/hooks";
import { Button, CircularProgress, Container, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { formatCreatedAtDate, isToday } from "@/utils/formatDate";
import { format } from "date-fns";
import useBlankProfileAvatart from "@/hooks/useBlankProfileAvatart";
import BaseButton from "@/components/base/button.component";
import NotificationsService, { NotificationShape } from "@/services/notifications.service";
import { toggleSnackbarOpen } from "@/app/ui/ui.actions";
import { Link } from "react-router-dom";
import { useAppDispatch } from "@/app/hooks";
import AvatarWithFallback from "@/components/AvatarWithFallback/AvatarWithFallback";

const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    zIndex: 1000,
    marginTop: 40,
    [theme.breakpoints.down("xl")]: {
      marginTop: 24,
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 14,
    },
  },

  header: {
    marginBottom: 27,
    [theme.breakpoints.down("md")]: {
      marginBottom: 32,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: 20,
    },
  },

  cardWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  headerButtonWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 26
  },

  subHeaderButton: {
    '&:first-child': {
      marginRight: 12
    }
  },

  iconWrapper: {
    paddingRight: '28px',
    '& svg': {
      width: '16px',
      height: '12px',
      '& path': {
        fill: 'rgb(80, 62, 182)'
      }
    }
  },

  notificationWrapper: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '18px',

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '11px 0',
    borderBottom: '1px solid #e7e6e6',

    '&:first-child': {
      borderTop: '1px solid #e7e6e6'

    }
  },

  nameWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px'
  },

  senderAvatar: {
    maxWidth: 42,
    minWidth: 42,
    maxHeight: 42,
    aspectRatio: "42 / 42",
    marginRight: '11px'
  },

  avatarInner: {
    borderRadius: "50%",
    width: '100%',
    height: '100%',
  },

  info: {
    color: '#808080'

  },

  name: {
    wordBreak: 'break-word',
    color: '#000000'
  },

  loadMoreWrapper: {
    alignSelf: "center",
    minWidth: "200px !important",
  },

  loadMoreBtn: {
    maxWidth: 200,
    minWidth: 200,
  },

  loadButtonSection: {
    display: 'flex',
    flex: '1',
    justifyContent: 'center',
    marginTop: '28px'
  },

  noNotifications: {
    margin: '0 auto',
    border: '2px dashed #503EB6',
    padding: '10px',
    width: '200px'
  }
}));


const Notifications = () => {
  const { currentUserPicture } = useBlankProfileAvatart();
  const dispatch = useAppDispatch();


  const { xs } = useAppBreakpoints();

  const stylesOverrides = useMemo(() => (
    {
      contentWrapper: {
        padding: 0,
        maxWidth: 968,
      },
      subHeaderButton: {
        width: '100%',
        height: xs ? 24 : 36,
        minHeight: xs ? 24 : 36,
      },
    }
  ), [xs]);

  const classes = useStyles();
  const width = useContainerWidth();

  const formatedCreatedAtDate = useCallback((createdAt: Date) => {
    const timestamp = new Date();

    const month = format(timestamp, "MMM do");
    const localeTime = formatCreatedAtDate(createdAt);

    return isToday(timestamp) ? `${localeTime}` : `${month}`;
  }, []);

  const notificationMapper = useCallback((notification: NotificationShape) => {
    switch (notification.type) {
      case 'message': {
        return <div className={classes.notificationWrapper} key={notification.id}>
          <div className={classes.nameWrapper}>
            <div className={classes.iconWrapper}>
              <MailIcon />
            </div>
            <div className={classes.senderAvatar}>
              <Link to={`/user/${notification.profileId}`}>
                <AvatarWithFallback src={notification.profileAvatarSmall} isUserPhoto className={classes.avatarInner}
                                    alt='avatar' />
              </Link>
            </div>
            <div className={classes.info}>
              <b className={classes.name}>{notification.profileName} </b>
              sent you a letter
            </div>
          </div>
          <div>
            {formatedCreatedAtDate(notification.createdAt)}
          </div>
        </div>
      }
      case 'guest': {
        return <div className={classes.notificationWrapper} key={notification.id}>
          <div className={classes.nameWrapper}>
            <div className={classes.iconWrapper}>
              <GuestsIcon />
            </div>
            <div className={classes.senderAvatar}>
              <Link to={`/user/${notification.profileId}`}>
                <AvatarWithFallback src={notification.profileAvatarSmall} isUserPhoto className={classes.avatarInner}
                                    alt='avatar' />
              </Link>

            </div>
            <div className={classes.info}>
              <b className={classes.name}>{notification.profileName} </b>
              visit your page
            </div>
          </div>
          <div>
            {formatedCreatedAtDate(notification.createdAt)}
          </div>
        </div>
      }
    }

  }, [classes.avatarInner, classes.iconWrapper, classes.info, classes.name, classes.nameWrapper, classes.notificationWrapper, classes.senderAvatar, currentUserPicture, formatedCreatedAtDate])


  const [notificationItems, setNotificationItems] = useState([]);
  const [isPaginationOver, setIsPaginationOver] = useState<boolean>(false);
  const [isBookmarks, setIsBookmarks] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paginationOffset, setPaginationOffset] = useState<number>(0);

  useEffect(() => {
    setPaginationOffset(0);
    setNotificationItems([]);
    setIsPaginationOver(false);
    loadMore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBookmarks])

  const loadMore = useCallback(
    async (offset = 0) => {
      setIsLoading(true);

      try {
        const response = await NotificationsService.getNotifications(offset, isBookmarks);

        if (response.length < 20) {
          setIsPaginationOver(true);
        }

        setNotificationItems(!offset ? response : notificationItems.concat(response));
      } catch {
        dispatch(
          toggleSnackbarOpen(
            "Something goes wrong during request execution",
            "error"
          )
        )
      }

      setPaginationOffset(offset);

      setIsLoading(false);
    },
    [isBookmarks, notificationItems]
  );

  const getLoadButton = useMemo(() => {
    if (isPaginationOver || !notificationItems.length) return null;
    if (isLoading) return <CircularProgress color="secondary" style={{ margin: "0 auto" }} />;

    return <BaseButton
      text="Load More (20)"
      color="primary"
      wrapperClass={classes.loadMoreWrapper}
      className={classes.loadMoreBtn}
      onClick={() => loadMore(paginationOffset + 20)}
    />

  }, [classes.loadMoreBtn, classes.loadMoreWrapper, isLoading, isPaginationOver, loadMore, notificationItems.length, paginationOffset])

  return <Container
    maxWidth={width}
    className={classes.contentWrapper}
    style={stylesOverrides.contentWrapper}
  >
    <BaseCard wrapperOverrideStyle={{ margin: 0, paddingBottom: 10 }}>
      <div
        className={classes.cardWrapper}
      >
        <header className={classes.header}>
          <Typography variant="h2">Notifications</Typography>
        </header>

        <section className={classes.headerButtonWrapper}>
          <Button
            disableElevation
            color={isBookmarks ? 'info' : "primary"}
            variant="contained"
            style={stylesOverrides.subHeaderButton}
            className={classes.subHeaderButton}
            onClick={() => {
              setIsBookmarks(false);
            }}
          >
            <Typography color="currentColor">All</Typography>
          </Button>

          <Button
            disableElevation
            color={isBookmarks ? "primary" : 'info'}
            variant="contained"
            style={stylesOverrides.subHeaderButton}
            className={classes.subHeaderButton}
            onClick={() => {
              setIsBookmarks(true);
            }}
          >
            <Typography color="currentColor">Contacts</Typography>
          </Button>
        </section>

        <div>
          {
            !notificationItems.length ?
              <div className={classes.noNotifications}>There are no notifications</div>
              :
              notificationItems.map(notificationMapper)
          }
        </div>
        <div className={classes.loadButtonSection}>
          {getLoadButton}
        </div>
      </div>
    </BaseCard>
  </Container>

}

export default Notifications;
