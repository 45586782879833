import { HttpService } from "./http.service";
import { ErrorWrapper } from "./utils";

enum ENewsType {
  PHOTO = 'photo'
}

export interface IPhotos {
  "small": string;
  "large": string;
  "origin": string;
}

export interface NewsShape {
  type: ENewsType.PHOTO;
  photos: Array<IPhotos>;
  profileId: string;
  profileName: string;
  profileAvatarSmall: string | null;
  profileAvatarLarge: string | null;
  profilePageId: number;
  dateForGroup: Date;
  activeAt: string;
  birthday: string | null;
}

export interface BirthdayShape {
  id: string;
  pageId: string;
  avatarSmall: string | null;
  avatarLarge: string | null;
  name: string;
}

export default class NewsService {
  static get entity(): string {
    return "news";
  }

  static async getNews(
    offset: number,
    isBookmarks?: boolean
  ): Promise<Array<NewsShape>> {
    const request = `/${this.entity}${isBookmarks ? '/bookmarks' : ''}?limit=20&offset=${offset}`

    try {
      const response = await HttpService.get(request);

      return response.data;
    } catch (error: any) {
      const message = error?.response?.data?.error || error?.response?.statusText;
      throw new ErrorWrapper(error, message);
    }
  }


  static async getBirthdays(): Promise<Array<BirthdayShape>> {
    const request = `/news/birthdays`

    try {
      const response = await HttpService.get(request);
      return response.data;
    } catch (error: any) {
      const message = error?.response?.data?.error || error?.response?.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
