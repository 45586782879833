import { HttpService } from "./http.service";
import { ErrorWrapper } from "./utils";

export interface GuestShape {
  id: string;
  createdAt: Date;
  profileId: string;
  profileName: string;
  profileAvatarSmall: string;
  profileAvatarLarge: string;
  profilePageId: number;
  seen: Date;
  activeAt: Date;
  birthday?: string;
  isBookmarks: boolean;
}

export default class GuestsService {
  static get entity(): string {
    return "guests";
  }

  static async getGuests(): Promise<Array<GuestShape>> {
    const request = `/${this.entity}`

    try {
      const response = await HttpService.get(request);
      return response.data;
    } catch (error: any) {
      const message = error?.response?.data?.error || error?.response?.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async markGuestAsSeen(
    profileId: string
  ): Promise<Array<boolean>> {
    const request = `/${this.entity}/seen`

    try {
      const response = await HttpService.post(request, {
        profileId
      });

      return response.data;
    } catch (error: any) {
      const message = error?.response?.data?.error || error?.response?.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async markAllGuestAsSeen(): Promise<Array<boolean>> {
    const request = `/${this.entity}/seen/all`

    try {
      const response = await HttpService.post(request);

      return response.data;
    } catch (error: any) {
      const message = error?.response?.data?.error || error?.response?.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
