import React, { FormEvent } from "react";
import {
  Autocomplete,
  Box,
  Avatar,
  Theme,
  Typography,
  SxProps,
  CircularProgress,
} from "@mui/material";
import { useStyles as useTextInputStyles } from "@/components/base/text-field.component";
import { useBinarySwitcher } from "../../hooks/useBinarySwitcher";

interface sxStyles {
  [key: string]: SxProps<Theme>;
}

const sxStyles: sxStyles = {
  listItem: {
    "& > img": { mr: 2, flexShrink: 0 },
  },
  avatar: {
    width: 24,
    height: 24,
    marginRight: 1,
  },
};

type BaseAutocompleteProps = {
  onChange: (event: React.SyntheticEvent<Element, Event>, value: any) => void;
  name?: string;
  placeholder: string;
  loading: boolean;
  options: Array<any>;
  onInput: (event: FormEvent<HTMLInputElement>) => void;
  setOptions: (value: any) => void;
};
const BaseAutocomplete = ({
                            onChange,
                            onInput,
                            name = "",
                            placeholder,
                            loading = false,
                            options,
                            setOptions,
                          }: BaseAutocompleteProps) => {
  const textFieldClasses = useTextInputStyles();
  const [open, show, hide] = useBinarySwitcher();

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  React.useEffect(() => {
    if (options.length > 0) {
      show();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return (
    <Autocomplete
      autoComplete
      autoHighlight
      autoSelect
      open={open}
      onOpen={show}
      onClose={hide}
      loading={loading}
      onChange={onChange}
      options={options}
      getOptionLabel={(option) => option.pageId.toString()}
      renderOption={(props, option) => (
        <Box component="li" sx={sxStyles.listItem} {...props}>
          <Avatar alt={option.name} src={option.avatarSmall} sx={sxStyles.avatar} />
          <Typography variant="body1">{option.name}</Typography>
        </Box>
      )}
      renderInput={(params) => (
        <div ref={params.InputProps.ref}>
          <label className={textFieldClasses.label}>
            <input
              {...params.inputProps}
              onInput={onInput}
              className={textFieldClasses.input}
              name={name}
              placeholder={placeholder}
              pattern="[0-9]*"
            />
          </label>
        </div>
      )}
    />
  );
};

export default BaseAutocomplete;
