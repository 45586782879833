import BaseButton from '@/components/base/button.component';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

import { ReactComponent as MailIcon } from '@/assets/icons/chats/mail.svg';
import { ReactComponent as GiftsIcon } from '@/assets/icons/chats/gift.svg';
import { ReactComponent as ContactsIcon } from '@/assets/icons/chats/contacts.svg';
import { ReactComponent as PinContactIcon } from '@/assets/icons/chats/pin-contact.svg';

import clsx from 'clsx';

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '14px',
    marginTop: '0',
    padding: '14px 0 0 0',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  chatTypeWrapper: {},
  rightButtons: {
    borderRadius: '20px',
    width: '36px',
    height: '36px',
  },
  rightIcons: {
    display: 'flex',
    width: '274px',
    gap: '8px'
  },
  togglePhoto: {
    fontWeight: '600',
    fontSize: '12px',
    lineHeight: '15px',
    color: '#503EB6',
    background: '#EEEEEE',
    padding: '10px 9px 11px 12px',
    borderRadius: '20px',
    cursor: 'pointer',
  },
  chatsWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '74px',
    background: '#EEEEEE',
    borderRadius: '20px',
  },
}));

type ActionsButtonsComponentProps = {
  contactsClickHandler?: () => void;
  giftClickHandler?: () => void;
  mailClickHandler?: () => void;
  togglePhotoHandler?: () => void;
  pinnedProfileHandler?: () => void;
  isPhotosSliderOpen?: boolean;
  isPhotosButtonVisible?: boolean;
};

const ActionsButtonsComponent = ({
                                   contactsClickHandler = () => {
                                   },
                                   giftClickHandler = () => {
                                   },
                                   mailClickHandler = () => {
                                   },
                                   togglePhotoHandler = () => {
                                   },
                                   pinnedProfileHandler = () => {
                                   },
                                   isPhotosSliderOpen,
                                   isPhotosButtonVisible,
                                 }: ActionsButtonsComponentProps) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.container)}>
      <div className={classes.rightIcons}>
        <BaseButton
          text=''
          inverted
          size='small'
          onClick={mailClickHandler}
          className={classes.rightButtons}
          Icon={<MailIcon />}
        />
        <BaseButton
          text=''
          inverted
          size='small'
          onClick={giftClickHandler}
          className={classes.rightButtons}
          Icon={<GiftsIcon />}
        />
        <BaseButton
          text=''
          inverted
          size='small'
          onClick={contactsClickHandler}
          className={classes.rightButtons}
          Icon={<ContactsIcon />}
        />

        <BaseButton
          text=''
          inverted
          size='small'
          onClick={pinnedProfileHandler}
          className={classes.rightButtons}
          Icon={<PinContactIcon />}
        />

        {isPhotosButtonVisible ? <div className={classes.togglePhoto} onClick={togglePhotoHandler}>
          {isPhotosSliderOpen ? 'Hide photos' : 'Show photos'}
        </div> : null}
      </div>
    </div>
  );
};

export default ActionsButtonsComponent;
