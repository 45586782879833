import { useCallback, useEffect, useMemo, useState } from "react";
import { UserPhotosDTO } from "@/app/users/users.dto";

export const useImageSizesCalculator = (defaultImages: Partial<UserPhotosDTO>[]) => {
  const [sizes, setSizes] = useState({});

  const calculateSize = useCallback((url) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        resolve({ width: img.naturalWidth, height: img.naturalHeight });
      };
    });
  }, [])

  useEffect(() => {
    const fetchSizes = async () => {
      const sizeMap = {};
      await Promise.all(
        defaultImages.map(async (item) => {
          const size = await calculateSize(item.origin);
          sizeMap[item.origin] = size;
        })
      );
      setSizes(sizeMap);
    };

    fetchSizes();
  }, [calculateSize, defaultImages]);

  const calculatedImages = useMemo(() => {
    return defaultImages.map((item) => ({
      ...item,
      width: sizes[item.origin]?.width || 0,
      height: sizes[item.origin]?.height || 0,
    }));
  }, [defaultImages, sizes]);

  return { calculatedImages }
};
