import { HttpService } from './http.service';
import { ErrorWrapper } from './utils';
import { GetCpaMeResponse } from "@/types/cpa.types";


export default class CpaService {
  static get entity(): string {
    return 'cpa';
  }

  static async getCpaMe(): Promise<GetCpaMeResponse> {
    try {
      const response = await HttpService.get(`/${this.entity}/me`);

      return response.data;
    } catch (error: any) {
      const message = error?.response?.data?.error || error?.response?.statusText;

      throw new ErrorWrapper(error, message);
    }
  }
}
