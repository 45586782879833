import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { writeMessageSchema } from '@/utils/validationSchemas';
import { ReactComponent as ArrowLeftIcon } from '@/assets/icons/left-arrow.svg';
import {
  Avatar,
  Chip,
  Collapse,
  Dialog,
  DialogProps,
  FormControl,
  Tab,
  Tabs,
  Theme,
  Typography,
  Zoom,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Formik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import BaseTextarea from '../base/base-textarea.component';
import BaseButton from '../base/button.component';
import BaseTextField from '../base/text-field.component';
import { CloseIconStyled } from './sign-up-dialog.component';
import { ReactComponent as AttachIcon } from '@/assets/icons/attach.svg';
import BaseAutocomplete from '../base/base-autocomplete';
import UploadPhotoLayout from '../layouts/upload-photo.layout';
import UploadPhotoItem from '../base/upload-photo-item';
import usePhotoFileUtils from './hooks/usePhotoFileUtils';
import { UserPhotosDTO, UserVideosDTO } from '@/app/users/users.dto';
import { Gallery } from 'react-photoswipe-gallery';
import { postPhotosRequest, postVideosRequest } from '@/app/users/users.actions';
import { UserPhotoType, UserVideoType } from '@/app/users/users.types';
import useDebounce from '@/hooks/useDebounce';
import ProfilesService from '@/services/profiles.service';
import { ISettings, UserProfile } from '@/types/user-profile.types';
import { sendMailRequest } from '@/app/mails/mails.actions';
import useBalanceCheckHook from '@/hooks/useBalanceCheckHook';
import UploadVideoLayout from '../layouts/upload-video.layout';
import useVideoFileUtils from './hooks/useVideoFileUtils';
import UploadVideoItem from '../base/upload-video-item';
import { isIOSPhone } from "@/helpers/constants";
import imageCompression from "browser-image-compression";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '18px 10px',
    overflow: 'auto',

    [theme.breakpoints.down('sm')]: {
      padding: '28px 12px',
    },
  },
  paper: {
    padding: 0,
  },
  dialogPaper: {
    overflowY: 'visible',
  },
  formTextFieldsContainer: {
    marginTop: 20,
    marginBottom: 20,
    '& > $formTextField:not(:first-child)': {
      marginTop: 20,
    },
  },
  attachedImagesContainer: {
    display: 'flex',
    gap: 10,
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: 20,
  },
  attachedIcon: {
    maxWidth: 42,
    maxHeight: 42,
    '& > .MuiButton-startIcon': {
      margin: 0,
    },
    minWidth: '0 !important',
  },
  formSubmitBtn: {
    width: 144,
  },
  formTextField: {},
  formContainer: {
    display: 'flex',
    width: 344,
    padding: '0 28px 24px',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  formControl: {
    width: '100%',
  },
  dialogTitle: {
    textAlign: 'center',
    height: 48,
  },
  formActions: {
    gap: 10,
    display: 'flex',
    width: '100%',
  },
  sendBtn: {
    width: '100%',
    maxWidth: 120,
  },
  attachedImagesInner: {
    border: `2px dashed ${theme.palette.radioSelected.main}`,
    borderRadius: 5,
    padding: 4,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    flex: '1 1 100%',
    maxHeight: '232px',
    overflow: 'auto',
  },
  profileSlideWrapper: {
    display: 'flex',
    flexWrap: 'wrap',

    '& .pswp__zoom-wrap': {
      display: 'flex',
      justifyContent: 'center',
    },

    '& .pswp__img': {
      width: 'auto !important',
      position: 'relative !important',
    },
    '& .pswp__img--placeholder': {
      display: 'none',
    },
  },
  attachBtn: {
    color: `${theme.palette.radioSelected.main} !important`,
  },
  attachTextStyle: {
    fontWeight: '600 !important',
    fontSize: '14px !important',
    [theme.breakpoints.down('md')]: {
      fontSize: '10px !important',
    },
  },
}));

type DialogWriteMailProps = {
  open: boolean;
  closeHandler: () => void;
  receiver?: IReceiver;
} & DialogProps;

export interface IReceiver {
  id: string;
  name: string;
  avatarSmall: string;
}

type InitValues = {
  receiverId: IReceiver | null;
  subject: string;
  text: string;
  photoIds: string[];
  videoIds: string[];
};

const DialogWriteMail = ({ open, closeHandler, receiver }: DialogWriteMailProps) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const isSending = useAppSelector((state) => state.mails.isSending);
  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState<UserProfile[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const windowWidth = useMemo(() => window.innerWidth, []);
  const windowHeight = useMemo(() => window.innerHeight - 90, []);

  const [tabValue, setTabValue] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const initValues: InitValues = useMemo(
    () => ({
      receiverId: receiver || null,
      subject: '',
      text: '',
      photoIds: [],
      videoIds: [],
    }),
    [receiver]
  );

  const [attachedImages, handleImageRemove, handleImageAdd, resetImageAttachments] = usePhotoFileUtils<UserPhotosDTO>();
  const [attachedVideos, handleVideoRemove, handleVideoAdd, resetVideoAttachments] = useVideoFileUtils<UserVideosDTO>();

  useEffect(() => {
    if (!open) resetImageAttachments();
    if (!open) resetVideoAttachments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setIsSearching(true);

      ProfilesService.getAllProfilesByPageId(debouncedSearchTerm).then((results) => {
        setIsSearching(false);
        setResults(results.data);
      });
    } else {
      setResults([]);
    }
  }, [debouncedSearchTerm]);

  const [isMessageShown, setMessage] = useState(true);

  const togglePhotoViews = useCallback(() => setMessage(!isMessageShown), [isMessageShown]);
  const handlePhotoSelect = (image) => {
    handleImageAdd(image);
    togglePhotoViews();
  };

  const toggleVideoViews = useCallback(() => setMessage(!isMessageShown), [isMessageShown]);
  const handleVideoSelect = (video) => {
    checkBalance(creditsMessageSend + (attachedVideos.length + 1) * creditsMessageVideoSend, () => {
      handleVideoAdd(video);
      toggleVideoViews();
    });
  };

  //************ */
  const isPhotoUploading = useAppSelector((state) => state.users.photosIsUploading);
  const isVideoUploading = useAppSelector((state) => state.users.videosIsUploading);

  const handlePhotoFileUploading = useCallback(
    (image: FormData) => {
      dispatch(postPhotosRequest(UserPhotoType.PRIVATE_VIEW, image));
    },
    [dispatch]
  );

  const handlePhotoDrop = useCallback(
    async (acceptedFiles: any) => {
      const formData = new FormData();
      if (isIOSPhone) {
        const options = {
          useWebWorker: true,
          alwaysKeepResolution: true,
          maxSizeMB: 20,
        };
        const compressedFile = await imageCompression(acceptedFiles[0], options);
        formData.append('file', compressedFile);
      } else {
        formData.append('file', acceptedFiles[0]);
      }
      handlePhotoFileUploading(formData);
    },
    [handlePhotoFileUploading]
  );

  const handleVideoFileUploading = useCallback(
    (video: File) => {
      dispatch(postVideosRequest(UserVideoType.PRIVATE_VIEW, video));
    },
    [dispatch]
  );

  const handleVideoDrop = useCallback(
    (acceptedFiles: any) => {
      handleVideoFileUploading(acceptedFiles[0]);
    },
    [handleVideoFileUploading]
  );

  const { creditsMessageSend, creditsMessageVideoSend } = useAppSelector<ISettings>((state) => state.users.settings);
  const wallet = useAppSelector((state) => state.users.wallet);
  const { checkBalance } = useBalanceCheckHook(wallet?.balance || 0);

  return (
    <Dialog
      classes={{
        root: classes.dialogPaper,
      }}
      TransitionComponent={Zoom}
      open={open}
      onClose={closeHandler}
    >
      <div className={classes.container}>
        <div className={classes.dialogTitle}>
          <Typography variant='h2' component='h2' gutterBottom>
            Creating new letter
          </Typography>
        </div>

        <Formik
          initialValues={initValues}
          validationSchema={writeMessageSchema}
          onSubmit={({ receiverId: { id }, ...rest }) => {
            const photoIds = attachedImages.map((attached) => attached.id);
            const videoIds = attachedVideos.map((attached) => attached.id);
            checkBalance(creditsMessageVideoSend * videoIds.length, () => {
              dispatch(
                sendMailRequest({
                  ...rest,
                  receiverId: id,
                  photoIds,
                  videoIds,
                  closeHandler,
                })
              );
            });
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <Collapse
              in={isMessageShown}
              onExited={() => setMessage(false)}
              onEnter={() => setMessage(true)}
              timeout={500}
            >
              <form onSubmit={handleSubmit} className={classes.formContainer}>
                <FormControl className={classes.formControl}>
                  <div className={classes.formTextFieldsContainer}>
                    {values.receiverId ? (
                      <Chip
                        onDelete={
                          receiver
                            ? undefined
                            : () => {
                              setSearchTerm('');
                              setFieldValue('receiverId', '');
                            }
                        }
                        label={values.receiverId.name}
                        color='primary'
                        avatar={<Avatar src={values.receiverId.avatarSmall} />}
                      />
                    ) : (
                      <BaseAutocomplete
                        setOptions={setResults}
                        name='receiverId'
                        placeholder='To (ID):'
                        onChange={(e, val) => {
                          setFieldValue('receiverId', val);
                        }}
                        onInput={(e: any) => {
                          if (e.target.validity.valid) {
                            setSearchTerm(e.currentTarget.value);
                          }
                        }}
                        options={results}
                        loading={isSearching}
                      />
                    )}
                    <BaseTextField
                      label='subject'
                      placeholder='Subject:'
                      onChange={handleChange}
                      type='text'
                      baseClass={classes.formTextField}
                    />
                    <BaseTextarea
                      label='text'
                      placeholder='Message:'
                      onChange={handleChange}
                      className={classes.formTextField}
                    />
                  </div>

                  {attachedImages && attachedImages.length > 0 && (
                    <div className={classes.attachedImagesContainer}>
                      <BaseButton
                        inverted
                        text=''
                        className={classes.attachedIcon}
                        textStyle=''
                        onClick={() => {
                        }}
                        Icon={<AttachIcon />}
                      />
                      <Typography variant='body2'>Attached images</Typography>

                      <div className={classes.attachedImagesInner}>
                        <div className={classes.profileSlideWrapper}>
                          <Gallery>
                            {attachedImages.map((image) => (
                              <UploadPhotoItem
                                key={image.id}
                                {...image}
                                isPrivate={image?.private}
                                isEditable={true}
                                handleRemoveFile={(id) => handleImageRemove({ id })}
                                isExpended={false}
                                windowWidth={windowWidth}
                                windowHeight={windowHeight}
                              />
                            ))}
                          </Gallery>
                        </div>
                      </div>
                    </div>
                  )}
                  {attachedVideos && attachedVideos.length > 0 && (
                    <div className={classes.attachedImagesContainer}>
                      <BaseButton
                        inverted
                        text=''
                        className={classes.attachedIcon}
                        textStyle=''
                        onClick={() => {
                        }}
                        Icon={<AttachIcon />}
                      />
                      <Typography variant='body2'>Attached videos</Typography>

                      <div className={classes.attachedImagesInner}>
                        <div className={classes.profileSlideWrapper}>
                          {attachedVideos.map((video) => (
                            <UploadVideoItem
                              key={video.id}
                              {...video}
                              isPrivate={video?.private}
                              isEditable={true}
                              handleRemoveFile={(id) => handleVideoRemove({ id })}
                              isExpended={false}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className={classes.formActions}>
                    <BaseButton
                      loading={isSending}
                      disabled={isSending}
                      type='submit'
                      color='primary'
                      text='Send'
                      className={classes.sendBtn}
                    />
                    {tabValue === 0 && (
                      <BaseButton
                        inverted
                        text='Attach'
                        className={classes.attachBtn}
                        textStyle={classes.attachTextStyle}
                        Icon={<AttachIcon />}
                        onClick={togglePhotoViews}
                      />
                    )}
                    {tabValue === 1 && (
                      <BaseButton
                        inverted
                        text='Attach'
                        className={classes.attachBtn}
                        textStyle={classes.attachTextStyle}
                        Icon={<AttachIcon />}
                        onClick={toggleVideoViews}
                      />
                    )}
                  </div>
                </FormControl>
              </form>
            </Collapse>
          )}
        </Formik>
        <Collapse in={!isMessageShown} timeout={500}>
          {tabValue === 0 && (
            <BaseButton
              onClick={togglePhotoViews}
              text=''
              variant='contained'
              size='small'
              Icon={<ArrowLeftIcon />}
              color='primary'
              disableElevation
            />
          )}
          {tabValue === 1 && (
            <BaseButton
              onClick={togglePhotoViews}
              text=''
              variant='contained'
              size='small'
              Icon={<ArrowLeftIcon />}
              color='primary'
              disableElevation
            />
          )}

          {tabValue === 0 && (
            <>
              <Tabs value={tabValue} onChange={handleChangeTab} centered>
                <Tab label='Photo' />
                <Tab label='Video' />
              </Tabs>

              <UploadPhotoLayout
                isEditable={true}
                isExpended={false}
                handleDrop={handlePhotoDrop}
                className={''}
                handleFileUploading={() => {
                }}
                handleRemoveFile={() => {
                }}
                handleSelect={handlePhotoSelect}
                isUploading={isPhotoUploading}
                isDelitable={false}
              />
            </>
          )}

          {tabValue === 1 && (
            <>
              <Tabs value={tabValue} onChange={handleChangeTab} centered>
                <Tab label='Photo' />
                <Tab label='Video' />
              </Tabs>

              <UploadVideoLayout
                isEditable={true}
                isExpended={false}
                handleDrop={handleVideoDrop}
                className={''}
                handleFileUploading={() => {
                }}
                handleRemoveFile={() => {
                }}
                handleSelect={handleVideoSelect}
                isUploading={isVideoUploading}
                isDelitable={false}
              />
            </>
          )}
        </Collapse>
      </div>
      <CloseIconStyled onClick={closeHandler} />
    </Dialog>
  );
};

export default DialogWriteMail;
