import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { writeGiftSchema } from '@/utils/validationSchemas';
import { ReactComponent as ArrowLeftIcon } from '@/assets/icons/left-arrow.svg';
import { Avatar, Chip, Collapse, Dialog, DialogProps, FormControl, Theme, Typography, Zoom } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Formik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import BaseTextarea from '../base/base-textarea.component';
import BaseButton from '../base/button.component';
import { CloseIconStyled } from './sign-up-dialog.component';
import { ReactComponent as AttachIcon } from '@/assets/icons/attach.svg';
import BaseAutocomplete from '../base/base-autocomplete';

import { Gallery } from 'react-photoswipe-gallery';
import { postPhotosRequest } from '@/app/users/users.actions';
import { UserPhotoType } from '@/app/users/users.types';
import useDebounce from '@/hooks/useDebounce';
import ProfilesService from '@/services/profiles.service';
import { UserProfile } from '@/types/user-profile.types';
import UploadGiftLayout from '../layouts/upload-gift.layout';
import UploadGift from '../base/upload-gift';
import useFileGiftUtils from './hooks/useFileGiftUtils';
import { GiftsDTO } from '@/app/gifts/gifts.types';
import { getAvailableToSendGiftsRequest, postGiftRequest } from '@/app/gifts/gifts.actions';
import useBalanceCheckHook from '@/hooks/useBalanceCheckHook';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '18px 10px',
    overflowY: 'scroll',

    [theme.breakpoints.down('sm')]: {
      padding: '28px 12px',
    },
  },
  paper: {
    padding: 0,
  },
  dialogPaper: {
    overflowY: 'visible',
  },
  formTextFieldsContainer: {
    marginTop: 20,
    marginBottom: 20,
    '& > $formTextField:not(:first-child)': {
      marginTop: 20,
    },
  },
  attachedImagesContainer: {
    display: 'flex',
    gap: 10,
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: 20,
  },
  attachedIcon: {
    maxWidth: 42,
    maxHeight: 42,
    '& > .MuiButton-startIcon': {
      margin: 0,
    },
    minWidth: '0 !important',
  },
  formSubmitBtn: {
    width: 144,
  },
  formTextField: {},
  formContainer: {
    display: 'flex',
    width: 344,
    padding: '0 28px 24px',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  formControl: {
    width: '100%',
  },
  dialogTitle: {
    textAlign: 'center',
    height: 48,
  },
  formActions: {
    gap: 10,
    display: 'flex',
    width: '100%',
  },
  sendBtn: {
    width: '100%',
    maxWidth: 120,
  },
  attachedImagesInner: {
    border: `2px dashed ${theme.palette.radioSelected.main}`,
    borderRadius: 5,
    padding: 4,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    flex: '1 1 100%',
    maxHeight: '232px',
    overflow: 'auto',
  },
  profileSlideWrapper: {
    display: 'flex',
    flexWrap: 'wrap',

    '& .pswp__zoom-wrap': {
      display: 'flex',
      justifyContent: 'center',
    },

    '& .pswp__img': {
      width: 'auto !important',
      position: 'relative !important',
    },
    '& .pswp__img--placeholder': {
      display: 'none',
    },
  },
  attachBtn: {
    color: `${theme.palette.radioSelected.main} !important`,
  },
  attachTextStyle: {
    fontWeight: '600 !important',
    fontSize: '14px !important',
    [theme.breakpoints.down('md')]: {
      fontSize: '10px !important',
    },
  },
}));

type DialogGiftProps = {
  open: boolean;
  closeHandler: () => void;
  receiver?: IReceiver;
  isDialog?: boolean;
} & DialogProps;

export interface IReceiver {
  id: string;
  name: string;
  avatarSmall: string;
}

type InitValues = {
  receiverId: IReceiver | null;
  text: string;
  virtualGiftId: string;
};

const DialogGift = ({ open, closeHandler, receiver, isDialog = false }: DialogGiftProps) => {
  const classes = useStyles();

  const [searchTerm, setSearchTerm] = useState('');
  const [results, setResults] = useState<UserProfile[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isMessageShown, setMessage] = useState(true);

  const wallet = useAppSelector((state) => state.users.wallet);
  const isSending = useAppSelector((state) => state.gifts.isSending);
  const gifts = useAppSelector((state) => state.gifts['gifts']);
  const isUploading = useAppSelector((state) => state.users.photosIsUploading);

  const dispatch = useAppDispatch();

  const debouncedSearchTerm = useDebounce(searchTerm, 500);


  const [attachedGift, handleRemove, handleAdd, resetAttachments] = useFileGiftUtils<GiftsDTO>();
  const { checkBalance } = useBalanceCheckHook(wallet?.balance || 0);

  const windowWidth = useMemo(() => window.innerWidth, []);
  const windowHeight = useMemo(() => window.innerHeight - 90, []);

  const initValues: InitValues = useMemo(
    () => ({
      receiverId: receiver || null,
      text: '',
      virtualGiftId: '',
    }),
    [receiver]
  );

  const images = useMemo(() => [...gifts], [gifts]);

  useEffect(() => {
    open && dispatch(getAvailableToSendGiftsRequest());
  }, [dispatch, open]);

  useEffect(() => {
    if (!open) resetAttachments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setIsSearching(true);

      ProfilesService.getAllProfilesByPageId(debouncedSearchTerm).then((results) => {
        setIsSearching(false);
        setResults(results.data);
      });
    } else {
      setResults([]);
    }
  }, [debouncedSearchTerm]);

  const toggleViews = useCallback(() => setMessage(!isMessageShown), [isMessageShown]);
  const handleSelect = (image) => {
    handleAdd(image);
    toggleViews();
  };

  //************ *

  const handleFileUploading = useCallback(
    (image: FormData) => {
      dispatch(postPhotosRequest(UserPhotoType.PRIVATE_VIEW, image));
    },
    [dispatch]
  );

  const handleDrop = useCallback(
    (acceptedFiles: any) => {
      const formData = new FormData();
      formData.append('file', acceptedFiles[0]);
      handleFileUploading(formData);
    },
    [handleFileUploading]
  );

  return (
    <Dialog
      classes={{
        root: classes.dialogPaper,
      }}
      TransitionComponent={Zoom}
      open={open}
      onClose={closeHandler}
    >
      <div className={classes.container}>
        <div className={classes.dialogTitle}>
          <Typography variant='h2' component='h2' gutterBottom>
            Send Gift
          </Typography>
        </div>

        <Formik
          initialValues={initValues}
          validationSchema={writeGiftSchema}
          onSubmit={({ receiverId: { id }, ...rest }, { setSubmitting }) => {
            setSubmitting(true);
            checkBalance(
              attachedGift?.credits
              || 0, () => {
                const virtualGiftId = attachedGift?.id;
                dispatch(
                  postGiftRequest({
                    ...rest,
                    receiverId: id,
                    virtualGiftId,
                    closeHandler: () => {
                      closeHandler();
                      setSubmitting(false);
                    },
                    isDialog,
                  })
                );
              },
              () => setSubmitting(false)
            );
          }}
        >
          {({ values, handleChange, handleSubmit, setFieldValue, isSubmitting }) => (
            <Collapse
              in={isMessageShown}
              onExited={() => setMessage(false)}
              onEnter={() => setMessage(true)}
              timeout={500}
            >
              <div className={classes.formContainer}>
                <FormControl className={classes.formControl}>
                  <div className={classes.formTextFieldsContainer}>
                    {values.receiverId ? (
                      <Chip
                        onDelete={
                          receiver
                            ? undefined
                            : () => {
                              setSearchTerm('');
                              setFieldValue('receiverId', '');
                            }
                        }
                        label={values.receiverId.name}
                        color='primary'
                        avatar={<Avatar src={values.receiverId.avatarSmall} />}
                      />
                    ) : (
                      <BaseAutocomplete
                        setOptions={setResults}
                        name='receiverId'
                        placeholder='To (ID):'
                        onChange={(e, val) => {
                          setFieldValue('receiverId', val);
                        }}
                        onInput={(e: any) => {
                          if (e.target.validity.valid) {
                            setSearchTerm(e.currentTarget.value);
                          }
                        }}
                        options={results}
                        loading={isSearching}
                      />
                    )}
                    <BaseTextarea
                      label='text'
                      placeholder='Message:'
                      onChange={handleChange}
                      className={classes.formTextField}
                    />
                  </div>

                  {attachedGift && (
                    <div className={classes.attachedImagesContainer}>
                      <BaseButton
                        inverted
                        text=''
                        className={classes.attachedIcon}
                        textStyle=''
                        onClick={() => {
                        }}
                        Icon={<AttachIcon />}
                      />
                      <Typography variant='body2'>Attached gift</Typography>

                      <div className={classes.attachedImagesInner}>
                        <div className={classes.profileSlideWrapper}>
                          <Gallery>
                            <UploadGift
                              key={attachedGift.id}
                              handleRemoveFile={(id) => handleRemove({ id })}
                              windowWidth={windowWidth}
                              windowHeight={windowHeight}
                              {...attachedGift}
                            />
                          </Gallery>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className={classes.formActions}>
                    <BaseButton
                      loading={isSending || isSubmitting}
                      disabled={isSending || isSubmitting}
                      type='submit'
                      color='primary'
                      text='Send'
                      className={classes.sendBtn}
                      onClick={() => handleSubmit()}
                    />
                    <BaseButton
                      inverted
                      text='Attach'
                      className={classes.attachBtn}
                      textStyle={classes.attachTextStyle}
                      Icon={<AttachIcon />}
                      onClick={toggleViews}
                    />
                  </div>
                </FormControl>
              </div>
            </Collapse>
          )}
        </Formik>
        <Collapse in={!isMessageShown} timeout={500}>
          <BaseButton
            onClick={toggleViews}
            text=''
            variant='contained'
            size='small'
            Icon={<ArrowLeftIcon />}
            color='primary'
            disableElevation
          />

          <UploadGiftLayout
            isEditable={true}
            isExpended={false}
            handleDrop={handleDrop}
            className={''}
            handleFileUploading={() => {
            }}
            handleRemoveFile={() => {
            }}
            handleSelect={handleSelect}
            defaultValue={images}
            isUploading={isUploading}
            isDelitable={true}
          />
        </Collapse>
      </div>
      <CloseIconStyled onClick={closeHandler} />
    </Dialog>
  )
    ;
};

export default DialogGift;
