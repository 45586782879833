import AppBootstrap from '@/components/core/app-bootstrap';
import React from 'react';
import SocketSubscriber from './components/SocketSubscriber';
import MainRouter from './routes';
import { BrowserRouter as Router } from 'react-router-dom';
import InappSubscriber from './components/InappSubscriber';
import QuestionsSubscriber from './components/QuestionsSubscriber';
import LiveChatWidget from './components/LiveChatWidget';
import AuthFromBlog from './components/AuthFromBlog';
import HotjarIntegration from "./components/HotjarIntegration";
import MetaTags from "@/components/MetaTags";

function App() {
  return (
    <AppBootstrap>
      <Router>
        <MetaTags />
        <HotjarIntegration />
        <AuthFromBlog />
        <SocketSubscriber />
        <InappSubscriber />
        <QuestionsSubscriber />
        <LiveChatWidget />
        <MainRouter />
      </Router>
    </AppBootstrap>
  );
}

export default App;
