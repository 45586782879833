import { Fragment, useCallback, useEffect, useState } from 'react';
import { Theme, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { UserProfile } from '@/types/user-profile.types';
import { calculateAge } from '@/helpers/helpers';
import BaseButton from '../base/button.component';
import ProfilesService from '@/services/profiles.service';
import clsx from 'clsx';
//TODO: button background color should be #eee
//TODO: refactor

type ViewProfileLayoutProps = {
  referenceProfile: UserProfile;
  handleViewRegistration: () => void;
  handleViewProfile: () => void;
  metaActions: {
    icon: JSX.Element;
    onClick: (data: { id: string; name: string; avatarSmall: string }) => void;
  }[];
};

const useStyles = makeStyles((theme: Theme) => ({
  dialogTitle: {
    textAlign: 'center',
    height: 48,
    textTransform: 'capitalize',
  },
  modalWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  viewProfileWrapper: {
    paddingLeft: '28px',
    width: '100%',
  },
  userAvatarWrapper: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  viewProfileButtonAgeWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    padding: '28px 0',
    borderBottom: '1px solid #EA4160',
  },
  ageWrapper: {},
  avatarAge: {},
  viewProfileButton: { maxWidth: '200px' },
  aboutMe: {},
  textBlock: {},
  textTitle: {
    fontWeight: 600,
    fontSize: 14,
  },
  textBody: {
    fontWeight: 500,
    fontSize: 14,
  },
  status: {
    width: '100%',
    background: '#f6f6f6',
    border: 'none',
    fontSize: 13,
    fontWeight: 400,
    padding: '12px 17px',
    lineHeight: 1,
    margin: '28px 0',
    color: '#767676',
  },
  carousel: {
    display: 'flex',
    overflowX: 'scroll',
  },
  photoItem: {
    height: '99px',
  },
  imageItem: {
    height: '99px',
    width: '110px',
    objectFit: 'cover',
  },
  hoverElement: {
    transition: 'all .3s',
    width: '100%',
    height: '100%',
    opacity: 0.9,
    position: 'relative',
    borderRadius: '10px',
    zIndex: 2,
  },
  hoverButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'self-end',
    position: 'absolute',
    top: '10px',
    right: '10px',
  },

  metaCtaIcon: {
    maxWidth: 32,
    maxHeight: 32,
    minHeight: '32px !important',
    minWidth: '0 !important',
    margin: '6px 6px 0 0 !important',

    '& > .MuiButton-startIcon': {
      margin: 0,
    },
  },
  cardInner: {
    textDecoration: 'none',
    padding: '10px 0',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    transition: 'all',
    borderRadius: 9,
    border: '2px solid #fff',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    '&:focus': {
      outline: 'none',
      opacity: 1,
    },

    '&:hover': {
      opacity: 1,
    },
    '&:hover $hoverElement': {
      opacity: '1',
    },
  },
}));

const ViewProfileLayout = ({
                             handleViewRegistration,
                             handleViewProfile,
                             referenceProfile,
                             metaActions,
                           }: ViewProfileLayoutProps) => {
  const [selectedImageToView, setSelectedImageToView] = useState(referenceProfile.avatarLarge);

  const [userImages, setUserImages] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    getImages();
  }, []);

  const getImages = useCallback(async () => {
    const profile = await ProfilesService.getProfilePhotosById(referenceProfile?.id);
    setUserImages(profile.photos);
  }, [referenceProfile]);

  const theme = useTheme();
  const viewButtonColor = theme.palette.radioSelected.main;

  const profileLink = `${window.location.origin}/user/${referenceProfile?.id}`
  return (
    <Fragment>
      <div className={classes.modalWrapper}>
        <div className={clsx(classes.userAvatarWrapper, classes.cardInner)}>
          <div className={classes.hoverElement}>
            <img width={450} src={selectedImageToView} alt={`Photo ${referenceProfile?.name}`} />
            <div className={classes.hoverButtonsContainer}>
              {metaActions.map((action, idx) => (
                <BaseButton
                  key={idx}
                  inverted
                  text=''
                  className={classes.metaCtaIcon}
                  textStyle=''
                  onClick={(e) => {
                    e.stopPropagation();
                    action.onClick({
                      id: referenceProfile.id,
                      name: referenceProfile.name,
                      avatarSmall: referenceProfile.avatarSmall,
                    });
                  }}
                  Icon={action.icon}
                />
              ))}
            </div>
          </div>
        </div>
        <div className={classes.viewProfileWrapper}>
          <div className={classes.dialogTitle}>
            <Typography variant='h2' component='h2' gutterBottom>
              {referenceProfile.name}'s profile
            </Typography>
          </div>
          {referenceProfile.status && <div className={classes.status}>{referenceProfile.status}</div>}
          <div className={classes.viewProfileButtonAgeWrapper}>
            <div className={classes.ageWrapper}>
              <Typography variant='body1' component='h6' gutterBottom>
                {referenceProfile?.birthday && (
                  <span className={classes.avatarAge}>Age: {`${calculateAge(referenceProfile.birthday)}`}</span>
                )}
              </Typography>
              <Typography variant='body1' component='h6' gutterBottom>
                {referenceProfile?.pageId && <span className={classes.avatarAge}>ID: {referenceProfile?.pageId}</span>}
              </Typography>
            </div>
            <div>
              <BaseButton
                href={profileLink}
                color='primary'
                text='View Profile'
                className={classes.viewProfileButton}
                onClick={() => handleViewProfile()}
              />
            </div>
          </div>
          {referenceProfile.body && (
            <div className={classes.textBlock}>
              <div className={classes.textTitle}>Body:</div>
              <div className={classes.textBody}>{referenceProfile.body}</div>
            </div>
          )}
          {referenceProfile.aboutMe && (
            <div className={classes.textBlock}>
              <div className={classes.textTitle}>About Me:</div>
              <div className={classes.textBody}>{referenceProfile.aboutMe}</div>
            </div>
          )}
        </div>
      </div>
      <div className={classes.carousel}>
        {userImages.map((image, index) => (
          <div className={classes.photoItem} key={image.origin} onClick={() => setSelectedImageToView(image.origin)}>
            <img className={classes.imageItem} src={image.origin} alt={`Photo ${referenceProfile?.name} ${index + 1}`} />
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default ViewProfileLayout;
