import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";

import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import jwtDecode from "jwt-decode";

import BaseBadge from "@/components/base/base-badge.component";
import DialogForgotPassword from "@/components/dialogs/forgot-password.dialog.component";
import UsersSlider from "@/components/sliders/users-slider.component";

import SlidersService from "@/services/sliders.service";
import { StorageService } from "@/services/storage.service";

import { useBinarySwitcher } from "@/hooks/useBinarySwitcher";
import { useQuery } from "@/hooks/useQuery";

import MainBanner from "./components/banner";

const Home = () => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const query = useQuery();
  const [isModalShown, showModal, hideModal] = useBinarySwitcher();
  const [resetCode, setResetCode] = useState('');

  const resetModalParams = useCallback(() => {
    navigate("/");
    hideModal();
    setResetCode('');
  }, [hideModal, navigate]);

  useEffect(() => {
    if (Boolean(query.get("resetPassword"))) {
      showModal();
      const code = query.get("resetCode");
      if (code) {
        setResetCode(code);
      }
    }
  }, [query, showModal]);

  useEffect(() => {
    const userToken = StorageService.getToken();
    if (userToken) {
      const { id: userId }: any = jwtDecode(userToken);
      navigate(`/user/${userId}`);
    }
  }, [navigate]);

  return (
    <Fragment>
      <DialogForgotPassword
        open={isModalShown}
        closeHandler={resetModalParams}
        resetCode={resetCode}
      />
      <MainBanner />

      <UsersSlider fetcher={SlidersService.getPublicTopUsers} isUnloginned={true}>
        {
          data =>
          (
            <>
              <BaseBadge value={data} color='#00E899'>
              <Typography variant="sliderTitle">Top Users</Typography>
              </BaseBadge>{" "}
            </>
          )
        }
      </UsersSlider>
      <UsersSlider fetcher={SlidersService.getPublicLastJoined} isUnloginned={true}>
        {
          data =>
          (
            <>
              <BaseBadge value={data} color={palette.radioSelected.main}>
                <Typography variant="sliderTitle">Last Joined Users</Typography>
              </BaseBadge>{" "}
            </>
          )
        }

      </UsersSlider>
    </Fragment>
  );
};

export default Home;
