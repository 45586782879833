import { makeStyles } from '@mui/styles';
import { Switch, Theme } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Peer, DataConnection, MediaConnection } from 'peerjs';
import { useBinarySwitcher } from '@/hooks/useBinarySwitcher';
import { showVideo } from './helepers/helpers-chat';
import { Camera } from './helepers/camera-helper';
import Socket from '@/services/Socket';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@/app/hooks';
import { toggleMyStream } from '@/app/ui/ui.actions';
import { setVideoChatProfile } from '@/app/users/users.actions';

export interface IProps {
  userId: string;
  referenceUserId: string;
  isOpenVideoChat?: boolean;
  availableConnection: DataConnection;
  availablePeer: Peer;
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start',
    flexDirection: 'column',
    position: 'relative',
    marginTop: '-15px',
    minHeight: '160px',
  },
  showMyVideo: {},
  textMyVideo: {},
  myVideoWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    position: 'absolute',
    zIndex: 10001,
  },
  selfVideo: {
    position: 'absolute',
    right: 0,
    [theme.breakpoints.down('sm')]: {
      width: '50px',
    },
    [theme.breakpoints.down("md")]: {
      width: '100px'
    },
    [theme.breakpoints.up('lg')]: {
      width: '200px',
    },
  },
  otherVideoContainer: {
    maxHeight: '350px',
    width: '100%',
    overflow: 'hidden',
  },
  otherVideo: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {},
  },
}));

const VideoChat = ({
                     userId,
                     referenceUserId,
                     isOpenVideoChat = false,
                     availableConnection = null,
                     availablePeer = null,
                   }: IProps) => {
  const otherVideo = useRef<HTMLVideoElement>();
  const selfVideo = useRef<HTMLVideoElement>();

  const [otherVideoOn, setOtherVideoOn] = useState(false);
  const { stream } = useAppSelector((state) => state.ui);
  const dispatch = useDispatch();

  const [showMyVideo, setShowMyVideo, setCloseMyVideo] =
    useBinarySwitcher(false);

  const [otherUserConnected, setOtherUserConnected] = useState(false);

  const toggleShowMyVideo = useCallback(() => {
    if (showMyVideo) {
      userClosedVideo();
      setCloseMyVideo();
    } else {
      initOpenVideo();
      setShowMyVideo();
    }
  }, [showMyVideo, setCloseMyVideo, setShowMyVideo]);

  useEffect(() => {
    Camera.getCamera(showMyVideo).then((currentStream) => {
      dispatch(toggleMyStream(currentStream));
      showVideo(currentStream, selfVideo.current, true);
    });
    return () => {
      Camera.getCamera(false).then((stream) => {
        return;
      });
    };
  }, [showMyVideo, dispatch]);

  const showStream = useCallback((
    call: MediaConnection,
    otherVideo: HTMLVideoElement,
    otherUserConnected: boolean,
  ) => {
    try {
      const handler = (remoteStream: MediaStream) => {
        dispatch(setVideoChatProfile({ videoProfileId: referenceUserId }));
        showVideo(remoteStream, otherVideo, false);
        setOtherVideoOn(true);
      };

      if (call) call.on('stream', handler);

      return () => {
        setOtherVideoOn(false);
        if (!otherUserConnected) {
          dispatch(setVideoChatProfile({ videoProfileId: null }));
        }
        if (call) call.off('stream', handler);
      }
    } catch (error) {
      console.log('error', error);
    }
  }, []);

  useEffect(() => {
    let dispose = () => {
    };
    const handler = async (call: MediaConnection) => {
      const otherVideoHandler = (remoteStream: MediaStream) => {
        showVideo(remoteStream, otherVideo.current, false);
        setOtherVideoOn(true);
      };
      call.on('stream', otherVideoHandler);
      call.answer(stream);
    };

    if (availableConnection && availablePeer) {
      if (
        availableConnection &&
        availableConnection['caller'] === availablePeer.id
      ) {
        showVideo(stream, selfVideo.current, true);
        dispose = showStream(
          availablePeer.call(availableConnection.peer, stream),
          otherVideo.current,
          otherUserConnected,
        );
      }
      availablePeer.on('call', handler);
    }
    return () => {
      availablePeer.off('call', handler);
      if (dispose) dispose();
    };
  }, [stream, showMyVideo, otherUserConnected]);

  useEffect(() => {
    if (Socket && Socket.socket) {
      Socket.on(
        'video:init:client',
        (data: { profileId: string; data?: any }) => {
          if (referenceUserId === data.profileId) {
            setOtherUserConnected(true);
          }
        }
      );
      Socket.on(
        'video:open:client',
        (data: { profileId: string; data?: any }) => {
          if (referenceUserId === data.profileId) {
            setOtherUserConnected(true);
          }
        }
      );
      Socket.on(
        'video:close:client',
        (data: { profileId: string; data?: any }) => {
          if (referenceUserId === data.profileId) {
            setOtherUserConnected(false);
          }
        }
      );
    }
  }, [referenceUserId]);

  const initOpenVideo = useCallback(() => {
    if (Socket && Socket.socket) {
      Socket.emit('video:open:server', {
        toProfileId: referenceUserId,
      });
    }
  }, [referenceUserId]);

  const userClosedVideo = useCallback(() => {
    if (Socket && Socket.socket) {
      Socket.emit('video:close:server', {
        toProfileId: referenceUserId,
      });
    }
  }, [referenceUserId]);

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.myVideoWrapper}>
        <div className={classes.textMyVideo}>My video:</div>
        <Switch
          className={classes.showMyVideo}
          checked={showMyVideo}
          onClick={toggleShowMyVideo}
        />
      </div>
      <div className={classes.otherVideoContainer}>
        <video
          className={classes.otherVideo}
          ref={otherVideo}
          width={'100%'}
          height={otherVideoOn ? 'initial' : '32px'}
          autoPlay={false}
          muted={false}
          style={{ pointerEvents: 'none' }}
          playsInline={true}
        />
      </div>
      <video
        className={classes.selfVideo}
        ref={selfVideo}
        height={showMyVideo ? 100 : 0}
        width={showMyVideo ? 100 : 0}
        autoPlay={false}
        muted={true}
        style={{ pointerEvents: 'none' }}
        playsInline={true}
      />
    </div>
  );
};

export default VideoChat;
