import ArchImage from "@/assets/images/arch.png";
import BgHeroMobile from "@/assets/images/bg-hero-mobile.jpg";
import BgHero from "@/assets/images/bg-hero.jpg";
import CircleBigImage from "@/assets/images/circle-big.png";
import CircleSmallImage from "@/assets/images/circle-small.png";
import HeroTabletImg from "@/assets/images/hero-img-tablet.png";
import HeroImg from "@/assets/images/hero-img.png";
import TriangleImage from "@/assets/images/triangle.png";
import BaseButton from "@/components/base/button.component";
import DialogSignUp from "@/components/dialogs/sign-up-dialog.component";
import {
    Box, Divider,
    SxProps,
    Theme,
    Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React, { useCallback, useState } from "react";





// TODO: make render of mobile elements more concise
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: "relative",
    maxHeight: 700,
    minHeight: 500,
    margin: "0 auto",
    display: "flex",
    marginBottom: 48,
    [theme.breakpoints.up("desktop")]: {
      maxWidth: `calc(${theme.breakpoints.values.xl}px * 1.22)`,
    },

    [theme.breakpoints.up("lg")]: {
      minHeight: 0,
      height: 400,
      maxHeight: "100%",
      padding: "0 !important",
    },
    [theme.breakpoints.up("md")]: {
      maxHeight: "100%",
      padding: "64px 0",
    },
  },
  bannerElements: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    overflow: "hidden",
    display: "block",
  },

  elementBase: {
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    position: "absolute",
  },
  element1: {
    top: 10,
    left: -75,
    height: 150,
    width: 150,
    backgroundImage: `url(${CircleSmallImage})`,
  },
  element2: {
    top: 0,
    right: -110,
    width: 327,
    height: 327,
    backgroundImage: `url(${CircleBigImage})`,
  },
  element3: {
    bottom: 40,
    right: -16,
    width: 104,
    height: 105,
    backgroundImage: `url(${TriangleImage})`,
  },
  element4: {
    bottom: -44,
    left: -64,
    width: 176,
    height: 158,
    backgroundImage: `url(${ArchImage})`,
  },

  heroBg: {
    "&::after": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      right: "calc((100% - 100vw) / 2)",
      left: "calc((100% - 100vw) / 2)",
      backgroundPosition: "center",
      backgroundSize: "cover",
      zIndex: -1,

      [theme.breakpoints.up(1400)]: {
        left: "70px !important",
      },

      [theme.breakpoints.up("lg")]: {
        borderTopLeftRadius: 90,
        borderBottomLeftRadius: 90,
        backgroundImage: `url(${BgHero})`,
      },

      [theme.breakpoints.between("lg", "xl")]: {
        left: 30,
      },

      [theme.breakpoints.up("xl")]: {
        left: "calc(70px - 4%)",
      },

      //   [theme.breakpoints.up("xs")]: {
      backgroundImage: `url(${BgHeroMobile})`,

      //   },
    },
    // marginLeft:
    width: "100%",
    position: "absolute",
    zIndex: 0,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  heroContainer: {
    width: "100%",
    position: "relative",
    zIndex: 1,
    height: "100%",

    maxWidth: 1168,
    margin: "0 auto",

    [theme.breakpoints.down("md")]: {
      marginTop: "auto",
      marginBottom: 96,
    },
  },
  heroContainerInner: {
    display: "flex",
    height: "100%",
    width: "100%",
    alignItems: "center",
    position: "relative",

    flexDirection: "column",
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
    },

    [theme.breakpoints.between("lg", "xl")]: {
      justifyContent: "space-around",
      maxWidth: 928,
      margin: "0 auto",
    },
  },
  heroCta: {
    order: 2,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",

    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      marginBottom: 0,
    },
    [theme.breakpoints.up("lg")]: {
      textAlign: "left",
      display: "block",
    },
  },
  bannerElementsDesktop: {
    right: 0,
    top: 0,
    pointerEvents: "none",
    alignItems: "center",
    order: 1,
    marginBottom: 32,

    [theme.breakpoints.up("lg")]: {
      //   position: "absolute",
      order: 2,
      top: 20,
      marginBottom: 0,
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },

    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  heroBannerTitle: {
    [theme.breakpoints.between("lg", "xl")]: {
      fontSize: "36px !important",
    },
  },
  ctaButtonStyle: {
    width: 190,
    // marginTop: 20,
  },
  ctaTextStyle: {
    fontSize: "18px !important",
    fontWeight: "700 !important",
  },
  heroBannerSubtitle: {
    display: "block",
    marginTop: "-15px !important",

    [theme.breakpoints.between("lg", "xl")]: {
      fontSize: "48px !important",
    },

    textAlign: "left",
    [theme.breakpoints.down("lg")]: {
      textAlign: "center",
    },
  },
  heroTitle: {
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
    marginBottom: 12,
  },
  bannerElementsDesktopInner: {
    display: "block",
    width: "100%",
    objectFit: "contain",
    [theme.breakpoints.up("lg")]: {
      maxWidth: theme.breakpoints.values.lg,
      maxHeight: 428,
    },

    [theme.breakpoints.up("xl")]: {
      maxWidth: 605,
    },
  },
}));

type DialogTypes = "sign-up";

const HomeBanner = () => {
  const classes = useStyles();
  const [openSignUp, setOpenSignUp] = useState(false);
  const dialogOpenHandlers = {
    "sign-up": () => setOpenSignUp(true),
  };

  const openDialogHandler = (type: DialogTypes) => dialogOpenHandlers[type]();

  const closeDialogHandler = useCallback(() => {
    setOpenSignUp(false);
  }, []);

  const elementsDispalyOptions: SxProps<Theme> = {
    display: {
      md: "none",
      xs: "block",
    },
  };

  return (
    <section className={classes.container}>
      <Box className={classes.bannerElements} sx={elementsDispalyOptions}>
        <Box className={clsx(classes.elementBase, classes.element1)}></Box>
        <Box className={clsx(classes.elementBase, classes.element2)}></Box>
        <Box className={clsx(classes.elementBase, classes.element3)}></Box>
        <Box className={clsx(classes.elementBase, classes.element4)}></Box>
      </Box>

      <Box className={classes.heroBg}></Box>
      <Box className={classes.heroContainer}>
        <div className={classes.heroContainerInner}>
          <div className={classes.heroCta}>
            <div className={classes.heroTitle}>
              <Typography
                variant="heroBannerTitle"
                className={classes.heroBannerTitle}
              >
                Connecting Singles
                <Divider component="br" />
                <Typography
                  variant="heroBannerSubtitle"
                  className={classes.heroBannerSubtitle}
                >
                  Across the world
                </Typography>
              </Typography>
            </div>
            <BaseButton
              color="primary"
              size="large"
              text="Join Now"
              className={classes.ctaButtonStyle}
              textStyle={classes.ctaTextStyle}
              onClick={() => openDialogHandler("sign-up")}
            />
          </div>

          <div className={classes.bannerElementsDesktop}>
            <picture style={{ width: "100%", height: "100%" }}>
              <source srcSet={HeroImg} media="(min-width: 1024px)" />
              <source srcSet={HeroTabletImg} />
              <img
                src={HeroTabletImg}
                alt="Connecting Singles Across the world"
                className={classes.bannerElementsDesktopInner}
              />
            </picture>
          </div>
        </div>
      </Box>
      <DialogSignUp open={openSignUp} closeHandler={closeDialogHandler} />
    </section>
  );
};

export default HomeBanner;
