import { getMeRequest } from '@/app/auth/auth.actions';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { ReactComponent as ArrowDown } from '@/assets/icons/arrow-down.svg';
import { ReactComponent as CoinIcon } from '@/assets/icons/coins.svg';
import { ReactComponent as MenuIcon } from '@/assets/icons/menu.svg';
import { ReactComponent as SoundIcon } from '@/assets/icons/sound.svg';
import { ReactComponent as SoundIconWhite } from '@/assets/icons/sound-white.svg';
import headerMenuLinks from '@/components/core/header-config';
import { useAppBreakpoints } from '@/hooks';
import { StorageService } from '@/services/storage.service';
import Logo from '@/ui/wd-logo.ui';
import { AppBar, Container, IconButton, Link, SwipeableDrawer, Theme, Toolbar, Typography, } from '@mui/material';
import { makeStyles } from '@mui/styles';
import jwtDecode from 'jwt-decode';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import BaseButton from './base/button.component';
import DialogProfileOptions from './dialogs/profile-options.dialog.component';
import DialogSignIn from './dialogs/sign-in-dialog.component';
import DialogSignUp from './dialogs/sign-up-dialog.component';
import DrawerTop from './drawer/components/drawer-top.component';
import useIsStaffUserRole from '@/hooks/useIsStaffUserRole';
import SearchTextField from './additional/search-text-field';
import DialogLogOut from './dialogs/log-out-dialog.component';
import BalanceCheckComponent from './dialogs/not-enough-balance.dialog';
import BottomBar from './bottom-bar/bottom-bar.layout';
import { ISettings } from '@/types/user-profile.types';
import { useSelector } from "react-redux";
import { selectCurrentUser } from "@/app/users/users.selectors";
import AvatarWithFallback from "@/components/AvatarWithFallback/AvatarWithFallback";

//Todo skeleton fade, zoom check in

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    marginTop: 24,
    marginBottom: 18,
    [theme.breakpoints.between('md', 'lg')]: {
      marginTop: 18.5,
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 14,
    },
  },
  searchedWrapper: {
    display: 'flex',
    maxHeight: 40,
    marginLeft: 64,

    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  headerMenuContainer: {
    listStyleType: 'none',
    width: '100%',
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'row',
    padding: 0,

    marginLeft: 68,
    '& > $headerMenuItem:first-child': {
      marginLeft: 0,
    },
  },
  menuBtn: {
    marginRight: '8px !important',
    backkground: 'transparent',
    width: 50,
    height: 50,
  },
  headerMenuItem: {
    padding: 0,
    marginLeft: 28,
    fontSize: 14,
    textDecoration: 'none',
  },
  toolbarInner: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  activeUserBar: {
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  rightSide: {
    flex: '1 0 auto',
    marginLeft: 'auto',
    '& > $headerButtonStyle:first-child': {
      marginRight: 20,
    },
    [theme.breakpoints.down('lg')]: {
      flex: 'none',
      marginLeft: 'auto',
    },
  },
  //props
  headerButtonStyle: {
    [theme.breakpoints.up('md')]: {
      minWidth: '150px !important',
    },

    [theme.breakpoints.down('md')]: {
      maxWidth: '100px !important',
    },
  },
  headerProfileOptionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',

    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  headerNotificationButton: {
    maxWidth: 42,
    maxHeight: 42,

    '& > .MuiButton-startIcon': {
      margin: 0,
    },
    minWidth: '0 !important',
  },
  headerBalanceButton: {
    maxWidth: 75,
    padding: '0 5px',
  },
  headerAvatarWrapper: {
    display: 'flex',
    marginLeft: 66,

    [theme.breakpoints.down('lg')]: {
      marginLeft: 18,
    },
  },

  headerTextStyle: {
    fontWeight: '600 !important',
    fontSize: '14px !important',
    [theme.breakpoints.down('md')]: {
      fontSize: '10px !important',
    },
  },
  logoutBtn: {
    marginLeft: 10,
  },
  searchedInput: {
    paddingRight: 36,
    backgroundSize: 14,
    outline: ' 8px ridge rgba(170, 50, 220, .6)',
    backgroundPosition: '94% 50%',
  },
  headerMenuContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
    paddingRight: 40,

    '& > $headerMenuItemMobile:first-child': {
      marginTop: 0,
    },
  },
  headerMenuItemMobile: {
    marginTop: 12,
  },
  avatarContainer: {
    marginRight: 12,
    marginLeft: 18,
    width: 40,
    height: 40,
    borderRadius: '50%'
  },
  avatar: {
    width: '100%',
    height: '100%',
    borderRadius: '50%'
  }
}));

type DialogTypes = 'sign-up' | 'log-out';
type HtmlElementRefType = ((instance: any) => void) | React.MutableRefObject<any> | null;
const Header = () => {
  const { liveChat } = useAppSelector<ISettings>((state) => state.users.settings);

  const isStaff = useIsStaffUserRole();
  const location = useLocation();

  const [signInEl, setSignInEl] = useState(null);
  const [profileOptionsEl, setProfileOptionsEl] = useState(null);

  const [buttonOffset, setButtonOffset] = useState(0);
  const signInRef = useRef<HTMLDivElement>(null);
  const profileOptionsRef = useRef<HTMLDivElement>();
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const [isTurnOnAudio, toggleAudio] = useState(true);

  useEffect(() => {
    const isTurnOnAudio = StorageService.getAudio();
    toggleAudio(!!isTurnOnAudio);
  }, []);

  const onSoundIconClick = useCallback(() => {
    if (isTurnOnAudio) {
      StorageService.removeAudio();
      toggleAudio(false);
    } else {
      StorageService.setAudio();
      toggleAudio(true);
    }
  }, [isTurnOnAudio]);

  const currentUser = useSelector(selectCurrentUser);
  const userToken = StorageService.getToken();
  const isLoggedIn = currentUser && userToken;

  const wallet = useAppSelector((state) => state.users.wallet);
  const username = currentUser?.profile?.name;
  const displayUsername = username && username.length > 12 ? username.slice(-12).concat('...') : username;

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { smAndDown, mdAndDown, lg, downLg } = useAppBreakpoints();
  const navigate = useNavigate();

  const [openSignUp, setOpenSignUp] = useState(false);
  const [openLogOut, setOpenLogOut] = useState(false);
  const dialogOpenHandlers = {
    'sign-up': () => setOpenSignUp(true),
    'log-out': () => setOpenLogOut(true),
  };

  const toggleDrawer = (event: any) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    if (currentUser) {
      closeDialogHandler();
      setSignInEl(null);
    }
  }, [currentUser]);

  useEffect(() => {
    if (signInRef.current) setButtonOffset(signInRef.current?.offsetWidth / 2);
  }, []);

  useEffect(() => {
    const userToken = StorageService.getToken();
    if (userToken && !currentUser) {
      const { id: userId }: any = jwtDecode(userToken);
      dispatch(getMeRequest());
    }
  }, []);

  const closeDialogHandler = useCallback(() => {
    setOpenSignUp(false);
  }, []);

  const closeLogOutDialogHandler = useCallback(() => {
    setOpenLogOut(false);
  }, []);

  const handleClickSignIn = (event: any) => {
    setSignInEl(signInRef.current as any);
  };

  const handleClickProfileOptions = (event: any) => {
    setProfileOptionsEl(profileOptionsRef.current as any);
  };

  const openDialogHandler = (type: DialogTypes) => dialogOpenHandlers[type]();

  const handleLogout = () => openDialogHandler('log-out');

  const openLiveChat = useCallback(
    () => ((window as any).LiveChatWidget as any) && ((window as any).LiveChatWidget as any).call('maximize'),
    [(window as any).LiveChatWidget]
  );

  let leftSide = !mdAndDown && (
    <ul className={classes.headerMenuContainer}>
      {headerMenuLinks.map((menuItem) => (
        <li key={menuItem.name} className={classes.headerMenuItem}>
          <Link href={menuItem.path} underline='none'>
            <Typography variant='body1'>{menuItem.name}</Typography>
          </Link>
        </li>
      ))}
      {liveChat && (
        <li key={'LiveChat'} className={classes.headerMenuItem} onClick={openLiveChat} style={{ cursor: 'pointer' }}>
          <Typography variant='body1'>Live Support</Typography>
        </li>
      )}
      {!userToken && (
        <li
          key={'Blog'}
          className={classes.headerMenuItem}
          onClick={() => window.open('https://blog.wedaf.com')}
          style={{ cursor: 'pointer' }}
        >
          <Typography variant='body1'>Blog</Typography>
        </li>
      )}
    </ul>
  );

  if (isLoggedIn) {
    if (location.pathname === '/user/search') {
      leftSide = null;
    } else {
      leftSide = (
        <div className={classes.searchedWrapper}>
          <SearchTextField label='Name or ID' inputClassName={classes.searchedInput} />
        </div>
      );
    }
  }

  let rightSide =
    isLoggedIn ? (
      <div className={classes.activeUserBar}>
        {!isStaff ? (
          <div>
            <BaseButton
              text={wallet?.balance}
              color='primary'
              className={classes.headerBalanceButton}
              textStyle={classes.headerTextStyle}
              onClick={() => navigate('/user/credits')}
              Icon={<CoinIcon />}
            />
          </div>
        ) : null}
        <div className={classes.headerAvatarWrapper}>
          <BaseButton
            inverted
            text=''
            className={classes.headerNotificationButton}
            textStyle={classes.headerTextStyle}
            onClick={onSoundIconClick}
            Icon={isTurnOnAudio ? <SoundIcon /> : <SoundIconWhite />}
          />
        </div>
        <div
          ref={profileOptionsRef as HtmlElementRefType}
          onClick={handleClickProfileOptions}
          className={classes.headerProfileOptionsWrapper}
        >
          <div className={classes.avatarContainer}>
            <AvatarWithFallback src={currentUser?.profile?.avatar || currentUser?.profile?.avatarSmall}
                                className={classes.avatar} gender={currentUser?.profile?.gender} />
          </div>
          <div style={{ marginRight: 12 }}>
            <Typography>{displayUsername}</Typography>
          </div>
          <div>
            {/* onClick={handleLogout} */}
            <div className={classes.logoutBtn}>
              <ArrowDown />
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className={classes.rightSide}>
        <BaseButton
          ref={signInRef}
          inverted
          text='Sign In'
          className={classes.headerButtonStyle}
          textStyle={classes.headerTextStyle}
          onClick={(e) => handleClickSignIn(e)}
        />
        <BaseButton
          color='primary'
          text='Join Now'
          className={classes.headerButtonStyle}
          textStyle={classes.headerTextStyle}
          onClick={() => openDialogHandler('sign-up')}
        />
      </div>
    );

  const drawerContent = (
    <>
      <DrawerTop toggleDrawer={toggleDrawer} />

      <ul className={classes.headerMenuContainerMobile}>
        {[
          {
            path: '/',
            name: 'Home',
            icon: null,
          },
          ...headerMenuLinks,
        ].map((menuItem) => (
          <li key={menuItem.name} className={classes.headerMenuItemMobile}>
            <Link href={menuItem.path} underline='none'>
              <Typography variant='body2'>{menuItem.name}</Typography>
            </Link>
          </li>
        ))}
        {liveChat && (
          <li key={'LiveChat'} className={classes.headerMenuItemMobile} onClick={openLiveChat}>
            <Typography variant='body2'>Live Support</Typography>
          </li>
        )}
        {!userToken && (
          <li
            key={'Blog'}
            className={classes.headerMenuItemMobile}
            onClick={() => window.open('https://blog.wedaf.com')}
          >
            <Typography variant='body2'>Blog</Typography>
          </li>
        )}
      </ul>
    </>
  );

  return (
    <AppBar position='static' className={classes.appBar} color='inherit' elevation={0}>
      <Container maxWidth={lg ? 'lg' : 'xl'} disableGutters>
        <Toolbar>
          <div className={classes.toolbarInner}>
            <div className={classes.logoContainer}>
              {!isLoggedIn && (
                <IconButton
                  onClick={toggleDrawer}
                  className={classes.menuBtn}
                  sx={{
                    display: {
                      lg: 'none',
                      xs: 'flex',
                    },
                  }}
                >
                  <MenuIcon />
                </IconButton>
              )}

              {isLoggedIn ? (
                <>
                  {downLg && <BottomBar handleLogout={handleLogout} />}
                </>
              ) : (
                <SwipeableDrawer
                  anchor='left'
                  style={{ overflowY: 'scroll' }}
                  open={isDrawerOpen}
                  onClose={toggleDrawer}
                  onOpen={toggleDrawer}
                >
                  {drawerContent}
                </SwipeableDrawer>
              )}


              <Link href='/'>
                <Logo small={smAndDown} vertical={mdAndDown && !smAndDown} />
              </Link>
            </div>

            {leftSide}
            {rightSide}
          </div>
        </Toolbar>
      </Container>
      {openSignUp && <DialogSignUp open={openSignUp} closeHandler={closeDialogHandler} />}
      <DialogLogOut open={openLogOut} closeHandler={closeLogOutDialogHandler} />
      <BalanceCheckComponent />
      {signInEl && <DialogSignIn anchorEl={signInEl} setAnchorEl={setSignInEl} horizontalOffset={buttonOffset} />}
      {profileOptionsEl && (
        <DialogProfileOptions
          handleLogout={handleLogout}
          anchorEl={profileOptionsEl}
          setAnchorEl={setProfileOptionsEl}
        />
      )}
    </AppBar>
  );
};

export default Header;
