import { useAppSelector } from '@/app/hooks';
import { ISettings } from '@/types/user-profile.types';
import React, { useEffect } from 'react';
import {StorageService} from "@/services/storage.service";

const AuthFromBlog = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('access_token');

    if (token) {
      //Store token in localStorage
      StorageService.setToken(token);

      window.location.href = window.location.origin;
    }

  }, [window.location.search]);

  return null
};

export default AuthFromBlog;
