import { HttpService } from './http.service';
import { ErrorWrapper } from './utils';

export default class ContactUsService {
  static get entity(): string {
    return 'public/contact-us';
  }

  static async sendContactUs(payload: { email: string; subject: string; message: string }): Promise<any> {
    try {
      const response = await HttpService.post(`/${this.entity}`, payload);
      return response.data;
    } catch (error: any) {
      const message = error?.response?.data?.error || error?.response?.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
