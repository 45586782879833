import { useAppBreakpoints, useContainerWidth } from "@/hooks";
import {
  Badge,
  CircularProgress,
  Container,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import GuestsService from "@/services/guests.service";
import BaseCard from "@/components/base/base-card.component";
import { toggleSnackbarOpen } from "@/app/ui/ui.actions";
import { useAppDispatch } from "@/app/hooks";
import GuestCard from "@/pages/guests/components/guest-card.component";
import MobileGuestCard from "@/pages/guests/components/mobile-guest-card.component";
import { useBinarySwitcher } from "@/hooks/useBinarySwitcher";
import DialogWriteMail from "@/components/dialogs/write-mail.dialog.component";
import useBlankProfileAvatart from "@/hooks/useBlankProfileAvatart";
import BaseButton from "@/components/base/button.component";
import Check from "@mui/icons-material/Check";
import clsx from "clsx";
import DialogGift from '@/components/dialogs/send-gift.dialog.component';

const useStyles = makeStyles((theme: Theme) => ({
  cardOverrides: {
    paddingBottom: 0,
  },
  contentWrapper: {
    zIndex: 1000,
    marginTop: 40,
    [theme.breakpoints.down("xl")]: {
      marginTop: 24,
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 14,
    },
  },
  cardWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  header: {
    display: "flex",
    justifyContent: 'space-between',
    alignItems: 'center',

    marginBottom: 27,
    [theme.breakpoints.down("md")]: {
      marginBottom: 32,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: 20,
    },
  },
  noNotifications: {
    margin: '0 auto',
    border: '2px dashed #503EB6',
    padding: '10px',
    width: '200px',
    display: 'flex',
    justifyContent: 'center',
  },
  loader: {
    margin: '0 auto',
    padding: '10px',
    width: '200px',
    display: 'flex',
    justifyContent: 'center',
  },
  guestCardContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, 170px)',
    gridGap: '20px',
  },
  label: {
    display: "inline-block",
  },
  badge: {
    '& > .MuiBadge-standard': {
      top: 'calc(100% - 13px)',
      right: '-22px',

      [theme.breakpoints.down("md")]: {
        top: 'calc(100% - 11px)',
      },
    }
  },
  metaCtaIcon: {
    maxWidth: 32,
    maxHeight: 32,
    minHeight: '32px !important',
    minWidth: "0 !important",

    "& > .MuiButton-startIcon": {
      margin: 0,
    },
  },
}));

const Guests = () => {
  const classes = useStyles();
  const width = useContainerWidth();
  const { xs, mdAndDown } = useAppBreakpoints();
  const dispatch = useAppDispatch();
  const { oppositeUserPicture } = useBlankProfileAvatart();

  const stylesOverrides = useMemo(() => (
    {
      contentWrapper: {
        padding: 0,
        maxWidth: 968,
      },
      subHeaderButton: {
        width: '100%',
        height: xs ? 24 : 36,
        minHeight: xs ? 24 : 36,
      },
    }
  ), [xs]);

  const [guests, setGuests] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const windowWidth = useMemo(() => window.innerWidth, []);
  const windowHeight = useMemo(() => window.innerHeight, []);
  const [isWriteDialogOpen, openDialog, hideDialog] = useBinarySwitcher();
  const [isGiftDialogOpen, openGiftDialog, hideGiftDialog] = useBinarySwitcher();
  const [currentDialogUser, setCurrentDialogUser] = useState(null);


  const openGiftDialoghandler = useCallback(({ id, name, avatarSmall }: {
    id: string,
    name: string,
    avatarSmall: string
  }) => {
    setCurrentDialogUser({
      id, name, avatarSmall
    });

    openGiftDialog();
  }, [openDialog])

  const openDialoghandler = useCallback(({ id, name, avatarSmall }: {
    id: string,
    name: string,
    avatarSmall: string
  }) => {
    setCurrentDialogUser({
      id, name, avatarSmall
    });

    openDialog();
  }, [openDialog])

  const closeDialogHandler = useCallback(() => {
    setCurrentDialogUser(null);
    hideDialog();
    hideGiftDialog();
  }, [hideDialog])


  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await GuestsService.getGuests();

        setGuests(response);
      } catch {
        dispatch(
          toggleSnackbarOpen(
            "Something goes wrong during request execution",
            "error"
          )
        )
      }

      setIsLoading(false);
    }

    fetchData();

  }, [dispatch])

  const contentBody = useMemo(() => {
    if (isLoading) return <div className={classes.loader}><CircularProgress color="secondary"
                                                                            style={{ margin: "0 auto" }} /></div>;
    if (!guests?.length) return <div className={classes.noNotifications}>No guests</div>

    if (mdAndDown) {
      return <BaseCard wrapperOverrideStyle={{ margin: 0, marginBottom: 10 }} cardOverrideStyle={classes.cardOverrides}>
        <div
          className={classes.cardWrapper}
        >
          {guests.map(item => <MobileGuestCard
            key={item.id}
            user={item}
            windowWidth={windowWidth}
            windowHeight={windowHeight}
            openDialoghandler={openDialoghandler}
            openGiftDialoghandler={openGiftDialoghandler} />
          )}
        </div>
      </BaseCard>
    }

    return <div className={classes.guestCardContainer}>
      {guests.map(item => <GuestCard user={item}
                                     key={item.id}
                                     windowWidth={windowWidth}
                                     windowHeight={windowHeight}
                                     openDialoghandler={openDialoghandler}
                                     openGiftDialoghandler={openGiftDialoghandler}
      />)}
    </div>
  }, [classes.guestCardContainer, classes.cardOverrides, classes.cardWrapper, classes.loader, classes.noNotifications, guests, isLoading, mdAndDown, openDialoghandler, windowHeight, windowWidth])

  const markAllAsViewed = useCallback(async () => {
    try {
      await GuestsService.markAllGuestAsSeen()

      dispatch(
        toggleSnackbarOpen(
          "Success!",
          "success"
        )
      )
    } catch {
      dispatch(
        toggleSnackbarOpen(
          "Something goes wrong during request execution",
          "error"
        )
      )
    }

  }, [dispatch])

  return (
    <>
      <Container
        maxWidth={width}
        className={classes.contentWrapper}
        style={stylesOverrides.contentWrapper}
      >
        <BaseCard wrapperOverrideStyle={{ margin: 0, marginBottom: 10 }} cardOverrideStyle={classes.cardOverrides}>
          <div
            className={classes.cardWrapper}
          >
            <header className={classes.header}>
              <Badge
                badgeContent={guests?.length}
                color="primary"
                className={classes.badge}
              >
                {/* @ts-ignore */}
                <Typography variant="h2">Guests</Typography>
              </Badge>
              {
                mdAndDown ?
                  <BaseButton
                    text=""
                    className={clsx(classes.metaCtaIcon)}
                    textStyle=""
                    onClick={markAllAsViewed}
                    Icon={<Check color="secondary" />}
                    color='info'
                    style={{ boxShadow: 'none', padding: '0px 16px' }}
                  />
                  : <Typography variant='link' onClick={markAllAsViewed}>Mark All As Viewed</Typography>
              }
            </header>
          </div>
        </BaseCard>

        <div>
          {contentBody}
        </div>

      </Container>

      <DialogWriteMail
        open={isWriteDialogOpen && currentDialogUser}
        closeHandler={closeDialogHandler}
        receiver={{
          id: currentDialogUser?.id,
          name: currentDialogUser?.name,
          avatarSmall: currentDialogUser?.avatarSmall || oppositeUserPicture,
        }}
      />
      <DialogGift
        open={isGiftDialogOpen && currentDialogUser}
        closeHandler={closeDialogHandler}
        receiver={{
          id: currentDialogUser?.id,
          name: currentDialogUser?.name,
          avatarSmall: currentDialogUser?.avatarSmall || oppositeUserPicture,
        }}
      />
    </>
  );
};

export default Guests;
