import React, { Fragment, useEffect, useMemo } from "react";
import clsx from "clsx";
import { Formik } from "formik";

import { FormControl, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { UserProfileDTO } from "@/app/users/users.reducer";
import { updateCurrentUserRequest } from "@/app/users/users.actions";

import BaseButton from "@/components/base/button.component";
import DatePicker from "@/components/base/date-picker.component";
import SecondaryTextField from "@/components/base/secondary-text-field.component";
import SelectTextField from "@/components/base/select-text-field.component";

import { DateOfBirth } from "@/services/utils";

import { LanguageOptions } from "@/types/edit-select.types";
import { UserProfile } from "@/types/user-profile.types";

import allCountries from "@/utils/countryList";
import { editUserSchema } from "@/utils/validationSchemas";

import useStaffCheck from "@/hooks/useStaffCheck";

export interface MainSettingType {
  name: string;
  country?: string | null;
  city?: string | null;
  birthday: DateOfBirth;
  langEnglish?: string | null;
  langSpanish?: string | null;
  langFrench?: string | null;
  langPortuguese?: string | null;
  langGerman?: string | null;
}

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    width: "100%",
  },
  inputAppendElement: {
    flexShrink: 0,
    padding: "0 6px",
    minWidth: 118,

    [theme.breakpoints.down("md")]: {
      minWidth: 80,
    },
  },
  formControl: {
    "& > $inputField:not(:first-child)": {
      marginTop: 20,
    },
  },
  inputField: {},
  saveButton: {
    width: "100%",
    maxWidth: 144,
    marginTop: 32,
  },
  datePickerContainer: {
    paddingLeft: 20,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
    },
  },
}));

const overrideStyles = {
  pageTitle: {
    marginBottom: 24,
  },
};

const EditMainInfo = () => {
  useStaffCheck();

  const classes = useStyles();
  const dispatch = useAppDispatch();

  const userIsLoading = useAppSelector((state) => state.users.userIsLoading);
  const { profile: currentUser }: UserProfileDTO =
  useAppSelector((state) => state.users.currentUser) || {};

  const userBirthday = useMemo(() => {
    if (currentUser?.birthday) {
      const date = new Date(currentUser?.birthday);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return {
        day,
        month,
        year,
      };
    } else {
      return {
        day: -1,
        month: -1,
        year: -1,
      };
    }
  }, [currentUser]);

  const countryList = useMemo(
    () => [
      ...allCountries.map((country: { name: any }) => {
        return {
          value: country.name,
          name: country.name,
        };
      }),
    ],
    [allCountries]
  );

  const languageList: LanguageOptions[] = useMemo(
    () => [
      { value: "", name: "Not chosen" },
      { value: "basic", name: "Basic" },
      { value: "upper intermediate", name: "Upper Intermediate" },
      { value: "fluent", name: "Fluent" },
    ],
    []
  );

  const languages = [
    { name: "English", value: "langEnglish" },
    { name: "Spanish", value: "langSpanish" },
    { name: "French", value: "langFrench" },
    { name: "Portuguese", value: "langPortuguese" },
    { name: "German", value: "langGerman" },
  ];

  const initialData: MainSettingType = {
    name: currentUser.name,
    country: currentUser.country || "Not chosen",
    city: Boolean(currentUser.city) ? currentUser.city : '',
    birthday: userBirthday,
    langEnglish: currentUser.langEnglish,
    langSpanish: currentUser.langSpanish,
    langFrench: currentUser.langFrench,
    langPortuguese: currentUser.langPortuguese,
    langGerman: currentUser.langGerman,
  };

  const normalizeDateValue = (value: number) => {
    if (value === -1) return '-';

    if (value < 10) return `0${value}`;

    return value;
  }

  const normalizeDate = (date: DateOfBirth) => {
    const formatedDate = [];

    formatedDate.push(normalizeDateValue(date.year));
    formatedDate.push(normalizeDateValue(date.month));
    formatedDate.push(normalizeDateValue(date.day));

    const formatedData = formatedDate.join('-');

    return formatedData === '-----' ? null : formatedData;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Typography variant="h3" style={overrideStyles.pageTitle}>
        Main Info
      </Typography>
      <Formik
        initialValues={initialData}
        validateOnChange={false}
        validationSchema={editUserSchema}
        onSubmit={(values, { setSubmitting }) => {
          const { birthday } = values;
          const normalizedBirthday = normalizeDate(birthday);
          dispatch(
            updateCurrentUserRequest({
              ...values,
              birthday: normalizedBirthday,
            } as unknown as UserProfile)
          );
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit} className={classes.formContainer}>
            <FormControl fullWidth className={classes.formControl}>
              <div className={classes.inputField}>
                <SecondaryTextField
                  label="name"
                  placeholder="Name"
                  prepend={
                    <Typography
                      variant="body1"
                      fontWeight="400"
                      className={classes.inputAppendElement}
                    >
                      Your name:
                    </Typography>
                  }
                  onChange={handleChange}
                  type="text"
                />
              </div>

              <div className={classes.inputField}>
                <SelectTextField
                  label="country"
                  placeholder="Select your country"
                  list={countryList}
                  setFieldValue={setFieldValue}
                  prepend={
                    <Typography
                      variant="body1"
                      fontWeight="400"
                      className={classes.inputAppendElement}
                    >
                      Country:
                    </Typography>
                  }
                  inputAppendElement={classes.inputAppendElement}
                />
              </div>

              <div className={classes.inputField}>
                <SecondaryTextField
                  label="city"
                  placeholder="City"
                  prepend={
                    <Typography
                      variant="body1"
                      fontWeight="400"
                      className={classes.inputAppendElement}
                    >
                      City:
                    </Typography>
                  }
                  onChange={handleChange}
                  type="text"
                />
              </div>

              <div
                className={clsx(
                  classes.inputField,
                  classes.datePickerContainer
                )}
              >
                <DatePicker
                  setFieldValue={setFieldValue}
                  birthday={values.birthday}
                  error={errors.birthday}
                />
              </div>

              {languages.map((language, idx) => (
                <div key={language.name} className={classes.inputField}>
                  <SelectTextField
                    placeholder={`${language.name} level`}
                    label={language.value}
                    list={languageList}
                    setFieldValue={setFieldValue}
                    prepend={
                      <Typography
                        variant="body1"
                        fontWeight="400"
                        className={classes.inputAppendElement}
                      >
                        {language.name}:
                      </Typography>
                    }
                    inputAppendElement={classes.inputAppendElement}
                  />
                </div>
              ))}

              <div style={{ marginTop: 32 }}>
                <BaseButton
                  loading={userIsLoading}
                  color="primary"
                  text="Save"
                  className={classes.saveButton}
                  onClick={() => handleSubmit()}
                />
              </div>
            </FormControl>
          </form>
        )}
      </Formik>
    </Fragment>
  );
};

export default EditMainInfo;
