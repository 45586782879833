import { useAppBreakpoints } from "@/hooks";
import React, { FC, useEffect, useState } from "react";
import "swiper/css";
import UsersSliderMobile from "@/components/sliders/UsersSlider/components/UsersSliderMobile";
import UsersSliderDesktop from "@/components/sliders/UsersSlider/components/UsersSliderDesktop";
import { setUpdateAvailableUsersFromComponent } from "@/app/users/users.actions";
import { useAppDispatch } from "@/app/hooks";
import { UserProfileState } from "@/types/user-profile.types";

export interface SliderSlidesPerView {
  sm?: number | "auto" | undefined;
  md?: number | "auto" | undefined;
  lg?: number | "auto" | undefined;
  xl?: number | "auto" | undefined;
}

type UsersSliderProps = {
  title: string;
  slidesPerView?: SliderSlidesPerView;
  disablePadding?: boolean;
  fetcher: () => Promise<any[]>;
  customSpacing?: number;
  isUnloginned?: boolean;
  isCheckOnlineIsState?: boolean;
};

const UsersSlider: FC<UsersSliderProps> = ({
                                             slidesPerView,
                                             disablePadding = false,
                                             fetcher,
                                             customSpacing,
                                             isUnloginned,
                                             title,
                                             isCheckOnlineIsState
                                           }) => {

  const [data, setData] = useState([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetcher();
        const dateNow = new Date();
        const usersOnline = res.filter(({ activeAt }) => {
          const activeDate = new Date(activeAt);
          return activeDate > dateNow;
        }) as UserProfileState[];
        dispatch(setUpdateAvailableUsersFromComponent(usersOnline))
        setData(res);
      } catch (error) {
        console.log('error:', error)
      }
    }

    fetchData();
  }, [dispatch, fetcher])

  const { mdAndDown } = useAppBreakpoints();

  if (!data.length) return null;

  return mdAndDown ? <UsersSliderMobile data={data} disablePadding={disablePadding} title={title}
                                        isCheckOnlineIsState={isCheckOnlineIsState} /> :
    <UsersSliderDesktop data={data} title={title} isUnloginned={isUnloginned} customSpacing={customSpacing}
                        disablePadding={disablePadding} slidesPerView={slidesPerView}
                        isCheckOnlineIsState={isCheckOnlineIsState} />;
};

export default UsersSlider;
