import { HttpService } from './http.service';
import { ErrorWrapper } from './utils';

export interface CreditPacksDTO {
  id: string;
  createdAt: string;
  image: string;
  name: string;
  credits: number;
  price: number;
  oldPrice: number;
  isActive: boolean;
  limit: number;
}

export default class CreditsService {
  static async getCreditPacks(): Promise<Array<CreditPacksDTO>> {
    try {
      const response = await HttpService.get(`/credit-packs`);

      return response.data;
    } catch (error: any) {
      const message = error?.response?.data?.error || error?.response?.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getPaymentLink(creditPackId, chosenProvider: string, currency: string = 'EUR'): Promise<any> {
    try {
      const response = await HttpService.post(`/payments/${chosenProvider}/checkout`, { creditPackId, currency });

      return response.data;
    } catch (error: any) {
      const message = error?.response?.data?.error || error?.response?.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
