import { makeStyles } from '@mui/styles';
import GuestsService from '@/services/guests.service';
import clsx from 'clsx';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Theme } from '@mui/material';
import { IBlackList } from '@/types/user-profile.types';
import AvatarWithFallback from "@/components/AvatarWithFallback/AvatarWithFallback";

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  profilePhoto: {
    width: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: 10,
    height: '100%',
  },
  photoWrapper: {
    position: 'relative',
    maxWidth: 72,
    maxHeight: 76,
    width: '72px',
    marginRight: 20,
    aspectRatio: '116 / 130',
  },
  photoInner: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    cursor: 'pointer',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 14,
    borderRadius: 10,
    marginBottom: 10,
    [theme.breakpoints.up('lg')]: {
      position: 'relative',
      '&:hover $profileCta': {
        opacity: 1,
      },
    },
  },
  messageInfo: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: '10px 0',
  },
  messageHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  messageFooter: {
    color: '#808080',

    marginTop: 12,
    display: 'flex',
    justifyContent: 'space-between',
  },
  profileInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  profileSubject: {
    width: '25vw',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    [theme.breakpoints.down('lg')]: {
      width: '60vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '50vw',
    },
  },
  profileText: {
    width: '25vw',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    [theme.breakpoints.down('lg')]: {
      width: '60vw',
    },
    [theme.breakpoints.down('sm')]: {
      width: '50vw',
    },
  },
  profileWrapper: {
    width: '100%',
  },
  profileActions: {
    display: 'flex',
    gap: 10,
    color: '#808080',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
    },
  },
  checkBox: {
    marginRight: '0 !important',
  },
  profileMeta: {
    display: 'flex',
    alignItems: 'center',
    gap: 9,
  },
  divider: {
    paddingTop: 15,
  },
  metaCtaIcon: {
    maxWidth: 42,
    maxHeight: 42,

    '& > .MuiButton-startIcon': {
      margin: 0,
    },
    minWidth: '0 !important',
  },
  profileCta: {
    display: 'flex',
    gap: 24,
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      backgroundColor: 'white',
      bottom: '5px',
      right: '5px',
      padding: '10px',
      borderRadius: '10px',
      boxShadow: '0px 0px 20px rgb(0 0 0 / 5%)',
      opacity: 0,
      transition: 'opacity .3s',
    },
  },
  profilePhotoStatus: {
    position: 'absolute',
    bottom: 6,
    left: 6,
  },
  statusUnread: {
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.05)',
  },
  statusRead: {
    backgroundColor: '#F6F6F6',
  },
  leftSide: {
    height: '76px',
    display: 'flex',
  },
  rightSide: {
    display: 'flex',
    alignItems: 'center',
  },
  profileName: {
    fontSize: 18,
    lineHeight: '20px',
    margin: 0,
    marginBottom: 5,
  },
  profileNameWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  avatarId: {
    fontSize: 14,
    lineHeight: '20px',
    margin: 0,
  },
}));

type GuestCardProps = {
  user?: IBlackList | null;
  windowWidth: number;
  windowHeight: number;
};

const MobileBlackListCard = ({
                               user = null,
                               windowWidth,
                               windowHeight,
                             }: GuestCardProps) => {
  const navigate = useNavigate();

  const classes = useStyles();

  const navigateToProfile = async () => {
    if (user?.profileId) {
      await GuestsService.markGuestAsSeen(user.profileId);
      navigate(`/user/${user.profileId}`);
    }
  };

  return (
    <div className={clsx(classes.cardContainer)}>
      <div className={classes.leftSide}>
        <div className={classes.photoWrapper}>
          <div className={classes.photoInner} onClick={navigateToProfile}>
            <div className={classes.profilePhoto} />
            <AvatarWithFallback src={user?.profileAvatarSmall}
                                className={classes.profilePhoto} />
          </div>
        </div>
        <div className={classes.profileNameWrapper}>
          <div className={classes.profileName}>
            {user.profileName}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileBlackListCard;
