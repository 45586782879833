import { ReactComponent as ContactsIcon } from '@/assets/icons/chats/contacts-black.svg';
import { ReactComponent as ContactsWhiteIcon } from '@/assets/icons/chats/contacts-white.svg';
import SearchIcon from '@/assets/icons/search.svg';
import profilePicture from '@/assets/images/demo-avatar.jpeg';
import BaseButton from '@/components/base/button.component';
import PrimaryBaseTextField from '@/components/base/text-field-base.component';
import { useBinarySwitcher } from '@/hooks/useBinarySwitcher';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import UserLink from './user-link';
import { useSelector } from "react-redux";
import { selectAllAvailableUsers, selectCurrentUser, selectUserBookmarks } from "@/app/users/users.selectors";

type AvailableUserComponentProps = {
  onClick?: () => void;
  availableUsers: any;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '172px',
    padding: '14px',
    marginTop: '-12px',
    zIndex: '90',
    background: '#FFFFFF',
    borderRadius: '10px 0 0 10px',
    boxShadow: '0px 0px 20px rgb(0 0 0 / 5%)',
    overflowY: 'scroll',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '56px',
    fontSize: '22px',
    fontWeight: '600',
    color: '#000000',
  },
  buttonsWrapper: {
    paddingTop: '8px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  mainButton: {
    width: '80px',
    height: '24px',
    minHeight: '24px!important',
    padding: '0',
    boxShadow: 'none!important',
  },
  searchUsers: {
    marginTop: '14px',
  },
  usersWrapper: {
    marginTop: '27px',
    overflowY: 'scroll',
    height: 'calc(100dvh - 314px)',
    [theme.breakpoints.down('lg')]: {
      height: 'calc(100dvh - 176px)',
    },
  },
  searchedWrapper: {
    display: 'flex',
    maxHeight: 30,
    width: '100%',
  },
  searchedInput: {
    width: '100%',
    paddingRight: 6,
    backgroundSize: 14,
    lineHeight: '30px',
    outline: ' 8px ridge rgba(170, 50, 220, .6)',
    backgroundPosition: '94% 50%',
    background: `url(${SearchIcon}) no-repeat right`,
  },
  shown: {
    '& span': {
      color: 'white!important',
    },
  },
  hide: {
    '& span': {
      color: 'black!important',
    },
  },
}));

const AvailableUsers = ({
                          onClick,
                          availableUsers = [],
                        }: AvailableUserComponentProps) => {
  const classes = useStyles();
  const currentUser = useSelector(selectCurrentUser);
  const bookmarks = useSelector(selectUserBookmarks);
  const allAvailableUsers = useSelector(selectAllAvailableUsers);

  const [filterUsers, setFilterUsers] = useState(null);

  const [isAllUserShown, show, hide] = useBinarySwitcher(true);

  const gender = currentUser.profile.gender;

  const usersOnline = useMemo(() => availableUsers.filter((availableUser) => {
    return allAvailableUsers.some((allAvailableUser) => allAvailableUser.userId === availableUser.userId)
  }), [availableUsers, allAvailableUsers])

  const bookmarksOnline = useMemo(() => {
    const prepareBookMark = bookmarks.map((bookmark) => {
        return bookmark.id;
      }
    );

    return usersOnline.filter((availableUser) =>
      prepareBookMark.includes(availableUser.id)
    );
  }, [usersOnline, bookmarks])


  const currentUsersData = useMemo(() => {
    let users = usersOnline
    if (!isAllUserShown) {
      users = bookmarksOnline
    }

    return users.filter(
      (user: { name: string; pageId: string; gender: string }) => {
        if (filterUsers) {
          return (user?.name.includes(filterUsers) ||
              `${user?.pageId}`.includes(filterUsers)) &&
            gender !== user.gender
        }

        return gender !== user.gender;
      }
    )
  }, [bookmarksOnline, gender, filterUsers, isAllUserShown, usersOnline])


  const genderLabel = gender === 'male' ? ' Girls' : 'Mens';

  return (
    <div className={classes.container}>
      <div className={classes.title}>{genderLabel} Online</div>
      <div className={classes.buttonsWrapper}>
        <BaseButton
          text='All'
          color={isAllUserShown ? 'primary' : 'info'}
          variant='contained'
          wrapperClass={clsx(
            classes.mainButton,
            isAllUserShown ? classes.shown : classes.hide
          )}
          onClick={show}
        />
        <BaseButton
          text={bookmarksOnline?.length || 0}
          color={isAllUserShown ? 'info' : 'primary'}
          variant='contained'
          wrapperClass={clsx(
            classes.mainButton,
            !isAllUserShown ? classes.shown : classes.hide
          )}
          onClick={hide}
          Icon={isAllUserShown ? <ContactsIcon /> : <ContactsWhiteIcon />}
        />
      </div>
      <div className={classes.searchUsers}>
        <div className={classes.searchedWrapper}>
          <PrimaryBaseTextField
            onChange={(event) => setFilterUsers(event.target.value)}
            label='Name or ID '
            inputClassName={classes.searchedInput}
          />
        </div>
      </div>
      <div className={classes.usersWrapper}>
        {currentUsersData.map(({ name, id, avatar, avatarSmall, pageId, itHasNewMessage }) => (
          <UserLink
            onClick={onClick}
            userName={name}
            user={currentUser}
            key={id}
            uid={id}
            avatar={avatar || avatarSmall || profilePicture} // fallback
            pageId={pageId}
            isNewMessage={itHasNewMessage}
          />
        ))}
      </div>
    </div>
  );
};

export default AvailableUsers;
