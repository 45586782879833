import { useAppSelector } from "@/app/hooks";
import { toggleSnackbarOpen } from "@/app/ui/ui.actions";
import { UserProfileDTO } from "@/app/users/users.reducer";
import ProfilesService from "@/services/profiles.service";
import { UserProfile } from "@/types/user-profile.types";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { selectCurrentUser } from "@/app/users/users.selectors";

type ResponseUseUserPhotos = [isLoading: boolean, profile: any];

const useUserProfile = (): ResponseUseUserPhotos => {
  const [isLoading, setLoading] = useState(true);
  const shouldLoad = useRef(true);
  const [profile, setProfile] = useState<Pick<
    UserProfileDTO,
    "email" | "profile" | "id"
  > | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector(selectCurrentUser);

  const { id } = useParams<{
    id: string;
  }>();

  const fetchProfile = useCallback(
    async (id: string) => {
      try {
        setLoading(true);
        const response = await ProfilesService.getProfileById(id);
        const normalizedUser = {
          id: response.id,
          email: null,
          profile: response,
        };
        setProfile(normalizedUser);
        if (currentUser && currentUser.profile.id !== response.id) {
          await ProfilesService.guestMark(id);
        }
        shouldLoad.current = true
        setLoading(false);
      } catch (e) {
        shouldLoad.current = true
        setLoading(false);
        dispatch(
          toggleSnackbarOpen(
            "Something goes wrong during request execution",
            "error"
          )
        );
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (!id) return navigate(-1);

    if (currentUser?.id === id) {
      setLoading(false);
      setProfile(currentUser);
    } else if (profile?.id !== id && shouldLoad.current) {
      fetchProfile(id);
    }
  }, [currentUser, fetchProfile, id, navigate, profile]);

  return [isLoading, profile];
};

export default useUserProfile;
