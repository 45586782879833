// import { subscribeOnTeleport } from 'actions/explorers/thunk-actions';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import {
  disconnectChat,
  setDisconnectedAvailableUsers,
  setReceiveMessageSuccess,
  setSendMessageSuccess,
  setReceivePhotoMessageSuccess,
  setSendPhotoMessageSuccess,
  setReceiveVideoMessageSuccess,
  setSendVideoMessageSuccess,
  setUpdateAvailableUsers,
  socketUpdateDialogCount,
  updateBalanceRequest,
  setConvertedVideoMessageSuccess,
  deleteVideosSuccess,
} from '@/app/users/users.actions';
import { StorageService } from '@/services/storage.service';
import { UserProfile } from '@/types/user-profile.types';
import { useCallback, useEffect, useState } from 'react';
import Socket from 'services/Socket';
import useSound from 'use-sound';
import letterSound from '@/assets/sounds/letter.mp3';
import messageSound from '@/assets/sounds/massage.mp3';
import { socketUpdateMessagesCount } from '@/app/mails/mails.actions';
import { CountersShape } from '@/services/mail.service';
import usePersistentTab from './base/use-persistance-tab-hook';
import PersistentTabManager from './base/persistance-tab-manage';
import { useLocation } from 'react-router-dom';
import { getGiftCountersAction, getGiftsFromSocketRequest, socketUpdateGiftsCount } from '@/app/gifts/gifts.actions';
import { GiftKind } from '@/app/gifts/gifts.types';
import { GiftShape, QueueNewVirtualGiftDto } from '@/services/gifts.service';
import { UserVideoType } from '@/app/users/users.types';
import { socketRoutes } from "@/components/socketRoutes";

const SocketSubscriber = () => {
  // const [isLoaded, setLoading] = useState(false);
  const currentUser = useAppSelector((state) => state.users.currentUser);
  const dispatch = useAppDispatch();

  const [playbackRate] = useState(1);

  const [play] = useSound(letterSound, {
    playbackRate,
    interrupt: true,
  });

  const [playMessage] = useSound(messageSound, {
    playbackRate,
    // `interrupt` ensures that if the sound starts again before it's
    // ended, it will truncate it. Otherwise, the sound can overlap.
    interrupt: true,
  });

  const receiveLetterSound = () => {
    const isAudioTurnOn = StorageService.getAudio();
    if (isAudioTurnOn) {
      play();
    }
  };

  const connectToSocket = useCallback(async () => {
    const token = StorageService.getToken();
    if (token && currentUser) {
      await Socket.connect();
      init();
    } else {
      Socket.disconnect();
    }
  }, [currentUser]);

  useEffect(() => {
    connectToSocket();
  }, [connectToSocket, currentUser]);

  const [, isChatOpen] = usePersistentTab('/chat');
  const location = useLocation();

  const pattern = /^\/chat\/\d+(\/\w+)*$/;
  const isChatRoute = pattern.test(location.pathname);

  const init = useCallback(() => {
    if (Socket && Socket.socket) {
      Socket.on('msg:receive', (data: any) => {
        dispatch(
          setReceiveMessageSuccess({
            ...data,
            currentUserId: currentUser?.profile?.id,
            isChatOpen: !!isChatOpen || isChatRoute,
          })
        );
        playMessage();
      });
      Socket.on('msg:receiveOwn', (data: any) => {
        dispatch(
          setSendMessageSuccess({
            toProfileId: data.profileId,
            text: data.text,
          })
        );
      });
      Socket.on('photo:receive', (data: any) => {
        dispatch(
          setReceivePhotoMessageSuccess({
            profileId: data.profileId,
            img: {
              photoId: data.photoId,
              origin: data.origin,
              large: data.large,
              small: data.small,
            },
            currentUserId: currentUser?.profile?.id,
            isChatOpen: !!isChatOpen || isChatRoute,
          })
        );
        playMessage();
      });
      Socket.on('photo:receiveOwn', (data: any) => {
        dispatch(
          setSendPhotoMessageSuccess({
            toProfileId: data.profileId,
            text: '',
            img: {
              photoId: data.photoId,
              origin: data.origin,
              large: data.large,
              small: data.small,
            },
          })
        );
      });
      Socket.on('video:receive', (data: any) => {
        dispatch(
          setReceiveVideoMessageSuccess({
            profileId: data.profileId,
            video: {
              videoId: data.videoId,
              origin: data.origin,
              thumbnail: data.thumbnail,
            },
            currentUserId: currentUser?.profile?.id,
            isChatOpen: !!isChatOpen || isChatRoute,
          })
        );
        playMessage();
      });
      Socket.on('video:receiveOwn', (data: any) => {
        dispatch(
          setSendVideoMessageSuccess({
            toProfileId: data.profileId,
            text: '',
            video: {
              videoId: data.videoId,
              origin: data.origin,
              thumbnail: data.thumbnail,
            },
          })
        );
      });

      Socket.on(
        'notifications:newMessage:client',
        (data: {
          profileId: string;
          data: {
            senderId: string;
            senderName: string;
            senderAvatar: string;
            receiverId: string;
          };
        }) => {
          receiveLetterSound();
        }
      );
      Socket.on('notifications:newVirtualGift:client', (data: { profileId: string; data: QueueNewVirtualGiftDto }) => {
        dispatch(getGiftCountersAction());
        const giftFromSocket: GiftShape = {
          id: data.data.id,
          text: data.data.text,
          seen: data.data.seen,
          profileId: data.data.senderId,
          profileName: data.data.sender.name,
          profileAvatarSmall: data.data.sender.avatar || data.data.sender.avatarSmall, // fallback
          createdAt: data.data.createdAt,
          activeAt: data.data.sender.activeAt,
          isBookmark: data.data.sender.isBookmark,
          profileBirthday: data.data.sender.birthday,
          pageId: data.data.sender.pageId,
          virtualGiftImage: data.data.image,
        };
        dispatch(getGiftsFromSocketRequest(GiftKind.INBOX, giftFromSocket));
        receiveLetterSound();
      });
      Socket.on('online:connected', (data: any) => {
        dispatch(setUpdateAvailableUsers(data));
      });
      Socket.on('online:disconnected', (data: any) => {
        dispatch(setDisconnectedAvailableUsers(data));
      });
      Socket.on('chat:close:client', (data: { profileId: string; data: any }) => {
        dispatch(disconnectChat(data.profileId));
      });
      Socket.on('notifications:counter:messages', (data: CountersShape) => {
        dispatch(socketUpdateMessagesCount(data));
      });
      Socket.on(socketRoutes.dialogNotificationCounter, (data: CountersShape) => {
        dispatch(socketUpdateDialogCount(data));
      });

      Socket.on('notifications:counter:virtualGifts', (data: CountersShape) => {
        dispatch(socketUpdateGiftsCount(data));
      });
      Socket.on('notifications:balance:client', (data: CountersShape) => {
        dispatch(updateBalanceRequest());
      });

      Socket.on('notifications:videoConverted', (data: any) => {
        dispatch(
          setConvertedVideoMessageSuccess({
            video: data,
            viewType: data.private ? UserVideoType.PRIVATE_VIEW : UserVideoType.PUBLIC_VIEW,
          })
        );
      });
      Socket.on('notifications:videoFailed', (data: any) => {
        dispatch(deleteVideosSuccess(data.private ? UserVideoType.PRIVATE_VIEW : UserVideoType.PUBLIC_VIEW, data.id));
      });
    }
  }, [currentUser?.profile?.id, dispatch, isChatOpen, playMessage, receiveLetterSound, location]);

  return null;
};

export default SocketSubscriber;
