import React, { Fragment, useEffect, useState } from "react";

import clsx from "clsx";
import { Formik } from "formik";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useAppDispatch, useAppSelector } from "@/app/hooks";
import { updateCurrentUserRequest } from "@/app/users/users.actions";
import { UserProfileDTO } from "@/app/users/users.reducer";

import BaseTextarea from "@/components/base/base-textarea.component";
import BaseButton from "@/components/base/button.component";
import SecondaryTextField from "@/components/base/secondary-text-field.component";
import SelectTextField from "@/components/base/select-text-field.component";

import { UserProfile } from "@/types/user-profile.types";

import useStaffCheck from "@/hooks/useStaffCheck";

import { TEXT_AREA_EDIT_MAX, aboutUserSchema } from "@/utils/validationSchemas";

import { ReactComponent as ArrowIcon } from "@/assets/icons/option-arrow.svg";

import {
  childrenList,
  drinkList,
  educationList,
  familyList,
  religionList,
  smokeList,
} from "../edit.consts";

export interface AboutSettingType {
  religion?: string | null;
  profession?: string | null;
  education?: string | null;
  smoke?: string | null;
  drink?: string | null;
  celebrationBirthday?: boolean | undefined;
  celebrationChristmasDay?: boolean | undefined;
  celebrationNewYearsDay?: boolean | undefined;
  celebrationEaster?: boolean | undefined;
  celebrationValentinesDay?: boolean | undefined;
  celebrationHalloween?: boolean | undefined;
  celebrationWomensDay?: boolean | undefined;
  children?: string | null;
  aboutMe?: string | null;
}

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    width: "100%",
  },
  inputAppendElement: {
    flexShrink: 0,
    padding: "0 6px",
    minWidth: 118,

    [theme.breakpoints.down("md")]: {
      minWidth: 80,
    },
  },
  formControl: {
    "& > $inputField:not(:first-child)": {
      marginTop: 20,
    },
  },
  optionViewBtn: {
    marginLeft: 75,
    [theme.breakpoints.down("md")]: {
      marginLeft: 40,
    },
    cursor: "pointer",
    display: "flex !important",
    alignItems: "center !important",
    all: "unset",
  },
  inputField: {},
  saveButton: {
    width: "100%",
    maxWidth: 144,
    marginTop: 32,
  },
  aboutMeContainer: {
    display: "flex",

    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  optionsHeader: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginBottom: 16,
  },
  optionsWrapper: {
    display: "grid",
    marginLeft: "auto",
    width: "77%",
    gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
    gap: 8,
    paddingLeft: 12,

    [theme.breakpoints.down("md")]: {
      width: "100%",
      gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
    },
  },
  optionsTitlte: {
    flexShrink: 0,
    fontWeight: "400 !important",
    paddingLeft: 20,
  },
  aboutMeWrapper: {
    width: "100%",
    marginLeft: 80,
    [theme.breakpoints.down("md")]: {
      margin: "10px 4px",
    },
  },
}));

const overrideStyles = {
  pageTitle: {
    marginBottom: 24,
  },
};

const AboutProfile = () => {
  useStaffCheck();
  const classes = useStyles();
  const userIsLoading = useAppSelector((state) => state.users.userIsLoading);
  const dispatch = useAppDispatch();
  const { profile: currentUser }: UserProfileDTO =
  useAppSelector((state) => state.users.currentUser) || {};
  const [isOptionsOpen, setOptionsOpen] = useState(false);

  const initialData: AboutSettingType = {
    religion: currentUser.religion,
    profession: currentUser.profession,
    education: currentUser.education,
    smoke: currentUser.smoke,
    drink: currentUser.drink,
    celebrationBirthday: currentUser.celebrationBirthday || false,
    celebrationChristmasDay: currentUser.celebrationChristmasDay || false,
    celebrationNewYearsDay: currentUser.celebrationNewYearsDay || false,
    celebrationEaster: currentUser.celebrationEaster || false,
    celebrationValentinesDay: currentUser.celebrationValentinesDay || false,
    celebrationHalloween: currentUser.celebrationHalloween || false,
    celebrationWomensDay: currentUser.celebrationWomensDay || false,
    children: currentUser.children,
    aboutMe: Boolean(currentUser.aboutMe) ? currentUser.aboutMe : '',
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Typography variant="h3" style={overrideStyles.pageTitle}>
        About Me
      </Typography>
      <Formik
        initialValues={initialData}
        validateOnChange={false}
        validationSchema={aboutUserSchema}
        onSubmit={(values, { setSubmitting }) => {
          if (
            ["Often", "Rarely", "Occasionally"].includes(String(values.drink))
          )
            values.drink = values.drink?.toLowerCase();
          if (["Often", "Rarely"].includes(String(values.smoke)))
            values.smoke = values.smoke?.toLowerCase();

          if (values.children === "no children") values.children = "no";
          if (values.children === "no but i want to have children") {
            values.children = "no but I want to have children";
          }
          dispatch(updateCurrentUserRequest(values as UserProfile));
        }}
      >
        {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
          <form onSubmit={handleSubmit} className={classes.formContainer}>
            <FormControl fullWidth className={classes.formControl}>
              <div className={classes.inputField}>
                <SecondaryTextField
                  label="profession"
                  placeholder="Profession"
                  prepend={
                    <Typography
                      variant="body1"
                      fontWeight="400"
                      className={classes.inputAppendElement}
                    >
                      Profession:
                    </Typography>
                  }
                  onChange={handleChange}
                  type="text"
                />
              </div>

              <div className={classes.inputField}>
                <SelectTextField
                  label="religion"
                  caseSensetive={true}
                  placeholder="Select your religion"
                  list={religionList}
                  setFieldValue={setFieldValue}
                  prepend={
                    <Typography
                      variant="body1"
                      fontWeight="400"
                      className={classes.inputAppendElement}
                    >
                      Religion:
                    </Typography>
                  }
                  inputAppendElement={classes.inputAppendElement}
                />
              </div>
              <div className={classes.inputField}>
                <SelectTextField
                  label="education"
                  caseSensetive={true}
                  placeholder="Select your education"
                  list={educationList}
                  setFieldValue={setFieldValue}
                  prepend={
                    <Typography
                      variant="body1"
                      fontWeight="400"
                      className={classes.inputAppendElement}
                    >
                      Education:
                    </Typography>
                  }
                  inputAppendElement={classes.inputAppendElement}
                />
              </div>
              <div className={classes.inputField}>
                <SelectTextField
                  label="smoke"
                  caseSensetive={true}
                  placeholder="Select how often you smoke"
                  list={smokeList}
                  setFieldValue={setFieldValue}
                  prepend={
                    <Typography
                      variant="body1"
                      fontWeight="400"
                      className={classes.inputAppendElement}
                    >
                      Smoking:
                    </Typography>
                  }
                  inputAppendElement={classes.inputAppendElement}
                />
              </div>

              <div className={classes.inputField}>
                <SelectTextField
                  label="drink"
                  caseSensetive={true}
                  placeholder="Select how often you drink"
                  list={drinkList}
                  setFieldValue={setFieldValue}
                  prepend={
                    <Typography
                      variant="body1"
                      fontWeight="400"
                      className={classes.inputAppendElement}
                    >
                      Alcohol:
                    </Typography>
                  }
                  inputAppendElement={classes.inputAppendElement}
                />
              </div>

              <div className={classes.inputField}>
                <div className={classes.optionsHeader}>
                  <Typography variant="body1" className={classes.optionsTitlte}>
                    Holidays:
                  </Typography>
                  <button
                    type="button"
                    className={classes.optionViewBtn}
                    onClick={() => setOptionsOpen(!isOptionsOpen)}
                  >
                    <Typography variant="link">
                      {isOptionsOpen ? "Hide options" : "Show options"}
                    </Typography>
                    <ArrowIcon
                      style={{
                        marginLeft: 6,
                        width: 18,
                        height: 18,
                        transform: isOptionsOpen ? "rotate(180deg)" : "",
                      }}
                    />
                  </button>
                </div>

                <div
                  className={classes.optionsWrapper}
                  style={{ display: isOptionsOpen ? "grid" : "none" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.celebrationBirthday}
                        value={values.celebrationBirthday}
                        name="celebrationBirthday"
                        onChange={handleChange}
                        color="warning"
                      />
                    }
                    label="Birthday"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.celebrationEaster}
                        value={values.celebrationEaster}
                        name="celebrationEaster"
                        onChange={handleChange}
                        color="warning"
                      />
                    }
                    label="Easter"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.celebrationWomensDay}
                        value={values.celebrationWomensDay}
                        name="celebrationWomensDay"
                        onChange={handleChange}
                        color="warning"
                      />
                    }
                    label="Womens Day"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.celebrationChristmasDay}
                        value={values.celebrationChristmasDay}
                        name="celebrationChristmasDay"
                        onChange={handleChange}
                        color="warning"
                      />
                    }
                    label="Christmas Day"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.celebrationValentinesDay}
                        value={values.celebrationValentinesDay}
                        name="celebrationValentinesDay"
                        onChange={handleChange}
                        color="warning"
                      />
                    }
                    label="Valentines Day"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.celebrationNewYearsDay}
                        value={values.celebrationNewYearsDay}
                        name="celebrationNewYearsDay"
                        onChange={handleChange}
                        color="warning"
                      />
                    }
                    label="New Years Day"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.celebrationHalloween}
                        value={values.celebrationHalloween}
                        name="celebrationHalloween"
                        onChange={handleChange}
                        color="warning"
                      />
                    }
                    label="Halloween"
                  />
                </div>
              </div>
              <div className={classes.inputField}>
                <SelectTextField
                  label="children"
                  placeholder="Children"
                  list={childrenList}
                  setFieldValue={setFieldValue}
                  prepend={
                    <Typography
                      variant="body1"
                      fontWeight="400"
                      className={classes.inputAppendElement}
                    >
                      Children:
                    </Typography>
                  }
                  inputAppendElement={classes.inputAppendElement}
                />
              </div>
              <div className={classes.inputField}>
                <SelectTextField
                  label="family"
                  placeholder="family"
                  list={familyList}
                  setFieldValue={setFieldValue}
                  prepend={
                    <Typography
                      variant="body1"
                      fontWeight="400"
                      className={classes.inputAppendElement}
                    >
                      family:
                    </Typography>
                  }
                  inputAppendElement={classes.inputAppendElement}
                />
              </div>

              <div
                className={clsx(classes.inputField, classes.aboutMeContainer)}
              >
                <Typography variant="body1" className={classes.optionsTitlte}>
                  About me:
                </Typography>
                <div className={classes.aboutMeWrapper}>
                  <BaseTextarea
                    label="aboutMe"
                    placeholder={`About me (${TEXT_AREA_EDIT_MAX} symbols max)`}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div style={{ marginTop: 32 }}>
                <BaseButton
                  loading={userIsLoading}
                  color="primary"
                  text="Save"
                  className={classes.saveButton}
                  onClick={() => handleSubmit()}
                />
              </div>
            </FormControl>
          </form>
        )}
      </Formik>
    </Fragment>
  );
};

export default AboutProfile;
