import React from 'react';
import { Box, Button, Modal, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { deleteBlockedByMeRequest } from '@/app/users/users.actions';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const DeleteBlockedUserModal = ({ currentProfileId, handleClose, open }) => {
  const dispatch = useDispatch();
  const handleDelete = () => {
    dispatch(deleteBlockedByMeRequest(currentProfileId));
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby='modal-title' aria-describedby='modal-description'>
      <Box sx={style}>
        <Typography id='modal-title' variant='h6' component='h2'>
          Confirm Deletion
        </Typography>
        <Typography id='modal-description' sx={{ mt: 2 }}>
          Are you sure you want to delete this profile from blocked users?
        </Typography>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Button variant='contained' color='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button variant='contained' color='secondary' onClick={handleDelete}>
            Delete
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteBlockedUserModal;
