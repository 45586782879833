import { useAppSelector } from '@/app/hooks';
import { IUserPhotosData, UserPhotosDTO, defaultUserPhotosData } from '@/app/users/users.dto';
import { UserProfileDTO } from '@/app/users/users.reducer';
import { UserPhotoType } from '@/app/users/users.types';
import PhotosService from '@/services/photos.service';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toggleSnackbarOpen } from '@/app/ui/ui.actions';
import { getPhotosRequest } from "@/app/users/users.actions";

type ResponseUseUserPhotos = [isLoading: boolean, photos: IUserPhotosData];

const useUserPhotos = (): ResponseUseUserPhotos => {
  const [isLoading, setLoading] = useState(true);
  const myPhotos = useAppSelector((state) => state.users['publicPhotos']);
  const myPrivatePhotos = useAppSelector((state) => state.users['privatePhotos']);
  const [photos, setPhotos] = useState<IUserPhotosData>(defaultUserPhotosData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, type } = useParams<{
    id: string;
    type: UserPhotoType;
  }>();
  const currentProfile: UserProfileDTO = useAppSelector((state) => state.users.currentUser);

  const fetchPhotos = useCallback(
    async (id: string, type: UserPhotoType) => {
      try {
        const response = await PhotosService.getUserPhotos(id, type);
        setPhotos(response);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        dispatch(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
      }
    },
    [id]
  );

  const backToProfile = useCallback(() => navigate(`/user/${currentProfile?.profile.id}`), [currentProfile, navigate]);

  useEffect(() => {
    if (id === currentProfile?.id) {
      dispatch(getPhotosRequest(currentProfile?.id, type === 'private' ? UserPhotoType.PRIVATE_VIEW : UserPhotoType.PUBLIC_VIEW))
      setLoading(false);
    }
  }, [currentProfile?.id, dispatch, id, type]);

  useEffect(() => {
    if (id === currentProfile?.id) {
      setPhotos(type === 'private' ? myPrivatePhotos : myPhotos)
    }
  }, [currentProfile?.id, id, myPhotos, type, myPrivatePhotos]);

  useEffect(() => {
    const isMyProfile = id === currentProfile?.id;
    const isTryingToAccessPrivate = type === 'private' && id !== currentProfile?.id;

    if (isTryingToAccessPrivate) {
      return backToProfile();
    }
    if (!isMyProfile && id) {
      fetchPhotos(id, UserPhotoType.PUBLIC_VIEW);
    }
    if (id && type && !isMyProfile) {
      fetchPhotos(id, type);
    }
  }, [fetchPhotos, currentProfile, id, type, backToProfile, myPhotos]);

  return [isLoading, photos];
};

export default useUserPhotos;
