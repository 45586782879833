import { useAppDispatch, useAppSelector } from '@/app/hooks';
import {
  postPhotosRequest,
  postVideosRequest,
  setSendPhotoMessageRequest,
  setSendVideoMessageRequest,
} from '@/app/users/users.actions';
import { UserPhotosDTO, UserVideosDTO } from '@/app/users/users.dto';
import { UserPhotoType, UserVideoType } from '@/app/users/users.types';
import { ReactComponent as AttachIcon } from '@/assets/icons/attach.svg';
import { ReactComponent as ArrowLeftIcon } from '@/assets/icons/left-arrow.svg';
import BaseButton from '@/components/base/button.component';
import UploadPhotoItem from '@/components/base/upload-photo-item';
import UploadVideoItem from '@/components/base/upload-video-item';
import { CloseIconStyled } from '@/components/dialogs/sign-up-dialog.component';
import UploadPhotoLayout from '@/components/layouts/upload-photo.layout';
import UploadVideoLayout from '@/components/layouts/upload-video.layout';
import useBalanceCheckHook from '@/hooks/useBalanceCheckHook';
import { ISettings, UserProfile } from '@/types/user-profile.types';

import { Dialog, Tab, Tabs, Theme, Zoom } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useState } from 'react';
import { Gallery } from 'react-photoswipe-gallery';
import imageCompression from "browser-image-compression";
import { isIOSPhone } from "@/helpers/constants";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '18px 10px',
    overflow: 'auto',

    [theme.breakpoints.down('sm')]: {
      padding: '28px 12px',
    },
  },
  paper: {
    padding: 0,
  },
  dialogPaper: {
    overflowY: 'visible',
  },
  formTextFieldsContainer: {
    marginTop: 20,
    marginBottom: 20,
    '& > $formTextField:not(:first-child)': {
      marginTop: 20,
    },
  },
  attachedImagesContainer: {
    display: 'flex',
    gap: 10,
    alignItems: 'center',
    flexWrap: 'wrap',
    marginBottom: 20,
  },
  attachedIcon: {
    maxWidth: 42,
    maxHeight: 42,
    '& > .MuiButton-startIcon': {
      margin: 0,
    },
    minWidth: '0 !important',
  },
  formSubmitBtn: {
    width: 144,
  },
  formTextField: {},
  formContainer: {
    display: 'flex',
    width: 344,
    padding: '0 28px 24px',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  formControl: {
    width: '100%',
  },
  dialogTitle: {
    textAlign: 'center',
    height: 48,
  },
  formActions: {
    gap: 10,
    display: 'flex',
    width: '100%',
  },
  sendBtn: {
    width: '100%',
    maxWidth: 120,
  },
  attachedImagesInner: {
    border: `2px dashed ${theme.palette.radioSelected.main}`,
    borderRadius: 5,
    padding: 4,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    flex: '1 1 100%',
  },
  attachBtn: {
    color: `${theme.palette.radioSelected.main} !important`,
    width: '100%',
    maxWidth: 120,
  },
  attachTextStyle: {
    fontWeight: '600 !important',
    fontSize: '14px !important',
    [theme.breakpoints.down('md')]: {
      fontSize: '10px !important',
    },
  },
}));

type ChatSendPhotoProps = {
  open: boolean;
  closeHandler: () => void;
  referenceUser: UserProfile;
  isStaff: boolean;
};

const DialogSendPhotoOrVideo = ({ open, closeHandler, referenceUser, isStaff }: ChatSendPhotoProps) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [attachedImage, setAttachedImage] = useState<UserPhotosDTO | null>(null);
  const [attachedVideo, setAttachedVideo] = useState<UserVideosDTO | null>(null);
  const [tabValue, setTabValue] = useState(0);

  const isSending = useAppSelector((state) => state.mails.isSending);
  const isUploading = useAppSelector((state) => state.users.photosIsUploading);
  const { creditsDialogPhotoSend, creditsDialogVideoSend } = useAppSelector<ISettings>((state) => state.users.settings);
  const wallet = useAppSelector((state) => state.users.wallet);

  const dispatch = useAppDispatch();

  const handleSelectPhoto = (image) => {
    setAttachedImage(image);
  };

  const handleSelectVideo = (image) => {
    setAttachedVideo(image);
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const { checkBalance } = useBalanceCheckHook(wallet?.balance || 0);

  const handlePhotoSubmit = useCallback(() => {
    setIsLoading(true)
    checkBalance(
      creditsDialogPhotoSend,
      () => {
        if (attachedImage) {
          dispatch(
            setSendPhotoMessageRequest({
              toProfileId: referenceUser.id,
              photoId: attachedImage.id,
              origin: attachedImage.origin,
              large: attachedImage.large,
              small: attachedImage.small,
              originBlur: attachedImage.originBlur,
              largeBlur: attachedImage.largeBlur,
              smallBlur: attachedImage.smallBlur,
              closeHandler: () => {
                closeHandler()
                setIsLoading(false)
              },
              isStaff,
              isDialog: true,
            })
          );
        }
      },
      () => {
        if (attachedImage) {
          setIsLoading(false)
        }
      });
  }, [checkBalance, creditsDialogPhotoSend, attachedImage, dispatch, referenceUser.id, closeHandler, isStaff]);

  const handleVideoSubmit = useCallback(() => {
    setIsLoading(true);
    checkBalance(
      creditsDialogVideoSend,
      () => {
        if (attachedVideo) {
          dispatch(
            setSendVideoMessageRequest({
              toProfileId: referenceUser.id,
              videoId: attachedVideo.id,
              thumbnail: attachedVideo.thumbnail,
              origin: attachedVideo.origin,
              closeHandler: () => {
                closeHandler()
                setIsLoading(false);
              },
              isStaff,
              isDialog: true,
            })
          );
        }
      },
      () => {
        if (attachedVideo) {
          setIsLoading(false);
        }
      }
    )
  }, [checkBalance, creditsDialogVideoSend, attachedVideo, dispatch, referenceUser.id, closeHandler, isStaff]);

  const handlePhotoFileUploading = useCallback(
    (image: FormData) => {
      dispatch(postPhotosRequest(UserPhotoType.PRIVATE_VIEW, image));
    },
    [dispatch]
  );

  const handleVideoFileUploading = useCallback(
    (video: File) => {
      dispatch(postVideosRequest(UserVideoType.PRIVATE_VIEW, video));
    },
    [dispatch]
  );

  const handlePhotoDrop = useCallback(
    async (acceptedFiles: any) => {
      const formData = new FormData();
      if (isIOSPhone) {
        setIsLoading(true)
        try {
          const options = {
            useWebWorker: true,
            alwaysKeepResolution: true,
            maxSizeMB: 20,
          };
          const compressedFile = await imageCompression(acceptedFiles[0], options);
          formData.append('file', compressedFile);
        } finally {
          setIsLoading(false)
        }
      } else {
        formData.append('file', acceptedFiles[0]);
      }
      handlePhotoFileUploading(formData);
    },
    [handlePhotoFileUploading]
  );

  const handleVideoDrop = useCallback(
    (acceptedFiles: any) => {
      handleVideoFileUploading(acceptedFiles[0]);
    },
    [handleVideoFileUploading]
  );

  return (
    <Dialog
      classes={{
        root: classes.dialogPaper,
      }}
      TransitionComponent={Zoom}
      open={open}
      onClose={() => closeHandler()}
    >
      <div className={classes.container}>
        <div className={classes.dialogTitle}>
          <Tabs value={tabValue} onChange={handleChangeTab} centered>
            <Tab label='Photo' />
            <Tab label='Video' />
          </Tabs>
        </div>
        {tabValue === 0 && (
          <Zoom in={open}>
            <div className={classes.container}>
              {attachedImage ? (
                <>
                  <div className={classes.attachedImagesContainer}>
                    <div className={classes.attachedImagesInner}>
                      <Gallery>
                        <UploadPhotoItem
                          key={attachedImage.id}
                          {...attachedImage}
                          isPrivate={attachedImage?.private}
                          isEditable={true}
                          handleRemoveFile={() => setAttachedImage(null)}
                          isExpended={false}
                        />
                      </Gallery>
                    </div>
                  </div>
                  <div className={classes.formActions}>
                    <div className={classes.formActions}>
                      <BaseButton
                        loading={isSending || isLoading}
                        disabled={isSending || isLoading}
                        onClick={handlePhotoSubmit}
                        color='primary'
                        text='Send'
                        className={classes.sendBtn}
                      />
                      <BaseButton
                        inverted
                        text='Attach'
                        className={classes.attachBtn}
                        textStyle={classes.attachTextStyle}
                        Icon={<AttachIcon />}
                        onClick={() => setAttachedImage(null)}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <button onClick={() => setAttachedImage(null)}>
                    <ArrowLeftIcon />
                  </button>
                  <UploadPhotoLayout
                    isEditable={true}
                    isExpended={false}
                    handleDrop={handlePhotoDrop}
                    className={''}
                    handleFileUploading={() => {
                    }}
                    handleRemoveFile={() => {
                    }}
                    handleSelect={handleSelectPhoto}
                    isUploading={isUploading}
                  />
                </>
              )}
            </div>
          </Zoom>
        )}
        {tabValue === 1 && (
          <Zoom in={open}>
            <div className={classes.container}>
              {attachedVideo ? (
                <>
                  <div className={classes.attachedImagesContainer}>
                    <div className={classes.attachedImagesInner}>
                      <UploadVideoItem
                        key={attachedVideo.id}
                        {...attachedVideo}
                        isPrivate={attachedVideo?.private}
                        isEditable={true}
                        handleRemoveFile={() => setAttachedVideo(null)}
                        isExpended={false}
                      />
                    </div>
                  </div>
                  <div className={classes.formActions}>
                    <div className={classes.formActions}>
                      <BaseButton
                        loading={isSending || isLoading}
                        disabled={isSending || isLoading}
                        onClick={handleVideoSubmit}
                        color='primary'
                        text='Send'
                        className={classes.sendBtn}
                      />
                      <BaseButton
                        inverted
                        text='Attach'
                        className={classes.attachBtn}
                        textStyle={classes.attachTextStyle}
                        Icon={<AttachIcon />}
                        onClick={() => setAttachedVideo(null)}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <button onClick={() => setAttachedVideo(null)}>
                    <ArrowLeftIcon />
                  </button>
                  <UploadVideoLayout
                    isEditable={true}
                    isExpended={false}
                    handleDrop={handleVideoDrop}
                    className={''}
                    handleFileUploading={() => {
                    }}
                    handleRemoveFile={() => {
                    }}
                    handleSelect={handleSelectVideo}
                    isUploading={isUploading}
                  />
                </>
              )}
            </div>
          </Zoom>
        )}
      </div>
      <CloseIconStyled onClick={closeHandler} />
    </Dialog>
  );
};

export default DialogSendPhotoOrVideo;
