import { useAppDispatch, useAppSelector } from '@/app/hooks';
import SearchIcon from '@/assets/icons/search.svg';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import UserLink from './user-link';
import profilePicture from '@/assets/images/demo-avatar.jpeg';

type LastUsersComponentProps = {
  onClick?: () => void;
  mobile?: boolean;
  availableUsers: any;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '172px',
    padding: '14px',
    marginTop: '-12px',
    zIndex: '90',
    background: '#FFFFFF',
    borderRadius: '0 10px 10px 0',
    boxShadow: '0px 0px 20px rgb(0 0 0 / 5%)',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '56px',
    fontSize: '22px',
    fontWeight: '600',
    color: '#000000',
  },
  buttonsWrapper: {
    paddingTop: '8px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  mainButton: {
    width: '80px',
    height: '24px',
    minHeight: '24px!important',
    padding: '0',
    boxShadow: 'none!important',
  },
  usersWrapper: {
    marginTop: '27px',
    overflowY: 'scroll',
    height: 'calc(100dvh - 236px)',
    [theme.breakpoints.down('lg')]: {
      height: 'calc(100dvh - 100px)',
    },
  },
  searchedWrapper: {
    display: 'flex',
    maxHeight: 30,
    width: '100%',
  },
  searchedInput: {
    width: '100%',
    paddingRight: 6,
    backgroundSize: 14,
    lineHeight: '30px',
    outline: ' 8px ridge rgba(170, 50, 220, .6)',
    backgroundPosition: '94% 50%',
    background: `url(${SearchIcon}) no-repeat right`,
  },
}));

const LatsUsers = ({
                     mobile = false,
                     onClick,
                     availableUsers = [],
                   }: LastUsersComponentProps) => {
  const classes = useStyles();
  const currentUser = useAppSelector((state) => state.users.currentUser);

  return (
    <div
      className={classes.container}
      style={mobile ? { paddingBottom: 23, paddingTop: 27 } : {}}
    >
      <div className={classes.title}>Last chats</div>
      <div className={classes.usersWrapper}>
        {availableUsers
          .filter(
            (user: {
              itHasMyMessage: boolean;
              itHasReferenceMessage: boolean;
            }) => user?.itHasMyMessage && user?.itHasReferenceMessage
          )
          .map(({ name, id, avatar, avatarSmall, pageId, itHasNewMessage }) => (
            <UserLink
              onClick={onClick}
              key={id}
              userName={name}
              avatar={avatar || avatarSmall || profilePicture} //fallback
              user={currentUser}
              uid={id}
              pageId={pageId}
              isNewMessage={itHasNewMessage}
            />
          ))}
      </div>
    </div>
  );
};

export default LatsUsers;
