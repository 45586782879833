import React, { memo, useCallback, useMemo, useState } from 'react';
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import clsx from "clsx";

import femalePicture from "@/assets/images/demo-avatar-f.png";
import malePicture from "@/assets/images/demo-avatar-m.png";
import { useAppSelector } from "@/app/hooks";

const useStyles = makeStyles<Theme>(() => ({
  avatar: {
    objectFit: "cover",
  }
}))

interface AvatarWithFallbackProps {
  src?: string,
  alt?: string,
  className?: string,
  gender?: string,
  isUserPhoto?: boolean,
}


const AvatarWithFallback = memo<AvatarWithFallbackProps>(
  ({ src, alt, className, gender, isUserPhoto }) => {
    const classes = useStyles();
    const userGender = useAppSelector((state) => state?.users?.currentUser?.profile?.gender);
    const [hasError, setHasError] = useState(false);

    const fallbackSrc = useMemo(() => {
      const resolvedGender = gender || (isUserPhoto ? userGender : userGender === "male" ? "female" : "male");
      return resolvedGender === "male" ? malePicture : femalePicture;
    }, [gender, userGender, isUserPhoto]);

    const handleError = useCallback(
      (event: any) => {
        if (!hasError) {
          event.target.src = fallbackSrc;
          setHasError(true);
        }
      },
      [hasError, fallbackSrc]
    );

    return <img src={src || fallbackSrc} alt={alt} onError={handleError} className={clsx(classes.avatar, className)} />;
  }
);

export default AvatarWithFallback;