export const HOME = '/';
export const TERMS_OF_USE = '/terms-of-use';
export const PRIVACY_POLICY = '/privacy-policy';
export const COMPLAINT_POLICY = '/complaint-policy';
export const ANTI_SCAM_POLICY = '/anti-scam-policy';
export const COOKIE_POLICY = '/cookie-policy';
export const FAQ_PAGE = '/faq';
export const REFUND_POLICY = '/refund-policy';
export const PRICE_LIST = '/price-list';
export const CANCELLATION_POLICY = '/cancellation-policy';
export const ABOUT = '/about';
export const CONTACTS = '/contacts';
export const CHAT = '/chat';
export const CHAT_SINGLE = '/chat/:id';
export const CHAT_ACTIVE = '/chat/:id/:otherId';
export const CHAT_ACTIVE_MESSAGE = '/chat/:id/:otherId/?message=:message';
export const DIALOG = '/dialog';
export const DIALOG_SINGLE = '/dialog/:id';
export const DIALOG_ACTIVE = '/dialog/:id/:otherId';
export const DIALOG_ACTIVE_MESSAGE = '/dialog/:id/:otherId/?message=:message';
export const USER = '/user';
export const USER_SEARCH = '/user/search';
export const USER_WALLET = '/user/wallet';
export const USER_PROFILE = '/user/:id';
export const USER_PHOTO_ALBUMS = '/user/photo-albums/:id';
export const USER_PHOTOS = '/user/photos/:type/:id';
export const USER_VIDEO_ALBUMS = '/user/video-albums/:id';
export const USER_VIDEOS = '/user/videos/:type/:id';
export const USER_CREDITS = '/user/credits';
export const USER_SIGN_IN_REDIRECT = '/user/sign-in-redirect/:token';
export const USER_MAIL = '/user/mail';
export const USER_CONTACTS = '/user/contacts';
export const USER_GUESTS = '/user/guests';
export const USER_GIFTS = '/user/gifts';
export const USER_BLACKLIST = '/user/blacklist';
export const USER_NEWS = '/user/news';
export const USER_NOTIFICATIONS = '/user/notifications';
export const USER_ORDER_HISTORY = '/user/order-history';
export const USER_PAYMENTS = '/user/payments';
export const USER_MESSAGES = '/user/messages/:kind';
export const USER_MESSAGES_DETAIL = '/user/messages/:kind/:id';
export const USER_GIFTS_KIND = '/user/gifts/:kind';
export const USER_GIFTS_KIND_DETAIL = '/user/gifts/:kind/:id';
export const USER_EDIT = '/user/edit';
export const USER_EDIT_ABOUT = '/user/edit/about';
export const USER_EDIT_APPEARANCE = '/user/edit/appearance';
export const USER_EDIT_PERFECT = '/user/edit/perfect';
export const USER_SETTINGS = '/user/settings';
export const USER_SETTINGS_EMAIL = '/user/settings/email';
export const USER_SETTINGS_NOTIFICATIONS = '/user/settings/notifications';
export const NOT_FOUND_PAGE = '*'

export const ROUTES = [
    HOME,
    TERMS_OF_USE,
    PRIVACY_POLICY,
    COMPLAINT_POLICY,
    ANTI_SCAM_POLICY,
    COOKIE_POLICY,
    FAQ_PAGE,
    REFUND_POLICY,
    PRICE_LIST,
    CANCELLATION_POLICY,
    ABOUT,
    CONTACTS,
    CHAT,
    CHAT_SINGLE,
    CHAT_ACTIVE,
    CHAT_ACTIVE_MESSAGE,
    DIALOG,
    DIALOG_SINGLE,
    DIALOG_ACTIVE,
    DIALOG_ACTIVE_MESSAGE,
    USER,
    USER_SEARCH,
    USER_WALLET,
    USER_PROFILE,
    USER_PHOTO_ALBUMS,
    USER_PHOTOS,
    USER_VIDEO_ALBUMS,
    USER_VIDEOS,
    USER_CREDITS,
    USER_SIGN_IN_REDIRECT,
    USER_MAIL,
    USER_CONTACTS,
    USER_GUESTS,
    USER_GIFTS,
    USER_BLACKLIST,
    USER_NEWS,
    USER_NOTIFICATIONS,
    USER_ORDER_HISTORY,
    USER_PAYMENTS,
    USER_MESSAGES,
    USER_MESSAGES_DETAIL,
    USER_GIFTS_KIND,
    USER_GIFTS_KIND_DETAIL,
    USER_EDIT,
    USER_EDIT_ABOUT,
    USER_EDIT_APPEARANCE,
    USER_EDIT_PERFECT,
    USER_SETTINGS,
    USER_SETTINGS_EMAIL,
    USER_SETTINGS_NOTIFICATIONS,
];