import { signInRequest, resetPasswordRequest } from '@/app/auth/auth.actions';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { AuthDto } from '@/services/auth.service';
import { FormControl, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Formik } from 'formik';
import React, { Fragment, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BaseButton from '../base/button.component';
import BaseTextField from '../base/text-field.component';
import { validationSchemaLogin, validationSchemaForgotPassword } from '@/utils/validationSchemas';
import ReCAPTCHA from 'react-google-recaptcha';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: '100%',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 56px)',
    padding: '0 28px 24px',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  formActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 6,
    flexWrap: 'wrap',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  formViewBtn: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '14px',
    },
  },
  formTextFieldsContainer: {
    marginTop: 20,
    marginBottom: 20,
    '& > $formTextField:not(:first-child)': {
      marginTop: 20,
    },
  },
  formSubmitBtn: {
    width: 144,
  },
  formTextField: {},
  captchaWrapper: {
    margin: '0 auto 10px',
  },
}));

interface SignInProps {
  closeHandler: () => void;
}

const SignInLayout = ({ closeHandler }: SignInProps) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const authIsLoading = useAppSelector((state) => state.auth.authIsLoading);
  const navigate = useNavigate();

  const requestHandler = {
    'sign-in': (values: AuthDto) => dispatch(signInRequest({ ...values, navigate, closeHandler })),
    'forgot-password': (values: any) => dispatch(resetPasswordRequest({ email: values.email, navigate })),
  };

  const [isForgotView, setForgotView] = useState(false);

  const handleChangeView = () => setForgotView(!isForgotView);
  const viewType = isForgotView ? 'forgot-password' : 'sign-in';

  const [captchaValue, setCaptchaValue] = useState(null);

  const onChange = (value: any) => {
    setCaptchaValue(value);
  };

  const renderInputs = useCallback(
    (handleChange: any) =>
      viewType === 'sign-in' ? (
        <Fragment>
          <BaseTextField
            label='email'
            onChange={handleChange}
            placeholder={'email'}
            type='email'
            baseClass={classes.formTextField}
          />
          <BaseTextField
            label='password'
            onChange={handleChange}
            type='password'
            placeholder={'password'}
            baseClass={classes.formTextField}
          />
        </Fragment>
      ) : (
        <BaseTextField
          label='email'
          onChange={handleChange}
          type='email'
          placeholder={'email'}
          baseClass={classes.formTextField}
        />
      ),
    [viewType]
  );

  const renderActions = useCallback(
    (isSubmitting) =>
      viewType === 'sign-in' ? (
        <Fragment>
          <div className={classes.formViewBtn}>
            <button
              type='button'
              style={{ all: 'unset', cursor: 'pointer' }}
              onClick={handleChangeView}
              disabled={isSubmitting}
            >
              <Typography variant='link'>Forgot password</Typography>
            </button>
          </div>
          <BaseButton
            type='submit'
            variant='contained'
            text='Sign in'
            className={classes.formSubmitBtn}
            loading={isSubmitting}
          />
        </Fragment>
      ) : (
        <Fragment>
          <div className={classes.formViewBtn}>
            <button
              type='button'
              style={{ all: 'unset', cursor: 'pointer' }}
              onClick={handleChangeView}
              disabled={isSubmitting}
            >
              <Typography variant='link'>Go to sign in</Typography>
            </button>
          </div>
          <BaseButton
            variant='contained'
            type='submit'
            text='Reset'
            className={classes.formSubmitBtn}
            loading={isSubmitting}
          />
        </Fragment>
      ),
    [viewType]
  );

  return (
    <Formik
      initialValues={viewType === 'sign-in' ? { email: '', password: '' } : { email: '' }}
      validationSchema={viewType === 'sign-in' ? validationSchemaLogin : validationSchemaForgotPassword}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        requestHandler[viewType](values as AuthDto);
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit} className={classes.formContainer}>
          <FormControl className={classes.formControl}>
            <div className={classes.formTextFieldsContainer}>{renderInputs(handleChange)}</div>

            <div className={classes.captchaWrapper}>
              <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY as string} onChange={onChange} />
            </div>

            {captchaValue && <div className={classes.formActions}>{renderActions(authIsLoading)}</div>}
          </FormControl>
        </form>
      )}
    </Formik>
  );
};

export default SignInLayout;
