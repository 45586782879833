import { HttpService } from "./http.service";
import { ErrorWrapper } from "./utils";

import { ReactComponent as VideoChatIcon } from "@/assets/icons/orders/VideoChat.svg";
import { ReactComponent as TextChatIcon } from "@/assets/icons/orders/TextChat.svg";
import { ReactComponent as WriteLetter } from "@/assets/icons/orders/WriteLetter.svg";
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import ImageIcon from '@mui/icons-material/Image';
import GifBox from '@mui/icons-material/GifBox';
import FreeCreditsIcon from '@mui/icons-material/FreeBreakfast';

export interface OrdersDto {
  id: string;
  createdAt: Date;
  profileId: string;
  targetProfileId: string | null;
  agencyId: string | null;
  action: CreditsAliasEnum;
  credits: number;
  data: string | null;
  targetProfileName: string,
  targetProfilePageId: 817946,
}

export enum CreditsAliasEnum {
  all = 'All',
  creditsMessageNew = 'New message',
  creditsMessagePhotoOpen = 'Message photo',
  creditsMessageVideoOpen = 'Message video',
  creditsMessageVideoSend = 'Message video send',
  creditsChatTextMin = 'Chat text minute',
  creditsChatVideoMin = 'Chat video minute',
  creditsChatPhotoOpen = 'Chat photo open',
  creditsChatVideoOpen = 'Chat video open',
  creditsChatPhotoSend = 'Chat photo send',
  creditsChatVideoSend = 'Chat video send',
  freeCreditsAdmin = 'Free Credits Admin',
  creditsMessageSend = 'Credits Message Send',
  creditsRealInfo = 'Credits Real Info',
  creditsMessageOpen = 'Credits Message Open',
  virtualGiftsSend = 'Virtual Gifts Send',
  virtualGiftsReceived = 'Virtual Gifts Received',
  creditsDialogPhotoSend = 'Credits Dialog Photo Send',
  creditsDialogVideoSend = 'Credits Dialog Video Send',
  creditsDialogTextSend = 'Credits Dialog Text Send',
  creditsDialogPhotoOpen = 'Credits Dialog Photo Open',
  creditsDialogVideoOpen = 'Credits Dialog Video Open',
  creditsProfilesBlacklistAdd = 'Credits Profiles Blacklist Add',
  creditsStickerSend = 'Credits Sticker Send',
}

export const CreditsAliasReverse = {
  'All': '',
  'New message': 'creditsMessageNew',
  'Message photo': 'creditsMessagePhotoOpen',
  'Message video': 'creditsMessageVideoOpen',
  'Message video send': 'creditsMessageVideoSend',
  'Chat text minute': 'creditsChatTextMin',
  'Chat video minute': 'creditsChatVideoMin',
  'Chat photo open': 'creditsChatPhotoOpen',
  'Chat video open': 'creditsChatVideoOpen',
  'Chat photo send': 'creditsChatPhotoSend',
  'Chat video send': 'creditsChatVideoSend',
  'Free Credits Admin': 'freeCreditsAdmin',
  'Credits Message Send': 'creditsMessageSend',
  'Credits Message Open': 'creditsMessageOpen',
  'Virtual Gifts Send': 'virtualGiftsSend',
  'Virtual Gifts Received': 'virtualGiftsReceived',
  'Credits Dialog Photo Send': 'creditsDialogPhotoSend',
  'Credits Dialog Video Send': 'creditsDialogVideoSend',
  'Credits Dialog Text Send': 'creditsDialogTextSend',
  'Credits Dialog Photo Open': 'creditsDialogPhotoOpen',
  'Credits Dialog Video Open': 'creditsDialogVideoOpen',
  'Credits Profiles Blacklist Add': 'creditsProfilesBlacklistAdd',
}

export const TypeIcons = {
  creditsMessageNew: WriteLetter,
  creditsMessagePhotoOpen: PhotoCameraBackIcon,
  creditsMessageVideoOpen: OndemandVideoIcon,
  creditsMessageVideoSend: OndemandVideoIcon,
  creditsChatTextMin: TextChatIcon,
  creditsChatVideoMin: VideoChatIcon,
  creditsChatPhotoOpen: ImageSearchIcon,
  creditsChatVideoOpen: VideoFileIcon,
  creditsChatPhotoSend: ImageIcon,
  creditsChatVideoSend: VideoCameraFrontIcon,
  freeCreditsAdmin: FreeCreditsIcon,
  creditsMessageSend: WriteLetter,
  creditsRealInfo: FreeCreditsIcon,
  creditsMessageOpen: WriteLetter,
  virtualGiftsSend: GifBox,
  virtualGiftsReceived: GifBox,
  creditsDialogPhotoSend: ImageSearchIcon,
  creditsDialogVideoSend: ImageSearchIcon,
  creditsDialogTextSend: TextChatIcon,
  creditsDialogPhotoOpen: ImageSearchIcon,
  creditsDialogVideoOpen: ImageSearchIcon,
  creditsProfilesBlacklistAdd: FreeCreditsIcon,
  creditsStickerSend: ImageIcon,
}

const mapResponce = (data: Array<OrdersDto>) => data.map(item => ({
  id: item.id,
  info: {
    name: item.targetProfileName,
    id: item.targetProfilePageId
  },
  action: item.action,
  credits: item.credits,
  createdAt: item.createdAt,
}))

const getQueryParams = (offset, sortBy, sortOrder, chosenProfile, sorting) => {
  if (!offset && !sortBy && !sortOrder && !chosenProfile && !sorting) return '';

  return `?limit=20&offset=${offset}${sortBy && sortOrder ? `&sortField=${sortBy}&sortOrder=${sortOrder.toUpperCase()}` : ''}${chosenProfile ? `&targetProfileId=${chosenProfile}` : ''}${sorting ? `&action=${sorting}` : ''}`
}

export default class OrdersService {
  static get entity(): string {
    return "transactions";
  }

  static async getOrders(
    offset: number,
    sortBy: string,
    sortOrder: "asc" | "desc" | null,
    chosenProfile: string,
    sorting: CreditsAliasEnum
  ): Promise<Array<OrdersDto>> {
    try {
      const response = await HttpService.get(`/${this.entity}${getQueryParams(offset, sortBy, sortOrder, chosenProfile, sorting)}`);
      //@ts-ignore
      return mapResponce(response.data);

      //@ts-ignore
      // return mapResponce(mock);
    } catch (error: any) {
      const message = error?.response?.data?.error || error?.response?.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}

