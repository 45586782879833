import React, { useCallback } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Paper from '@mui/material/Paper';

import { Grid } from '@mui/material';
import { Badge } from "@mui/material";

import { Theme } from "@mui/material/styles";
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { makeStyles } from "@mui/styles";

import { ReactComponent as WomanIcon } from "@/assets/icons/navigation/woman.svg";
import { ReactComponent as ManIcon } from "@/assets/icons/navigation/man.svg";
import { ReactComponent as ChatIcon } from "@/assets/icons/navigation/chat.svg";
import { ReactComponent as MailIcon } from "@/assets/icons/navigation/mail.svg";
import { ReactComponent as MessageIcon } from '@/assets/icons/profile-actions/message.svg';
import { ReactComponent as SettingsIcon } from "@/assets/icons/navigation/settings.svg";
import { ReactComponent as PaymentsIcon } from "@/assets/icons/navigation/payments.svg";
import { ReactComponent as LogOutIcon } from "@/assets/icons/navigation/logout.svg";
import { ReactComponent as BlackListIcon } from "@/assets/icons/navigation/black-list.svg";
import { ReactComponent as LiveChatIcon } from "@/assets/icons/navigation/live-chat.svg";
import { ReactComponent as NewsIcon } from "@/assets/icons/navigation/news.svg";
import { ReactComponent as NotificationsIcon } from "@/assets/icons/navigation/notifications.svg";
import { ReactComponent as HistoryIcon } from "@/assets/icons/navigation/history.svg";
import { ReactComponent as ContactsIcon } from "@/assets/icons/navigation/contacts.svg";
import { ReactComponent as GuestsIcon } from "@/assets/icons/navigation/guests.svg";
import { ReactComponent as GiftsIcon } from "@/assets/icons/navigation/gifts.svg";
import { ReactComponent as MoreIcon } from "@/assets/icons/navigation/more.svg";

import { UserProfileDTO } from "@/app/users/users.reducer";
import { ISettings } from "@/types/user-profile.types";

import { useAppSelector } from "@/app/hooks";
import useIsStaffUserRole from "@/hooks/useIsStaffUserRole";

const drawerBleeding = 56;

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: '#503EB6',
  },
  active: {
    color: `${theme.palette.primary.main} !important`,
  },
  default: {
    color: '#503EB6!important',
  },
  iconBadge: {
    '& > span': { top: 6, padding: '0 4px', fontSize: '10px', height: '14px' }
  },
  drawer: {
    borderTopLeftRadius: '16px!important',
    borderTopRightRadius: '16px!important',
    overflow: 'hidden!important',
  },
  drawerRoot: {
    '& .MuiBackdrop-root': {
      background: 'rgba(80, 62, 182, 0.3)'
    }
  },
  drawerItem: {
    textAlign: 'center',
    textDecoration: 'none',
  },
  drawerItemLabel: {
    display: 'block',
    fontSize: '12px',
    fontWeight: 'bold',
    marginTop: '6px',
  },
  drawerItemIcon: {
    display: 'inline-block',
    lineHeight: '0',
    backgroundColor: '#EEEEEE',
    padding: '4px',
    borderRadius: '4px',
  },
  drawerItemGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}));

const StyledBox = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
}));

const Puller = styled('div')(({ theme }) => ({
  width: 60,
  height: 6,
  backgroundColor: grey[300],
  borderRadius: 3,
  position: 'absolute',
  top: 12,
  left: 'calc(50% - 30px)',
}));

const BottomBar = ({ handleLogout }) => {
  const classes = useStyles();

  const location = useLocation();
  const navigate = useNavigate();

  const { profile: currentUser }: UserProfileDTO = useAppSelector((state) => state.users.currentUser) || {};
  const inboxNewMessagesCount = useAppSelector((state) => state.mails.messageCounters?.unseen);
  const inboxNewDialogCount = useAppSelector((state) => state.users.dialogCounters?.unseen);
  const inboxNewGiftsCount = useAppSelector((state) => state.gifts.giftCounters?.unseen);
  const { liveChat } = useAppSelector<ISettings>((state) => state.users.settings);

  const isStaff = useIsStaffUserRole();

  const [open, setOpen] = React.useState(false);

  const userProfileId = currentUser?.id || '';

  const openLiveChat = useCallback(
    () => ((window as any).LiveChatWidget as any) && ((window as any).LiveChatWidget as any).call('maximize'),
    [(window as any).LiveChatWidget]
  );

  const isActive = useCallback((route) => {
    if (location.pathname.includes('/user/messages')) {
      return route.includes('/user/messages');
    }

    return location.pathname.includes(route);
  }, [location.pathname]);

  const navItems = currentUser?.id ? [
    {
      route: '/user/search',
      label: currentUser.gender === 'male' ? 'Women' : 'Man',
      icon: currentUser.gender === 'male' ? <WomanIcon /> : <ManIcon />,
      classes: clsx(classes.link, isActive('/user/search') ? classes.active : classes.default),
    },
    {
      route: '/user/messages/inbox',
      label: 'Mail',
      icon: (
        <Badge className={classes.iconBadge} badgeContent={inboxNewMessagesCount} color='primary'>
          <MailIcon />
        </Badge>
      ),
      classes: clsx(classes.link, isActive(`/user/messages/inbox`) ? classes.active : classes.default),
    },
    {
      route: `/dialog/${userProfileId}`,
      label: 'SMS',
      icon: (
        <Badge className={classes.iconBadge} badgeContent={inboxNewDialogCount} color='primary'>
          <MessageIcon />
        </Badge>
      ),
      classes: clsx(classes.link, isActive(`/dialog/${userProfileId}`) ? classes.active : classes.default),
    },
    {
      route: `#`,
      label: 'More',
      icon: <MoreIcon />,
      onClick: (e) => {
        toggleDrawer(e);
      },
      classes: clsx(classes.link, open ? classes.active : classes.default),
    },
  ] : []
  const drawerTopItems = currentUser?.id ? [
    {
      route: '/user/settings',
      label: 'Settings',
      icon: <SettingsIcon />,
      classes: clsx(classes.link, isActive(`/user/settings`) ? classes.active : classes.default),
    },
    {
      route: '/user/payments',
      label: 'Payments',
      icon: <PaymentsIcon />,
      classes: clsx(classes.link, isActive(`/user/payments`) ? classes.active : classes.default),
    },
    {
      route: '/logout',
      label: 'Log Out',
      icon: <LogOutIcon />,
      classes: clsx(classes.link, classes.default),
      onClick: handleLogout,
    },
    {
      route: '/user/blacklist',
      label: 'Blacklist',
      icon: <BlackListIcon />,
      classes: clsx(classes.link, isActive(`/user/blacklist`) ? classes.active : classes.default),
    },
    ...liveChat ? [{
      route: '/liveChat',
      label: 'Live support',
      icon: <LiveChatIcon />,
      classes: clsx(classes.link, isActive(`/liveChat`) ? classes.active : classes.default),
      onClick: openLiveChat
    }] : []
  ] : []
  const drawerBottomItems = currentUser?.id ? [
    {
      route: '/user/news',
      label: 'News',
      icon: <NewsIcon />,
      classes: clsx(classes.link, isActive(`/user/news`) ? classes.active : classes.default),
    },
    {
      route: '/user/notifications',
      label: 'Notifications',
      icon: <NotificationsIcon />,
      classes: clsx(classes.link, isActive(`/user/notifications`) ? classes.active : classes.default),
    },
    ...!isStaff ? [{
      route: '/user/order-history',
      label: 'Order history',
      icon: <HistoryIcon />,
      classes: clsx(classes.link, isActive(`/user/order-history`) ? classes.active : classes.default),
      hidden: true,
    }] : [],
    {
      route: '/user/contacts',
      label: 'Contacts',
      icon: <ContactsIcon />,
      classes: clsx(classes.link, isActive(`/user/contacts`) ? classes.active : classes.default),
    },
    {
      route: '/user/guests',
      label: 'Guests',
      icon: <GuestsIcon />,
      classes: clsx(classes.link, isActive(`/user/guests`) ? classes.active : classes.default),
    },
    {
      route: '/user/gifts/inbox',
      label: 'Gifts',
      icon: (
        <Badge className={classes.iconBadge} badgeContent={inboxNewGiftsCount} color='primary'>
          <GiftsIcon />
        </Badge>
      ),
      classes: clsx(classes.link, isActive(`/user/gifts/inbox`) ? classes.active : classes.default),
    },
    {
      route: `/chat/${userProfileId}`,
      label: 'Chat',
      icon: <ChatIcon />,
      classes: clsx(classes.link, isActive(`/chat/${userProfileId}`) ? classes.active : classes.default),
    },
  ] : []

  const toggleDrawer = (event: any) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpen(!open);
  };

  const handleMenuItemClick = (e, item) => {
    if (item?.onClick) {
      e.preventDefault();
      item.onClick(e);
    } else {
      window.scrollTo(0, 0)
      navigate(item.route);
    }

    if (open) {
      toggleDrawer(e);
    }
  }

  const DrawerContent = () => {
    return (
      <>
        <Grid container rowSpacing={3} sx={{ padding: 2 }}>
          {drawerTopItems.map((item, index) => (
            <Grid className={classes.drawerItemGrid} key={index} item xs={4}>
              <NavLink
                to={item.route}
                className={clsx(item.classes, classes.drawerItem)}
                onClick={(e) => handleMenuItemClick(e, item)}
              >
                <div className={classes.drawerItemIcon}>{item.icon}</div>
                <span className={classes.drawerItemLabel}>{item.label}</span>
              </NavLink>
            </Grid>
          ))}
        </Grid>
        <Grid container rowSpacing={3} sx={{ padding: 2 }}>
          {drawerBottomItems.map((item, index) => (
            <Grid className={classes.drawerItemGrid} key={index} item xs={4}>
              <NavLink
                to={item.route}
                className={clsx(item.classes, classes.drawerItem)}
                onClick={(e) => handleMenuItemClick(e, item)}
              >
                <div className={classes.drawerItemIcon}>{item.icon}</div>
                <span className={classes.drawerItemLabel}>{item.label}</span>
              </NavLink>
            </Grid>
          ))}
        </Grid>
      </>
    )
  }

  return (
    <>
      <SwipeableDrawer
        anchor="bottom"
        className={classes.drawer}
        open={open}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{ keepMounted: true }}
        classes={{
          paper: classes.drawer,
          root: classes.drawerRoot,
        }}
      >
        <Puller />
        <StyledBox sx={{
          px: '24px',
          py: '24px',
          paddingTop: '40px',
          marginBottom: '60px',
          height: '60vh',
          maxHeight: '330px',
          overflow: 'auto'
        }}>
          <DrawerContent />
        </StyledBox>
      </SwipeableDrawer>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 9999 }}>
        <BottomNavigation showLabels>
          {navItems.map((item, index) => (
            <BottomNavigationAction
              key={index}
              className={item.classes}
              label={item.label}
              icon={item.icon}
              onClick={(e) => handleMenuItemClick(e, item)}
            />
          ))}
        </BottomNavigation>
      </Paper>
    </>
  );
}

export default BottomBar;
