import { useAppSelector } from "@/app/hooks";
import { UserProfileDTO } from "@/app/users/users.reducer";
import { UserPhotoType } from "@/app/users/users.types";
import BaseCard from "@/components/base/base-card.component";
import { useContainerStyle, useContainerWidth } from "@/hooks";
import useUserPhotos from "@/hooks/useUserPhotos";
import useUserProfile from "@/hooks/useUserProfile";
import { CircularProgress, Container, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useCallback, useEffect, useMemo } from "react";
import { Gallery, Item } from "react-photoswipe-gallery";
import { useParams } from "react-router-dom";
import { useImageSizesCalculator } from "@/hooks/useImageSizesCalculator";

export function capitalizeFirstLetter(string: string | undefined) {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const useStyles = makeStyles((theme: Theme) => ({
  innerContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    padding: "14px 28px 42px",
  },
  dropzone: {
    cursor: "pointer",
    color: theme.palette.radioSelected.main,
    outline: "none",
    margin: 12,
    borderRadius: 10,
    width: 107,
    height: 131,
    backgroundColor: theme.palette.info.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  innerZone: {
    borderRadius: "inherit",
    width: "100%",
    height: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
  },

  profileSlideWrapper: {
    marginBottom: 24,
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

const AlbumPhotoDirectory = React.memo(() => {
  const width = useContainerWidth();
  const classes = useStyles();

  const windowHeight = useMemo(() => window.innerHeight, []);
  const windowWidth = useMemo(() => window.innerWidth, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, currentProfile] = useUserProfile();

  const { wrapperClass, wrapperOverride } = useContainerStyle();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, type } = useParams<{
    id: string;
    type: UserPhotoType;
  }>();

  const [isLoading, photos] = useUserPhotos();

  const { calculatedImages } = useImageSizesCalculator(photos.data || [])

  const renderContent = useCallback(() => {
    if (isLoading) return <CircularProgress color="inherit" size={24} />;
    if (photos.data.length === 0)
      return <Typography variant="body3">No Photos</Typography>;

    if (calculatedImages.length > 0)
      return (
        <div className={classes.profileSlideWrapper}>

          <Gallery>
            {calculatedImages.map(({ small, origin, id, height, width }) => (
              <div key={small} style={{ position: "relative" }}>
                <Item
                  original={origin}
                  thumbnail={small}
                  width={width}
                  height={height}
                >
                  {({ ref, open }) => (
                    <div className={classes.dropzone}>
                      <div
                        ref={ref as any}
                        onClick={open}
                        className={classes.innerZone}
                        style={{
                          backgroundImage: `url(${small})`,
                        }}
                      ></div>
                    </div>
                  )}
                </Item>
              </div>
            ))}
          </Gallery>
        </div>
      );
  }, [classes?.dropzone, classes?.innerZone, isLoading, calculatedImages, windowHeight, windowWidth, classes?.profileSlideWrapper]);

  const breadcrump = ` / Photos / ${type}`;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container
      maxWidth={width}
      className={wrapperClass}
      style={wrapperOverride}
    >
      <BaseCard wrapperOverrideStyle={{ margin: 0, paddingBottom: 10 }}>
        <div className={classes.innerContainer}>
          <span style={{ textTransform: "capitalize" }}>{`${currentProfile?.profile?.name}`}{breadcrump}</span>
          <Typography
            variant="sliderTitle"
            style={{ marginTop: 24 }}
          >{`All photos in “${capitalizeFirstLetter(type)}” album`}</Typography>

          <div
            style={{
              marginTop: 40,
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            {renderContent()}
          </div>
        </div>
      </BaseCard>
    </Container>
  );
});

export default AlbumPhotoDirectory;
