import { useAppSelector } from '@/app/hooks';
import {
  deleteBookmarksRequest,
  disconnectChat,
  setBookmarksRequest,
  setSendMessageRequest,
  translateMessageSuccess,
  revealPhotoMessageSuccess,
  revealPhotoMessageRequest,
  setVideoInviteAdd,
  revealVideoMessageRequest, setSendVideoMessageRequest,
} from '@/app/users/users.actions';
import BaseTextarea from '@/components/base/base-textarea.component';
import BaseButton from '@/components/base/button.component';
import { calculateAge, getGoogleTranslationKey } from '@/helpers/helpers';
import { validationSchemaChatMessage } from '@/utils/validationSchemas';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Field, Formik } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import EmptyChat from './empty-chat.component';
import ChatMessages from './messages.component';
import UserInfo from './user-info.component';
import ChatUserPhotos from './chat-user-photos.component';
import ActionsButtonsComponent from './actions-buttons.component';
import { useBinarySwitcher } from '@/hooks/useBinarySwitcher';
import { UserProfileDTO } from '@/app/users/users.reducer';
import DialogProfileInfo from './profile-info.dialog.component';
import VideoChat from './video-chat.component';
import { ReactComponent as SendButton } from '@/assets/icons/chats/send.svg';
import { ReactComponent as TranslateButton } from '@/assets/icons/chats/translate.svg';
import { IconButton } from '@mui/material';
import { ReactComponent as AttachFile } from '@/assets/icons/attach.svg';

import { ReactComponent as ChatIcon } from '@/assets/icons/chats/chat.svg';
import { ReactComponent as WebcameraIcon } from '@/assets/icons/chats/webcamera.svg';
import { ReactComponent as PhoneOffIcon } from '@/assets/icons/chats/phone_off.svg';

import { ReactComponent as ArrowLeft } from '@/assets/icons/chats/arrow-left.svg';
import { ReactComponent as ArrowRight } from '@/assets/icons/chats/arrow-right.svg';
import { toggleAvailableUsersBar, toggleLastUsersBar, toggleVideoChatOpen } from '@/app/ui/ui.actions';
import { Peer } from 'peerjs';
import useSound from 'use-sound';
import { StorageService } from '@/services/storage.service';
import messageSound from '@/assets/sounds/massage.mp3';
import { UserRolesEnum } from '../enums/user-roles.enum';
import Socket from '@/services/Socket';
import { BalanceDialog } from './balance-dialog.component';
import ChatSendPhotoOrVideo from './chat-send-photo.component';
import DialogWriteMail from '@/components/dialogs/write-mail.dialog.component';
import VideoInvitations from '@/components/base/video-invitations.component';
import DialogGift from '@/components/dialogs/send-gift.dialog.component';
import ProfilesService from '@/services/profiles.service';
import { ISettings } from '@/types/user-profile.types';
import useBalanceCheckHook from '@/hooks/useBalanceCheckHook';
import StickersUploadButton from "@/components/base/StickersUploadButton";
import StickersContainer from "@/components/base/StickersContainer/StickersContainer";
import { defaultUserPhotosData, IUserPhotosData } from "@/app/users/users.dto";
import PhotosService from "@/services/photos.service";
import { UserPhotoType } from "@/app/users/users.types";

const isLessThanOneMinute = (date) => {
  const difference = new Date().getTime() - new Date(date).getTime();
  return difference < 60 * 1000;
};

const useStyles = makeStyles<Theme, { isOpenVideoChat: boolean }>((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: (props) => (props.isOpenVideoChat ? '28px 0 0 0' : '28px 28px 0 28px'),
    zIndex: '90',
    background: '#FFFFFF',
    borderRadius: '10px 10px 10px 10px',
    boxShadow: '0px 0px 20px rgb(0 0 0 / 5%)',
    textAlign: 'center',
    overflow: 'hidden',
    wordBreak: 'break-word',
    [theme.breakpoints.down('sm')]: {
      padding: '12px',
    },
    height: 'calc(100% - 28px)',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
  },
  image: {},
  name: {},
  chatContainer: {
    height: 'calc(100vh - 424px)',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 200px)',
      position: 'relative',
    },
  },
  chatContainerOpen: {
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 428px)',
      position: 'relative',
    },
  },
  chatDate: {},
  myMessage: {},
  referenceMessage: {},
  smallAvatar: {
    width: '56px',
    height: '56px',
    borderRadius: '60px',
  },
  formTextField: {
    width: 'auto',
    padding: '10px 30px',
  },
  senButton: {
    width: '100%',
    maxWidth: 144,
    marginTop: 32,
  },
  formBtn: {
    minWidth: '120px !important',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: (props) => (props.isOpenVideoChat ? 'calc(100% - 54px)' : '100%'),
    margin: '0 auto',
    minHeight: '40px',
    position: 'relative',
  },
  formActions: {
    gap: 10,
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobileButton: {
    position: 'absolute',
    right: '0',
    bottom: '0'
  },
  mobilePhotoButton: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    '& span': {
      marginRight: '0!important',
      marginLeft: '0!important',
    },
  },
  translateMobile: {
    position: 'absolute',
    right: '7px',
    top: '-10px',
    [theme.breakpoints.down('lg')]: {
      right: '-6px',
    },
  },
  chatMessages: {},
  sendButtonMobile: {
    background: 'none!important',
    paddingLeft: '0!important',
    paddingRight: '0!important',
    boxShadow: 'none!important',
    width: '20px',
    minWidth: '20px',
    height: '20px',
  },
  sendStickerButton: {},
  translateMobileButton: {
    background: 'none!important',
    paddingLeft: '0!important',
    paddingRight: '0!important',
    boxShadow: 'none!important',
    width: '20px',
    minWidth: '20px',
    height: '20px',
    cursor: 'pointer',
  },
  actionsFullScreen: {
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  chats: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down('lg')]: {
      marginTop: (props) => (props.isOpenVideoChat ? '-32px' : '0px'),
    },
  },
  availableChatWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100px',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  lastChatsWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100px',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },

  availableChatBtn: {
    background: '#eee!important',
  },
  lastChatBtn: {
    background: '#eee!important',
  },

  genderChatBtn: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#000000',
    paddingLeft: '12px',
  },
  chatsBtn: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#000000',
    paddingLeft: '12px',
  },
  chatTypeWrapper: {
    '& button': {
      paddingLeft: '0!important',
      paddingRight: '0!important',
    },
  },
  chatsWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '74px',
    background: '#EEEEEE',
    borderRadius: '20px',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  formButton: {
    background: 'none!important',
    paddingLeft: '0!important',
    paddingRight: '0!important',
    boxShadow: 'none!important',
    minWidth: '34px !important',

    '& span': {
      marginRight: '0!important',
      marginLeft: '0!important',
    },
  },
}));

const Chat = () => {
  const { isOpenVideoChat } = useAppSelector((state) => state.ui);
  const classes = useStyles({ isOpenVideoChat });
  let { id, otherId } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();

  const { wallet, videoActive, usersWithActiveTextChat } = useAppSelector((state) => state.users);

  const {
    creditsChatTextMin,
    creditsChatVideoMin,
    creditsChatVideoOpen,
    creditsChatPhotoOpen,
    creditsStickerSend
  } = useAppSelector(
    (state) => state.users.settings
  );

  const [showStickers, setShowStickers] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [publicPhotos, setPublicPhotos] = useState<IUserPhotosData>(defaultUserPhotosData);
  const loadedPhotoForId = useRef('');

  let initialMessage = searchParams.get('message');

  let openVideoFromInvite = searchParams.get('openVideoFromInvite');

  const dispatch = useDispatch();

  const { currentUser, availableUsers, bookmarks } = useAppSelector((state) => state.users);

  const [userInBookmark, setUserInBookmark] = useState(false);

  const [referenceUser, setReferenceUser] = useState(null);
  const [detailedUser, setDetailedUser] = useState(null);
  const [isWriteDialogOpen, openDialog, hideDialog] = useBinarySwitcher();
  const [isGiftDialogOpen, openGiftDialog, hideGiftDialog] = useBinarySwitcher();
  const [isSendPhotoDialogOpen, openSendPhotoDialog, hideSendPhotoDialog] = useBinarySwitcher();
  const [isProfileDialogOpen, openProfileDialog, closeProfileDialog] = useBinarySwitcher();

  const getReferenceUserInfo = useCallback(async () => {
    const data = await ProfilesService.getProfileById(referenceUser?.id);
    setDetailedUser(data);
    openProfileDialog();
  }, [openProfileDialog, referenceUser?.id]);

  const [age, setAge] = useState('');

  const gender = currentUser.profile.gender;

  const toggleVideoChat = useCallback(() => {
    dispatch(toggleVideoChatOpen());
  }, [dispatch]);

  useEffect(() => {
    if (openVideoFromInvite) {
      toggleVideoChat();
    }
  }, [openVideoFromInvite, toggleVideoChat]);

  const disconnectTextChat = useCallback(() => {
    if (Socket && Socket.socket) {
      Socket.emit('chat:close:server', { toProfileId: otherId, data: {} });
    }
    dispatch(disconnectChat(otherId));
  }, [dispatch, otherId]);

  const genderLabel = gender === 'male' ? ' Girl' : 'Man';

  const [isPhotosSliderOpen, setPhotosSliderOpen] = useState(false);

  const togglePhotoHandler = useCallback(() => {
    if (referenceUser && publicPhotos.data.length > 0) {
      setPhotosSliderOpen((state) => !state);
    }
  }, [referenceUser, publicPhotos.data.length]);

  const toggleBookmarkHandler = useCallback(() => {
    if (userInBookmark) {
      dispatch(deleteBookmarksRequest(referenceUser));
      return;
    }
    dispatch(setBookmarksRequest(referenceUser));
  }, [dispatch, referenceUser, userInBookmark]);

  const toggleAvailableDrawer = useCallback(() => {
    dispatch(toggleAvailableUsersBar());
  }, [dispatch]);

  const toggleLastChatDrawer = useCallback(() => {
    dispatch(toggleLastUsersBar());
  }, [dispatch]);

  const loadPhotosData = useCallback(async (id: string) => {
    try {
      loadedPhotoForId.current = id;
      const photos = await PhotosService.getUserPhotos(id, UserPhotoType.PUBLIC_VIEW)
      setPublicPhotos(photos || defaultUserPhotosData)
    } catch {
    }
  }, [])

  useEffect(() => {
    if (referenceUser?.id && loadedPhotoForId.current !== referenceUser?.id) {
      loadPhotosData(referenceUser.id)
    }
  }, [loadPhotosData, referenceUser?.id]);

  useEffect(() => {
    const reference = (availableUsers || []).filter((user) => user.id === otherId);
    if (reference.length) {
      const referenceUser = reference[0];
      setReferenceUser(referenceUser);
      if (referenceUser.birthday) {
        const calcAge = calculateAge(referenceUser.birthday);
        setAge(`, ${calcAge}`);
      } else {
        setAge('');
      }
    }
  }, [availableUsers, loadPhotosData, otherId]);

  useEffect(() => {
    if (!referenceUser || !bookmarks) return;
    const prepareBookMark = bookmarks.map((bookmark: UserProfileDTO) => {
      return bookmark.id;
    });

    setUserInBookmark(prepareBookMark.includes(referenceUser?.id));
  }, [bookmarks, referenceUser]);

  useEffect(() => {
    if (!referenceUser) {
      return;
    }

    const prepareBookMark = bookmarks.map((bookmark: UserProfileDTO) => {
      return bookmark.id;
    });

    const isIsInBookMarks = prepareBookMark.includes(referenceUser?.id);

    if (!isIsInBookMarks && referenceUser.itHasMyMessage && referenceUser.itHasReferenceMessage) {
      dispatch(setBookmarksRequest(referenceUser));
    }
  }, [referenceUser?.itHasMyMessage, referenceUser?.itHasReferenceMessage, referenceUser?.id]);

  const initValues = {
    message: '',
  };

  /**
   * CHAT SECTION
   */
  const [availableConnection, setAvailableConnection] = useState(null);

  const [availablePeer, setAvailablePeer] = useState(null);

  useEffect(() => {
    const peer = new Peer(currentUser?.profile?.id);
    peer.on('open', (id) => {
    });

    peer.on('connection', (dataConnection) => {
      const closeHandler = () => {
        setAvailableConnection(undefined);
      };
      dataConnection.on('close', closeHandler);
      dataConnection.off('close', closeHandler);
      setAvailableConnection(dataConnection);
    });
    peer.off('connection', setAvailableConnection);
    setAvailablePeer(peer);
  }, []);

  const disconnect = useCallback(() => {
    availableConnection.close();
    setAvailableConnection(undefined);
  }, [availableConnection]);

  useEffect(() => {
    if (referenceUser && referenceUser?.id) {
      try {
        const connection = availablePeer.connect(referenceUser?.id);
        connection['caller'] = availablePeer.id;
        setAvailableConnection(connection);
      } catch (error) {
        console.log('error on show other video,', error);
      }
    }
  }, [isOpenVideoChat]);

  const [playbackRate] = React.useState(1);
  const [play] = useSound(messageSound, {
    playbackRate,
    // `interrupt` ensures that if the sound starts again before it's
    // ended, it will truncate it. Otherwise, the sound can overlap.
    interrupt: true,
  });

  const sendMessageSound = () => {
    const isAudioTurnOn = StorageService.getAudio();
    if (isAudioTurnOn) {
      play();
    }
  };

  const isStaff = currentUser.role === UserRolesEnum.STAFF;

  const fetchGoogleTranslation = useCallback(async (value: string, from: string, to: string) => {
    const apiKey = getGoogleTranslationKey();

    const response = await fetch(
      `https://translation.googleapis.com/language/translate/v2?source=${from}&target=${to}&key=${apiKey}&q=${value}&format=text`
    );
    const { data } = await response.json();

    return data.translations[0].translatedText;
  }, []);

  const translateHisText = useCallback(
    async (message) => {
      if (message.translate || !isStaff) {
        return;
      }
      const translate = await fetchGoogleTranslation(message.text, 'en', 'ru');
      dispatch(
        translateMessageSuccess({
          toProfileId: referenceUser?.id,
          date: message.date,
          translate,
        })
      );
    },
    [referenceUser]
  );

  const closeRevealPhotoHandler = useCallback(() => {
  }, []);

  const closeRevealVideoHandler = useCallback(() => {
  }, []);

  const { checkBalance } = useBalanceCheckHook(wallet?.balance || 0);

  const revealPhoto = useCallback(
    async (message) => {
      checkBalance(creditsChatPhotoOpen, () => {
        //if paid
        dispatch(
          revealPhotoMessageRequest({
            toProfileId: referenceUser?.id,
            date: message.date,
            photoId: message.img.photoId,
            closeHandler: closeRevealPhotoHandler,
          })
        );
      });
    },
    [referenceUser]
  );

  const revealVideo = useCallback(
    async (message) => {
      checkBalance(creditsChatVideoOpen, () => {
        //if paid
        dispatch(
          revealVideoMessageRequest({
            toProfileId: referenceUser?.id,
            date: message.date,
            videoId: message.video.videoId,
            closeHandler: closeRevealVideoHandler,
          })
        );
      });
    },
    [referenceUser]
  );

  const translateMyText = useCallback(async (text, form: any) => {
    const translate = await fetchGoogleTranslation(text, 'ru', 'en');
    form.setFieldValue('message', translate);
  }, []);

  useEffect(() => {
    if (Socket && Socket.socket) {
      Socket.on('video:open:client', (data: { profileId: string; name: string; avatar: string }) => {
        dispatch(setVideoInviteAdd({ ...data, date: new Date() }));
      });
    }
  }, []);

  const handleStickerSubmit = useCallback((stickerId, item) => {
    checkBalance(creditsStickerSend, () => {
      if (stickerId) {
        dispatch(
          setSendMessageRequest({
            toProfileId: referenceUser.id,
            text: '',
            isSticker: true,
            sticker: item,
            isStaff,
          })
        );
      }
    });
    setShowStickers(false);
  }, [dispatch, referenceUser?.id]);

  /**
   * END CHAT SECTION
   */
  if (!referenceUser) return <EmptyChat />;

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        {isOpenVideoChat && referenceUser && availablePeer ? (
          <VideoChat
            userId={currentUser?.profile?.id}
            referenceUserId={referenceUser.id}
            availablePeer={availablePeer}
            availableConnection={availableConnection}
          />
        ) : null}
        <UserInfo
          openDialogHandler={getReferenceUserInfo}
          profilePicture={referenceUser.avatar || referenceUser.avatarSmall}
          name={referenceUser.name}
          age={age}
          mailClickHandler={openDialog}
          togglePhotoHandler={togglePhotoHandler}
          isPhotosSliderOpen={isPhotosSliderOpen}
          contactsClickHandler={toggleBookmarkHandler}
          giftClickHandler={openGiftDialog}
          isOpenVideoChat={isOpenVideoChat}
          isOnline={referenceUser.isOnline}
          isPhotosButtonVisible={publicPhotos.data.length > 0}
        />
        <div
          className={classes.chats}
          style={{
            justifyContent: isOpenVideoChat ? 'center' : 'space-between',
          }}
        >
          <div style={{ display: isOpenVideoChat ? 'none' : 'block' }}>
            <div className={classes.availableChatWrapper} onClick={toggleAvailableDrawer}>
              <IconButton className={classes.availableChatBtn}>
                <ArrowRight />
              </IconButton>
              <div className={classes.genderChatBtn}>{genderLabel}</div>
            </div>
          </div>
          <div className={classes.chatsWrapper}>
            <div className={classes.chatTypeWrapper}>
              <BaseButton
                text=''
                inverted={isOpenVideoChat}
                size='small'
                onClick={isOpenVideoChat ? toggleVideoChat : disconnectTextChat}
                Icon={isOpenVideoChat ? <PhoneOffIcon /> : <ChatIcon />}
              />
            </div>
            <div className={classes.chatTypeWrapper}>
              <BaseButton
                text=''
                inverted={!isOpenVideoChat}
                size='small'
                onClick={toggleVideoChat}
                Icon={!isOpenVideoChat ? <WebcameraIcon /> : <PhoneOffIcon />}
              />
            </div>
          </div>
          <div style={{ display: isOpenVideoChat ? 'none' : 'block' }}>
            <div className={classes.lastChatsWrapper} onClick={toggleLastChatDrawer}>
              <IconButton className={classes.lastChatBtn}>
                <ArrowLeft />
              </IconButton>
              <div className={classes.chatsBtn}>Chats</div>
            </div>
          </div>
        </div>
      </div>
      <ChatUserPhotos isPhotosSliderOpen={isPhotosSliderOpen} photos={publicPhotos} />

      <ChatSendPhotoOrVideo
        referenceUser={referenceUser}
        open={isSendPhotoDialogOpen}
        closeHandler={hideSendPhotoDialog}
        isStaff={isStaff}
      />

      <DialogWriteMail receiver={referenceUser} open={isWriteDialogOpen} closeHandler={hideDialog} />
      <DialogGift receiver={referenceUser} open={isGiftDialogOpen} closeHandler={hideGiftDialog} />

      <ActionsButtonsComponent
        mailClickHandler={openDialog}
        togglePhotoHandler={togglePhotoHandler}
        isPhotosSliderOpen={isPhotosSliderOpen}
        contactsClickHandler={toggleBookmarkHandler}
        toggleVideoChat={toggleVideoChat}
        isOpenVideoChat={isOpenVideoChat}
        disconnectTextChat={disconnectTextChat}
        giftClickHandler={openGiftDialog}
        isPhotosButtonVisible={publicPhotos.data.length > 0}
      />

      <ChatMessages
        initialMessage={initialMessage}
        currentUser={currentUser.profile}
        referenceUser={referenceUser}
        isOpenVideoChat={isOpenVideoChat}
        translateHisText={translateHisText}
        revealPhoto={revealPhoto}
        revealVideo={revealVideo}
      />
      <Formik
        initialValues={initValues}
        validationSchema={validationSchemaChatMessage}
        onSubmit={(
          values: {
            message: string;
          },
          { setSubmitting, resetForm }
        ) => {
          if (!values.message) {
            return;
          }
          if (isStaff) {
            setSubmitting(true);
            sendMessageSound();
            dispatch(
              setSendMessageRequest({
                toProfileId: referenceUser.id,
                text: values.message,
              })
            );
            resetForm();
            return;
          }
          if (wallet.balance <= creditsChatVideoMin) {
            const activeUser = usersWithActiveTextChat.find((user) => user.id === referenceUser.id);
            if (
              (activeUser && isLessThanOneMinute(activeUser.date) && wallet.balance <= creditsChatTextMin) ||
              (videoActive.videoProfileId === referenceUser.id &&
                isLessThanOneMinute(videoActive.date) &&
                wallet.balance <= creditsChatVideoMin)
            ) {
              setSubmitting(true);
              sendMessageSound();
              dispatch(
                setSendMessageRequest({
                  toProfileId: referenceUser.id,
                  text: values.message,
                })
              );
              resetForm();
            } else {
              checkBalance(creditsChatTextMin, () => {
                setDialogOpen(true);
              });
            }
          } else {
            if (wallet.balance >= creditsChatTextMin || wallet.balance >= creditsChatVideoMin) {
              setSubmitting(true);
              sendMessageSound();
              dispatch(
                setSendMessageRequest({
                  toProfileId: referenceUser.id,
                  text: values.message,
                })
              );
            }
            resetForm();
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
          <>
            <form
              onSubmit={handleSubmit}
              className={classes.formContainer}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleSubmit();
                }
              }}
            >
              <Field
                name='message'
                value={values.message}
                render={({ field, form }) => (
                  <>
                    <BaseTextarea
                      label='message'
                      placeholder='Message:'
                      onChange={handleChange}
                      className={classes.formTextField}
                    />

                    {isStaff && (
                      <div className={classes.translateMobile}>
                        <TranslateButton
                          className={clsx(classes.translateMobileButton)}
                          onClick={() => translateMyText(values.message, form)}
                        />
                      </div>
                    )}
                  </>
                )}
              />
              <div className={classes.mobilePhotoButton}>
                <BaseButton
                  text=''
                  inverted
                  className={clsx(classes.formButton)}
                  textStyle={classes.attachTextStyle}
                  onClick={openSendPhotoDialog}
                  Icon={<AttachFile />}
                />
              </div>
              <div className={classes.mobileButton}>
                <StickersUploadButton className={classes.sendStickerButton}
                                      onClick={() => setShowStickers(!showStickers)} inputText={values.message} />
                <BaseButton
                  type='submit'
                  text=''
                  className={clsx(classes.formButton)}
                  onClick={() => handleSubmit}
                  Icon={<SendButton />}
                />
              </div>
            </form>
            <StickersContainer onClick={(id, item) => handleStickerSubmit(id, item)}
                               onClose={() => setShowStickers(false)} inputText={values.message}
                               isOpen={showStickers} />
          </>
        )}
      </Formik>

      {detailedUser && (
        <DialogProfileInfo open={isProfileDialogOpen} closeHandler={closeProfileDialog} referenceUser={detailedUser} />
      )}
      <BalanceDialog open={dialogOpen} onClose={() => setDialogOpen(false)} />
      <VideoInvitations />
    </div>
  );
};

export default Chat;
