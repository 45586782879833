import { IUserPhotosData } from "@/app/users/users.dto";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  CircularProgress,
  Dialog,
  Fade,
  IconButton,
  IconButtonProps,
  Slider,
  styled,
  Theme,
  Zoom
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React, { useCallback, useMemo, useState } from "react";
import Dropzone from "react-dropzone";
import { useAppSelector } from "../../app/hooks";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircle from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";

import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";

import { Gallery, Item } from "react-photoswipe-gallery";
import { useImageSizesCalculator } from "@/hooks/useImageSizesCalculator";
import Cropper from "react-easy-crop";
import BaseButton from "@/components/base/button.component";
import { getCroppedImage } from "@/utils/getCroppedImage";
import imageCompression from "browser-image-compression";
import { isIOSPhone } from "@/helpers/constants";

type DialogPhotoFileUploadProps = {
  title?: JSX.Element;
  className: string;
  titleClassName?: string;
  handleFileUploading: (image: FormData | any) => void;
  handleRemoveFile?: (id: string) => void;
  defaultValue: IUserPhotosData;
  isOpen: boolean;
  onClose: () => void;
  handleClickPhoto?: (id: string, small: string, origin: string, openHandler: () => void, onClose: () => void) => void;
  attachedPhotoIds?: Set<string>;
  withCrop?: boolean;
  isLoading?: boolean;
};

type DeleteIconStyledType = React.ComponentType<IconButtonProps>;

export const DeleteIconStyled: DeleteIconStyledType = styled((props) => (
  <IconButton {...props} size="small">
    <DeleteIcon color="inherit" />
  </IconButton>
))(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    right: 10,
    top: 10,
  },

  [theme.breakpoints.down(640)]: {
    right: -5,
    top: -5,
  },
  width: 28,
  height: 28,
  position: "absolute",
  right: -10,
  top: -10,
  backgroundColor: "#DEDEDE",
  color: theme.palette.radioSelected.main,
  "& > .MuiSvgIcon-root": {
    color: "#000",
  },
  ":hover": {
    backgroundColor: theme.palette.grey[400],
  },
}));

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: 20,
    padding: 24,
    [theme.breakpoints.down('lg')]: {
      marginBottom: 40,
    },
  },

  root: {
    border: `2px dashed ${theme.palette.radioSelected.main}`,
    padding: '12px 2px',
    borderRadius: 10,
    flexWrap: "wrap",
    display: "flex",
    flexwrap: "wrap",
    alignContent: "space-between",
    overflowY: 'auto'
  },
  dropzone: {
    cursor: "pointer",
    color: theme.palette.radioSelected.main,
    outline: "none",
    margin: 12,
    borderRadius: 15,
    width: 180,
    height: 180,
    backgroundColor: theme.palette.info.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  innerZone: {
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    borderRadius: "inherit",
    width: "100%",
    height: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
  },
  dropIcon: {
    fontSize: "2.7rem",
  },
  paper: {
    padding: 24,
  },
  cropperPaper: {
    maxWidth: '100% !important'
  },
  loader: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    zIndex: 999,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cropperDialog: {
    [theme.breakpoints.down('lg')]: {
      marginBottom: 40,
    },
  },
  cropperContainer: {
    position: 'relative',
    boxSizing: 'border-box'
  },
  cropperContainerFooter: {
    justifyContent: 'center',
    padding: '3px 16px',
    display: "flex",
    flexDirection: 'column'
  }
}));

type CloseIconStyledType = React.ComponentType<IconButtonProps>;

export const CloseIconStyled: CloseIconStyledType = styled((props) => (
  <IconButton {...props} size="small">
    <CloseIcon color="inherit" />
  </IconButton>
))(({ theme }) => ({
  width: 28,
  height: 28,
  position: "absolute",
  right: -10,
  top: -10,
  backgroundColor: "#DEDEDE",
  color: theme.palette.grey[500],
  "& > .MuiSvgIcon-root": {
    color: "#000",
  },
  ":hover": {
    backgroundColor: theme.palette.grey[400],
  },
}));

const DialogPhotoFileUpload = ({
                                 title,
                                 handleFileUploading,
                                 handleRemoveFile,
                                 className,
                                 titleClassName,
                                 defaultValue,
                                 isOpen,
                                 onClose,
                                 handleClickPhoto,
                                 attachedPhotoIds,
                                 withCrop,
                                 isLoading,
                               }: DialogPhotoFileUploadProps) => {
  const isUploading = useAppSelector((state) => state.users.photosIsUploading);
  const classes = useStyles();

  const { calculatedImages } = useImageSizesCalculator(defaultValue.data || [])

  const [isCroppingLoading, setIsCroppingLoading] = useState<boolean>(false);

  const [cropping, setCropping] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [imageAspectRatio, setImageAspectRatio] = useState(4 / 3);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const handleDrop = useCallback(
    async (acceptedFiles: any) => {
      const file = acceptedFiles[0];

      if (withCrop) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (typeof reader.result === "string") {
            const img = new Image();
            img.src = reader.result;
            img.onload = () => {
              setImageAspectRatio(withCrop ? 0.7 : 4 / 3);
              setImageSrc(reader.result);
              setCropping(true);
            };
          }
        };
      } else {
        const formData = new FormData();
        if (isIOSPhone) {
          try {
            setIsCroppingLoading(true)
            const options = {
              useWebWorker: true,
              alwaysKeepResolution: true,
              maxSizeMB: 20,
            };
            const compressedFile = await imageCompression(file, options);
            formData.append("file", compressedFile);
          } catch (e) {
            console.log(e, 'compression error');
          } finally {
            setIsCroppingLoading(false)
          }
        } else {
          formData.append("file", file);
        }
        handleFileUploading(formData);
        setIsCroppingLoading(false)
      }
    },
    [handleFileUploading, withCrop]
  );

  const cropperContainer = useMemo(() => {
    const containerMaxWidth = window.innerWidth - 64;
    const containerMaxHeight = window.innerHeight - 64 - 85;

    let width = containerMaxWidth;
    let height = width / imageAspectRatio;

    if (height > containerMaxHeight) {
      height = containerMaxHeight;
      width = height * imageAspectRatio;
    }

    return {
      width: `${width}px`,
      height: `${height}px`,
    };
  }, [imageAspectRatio])

  const clearCropper = useCallback(() => {
    setCropping(false);
    setImageSrc(null);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setImageAspectRatio(4 / 3);
    setCroppedAreaPixels(null);
    setIsCroppingLoading(false);
  }, [])

  const handleCropComplete = useCallback(async () => {
    setIsCroppingLoading(true);
    const croppedImage = await getCroppedImage(imageSrc, croppedAreaPixels);
    const formData = new FormData();
    formData.append("file", croppedImage as any);
    handleFileUploading(formData);
    clearCropper();
  }, [croppedAreaPixels, handleFileUploading, imageSrc, clearCropper])

  return (
    <Dialog
      TransitionComponent={Zoom}
      open={isOpen}
      onClose={onClose}
      className={classes.container}
      classes={{
        paper: classes.paper,
      }}
    >
      {(isLoading || isCroppingLoading) &&
          <div className={classes.loader}><CircularProgress color='secondary' style={{ margin: '0 auto' }} /></div>}
      <CloseIconStyled onClick={onClose} />
      {title}
      <Gallery>
        <Dropzone onDrop={handleDrop} accept={["image/jpeg", "image/png", "image/heic"]}>
          {({ getRootProps, getInputProps }) => (
            <section className={clsx(classes.root, className)}>
              <div {...getRootProps()} className={classes.dropzone}>
                <input {...getInputProps()} />
                <AddCircleOutlineIcon
                  fontSize="medium"
                  color="inherit"
                  className={classes.dropIcon}
                />
              </div>
              {isUploading && <Fade in={isUploading}>
                  <div className={classes.dropzone}>
                      <CircularProgress color="inherit" size={24} />
                  </div>
              </Fade>}
              {calculatedImages.map(({ large, origin, id, width, height }) => (
                <div key={large} style={{ position: "relative" }}>
                  <Item
                    original={origin}
                    thumbnail={large}
                    width={width}
                    height={height}
                  >
                    {({ ref, open }) => (
                      <div className={classes.dropzone}>
                        <div
                          ref={ref as any}
                          onClick={
                            () => handleClickPhoto ? handleClickPhoto(id, large, origin, open, onClose) : open()
                          }
                          className={classes.innerZone}
                          style={{
                            backgroundImage: `url(${large})`,
                          }}
                        ></div>
                      </div>
                    )}
                  </Item>
                  {
                    handleRemoveFile ?
                      <div
                        style={{
                          position: "absolute",
                          right: 10,
                          top: 10,
                          zIndex: 1000,
                        }}
                        onClick={() => handleRemoveFile(id)}
                      >
                        <DeleteIconStyled />
                      </div>
                      : null
                  }
                  {
                    !handleRemoveFile && attachedPhotoIds?.has(id) ?
                      <div
                        style={{
                          position: "absolute",
                          right: 5,
                          top: 5,
                          zIndex: 1000,
                        }}
                      >
                        <CheckCircle color="primary" />
                      </div>
                      : null
                  }

                </div>
              ))}
            </section>
          )}
        </Dropzone>
      </Gallery>
      {cropping && (
        <Dialog open={cropping} onClose={clearCropper} className={classes.cropperDialog}
                classes={{ paper: classes.cropperPaper }}>
          <div
            className={classes.cropperContainer}
            style={cropperContainer}
          >
            <Cropper
              image={imageSrc}
              crop={crop}
              zoom={zoom}
              aspect={imageAspectRatio}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={(_, pixels) => setCroppedAreaPixels(pixels)}
            />
          </div>
          <div className={classes.cropperContainerFooter}>
            <Slider min={1} max={3} step={0.1} value={zoom} onChange={(e, z) => setZoom(z as number)} />
            <BaseButton
              variant='contained'
              type='submit'
              text='Crop & Upload'
              onClick={handleCropComplete}
            />
          </div>
        </Dialog>
      )}
    </Dialog>
  );
};

export default DialogPhotoFileUpload;
